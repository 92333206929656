
import axios from 'axios';

import getApiHeader from '../helpers/getApiHeader';

import {CONFIG} from '../helpers/getApiConfig';


const onBoardingFourthStep = async (processType = 4, brandName = ``, tagLine = ``, brandLogo = ``) => {

    const headers = await getApiHeader() || {};;
    
    if(!brandName) {
        return {
            error: true,
            message: 'brandName mandatory in onBoardingFourthStep function' 
        }
    }

    if(!brandLogo) {
        return {
            error: true,
            message: 'brandLogo mandatory in onBoardingFourthStep function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in onBoardingFirstStep function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    let params = {}

    var formData = new FormData();

    if(processType) formData.append("processtype", processType);
    if(brandName) formData.append("name", brandName);
    if(tagLine) formData.append("tagline", tagLine);
    if(brandLogo) formData.append("b_logo", brandLogo);

    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/registerOnboarding`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default onBoardingFourthStep;