
import axios from 'axios';

import getApiHeader from '../helpers/getApiHeader';

import {CONFIG} from '../helpers/getApiConfig';

const onBoardingFirstStep = async (processType = 1, companyName = '', businessType = '', yearOfEstablish = ``, numberOfBrand = ``, companyWebsite = ``, tradeLicenseImage = ``, isImageChange = false) => {

    const headers = await getApiHeader() || {};;

    if(!processType) {
        return {
            error: true,
            message: 'processType mandatory in onBoardingFirstStep function' 
        }
    }

    if(!companyName) {
        return {
            error: true,
            message: 'companyName mandatory in onBoardingFirstStep function' 
        }
    }

    if(!businessType) {
        return {
            error: true,
            message: 'businessType mandatory in onBoardingFirstStep function' 
        }
    }

    if(!yearOfEstablish) {
        return {
            error: true,
            message: 'yearOfEstablish mandatory in onBoardingFirstStep function' 
        }
    }

    if(!numberOfBrand) {
        return {
            error: true,
            message: 'numberOfBrand mandatory in onBoardingFirstStep function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in onBoardingFirstStep function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();
    if(processType) formData.append("processtype", processType);
    if(companyName) formData.append("company_name", companyName);
    if(businessType) formData.append("businesstype", businessType);
    if(yearOfEstablish) formData.append("yoe", yearOfEstablish);
    if(numberOfBrand) formData.append("noe", numberOfBrand);
    if(companyWebsite) formData.append("website_url", companyWebsite);
    if(tradeLicenseImage) formData.append("trade_license", tradeLicenseImage);
    formData.append("trade_license_flag", isImageChange ? 1 : 0);

    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/registerOnboarding`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default onBoardingFirstStep;