import axios from 'axios';

import {CONFIG, getDiscoverrrAPIBase} from '../helpers/getApiConfig';

const tokenRefresh = async (refreshToken) => {

    if(!refreshToken) {
        return {
            error: true,
            message: 'refreshToken mandatory in login function' 
        }
    }

    const instance = axios.create();
    
    instance.defaults.timeout = CONFIG.timeout || 15000;
    
    var formData = new FormData();
    formData.append("refresh", refreshToken)

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/tokenRefresh` , formData)
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default tokenRefresh;