// React related imports
import React, { useState, useEffect } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';

//Redux related imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Other imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Icons related imports
import EditImageIcon from "../../../../icons/svgs/editImageIcon";

//Apis related imports
import onBoardingFourthStep from "../../../../apis/onBoardingFourthStep";
import addBrand from "../../../../apis/Supplier/addBrand";
import brandUpdate from '../../../../apis/Supplier/brandUpdate';

//Components related imports
import Loader from "../../Loader";
import AlertMessage from "../../AlertMessage";

const CreateBrand = (props) => {

    const { firstHeading, secondHeading, buttonText, data, isEditBrandPopup, isOnboardingprocess } = props;
    const [uploadedImagePath, setUploadedImagePath] = useState(data?.b_logo || ``),
        [uploadImage, setUploadImage] = useState(data?.b_logo || ``),
        [tagLine, setTagLine] = useState(data?.tagline || ``),
        [isLoading, setIsLoading] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isAddBrand, setIsAddBrand] = useState(props.isAddBrandPopup || false),
        [editBrandData, setEditBrandData] = useState(props.data || {}),
        [isButtonDisabled, setButtonDisabled] = useState(false);

    const schema = yup
        .object({
            brandName: yup
                .string()
                .trim()
                .required("Persona Name is required")
                .max(60, 'Maximum allowed characters is 60'),
        })
        .required();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
    });

    useEffect(() => {
        if(isButtonDisabled) {
            if(document.getElementById(`add-company-btn`)) {
                document.getElementById(`add-company-btn`).classList.add(`disabled`);
                document.getElementById("add-company-btn").disabled = true;
            }
        } else {
            if(document.getElementById(`add-company-btn`)) {
                document.getElementById(`add-company-btn`).classList.remove(`disabled`);
                document.getElementById("add-company-btn").disabled = false;
            }
        }
    },[isButtonDisabled])

    const onSubmit = (data) => {
        if(!uploadImage) {
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            setAlertMessage(`Persona Logo is required`);
            return;
        }
        setIsLoading(true);
        setButtonDisabled(true);
        if (isAddBrand) {
            addBrand(data[`brandName`], tagLine, uploadImage)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                        // setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("success");
                        setAlertMessage(response.data.message);
                        setTimeout(() => {
                            if (props.onBrandClosePopup) {
                                setButtonDisabled(false);
                                props.onBrandClosePopup(false, false, false);
                            }
                        }, 800);
                    } else {

                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        setButtonDisabled(false);
                        if (response?.message === `Network Error`) {
                            setAlertMessage(`No internet connection`);
                        } else {
                            setAlertMessage(response?.data?.message);
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setButtonDisabled(false);
                    console.log("we have error at addPersona", error);
                })
        } else if (isEditBrandPopup) {
            console.log("Edit product here", tagLine);
            let editedBrandName = ``, editedBrandTagline = '', editedBrandLogo = '';

            if (editBrandData[`name`] !== data['brandName']) {
                editedBrandName = data['brandName'];
            }
            editedBrandTagline = tagLine;
            // if (editBrandData[`tagline`] !== tagLine) {
            //     editedBrandTagline = tagLine;
            // }

            if (editBrandData[`b_logo`] !== uploadedImagePath) {
                editedBrandLogo = uploadImage;
            }

            brandUpdate(editBrandData?.brand_id, editedBrandName, editedBrandTagline, editedBrandLogo, ``,1)
                .then((response) => {
                    if (response && response.status === 200 && response.data.status) {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("success");
                        setAlertMessage(response?.data?.message);
                        setTimeout(() => {
                            if (props.onBrandClosePopup) {
                                setButtonDisabled(false);
                                props.onBrandClosePopup(false, false, false);
                            }
                        }, 800);

                    } else {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        setButtonDisabled(false);
                        if (response?.message === `Network Error`) {
                            setAlertMessage(`No internet connection`);
                        } else {
                            setAlertMessage(response?.data?.message)
                        }
                    }
                })
        } else {
            onBoardingFourthStep(4, data[`brandName`], tagLine, uploadImage)
                .then((response) => {

                    if (response && response.status === 200 && response.data && response.data.data) {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("success");
                        setAlertMessage(response?.data?.message);
                        if (props.onBrandCreateResponse) {
                            setButtonDisabled(false);
                            props.onBrandCreateResponse(`success`);
                        }
                    } else {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        setButtonDisabled(false);
                        if (response?.message === `Network Error`) {
                            setAlertMessage(`No internet connection`);
                        } else {
                            setAlertMessage(response?.data?.message)
                        }
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    setButtonDisabled(false);
                    console.log("we have error at onBoardingFourthStep", err);
                })
        }
    };
    useEffect(() => {
        document.body.classList.add("overflow-hide");

        return () => {
            document.body.classList.remove("overflow-hide");
        }
    }, [])

    const showUploadImage = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (event) => {
            resolve(event.target.result);
        }
        reader.onerror = (error) => {
            reject(error);
        }
    });

    const onImageUpload = async (file) => {
        setUploadImage(file);
        setUploadedImagePath(await showUploadImage(file));
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    return (
        <Box className={`create-brand-wrapper`}>
            {isLoading && <Loader />}

            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className="container-for-firfox">
                <div className={`popup-wrapper`}>
                    {
                        isAddBrand || isEditBrandPopup ? <div className={`close-icon`} onClick={() => { if (props.onBrandClosePopup) props.onBrandClosePopup(false, false, true) }}>
                            <CloseIcon />
                        </div> : <></>
                    }

                    <div className={`first-heading`}>
                        {firstHeading || 'Add another persona'}
                    </div>

                    <div className={`second-heading`}>
                        {secondHeading || 'Start by adding the persona name and logo'}
                    </div>

                    <form className={`form-input-control`} autoComplete="off" onSubmit={handleSubmit(onSubmit)} style={{ position: "relative" }}>

                        <Box className={`form-control margin-bottom-28`}>
                            <Typography className={`label`}>Persona Name <span className="required">*</span> </Typography>
                            <TextField
                                type={"text"}
                                id="brandName"
                                name="brandName"
                                className={`input-wrap`}
                                placeholder={`Enter Persona Name`}
                                margin="dense"
                                {...register("brandName")}
                                error={errors.brandName ? true : false}
                                spellCheck={false}
                                defaultValue={editBrandData?.name || ``}
                                inputProps={{ maxLength: 60 }}
                            />
                            <p className={`field-err-msg`}>{errors.brandName?.message}</p>
                        </Box>
                        <Box className={`form-control brand-logo-section-wrap`}>
                            <Typography className={`label`}>Add Persona Logo</Typography>
                            {
                                uploadedImagePath === ``
                                    ?
                                    <div className={`logo-wrap`} onClick={() => { if (document.getElementById("imageupload")) document.getElementById("imageupload").click() }}>
                                        <div className={`logo flex-column`}>
                                            +Add Logo
                                            <p className={`modal-img-info`}>Image must be at least 160 x 160 px.</p>
                                        </div>
                                        <div className={`edit-icon`}>
                                            <EditImageIcon />
                                        </div>
                                    </div>
                                    :
                                    <div class={`logo-wrap`} onClick={() => { if (document.getElementById("imageupload")) document.getElementById("imageupload").click() }}>
                                        <img src={uploadedImagePath} alt="Persona logo" loading="lazy" />
                                        <div className={`edit-icon`}>
                                            <EditImageIcon />
                                        </div>
                                    </div>
                            }
                            <input className={`visibility-hidden width-0`} type='file' name='imageupload' id='imageupload' onChange={(event) => { onImageUpload(event.target.files[0]) }} accept="image/x-png,image/gif,image/jpeg" multiple />
                        </Box>

                        <Box className={`form-control margin-bottom-28`}>
                            <Typography className={`label`}>Persona Tagline (Optional)</Typography>
                            <TextField
                                type={"text"}
                                id="tagline"
                                name="tagline"
                                className={`input-wrap`}
                                placeholder={`Enter tagline of your Persona`}
                                margin="dense"
                                spellCheck={false}
                                inputProps={{
                                    onChange: (e) => { setTagLine(e.target.value) },
                                    maxLength: 60,
                                }}
                                defaultValue={editBrandData?.tagline || ``}
                            />
                        </Box>

                        <Box className={`hr`} />

                        <Box className={`form-control button-wrap`}>
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                id="add-company-btn"
                                className={`btn-primary-after-login ${isValid && uploadedImagePath !== `` && !isButtonDisabled ? `` : `disabled`}`}
                                disabled={isValid && uploadedImagePath !== `` && !isButtonDisabled ? false : true}
                            >
                                {buttonText || 'Create persona'}
                            </Button>
                        </Box>

                    </form>
                </div>
            </div>
        </Box>
    );
}

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateBrand);
