import  getPageContainerSize  from './getPageContainerSize';

const getAppDataObject = () => {
  return {
    baseUrl: `${window.location.origin}/`,
    isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    pagesContainerSize : getPageContainerSize(),
    isEmailVerificationShowCatalogue : true,
    isTokenRefreshed : false,
    productSelectedBrand : {}
  };
};

export default getAppDataObject;
