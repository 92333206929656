import readCookie from "../../helpers/readCookie";

const readUserToken = () => {
  if (readCookie(`discoverrrToken`)) {
    return {
      "token": {
        "access": readCookie(`discoverrrToken`)
      }
    }
  } else {
    return {}
  }
}

const initialState = {
  user: readUserToken(),
  loading: true,
  error: false,
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case "LOAD_USER":
      return { ...state, user: {}, error: false, loading: true };
    case "GET_USER":
      return state;
    case "SET_USER":
      return { ...state, user: action.payload, error: false, loading: false };
    case "ERROR_USER":
      return { ...state, user: {}, error: true, loading: false };
    default:
      return state;
  }
}
export default userReducer;
