import React, { useState, useEffect } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

//Icons related imports
import ShareProductCopyIcon from '../../../../icons/svgs/shareProductCopyIcon';
import WhatsappIcon from '../../../../icons/svgs/whatsappIcon';

//helper functions related functions
import copyToClipboard from '../../../../helpers/copyToClipboard';

//Components related imports
import AlertMessage from "../../AlertMessage";
import Loader from '../../Loader';
//Apis related imports
import catalogueContactCreate from "../../../../apis/Supplier/catalogueContactCreate";


const ProductShare = (props) => {
    const { onClose, catalogueData } = props;

    const emailRegex = /\S+@\S+\.\S+/;
    const [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isLoading, setIsLoading] = useState(false),
        [inputCount, setInputCount] = useState(1);

    useEffect(() => {
        document.body.classList.add("overflow-hide");

        return () => {
            document.body.classList.remove("overflow-hide");
        }
    }, [])

    const showError = (id, isErrorShow = true) => {
        if (isErrorShow) {
            document.getElementById(`${id}`).style.borderColor = `rgb(211, 47, 47)`;
            document.getElementById(`${id}`).style.outlineColor = `rgb(211, 47, 47)`;
        } else {
            document.getElementById(`${id}`).style.borderColor = ``;
            document.getElementById(`${id}`).style.outlineColor = ``;
        }

    }
    const onSubmit = (event) => {
        event.preventDefault();

        let isError = false;

        let nameInputs = document.getElementsByClassName(`name-input`);
        let emailInputs = document.getElementsByClassName(`email-input`);

        for (let i = 0; i < nameInputs.length; i++) {
            let id = nameInputs[i].id;

            if (document.getElementById(id).value.trim()) {
                document.getElementById(`error-${id}`).classList.add(`visibility-hidden`);
                showError(id, false);
            } else {
                document.getElementById(`error-${id}`).classList.remove(`visibility-hidden`);
                showError(id, true);
                isError = true;
            }
        }

        for (let i = 0; i < emailInputs.length; i++) {
            let id = emailInputs[i].id;

            if (document.getElementById(id).value.trim()) {
                if (!emailRegex.test(document.getElementById(id).value.trim())) {
                    document.getElementById(`error-${id}`).innerText = `Invalid email format`;
                    document.getElementById(`error-${id}`).classList.remove(`visibility-hidden`);
                    showError(id, true);
                    isError = true;
                } else {
                    document.getElementById(`error-${id}`).classList.add(`visibility-hidden`);
                    showError(id, false);
                }

            } else {
                document.getElementById(`error-${id}`).innerText = `email is required`;
                document.getElementById(`error-${id}`).classList.remove(`visibility-hidden`);
                showError(id, true);
                isError = true;
            }
        }

        if (!isError) {
            let inputArray = document.getElementsByClassName(`name-input`);
            let contactArray = [];
            for (let i = 0; i < inputArray.length; i++) {
                let id = inputArray[i].id.split(`name-`)[1];
                contactArray.push({
                    "name": document.getElementById(`name-${id}`).value.trim(),
                    "email": document.getElementById(`email-${id}`).value.trim()
                });
            }

            setIsLoading(true);

            catalogueContactCreate(catalogueData?.catalogue_web_id, contactArray)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status) {
                        setAlternateMessageShow(true);
                        setAlertMessageType("success");
                        setAlertMessage(response?.data?.message);
                        setIsLoading(false);

                        setTimeout(() => {
                            if (onClose) {
                                onClose(false, `success`);
                            }
                        }, 2000);
                    } else {
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        if (response?.message === `Network Error`) {
                            setAlertMessage(`No internet connection`);
                        } else {
                            setAlertMessage(response?.data?.message)
                        }
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    console.log("We have a error at catalogueContactCreate", err);
                    setIsLoading(false);
                })
        }

    }

    const onAddAnotherContact = () => {

        if (inputCount === 5) {
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage("you cannot add more than 5");
        } else {
            setInputCount(inputCount => inputCount + 1);

            let randomNumber = Math.random();
            let randomId = `contact-details-${randomNumber}`;
            let element = ` 
                <div class="form-control position-relative">
                    <input type="text" class="input-wrap name-input" placeholder="Name" id="name-${randomNumber}"/>
                    <p class="field-err-msg visibility-hidden" id="error-name-${randomNumber}">Too short - should be at least 3 characters</p>
                </div>
    
                <div class="form-control position-relative">
                    <input type="text" class="input-wrap email-input" placeholder="eg.johndoe@company.com" id="email-${randomNumber}"/>
                    <p class="field-err-msg visibility-hidden" id="error-email-${randomNumber}">Invalid email format</p>
                </div>
    
                <div class="remove-field" id="remove-field-${randomId}">
                    <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
                </div>
            `
            var div = document.createElement('div');
            div.classList.add('input-wrapper');
            div.id = randomId;
            div.innerHTML = element;
            document.getElementsByClassName(`contact-y-scroll`)[0].append(div);

            if (document.getElementById(`remove-field-${randomId}`)) {
                document.getElementById(`remove-field-${randomId}`).addEventListener('click', () => {
                    removeField(randomId)
                })
            }

            if (document.getElementById(`name-${randomNumber}`)) {
                document.getElementById(`name-${randomNumber}`).addEventListener('input', (event) => {
                    onNameInputChange(event)
                })
            }

            if (document.getElementById(`email-${randomNumber}`)) {
                document.getElementById(`email-${randomNumber}`).addEventListener('input', (event) => {
                    onEmailChange(event)
                })
            }

        }
    }

    const removeField = (id) => {
        setInputCount(inputCount => inputCount - 1);
        document.getElementById(id).remove();
    }

    const onNameInputChange = (event) => {
        let id = event.target.id;
        let value = event.target.value.trim();

        if (value.length < 3 && value) {
            document.getElementById(`error-${id}`).classList.remove(`visibility-hidden`);
            showError(id, true);
        } else if (value === `` && value.length === 0) {
            document.getElementById(`error-${id}`).classList.remove(`visibility-hidden`);
            showError(id, true);
        }
        else {
            document.getElementById(`error-${id}`).classList.add(`visibility-hidden`);
            showError(id, false);
        }
    }

    const onEmailChange = (event) => {

        let id = event.target.id;
        let value = event.target.value.trim();

        if (!emailRegex.test(value) && value) {
            document.getElementById(`error-${id}`).classList.remove(`visibility-hidden`);
            showError(id, true);
            document.getElementById(`error-${id}`).innerText = `Invalid email format`;
        } else if (value === `` && value.length === 0) {
            document.getElementById(`error-${id}`).classList.remove(`visibility-hidden`);
            showError(id, true);
            document.getElementById(`error-${id}`).innerText = `Invalid email format`;
        } else {
            document.getElementById(`error-${id}`).classList.add(`visibility-hidden`);
            showError(id, false);
        }

    }

    const onCopyClick = () => {
        copyToClipboard(catalogueData?.website_url).then((response) => {
            setAlternateMessageShow(true);
            setAlertMessageType("success");
            setAlertMessage("Share link has been copied successfully");
        }).catch((error) => {
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            setAlertMessage("Failed to copy");
        })
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onWhatsappClick = () => {
        copyToClipboard(catalogueData?.website_url).then((response) => {
            // setAlternateMessageShow(true);
            // setAlertMessageType("success");
            // setAlertMessage("Successfully copied");
            // window.open(`https://wa.me/`, '_blank');
            var whatsappweblink = 'whatsapp://send?text=' + catalogueData?.website_url
            window.open(whatsappweblink, '_blank');

        }).catch((error) => {
            // setAlternateMessageShow(true);
            // setAlertMessageType("error");
            // setAlertMessage("Failed to copy");
        })
    }

    return (
        <Box className={`product-share-wrapper`}>

            {isLoading && <Loader />}
            {isAlertMessageShow && (
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            )}
            <div className={`popup-wrapper`}>

                <div className={`close-wrapper w-100 w-90-popup-content`}>
                    <div className={`back-wrap`} onClick={() => { if (onClose) onClose(false) }}>
                        <KeyboardBackspaceIcon /> Back
                    </div>
                    <div className={`close-wrap`} onClick={() => { if (onClose) onClose(false) }}>
                        <CloseIcon />
                    </div>
                </div>

                <div className={`header-wrap w-100 w-90-popup-content`}>
                    <div className={`text`}>
                        Share catalogue with contact
                    </div>

                    <div className={`share-icons-wrap`}>
                        <div className={`icons-shadow`} onClick={() => { onCopyClick() }}>
                            <ShareProductCopyIcon />
                        </div>
                        <div className={`icons-shadow`} onClick={() => { onWhatsappClick() }}>
                            <WhatsappIcon />
                        </div>
                    </div>
                </div>

                <div></div>

                <div className={`copy-link-description w-100 w-90-popup-content`}>
                    <div className={`desc`}>
                        Entering your contact’s email address will enable you to track  when they open your profile and which products they view.
                    </div>
                </div>

                <div className={`divider w-100 w-90-popup-content`}></div>

                <div className={'contact-details-form-wrap w-100'}>
                    <div className={`text w-90-popup-content`}>
                        Contact’s Details
                    </div>

                    <form className={`form-input-control`} onSubmit={onSubmit}>
                        <div className={`contact-y-scroll`}>

                            <div className={`input-wrapper`}>
                                <div className={`form-control position-relative`}>
                                    <input type="text" id="name-1" className={`input-wrap name-input`} placeholder={`Name`} onInput={(event) => onNameInputChange(event)} />
                                    <p class="field-err-msg visibility-hidden" id="error-name-1">{`Too short - should be at least 3 characters`}</p>
                                </div>

                                <div className={`form-control position-relative`}>
                                    <input type="text" id="email-1" className={`input-wrap email-input`} placeholder={`eg. johndoe@company.com`} onInput={(event) => onEmailChange(event)} />
                                    <p class="field-err-msg visibility-hidden" id="error-email-1"></p>
                                </div>

                            </div>
                        </div>

                        {
                            inputCount !== 5 &&
                            <div className={'skip-btn'}>
                                <div className={`add-another-contact-wrap`} onClick={() => { onAddAnotherContact() }}>
                                    <AddIcon />
                                    Add another contact
                                </div>
                            </div>
                        }

                        <div className={`divider w-100 w-90-popup-content`}></div>

                        <Box className={`form-control button-wrap`}>
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                className={`btn-primary-after-login`}
                            >
                                {'Send Email'}
                            </Button>
                        </Box>
                    </form>
                </div>
            </div>
        </Box>
    )
}

export default ProductShare;