import axios from 'axios';

import {CONFIG} from '../helpers/getApiConfig';

const resetPasswordConfirm = async (email = ``, password = ``) => {

    if(!email) {
        return {
            error: true,
            message: 'email mandatory in Reset password' 
        }
    }

    const instance = axios.create();
    
    instance.defaults.timeout = CONFIG.timeout || 15000;
    
    var formData = new FormData();
    formData.append("email", email)
    formData.append("password", password)
    formData.append("confirmpassword", password)

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/resetpasswordconfirm` , formData)
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default resetPasswordConfirm;