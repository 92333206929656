import React from "react";

const camera = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="69" height="55" viewBox="0 0 69 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M68.4439 17.0093C68.4439 27.1122 68.4439 37.2151 68.4439 47.3163C68.4116 47.3716 68.3632 47.4252 68.3506 47.4842C67.4588 51.8811 64.4068 54.3849 59.9174 54.3849C43.0726 54.3867 26.226 54.3885 9.38124 54.3832C4.42362 54.3814 0.95884 50.9345 0.953457 46.0161C0.949869 42.5193 0.951663 39.0207 0.953457 35.5239C0.953457 29.6875 0.930131 23.8511 0.962429 18.0148C0.985754 13.6839 4.20292 10.2674 8.52536 10.0353C10.8023 9.91205 13.0918 9.97099 15.376 9.98885C15.9053 9.99242 16.2031 9.8174 16.483 9.38163C17.5471 7.72609 18.6577 6.09912 19.7415 4.45608C21.4335 1.89329 23.8432 0.641361 26.8953 0.630645C32.1005 0.611 37.3058 0.609214 42.511 0.630645C45.5631 0.643147 47.971 1.89686 49.6631 4.45965C50.7719 6.1402 51.9005 7.80467 52.9969 9.49236C53.2265 9.84597 53.4688 9.98885 53.8976 9.98527C55.961 9.96384 58.0263 9.96563 60.0915 9.97813C63.3158 9.99599 65.835 11.3122 67.4176 14.1411C67.9038 15.0162 68.1102 16.0485 68.4439 17.0093ZM34.6843 47.811C44.6462 47.8503 52.8731 39.7029 52.891 29.7822C52.9089 19.8257 44.7306 11.673 34.7077 11.6569C24.6955 11.6408 16.5315 19.7239 16.51 29.675C16.4866 39.6315 24.6435 47.7717 34.6843 47.811ZM57.7607 16.5753C57.7607 16.5717 57.7607 16.5664 57.7607 16.5628C57.235 16.5628 56.7074 16.5396 56.1817 16.5682C54.8611 16.6396 53.8958 17.5951 53.8438 18.8559C53.7935 20.0453 54.6422 21.124 55.9126 21.2365C57.1273 21.3455 58.369 21.3419 59.5837 21.2294C60.8056 21.1169 61.6202 20.0257 61.552 18.847C61.4803 17.6111 60.5652 16.6575 59.3397 16.5807C58.8139 16.5485 58.2864 16.5753 57.7607 16.5753Z" fill="#CCCCCC" />
            <path d="M34.7746 19.6936C40.4391 19.815 44.932 24.328 44.8298 29.7947C44.7257 35.3614 40.1216 39.8584 34.6256 39.7619C28.9754 39.6637 24.4718 35.1435 24.5704 29.6697C24.6709 24.1137 29.3092 19.5775 34.7746 19.6936Z" fill="#CCCCCC" />
        </svg>
    );
};

export default camera;
