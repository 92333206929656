import React from "react";

//Components realted imports
import EmailVerification from "../EmailVerification";

const InviteResetLink = (props) => {
    return(
        <div>
            <EmailVerification 
                pageName={`inviteResetLink`}
            />
        </div>
    )
}

export default InviteResetLink;