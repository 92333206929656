import React from "react";

const settingIcon = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 7.88091C16 7.56603 16 7.25148 16 6.9366C15.9556 6.63216 15.9148 6.32707 15.866 6.02361C15.7852 5.52177 15.4661 5.21961 14.955 5.15174C13.5635 4.96706 12.7657 3.68699 13.2391 2.39876C13.4135 1.924 13.2952 1.52363 12.8886 1.21527C12.2618 0.739857 11.5776 0.363635 10.8353 0.0885652C10.3508 -0.0912254 9.95728 0.00242234 9.62352 0.382234C8.72749 1.40094 7.26885 1.39931 6.36947 0.378318C6.04072 0.00503276 5.64382 -0.0902465 5.17209 0.0846496C4.37628 0.379297 3.64963 0.789455 2.98779 1.31186C2.5508 1.65643 2.44623 2.00883 2.62631 2.5345C2.79302 3.02134 2.79001 3.50883 2.58287 3.98131C2.28319 4.6636 1.75266 5.07865 1.00229 5.21537C0.489455 5.30869 0.216168 5.57365 0.119281 6.07713C-0.049435 6.95683 -0.0347346 7.83555 0.135318 8.71297C0.217839 9.13846 0.546585 9.44127 0.994268 9.5075C2.35937 9.70948 3.14381 11.0075 2.66139 12.266C2.47864 12.7431 2.58655 13.1356 2.98913 13.4557C3.60453 13.9455 4.27839 14.3387 5.01306 14.6333C5.47477 14.8183 5.90809 14.7113 6.22047 14.3325C7.13989 13.2175 8.69909 13.2391 9.59212 14.3791C9.89948 14.7717 10.3321 14.8908 10.8062 14.7126C11.5947 14.4167 12.3203 14.0147 12.9775 13.4955C13.4272 13.1402 13.5287 12.7633 13.3106 12.2448C13.1104 11.7697 13.0774 11.2842 13.2568 10.7996C13.5621 9.97411 14.1822 9.53263 15.0645 9.41549C15.5179 9.35545 15.837 9.03013 15.9018 8.58865C15.9359 8.35241 15.9673 8.11649 16 7.88091ZM5.33145 7.38461C5.32978 5.9639 6.52783 4.78988 7.98046 4.78792C9.46249 4.78596 10.6649 5.94824 10.6649 7.38297C10.6649 8.82815 9.47619 9.99336 7.99917 9.9963C6.52783 9.99891 5.33312 8.82946 5.33145 7.38461Z" fill="#3357FF"/>
        </svg>        
    );
};

export default settingIcon;
