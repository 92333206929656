
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const getShelfLifeList = async () => {

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    const headers = await getApiHeader() || {};;
    
    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/master/shelfLife`,{headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default getShelfLifeList