
import axios from 'axios';

import { CONFIG } from '../../helpers/getApiConfig';

import getApiHeader from '../../helpers/getApiHeader';

const getOurTeamInviteResend = async (email) => {

    if (!email) {
        return {
            error: true,
            message: 'email mandatory in getOurTeamInviteResend function'
        }
    }

    const headers = await await getApiHeader() || {};

    const instance = axios.create();

    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();
    if (email) formData.append("email", email);

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/email-new-link`, formData, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default getOurTeamInviteResend