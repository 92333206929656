const initialState = {
    industryList: {},
    loading: true,
    error: false,
  };
  
  function industryListReducer(state = initialState, action) {
    switch (action.type) {
      case "LOAD_INDUSTRY_LIST":
        return { ...state, industryList: {}, error: false, loading: true };
      case "GET_INDUSTRY_LIST":
        return state;
      case "SET_INDUSTRY_LIST":
        return { ...state, industryList: action.payload, error: false, loading: false };
      case "ERROR_INDUSTRY_LIST":
        return { ...state, industryList: {}, error: true, loading: false };
      default:
        return state;
    }
  }
  export default industryListReducer;
  