import React, { useEffect, useState } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Components related imports
import Accordion from "../../Accordion";

//React-router-dom related imports
import { useNavigate } from 'react-router-dom';

const LeftPanel = (props) => {
    const { onMenuChange,
        accountSettingsData,
        companySettingsData,
        defaultAccordionType,
        defaultSelectedMenu,
        isCustomizationTabShow,
        permission,
        isAllTabClose,
        onAccordionChange
    } = props;
    const [selectedAccordionType, setSelectedAccordionType] = useState(defaultAccordionType || ``),
        [selectedMenu, setSelectedMenu] = useState(defaultSelectedMenu || ``),
        [permissions, setPermission] = useState(permission || []);

    const navigate = useNavigate();

    useEffect(() => {
        setPermission(permission);
    }, permissions)
    const onLeftNavigationMenuChange = (value) => {
        setSelectedMenu(value);
        if (onMenuChange) onMenuChange(value);
    }

    const onAccordionTypeChange = (type) => {
        setSelectedAccordionType(type);
        if(onAccordionChange) {
            onAccordionChange(false);
        }
    }

    const onBackClick = () => {


        if (permissions?.[0]?.product_auth_type === `5`) {
            navigate('/catalogues');
        } else if (permissions?.[0]?.catalogue_auth_type === `5`) {
            navigate('/myproducts');
        } else {
            navigate('/myproducts');
        }
    }

    return (
        <Box className={`left-panel`}>

            <div className={`back-icon`} onClick={() => { onBackClick() }}>
                <ArrowBackIcon />
            </div>
            <Accordion title={`Account Settings`}
                data={accountSettingsData}
                isOpen={isAllTabClose ? false : selectedAccordionType === "account" ? true : false} // Uncomment this section if we want to open single Accordion at a time and remove below isOpen props
                // isOpen={true}
                onMenuChange={onLeftNavigationMenuChange}
                type={"account"}
                onTypeChange={onAccordionTypeChange}
                selectedValue={selectedMenu}
            />
            {
                isCustomizationTabShow &&
                <Accordion
                    title={`Customization`}
                    data={companySettingsData}
                    isOpen={isAllTabClose ? false : selectedAccordionType === "product" ? true : false} // Uncomment this section if we want to open single Accordion at a time and remove below isOpen props
                    // isOpen={true}
                    onMenuChange={onLeftNavigationMenuChange}
                    type={"product"}
                    onTypeChange={onAccordionTypeChange}
                    selectedValue={selectedMenu}
                />
            }

        </Box>
    )
}

export default LeftPanel;