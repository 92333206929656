const setSupplierBrandList = (brandListObject) => {
    return {
        type: "SET_SUPPLIER_BRAND_LIST",
        payload: brandListObject
    }
}

const getSupplierBrandList = () => {
    return {
        type: "GET_SUPPLIER_BRAND_LIST"
    }
}


export default {
    setSupplierBrandList,
    getSupplierBrandList
}