const initialState = {
    supplierBrandList: {},
    loading: true,
    error: false,
  };
  
  function supplierBrandListReducer(state = initialState, action) {
    switch (action.type) {
      case "LOAD_SUPPLIER_BRAND_LIST":
        return { ...state, supplierBrandList: {}, error: false, loading: true };
      case "GET_SUPPLIER_BRAND_LIST":
        return state;
      case "SET_SUPPLIER_BRAND_LIST":
        return { ...state, supplierBrandList: action.payload, error: false, loading: false };
      case "ERROR_SUPPLIER_BRAND_LIST":
        return { ...state, supplierBrandList: {}, error: true, loading: false };
      default:
        return state;
    }
  }
  export default supplierBrandListReducer;
  