// React related imports
import React, {useState} from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//Redux related imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import getAppDataObject from '../../../helpers/getAppDataObject';

// Icons related imports
import Facebook from "../../../icons/svgs/facebook";
import Instagram from "../../../icons/svgs/instagram";
import Twitter from "../../../icons/svgs/twitter";
import LinkedIn from "../../../icons/svgs/linkedIn";
import Copy from "../../../icons/svgs/copy";

//Helpers related imports
import copyToClipboard from "../../../helpers/copyToClipboard";

//Components related imports
import AlertMessage from "../AlertMessage";

//Style related imports
import "./style.scss";

const PreLaunchEmailSuccess = (props) => {

  const RegisterUrl = getAppDataObject().baseUrl + "register";

  const [isAlertMessageShow, setAlternateMessageShow] = useState(false),
    [alertMessageType, setAlertMessageType] = useState(``),
    [alertMessage, setAlertMessage] = useState(``);

  const copyText = (text) => {
    copyToClipboard(text).then((response) => {
      setAlternateMessageShow(true);
      setAlertMessageType("success");
      setAlertMessage("Successfully copied");
    }).catch((error) => {
      setAlternateMessageShow(true);
      setAlertMessageType("error");
      setAlertMessage("Failed to copy");
    })
  }

  const onAlertClose = (value) => {
    setAlternateMessageShow(value);
  };

  return (
    <Box className={`pre-launch-success-email-wrap site-wrap`}>
      {isAlertMessageShow && (
        <AlertMessage
          message={alertMessage}
          alertType={alertMessageType}
          isAlertShow={isAlertMessageShow}
          onClose={onAlertClose}
        />
      )}
      <Typography align="center" variant="h1" className={`first-heading`}>
        Thank you!
      </Typography>
      <Typography align="center" variant="h2" className={`second-heading`}>
        For choosing Discoverrr. We’ll notify you by email when we launch.
      </Typography>

      <Box className={`recommended-section`}>
        <Typography align="center" variant="h2" className={`sooner-text`}>
          Want to get in sooner?
        </Typography>

        <Typography align="center" variant="h2" className={`second-text`}>
          Recommend Discoverrr to your network
          <div className={`flex flex-justify-content-center flex-row`}>
            <span> using this link:</span>
            <a className={`icon-copy skip-btn`} href={RegisterUrl}>
            {RegisterUrl} <span onClick={(e) => { e.preventDefault(); copyText(RegisterUrl) }}><Copy /></span>
          </a>{" "}
          </div>
          or the icons below.
        </Typography>

        <Box className={`social-icons`}>
          <div className={`ld`}>
            <a href={`https://www.linkedin.com/company/discoverrr/`} target="_blank">
              <LinkedIn />
            </a>
          </div>

          <div className={`fb`}>
            <a href={`https://www.facebook.com/Discoverrrcatalog`} target="_blank">
              <Facebook />
            </a>
          </div>

          {/* <div>
            <a href="#" target="_blank">
              <Twitter />
            </a>
          </div> */}

          <div className={`insta`}>
            <a href={`https://www.instagram.com/discoverrr_/?hl=en`} target="_blank">
              <Instagram />
            </a>
          </div>
        </Box>
      </Box>

      <Typography align="center" variant="h4" className={`disclaimer`}>
        In the meantime, you can reach out to us on{" "}
        <a href={`mailto:contact@discoverrr.com`} className={`skip-btn`}>contact@discoverrr.com</a> for any
        questions or suggestions.
      </Typography>
    </Box>
  );
};

//map state
const mapStateToProps = (state) => {
  return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreLaunchEmailSuccess);
