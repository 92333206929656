
import axios from 'axios';

import { CONFIG } from '../../helpers/getApiConfig';

import getApiHeader from '../../helpers/getApiHeader';

const getOurTeamDelete = async (id, action) => {

    if (!id) {
        return {
            error: true,
            message: 'id mandatory in getOurTeamDelete function'
        }
    }

    const headers = await await getApiHeader() || {};

    const instance = axios.create();

    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();
    if (action) formData.append("action", action.toUpperCase());
    
    return instance.delete(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/our-teams-action/${id}`, formData, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default getOurTeamDelete