const setUserDetail = (userObj) => {
    return {
        type: "SET_USERDETAIL",
        payload: userObj
    }
}

const getUserDetail = () => {
    return {
        type: "GET_USERDETAIL"
    }
}

const setUserPagiantion = (paginationObject) => {
    return {
        type: "SET_PAGINATION",
        payload: paginationObject
    }
}


export default {
    setUserDetail,
    getUserDetail,
    setUserPagiantion
}