import React, { useState, useEffect, Fragment } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//import other component related imports
import AlertMessage from "../../../../AlertMessage";
import TrashIcon from '../../../../../../icons/svgs/trashIcon';
import ConformationPopup from "../../../ConformationPopup";

//Api related imports
import getBrandList from '../../../../../../apis/Supplier/getBrandList';
import brandDelete from '../../../../../../apis/Supplier/brandDelete';

const CompanySection = (props) => {

    const [companyList, setcompanyList] = useState([]);
    const [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isLoading, setIsLoading] = useState(false),
        [isConfirmationPopupShow, setIsConfirmationPopupShow] = useState(false),
        [conformationPopupSuccessBtnText, setConformationPopupSuccessBtnText] = useState(``),
        [conformationPopupCancelBtnText, setConformationPopupCancelBtnText] = useState(``),
        [conformationPopupText, setConformationPopText] = useState(``),
        [conformationPopupType, setConformationType] = useState(``);

    useEffect(() => {
        setIsLoading(true);
        getBrandListData()
    }, []);


    const getBrandListData = () => {
        setIsLoading(true);
        getBrandList()
            .then((response) => {
                if (
                    response &&
                    response.status === 200 &&
                    response.data.status &&
                    response.data.data &&
                    response.data.data.length > 0
                ) {
                    setIsLoading(false);
                    setcompanyList(response.data.data);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("We have error", error);
            });
    }

    const ondeleteClick = (e, value) => {

        setIsConfirmationPopupShow(true);
        setConformationPopText(`Are you sure you want to delete this persona?`);
        setConformationPopupSuccessBtnText(`Yes`);
        setConformationPopupCancelBtnText(`No`);
        setConformationType(value);
    }

    const onConformationPopupClose = (value) => {
        setIsConfirmationPopupShow(value);
    }
    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };
    const onConformationPopupSuccess = () => {
        setIsConfirmationPopupShow(false);
        brandDelete(conformationPopupType)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        getBrandListData()
                    }, 500);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((err) => {
                console.log("we have error on Product Action", err);
            })
    }

    return (
        <Fragment>

            <Box className={`company-section-wrapper`}>
                {
                    isConfirmationPopupShow &&
                    <ConformationPopup
                        heading={conformationPopupText}
                        cancelBtnText={conformationPopupCancelBtnText}
                        submitBtnText={conformationPopupSuccessBtnText}
                        onCancel={onConformationPopupClose}
                        onSubmit={onConformationPopupSuccess}

                    />
                }
                {isAlertMessageShow &&
                    <AlertMessage
                        message={alertMessage}
                        alertType={alertMessageType}
                        isAlertShow={isAlertMessageShow}
                        onClose={onAlertClose}
                    />
                }
                <div className={`heading-wrap m-mt-20`}>
                    <div className={`heading`}>
                        My Persona List
                    </div>

                    {/* <div className={`close-icon`}>
                        <CloseIcon />
                    </div> */}
                </div>

                <div className={`company-display-wrap product-table table-scroll-wrap`}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={`table-heading sixtyp`}>Name</TableCell>
                                    <TableCell className={`table-heading twentyp`} align="left">Number of Products</TableCell>
                                    <TableCell className={`table-heading twentyp`} align="left">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    companyList && companyList.length > 0 &&
                                    companyList.map((company, index) => {
                                        return (
                                            <Fragment key={index} >
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" className={`category-name`}>
                                                        {
                                                            company.b_logo
                                                                ?
                                                                <div className={`image-wrap`}>
                                                                    <img src={company.b_logo} alt="Product image" loading="lazy" />
                                                                </div>
                                                                :
                                                                <div className={`image-wrap`}>
                                                                    <div className={`pro-image`}>
                                                                    </div>
                                                                </div>
                                                        }
                                                        {company.name}
                                                    </TableCell>
                                                    <TableCell align="left" className={`category-count`}>{company.product_count}</TableCell>
                                                    <TableCell align="left">
                                                        <div className={`category-icons-wrap`}>
                                                            {/* <div className={`edit-icon`}>
                                                edit
                                            </div> */}
                                                            {
                                                                companyList.length > 1 &&
                                                                <div className={`delete-icon`} onClick={(e) => { ondeleteClick(e, company.brand_id) }} tooltip="Delete">
                                                                    <TrashIcon />
                                                                </div>
                                                            }

                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </Fragment>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>
        </Fragment>
    )
}

export default CompanySection;