import React from "react";

const copy = (props) => {
  const { name = "", size = "s", color = "#fff", className = "" } = props;

  return (
  <svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.6 0H6.91765C5.59426 0 4.51765 1.43548 4.51765 3.2V4.51765H2.4C1.07661 4.51765 0 5.95313 0 7.71765V28.8C0 30.5645 1.07661 32 2.4 32H18.2118C19.5352 32 20.6118 30.5645 20.6118 28.8V25.9765H21.6C22.9234 25.9765 24 24.541 24 22.7765V3.2C24 1.43548 22.9234 0 21.6 0ZM18.2118 30.1176H2.4C1.85506 30.1176 1.41176 29.5266 1.41176 28.8V7.71765C1.41176 6.99106 1.85506 6.4 2.4 6.4H18.2118C18.7567 6.4 19.2 6.99106 19.2 7.71765V28.8C19.2 29.5266 18.7567 30.1176 18.2118 30.1176ZM21.6 24.0941H20.6118V7.71765C20.6118 5.95313 19.5352 4.51765 18.2118 4.51765H5.92941V3.2C5.92941 2.47341 6.37271 1.88235 6.91765 1.88235H21.6C22.1449 1.88235 22.5882 2.47341 22.5882 3.2V22.7765C22.5882 23.5031 22.1449 24.0941 21.6 24.0941Z" fill="#3357FF"/>
    <path d="M15.3543 10.5703C14.8162 9.85237 14.1035 9.44992 13.3479 9.43788C13.3361 9.43788 13.3239 9.4375 13.3121 9.4375C12.5664 9.4375 11.8701 9.81924 11.3477 10.515C11.3466 10.5165 11.3455 10.518 11.3443 10.5195L8.34886 14.678C8.29917 14.7469 8.25794 14.8248 8.22632 14.9099C7.79827 15.6365 7.59074 16.5415 7.64157 17.4616C7.68985 18.3926 7.98971 19.2679 8.48609 19.926C8.61936 20.1025 8.79611 20.1997 8.98387 20.1997C9.17333 20.1997 9.35093 20.101 9.48449 19.9222C9.61747 19.7438 9.69032 19.5073 9.68976 19.2559C9.68919 19.0044 9.61522 18.7683 9.48166 18.591C9.22811 18.2548 9.07479 17.8064 9.04994 17.3272C9.02086 16.8009 9.1643 16.2847 9.44354 15.9105C9.48477 15.8556 9.52034 15.7938 9.54999 15.7276L12.3501 11.8405C12.8939 11.123 13.7932 11.1497 14.3565 11.9011C14.6329 12.2693 14.7882 12.7535 14.7933 13.2643C14.7984 13.7658 14.6575 14.2337 14.3963 14.582C14.3949 14.5839 14.3935 14.5857 14.3921 14.5876L13.3042 16.1236C13.0371 16.5008 13.05 17.0979 13.333 17.4541C13.4645 17.6197 13.6365 17.7108 13.8175 17.7108C14.0109 17.7108 14.1981 17.6031 14.3308 17.4157L15.4029 15.9015C15.9303 15.1926 16.2152 14.2473 16.2051 13.2383C16.1946 12.2347 15.8925 11.2867 15.3543 10.5703Z" fill="#3357FF"/>
    <path d="M11.5505 16.7359C11.4172 16.5582 11.2399 16.46 11.0513 16.46C10.863 16.46 10.6857 16.5578 10.5524 16.7355C10.4191 16.9132 10.3454 17.1497 10.3454 17.4011C10.3454 17.6526 10.4188 17.889 10.5521 18.0667C10.8285 18.4353 10.9835 18.9202 10.9886 19.4314C10.9937 19.9265 10.8562 20.3888 10.6018 20.7348L7.70007 24.2469C7.69216 24.2567 7.68426 24.2665 7.67607 24.2774C7.41546 24.6249 7.06506 24.8131 6.68811 24.8063C6.30468 24.7995 5.94157 24.5929 5.66543 24.2247C5.39861 23.8689 5.2467 23.3501 5.24868 22.8012C5.25065 22.2572 5.40115 21.746 5.66148 21.3974C5.80717 21.2095 6.29451 20.5797 6.5492 20.2401C6.68247 20.0624 6.7556 19.826 6.7556 19.5745C6.7556 19.323 6.68218 19.0866 6.54891 18.9089C6.27362 18.5422 5.82609 18.5422 5.5508 18.9089C5.27183 19.2809 4.68058 20.0436 4.67522 20.0504C4.67466 20.0511 4.67381 20.0522 4.67324 20.053L4.6676 20.0605C4.14355 20.7581 3.84087 21.7539 3.8372 22.7918C3.83324 23.8395 4.13593 24.847 4.66731 25.5559C5.2052 26.273 5.91616 26.6751 6.66948 26.6883C6.68218 26.6887 6.69517 26.6887 6.70788 26.6887C7.44708 26.6887 8.14054 26.3111 8.66148 25.6255L11.5669 22.1089C11.5711 22.104 11.5751 22.0987 11.5787 22.0938C11.5824 22.0893 11.5861 22.0844 11.5903 22.0788C12.1228 21.3695 12.4103 20.4201 12.4001 19.4058C12.3899 18.4014 12.0884 17.4531 11.5505 16.7359Z" fill="#3357FF"/>
  </svg>
  );
};

export default copy;
