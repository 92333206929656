import React from "react";

const shareProductCopyIcon = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="25" height="33" viewBox="0 0 25 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1412_3073)">
                <path d="M21.9359 0.460938H7.25358C5.9302 0.460938 4.85358 1.89642 4.85358 3.66094V4.97858H2.73594C1.41255 4.97858 0.335938 6.41407 0.335938 8.17858V29.2609C0.335938 31.0255 1.41255 32.4609 2.73594 32.4609H18.5477C19.8711 32.4609 20.9477 31.0255 20.9477 29.2609V26.4374H21.9359C23.2593 26.4374 24.3359 25.0019 24.3359 23.2374V3.66094C24.3359 1.89642 23.2593 0.460938 21.9359 0.460938ZM18.5477 30.5786H2.73594C2.191 30.5786 1.7477 29.9875 1.7477 29.2609V8.17858C1.7477 7.452 2.191 6.86094 2.73594 6.86094H18.5477C19.0926 6.86094 19.5359 7.452 19.5359 8.17858V29.2609C19.5359 29.9875 19.0926 30.5786 18.5477 30.5786ZM21.9359 24.5551H20.9477V8.17858C20.9477 6.41407 19.8711 4.97858 18.5477 4.97858H6.26535V3.66094C6.26535 2.93435 6.70864 2.34329 7.25358 2.34329H21.9359C22.4809 2.34329 22.9242 2.93435 22.9242 3.66094V23.2374C22.9242 23.964 22.4809 24.5551 21.9359 24.5551Z" fill="#3357FF" />
                <path d="M15.69 11.0312C15.1519 10.3133 14.4392 9.91086 13.6836 9.89881C13.6718 9.89881 13.6596 9.89844 13.6478 9.89844C12.9021 9.89844 12.2058 10.2802 11.6834 10.9759C11.6823 10.9774 11.6812 10.9789 11.68 10.9804L8.68456 15.1389C8.63486 15.2078 8.59364 15.2857 8.56201 15.3708C8.13397 16.0974 7.92644 17.0024 7.97726 17.9225C8.02554 18.8535 8.3254 19.7288 8.82178 20.3869C8.95505 20.5635 9.1318 20.6606 9.31957 20.6606C9.50903 20.6606 9.68663 20.562 9.82018 20.3831C9.95317 20.2047 10.026 19.9683 10.0254 19.7168C10.0249 19.4653 9.95091 19.2293 9.81736 19.0519C9.5638 18.7158 9.41049 18.2674 9.38564 17.7881C9.35656 17.2618 9.49999 16.7457 9.77924 16.3715C9.82046 16.3165 9.85604 16.2548 9.88568 16.1885L12.6858 12.3014C13.2296 11.5839 14.1289 11.6106 14.6922 12.3621C14.9686 12.7302 15.1239 13.2144 15.129 13.7253C15.1341 14.2267 14.9932 14.6947 14.732 15.0429C14.7306 15.0448 14.7292 15.0467 14.7278 15.0486L13.6398 16.5846C13.3727 16.9618 13.3857 17.5589 13.6686 17.915C13.8002 18.0806 13.9722 18.1718 14.1532 18.1718C14.3466 18.1718 14.5338 18.0641 14.6665 17.8766L15.7386 16.3624C16.266 15.6535 16.5509 14.7082 16.5407 13.6993C16.5303 12.6956 16.2282 11.7477 15.69 11.0312Z" fill="#3357FF" />
                <path d="M11.8862 17.1978C11.7529 17.0201 11.5756 16.9219 11.387 16.9219C11.1987 16.9219 11.0214 17.0198 10.8881 17.1975C10.7548 17.3751 10.6811 17.6116 10.6811 17.8631C10.6811 18.1145 10.7545 18.351 10.8878 18.5287C11.1642 18.8972 11.3192 19.3821 11.3243 19.8934C11.3294 20.3884 11.1919 20.8507 10.9375 21.1967L8.03576 24.7088C8.02785 24.7186 8.01995 24.7284 8.01176 24.7393C7.75115 25.0868 7.40075 25.275 7.02381 25.2682C6.64037 25.2615 6.27727 25.0548 6.00113 24.6866C5.7343 24.3308 5.5824 23.812 5.58437 23.2631C5.58635 22.7191 5.73684 22.2079 5.99717 21.8593C6.14287 21.6714 6.63021 21.0416 6.88489 20.702C7.01816 20.5243 7.09129 20.2879 7.09129 20.0364C7.09129 19.7849 7.01788 19.5485 6.88461 19.3708C6.60931 19.0041 6.16178 19.0041 5.88649 19.3708C5.60753 19.7428 5.01628 20.5055 5.01091 20.5123C5.01035 20.513 5.0095 20.5142 5.00894 20.5149L5.00329 20.5224C4.47924 21.22 4.17656 22.2158 4.17289 23.2537C4.16894 24.3015 4.47162 25.3089 5.00301 26.0178C5.54089 26.735 6.25185 27.137 7.00517 27.1502C7.01788 27.1506 7.03087 27.1506 7.04357 27.1506C7.78277 27.1506 8.47623 26.773 8.99717 26.0874L11.9026 22.5708C11.9068 22.5659 11.9108 22.5607 11.9144 22.5558C11.9181 22.5512 11.9218 22.5463 11.926 22.5407C12.4585 21.8314 12.746 20.882 12.7358 19.8678C12.7256 18.8633 12.4241 17.915 11.8862 17.1978Z" fill="#3357FF" />
            </g>
            <defs>
                <clipPath id="clip0_1412_3073">
                    <rect width="24" height="32" fill="white" transform="translate(0.335938 0.460938)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default shareProductCopyIcon;
