import React, { useState } from 'react';

//Redux related imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// mui or UI related imports
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const ProcessVideo = (props) => {

    const [autoPlayValue, setAutoPlayValue] = useState(0);
    return (
        <Box className={`process-video--wrapper`}>
            <div className={`popup-wrapper`}>
                <div className={`first-heading`}>
                    Congrats, first persona created.
                </div>

                <div className={`second-heading`}>
                    Let’s watch a short video to get you started.
                </div>

                <div className={`video`}>
                    <iframe title="vimeo-player" src={`https://player.vimeo.com/video/757908506?h=3d255650b3`} id="player" frameBorder="0" allowfullscreen></iframe>            
                </div>

                <Box className={`hr`} />

                <div className={`button-wrapper`}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" className={`btn-secondary-after-login`} onClick={() => { if(props.onPopupClose) { props.onPopupClose(false);}}}>
                            No Thanks
                        </Button>
                        {/* <Button variant="contained" className={`btn-primary-after-login`} onClick={() => { setAutoPlayValue(1) }}>
                            Play Video
                        </Button> */}
                    </Stack>
                </div>
            </div>
        </Box>
    )
}


//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessVideo);