const setYearOfEstablish = (yearOfEstablishObj) => {
    return {
        type: "SET_YEAR_OF_ESTABLISH",
        payload: yearOfEstablishObj
    }
}

const getYearOfEstablish = () => {
    return {
        type: "GET_YEAR_OF_ESTABLISH",
    }
}


export default {
    setYearOfEstablish,
    getYearOfEstablish
}