import React, { useEffect, useState, Fragment } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from "@mui/material/Button";
import LinearProgress from '@mui/material/LinearProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

//Icons related imports
import HelpQuestionIcon from '../../../../icons/svgs/helpQuestionIcon';
import SettingIcon from '../../../../icons/svgs/settingIcon';

// React route related imports
import { useNavigate, useParams } from "react-router-dom";

//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import editProductAction from '../../../../store/action/supplier/editProductAction';

//Apis related imports
import getBarcodeList from '../../../../apis/Supplier/getBarcodeList';
import getVolumeList from '../../../../apis/Supplier/getVolumeList';
import getPackVolumeWeight from '../../../../apis/Supplier/getPackVolumeWeight';
import getCurrencyList from '../../../../apis/Supplier/getCurrencyList';
import getCountryList from '../../../../apis/getCountryList';
import getProductDetails from '../../../../apis/Supplier/getProductDetails';
import getProductDimensionList from '../../../../apis/Supplier/getProductDimensionList';
import getProductWeightList from '../../../../apis/Supplier/getProductWeightList';
import getProductSetting from '../../../../apis/Supplier/getProductSetting';
import getProductPageSetting from '../../../../apis/Supplier/getProductPageSetting';

//Helpers function related imports
import getDefaultCountry from '../../../../helpers/getDefaultCountry';
import getDefaultBarcodeType from '../../../../helpers/getDefaultBarcodeType';
import getDefaultPackVolumeWeight from '../../../../helpers/getDefaultPackVolumeWeight';
import getDefaultCurrency from '../../../../helpers/getDefaultCurrency';
import getDefaultVolume from '../../../../helpers/getDefaultVolume';
import getDefaultWeight from '../../../../helpers/getDefaultWeight';
import getDefaultDimension from '../../../../helpers/getDefaultDimension';
import createLocalStorage from '../../../../helpers/createLocalStorage';
import getLocalStorage from '../../../../helpers/getLocalStorage';
import removeLocalStorage from '../../../../helpers/removeLocalStorage';

//Components related imports
import Loader from "../../Loader";
import ProductView from "../ProductView";
import AlertMessage from "../../AlertMessage";
import ProductSetting from '../ProductSetting';
import ProductPreview from './ProductPreview';
import ProductDetailPage from './ProductDetailPage';
import ProductMarketing from './ProductMarketing';
import ProductDistribution from './ProductDistribution';
import ProductTechnicalSpecification from './ProductTechnicalSpecification';
import ProductCertification from './ProductCertification';

const HeaderSection = (props) => {
    const navigate = useNavigate();
    const { productDetails, onSettingClick } = props;

    const onBackButtonClick = () => {
        removeLocalStorage(`productSelectedBrand`);
        removeLocalStorage(`productEditingStep`);
        navigate(`/myproducts`)
    }
    return (
        <Box className={`header-section-wrapper fixed-header`}>
            <div className={`back-button-wrapper`} onClick={() => { onBackButtonClick() }}>
                <div className={`back-icon element-vertically-center`}>
                    <KeyboardBackspaceIcon />
                </div>
                <div className={`text`}>
                    Back to My Products
                </div>
            </div>

            <div className={`divider`}></div>

            <div className={`heading-text-wrapper`}>
                <div className={`heading-text`}>
                    {productDetails?.name || ``}
                </div>
                <div className={`product-label`}>
                    Product
                </div>
                <div className={`btn-preview only-mobile`}>
                    {/* <a href="#" className={`btn-primary-after-login`}>
                        Preview
                    </a> */}
                    <div className={`btn-secondary-after-login`} onClick={() => { if (onSettingClick) onSettingClick(true) }}>Show/hide</div>
                </div>
            </div>
        </Box>
    )
}


const RightPanel = (props) => {
    const {
        countryOfOrigin,
        countryList,
        currencyList,
        productDetails,
        categoryList,
        uspsList,
        barcodeValue,
        itemCodeValue,
        priceValue,
        selectedPriceCurrency,
        selectedMSRPType,
        itemValue,
        companyName,
        packageSizeFirstValue,
        packageSizeSecondValue,
        packageSizeThirdValue,
        measType,
        measList,
        msrpValue,
        dimensionList,
        volumeList,
        weightList,
        selectedDimesnion,
        selectedVolume,
        selectedWeight,
        productWidth,
        productHeight,
        productDepth,
        productVolume,
        productWeight,
        userSettings,
        onCustomiseProductCardInfoClick,
        primaryImageValue,
        containerLoadability20Ft,
        containerLoadability40Ft,
        containerLoadability20FtLoose,
        containerLoadability40FtLoose,
        fullTruckLoad,
        producthscode,
        casePalaleteQuantity,
        unitPerCase,
        brandChangeValue,
        flavourChangeValue,
    } = props;

    const [countryFlag, setCountryFlag] = useState(``),
        [priceCurrency, setPriceCurrency] = useState(``),
        [selectedMeasType, setMeasType] = useState(``),
        [dimensionType, setDimensionType] = useState(``),
        [volumeType, setVolumeType] = useState(``),
        [weightType, setWeightType] = useState(``),
        [uploadImages, setUploadImages] = useState(props?.uploadImages || []);

    useEffect(() => {
        countryList && countryList.length > 0 &&
            countryList.map((country) => {
                if (country[`country_id`] === countryOfOrigin) {
                    var countryCode = Array.from(country[`emoji`], (codeUnit) => codeUnit.codePointAt()).map(char => String.fromCharCode(char - 127397).toLowerCase()).join('')
                    setCountryFlag(`https://flagcdn.com/24x18/${countryCode}.png`)
                }
            })
    }, [countryOfOrigin, countryList])

    useEffect(() => {
        currencyList && currencyList.length > 0 &&
            currencyList.map((country) => {
                if (country[`currency_id`] === selectedPriceCurrency) {
                    setPriceCurrency(country[`currency`]);
                }
            })
    }, [selectedPriceCurrency, currencyList])

    useEffect(() => {
        currencyList && currencyList.length > 0 &&
            currencyList.map((country) => {
                if (country[`currency_id`] === selectedMSRPType) {
                    setPriceCurrency(country[`currency`]);
                }
            })
    }, [selectedMSRPType, currencyList])

    useEffect(() => {
        measList && measList.length > 0 &&
            measList.map((item) => {
                if (item[`pack_vol_wht_id`] === measType) {
                    setMeasType(item[`name`]);
                }
            })
    }, [measType, measList])

    useEffect(() => {
        volumeList && volumeList.length > 0 &&
            volumeList.map((item) => {
                if (item[`volume_id`] === selectedVolume) {
                    setVolumeType(item[`name`]);
                }
            })
    }, [selectedVolume, volumeList])

    useEffect(() => {
        dimensionList && dimensionList.length > 0 &&
            dimensionList.map((item) => {
                if (item[`dimension_id`] === selectedDimesnion) {
                    setDimensionType(item[`name`]);
                }
            })
    }, [selectedDimesnion, dimensionList])

    useEffect(() => {
        weightList && weightList.length > 0 &&
            weightList.map((item) => {
                if (item[`weight_id`] === selectedWeight) {
                    setWeightType(item[`name`]);
                }
            })
    }, [selectedWeight, weightList])

    useEffect(() => {
        setUploadImages(props?.uploadImages || []);
    }, [props?.uploadImages])

    return (
        <Box className={`right-panel-wrapper only-desktop dynamic-container-height scroll-bar-theme`}>
            <div className={`heading-wrap`}>
                <SettingIcon />
                <div className={`skip-btn`} onClick={() => { if (onCustomiseProductCardInfoClick) onCustomiseProductCardInfoClick(true) }}>
                    Show/hide product details
                </div>
            </div>
            <div className={`heading-wrap`}>
                <div className={`heading`}>
                    Preview
                </div>
                <div className={`view-link skip-btn`}>
                    {/* view */}
                </div>
            </div>
            <ProductView
                flagImage={countryFlag}
                productImages={uploadImages}
                variantInfo={productDetails?.product_variant || []}
                companyName={companyName}
                categoryList={categoryList}
                uspsList={uspsList}
                barcodeValue={barcodeValue}
                itemCodeValue={itemCodeValue}
                priceValue={priceValue}
                priceCurrency={priceCurrency}
                itemValue={itemValue}
                packageSizeFirstValue={packageSizeFirstValue}
                packageSizeSecondValue={packageSizeSecondValue}
                packageSizeThirdValue={packageSizeThirdValue}
                selectedMeasType={selectedMeasType}
                msrpValue={msrpValue}
                is_variant={productDetails?.parent || ``}
                dimensionList={dimensionList}
                volumeList={volumeList}
                weightList={weightList}
                selectedDimesnion={selectedDimesnion}
                selectedVolume={selectedVolume}
                selectedWeight={selectedWeight}
                productWidth={productWidth}
                productHeight={productHeight}
                productDepth={productDepth}
                productVolume={productVolume}
                productWeight={productWeight}
                dimensionType={dimensionType}
                volumeType={volumeType}
                weightType={weightType}
                userSettings={userSettings}
                primaryImageValue={primaryImageValue}
                containerLoadability20Ft={containerLoadability20Ft}
                containerLoadability40Ft={containerLoadability40Ft}
                containerLoadability20FtLoose={containerLoadability20FtLoose}
                containerLoadability40FtLoose={containerLoadability40FtLoose}
                fullTruckLoad={fullTruckLoad}
                producthscode={producthscode}
                casePalaleteQuantity={casePalaleteQuantity}
                unitPerCase={unitPerCase} 
                brandChangeValue={brandChangeValue} 
                flavourChangeValue={flavourChangeValue}
                
            />

            <div className={`product-preview-wrapper`}>
                {/* <BlueEyeIcon /> */}
                {/* Preview product page */}
            </div>

            <div className={`need-help-wrapper`}>
                <div className={`icon-wrap`}>
                    <HelpQuestionIcon />
                </div>

                <div className={`need-help-text`}>Need help?</div>

                <div className={`guide-text`}>We will help guide you along the process.</div>

                <div className={`more-infor-text skip-btn`}>Find more information</div>
            </div>
        </Box>
    )
}
const EditProduct = (props) => {

    const { appData } = props;
    const brandId = useParams()?.productId || ``, truncateValue = 12, productEditStep = getLocalStorage(`productEditingStep`) ? parseInt(getLocalStorage(`productEditingStep`)) : 1;
    const [barcodeList, setBarcodeList] = useState([]),
        [MEASList, setMEASList] = useState([]),
        [countryList, setCountryList] = useState([]),
        [currencyList, setCurrencyList] = useState([]),
        [dimensionList, setDimensionList] = useState([]),
        [volumeList, setvolumeList] = useState([]),
        [weightList, setWeightList] = useState([]),
        [productDetails, setProductDetails] = useState(props?.editProductData?.editProduct || {}),
        [isLoading, setIsLoading] = useState(false),
        [uploadImages, setUploadImagesValue] = useState(productDetails?.product_img || []),
        [primaryImageValue, setPrimaryImageValue] = useState(''),
        [uspsValues, setUspsValuesValue] = useState(productDetails?.product_usps || []),
        [categoryValues, setCategoryValue] = useState([]),
        [isUSPsError, setUSPsErrorValue] = useState(false),
        [isUSPsErrorCheck, setUSPsErrorCheckValue] = useState(false),
        [isCategoryError, SetCategoryErrorValue] = useState(false),
        [isCategoryErrorCheck, setCategoryErrorCheckValue] = useState(false),
        [selectedBarcodeType, setSelectedBarcodeType] = useState(productDetails?.barcodetype || getDefaultBarcodeType() || ``),
        [selectedPriceCurrency, setSelectedPriceCurrency] = useState(productDetails?.product_price?.[0]?.s_price_currency || getDefaultCurrency() || ``),
        [selectedMSRPType, setSelectedMSRPType] = useState(productDetails?.product_price?.[0]?.m_price_currency || getDefaultCurrency() || ``),
        [selectedCountry, setSelectedCountry] = useState(productDetails?.country || "none"),
        [selectedMEASType, setSelectedMEASType] = useState(productDetails?.ps_meas || getDefaultPackVolumeWeight() || ``),
        [isImageUploadSliderShow, setImageUploadSliderShow] = useState(false),
        [barcodeValue, setBarcodeValue] = useState(productDetails?.barcode || ``),
        [itemCodeValue, setItemCodeValue] = useState(productDetails?.sku_id || ``),
        [priceValue, setPriceValue] = useState(parseInt(productDetails?.product_price?.[0]?.s_price_from) || ``),
        [itemValue, setItemValue] = useState(parseInt(productDetails?.product_price?.[0]?.s_price_to) || ``),
        [companyName, setCompanyName] = useState(``),
        [packageSizeFirstValue, setPackageSizeFirstValue] = useState(parseInt(productDetails?.ps_unit) || ``),
        [packageSizeSecondValue, setPackageSizeSecondValue] = useState(parseInt(productDetails?.ps_quantity) || ``),
        [packageSizeThirdValue, setPackageSizeThirdValue] = useState(parseInt(productDetails?.ps_meas_value) || ``),
        [msrpValue, setMsrpValue] = useState(parseInt(productDetails?.product_price?.[0]?.m_price) || ``),
        [isAutoCompleteOpen, setAutoCompleteFlag] = useState(false),
        [categoryTextBoxValue, setCategoryTextBoxValue] = useState(``),
        [categoryArray, setCategoryArray] = useState([]),
        [selectedBrandCategoryId, setSelectedBrandCategoryId] = useState(productDetails?.brandcategory || ``),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [productName, setProductName] = useState(productDetails?.name || ``),
        [removedImageIds, setRemovedImageIds] = useState([]),
        [brandTextBoxValue, setBrandTextBoxValue] = useState(``),
        [isProductBrandAutoComplete, setIsProductBrandAutoComplete] = useState(false),
        [productBrandArray, setProductBrandArray] = useState([]),
        [productBrandValues, setProductBrandValue] = useState([]),
        [selectedProductBrandId, setSelectedProductBrandCategoryId] = useState(productDetails?.productbrand || ``),
        [isProductBrandError, SetProductBrandErrorValue] = useState(false),
        [isProductBrandErrorCheck, setProductBrandErrorCheckValue] = useState(false),
        [selectedDimesnion, setSelectedDimension] = useState(productDetails?.product_packaging?.[0]?.dimensiontype || getDefaultDimension() || ``),
        [selectedVolume, setSelectedVolume] = useState(productDetails?.product_packaging?.[0]?.volumetype || getDefaultVolume() || ``),
        [selectedWeight, setSelectedWeight] = useState(productDetails?.product_packaging?.[0]?.weighttype || getDefaultWeight() || ``),
        [productWidth, setProductWidth] = useState(productDetails?.product_packaging?.[0]?.pro_width || ``),
        [productHeight, setProductHeight] = useState(productDetails?.product_packaging?.[0]?.pro_height || ``),
        [productDepth, setProductDepth] = useState(productDetails?.product_packaging?.[0]?.pro_depth || ``),
        [productVolume, setProductVolume] = useState(productDetails?.product_packaging?.[0]?.pro_volume || ``),
        [productWeight, setProductWeight] = useState(productDetails?.product_packaging?.[0]?.pro_weight || ``),
        [isSettingShow, setIsSettingShow] = useState(false),
        [isProductNameHasError, setIsProductNameHasError] = useState(false),
        [productNameErrorMessage, setProductNameErrorMessage] = useState(``),
        [isItemCodeHasError, setIsItemCodeHasError] = useState(false),
        [itemCodeErrorMessage, setItemCodeErrorMessage] = useState(``),
        [userSettings, setUserSettings] = useState(props?.userSettings || {}),
        [productEditingStep, setProductEditingStep] = useState(1),
        [submitButtonId, setSubmitButtonId] = useState(``),
        [productPageSetting, setProductPageSetting] = useState({}),
        [productPageSettingArray, setProductPageSettingArray] = useState([]),
        [containerLoadability20Ft, setContainerLoadability20Ft] = useState(productDetails?.product_case_package?.[0]?.container_loadability_20_ft || ``),
        [containerLoadability40Ft, setcontainerLoadability40Ft] = useState(productDetails?.product_case_package?.[0]?.container_loadability_40_ft || ``),
        [containerLoadability20FtLoose, setcontainerLoadability20FtLoose] = useState(productDetails?.product_case_package?.[0]?.container_load_20_ft_loose || ``),
        [containerLoadability40FtLoose, setcontainerLoadability40FtLoose] = useState(productDetails?.product_case_package?.[0]?.container_load_40_ft_loose || ``),
        [fullTruckLoad, setfullTruckLoad] = useState(productDetails?.product_case_package?.[0]?.full_truck_load || ``),
        [producthscode, setproducthscode] = useState(productDetails?.hs_code || ``),
        [casePalaleteQuantity, setcasePalaleteQuantity] = useState(productDetails?.product_case_package?.[0]?.case_pallete_quantity || ``),
        [unitPerCase, setunitPerCase] = useState(productDetails?.product_price?.[0]?.p_p_case || ``),
        [brandChangeValue, setbrandChangeValue] = useState(productDetails?.productbrandname || ``),
        [flavourTextBoxValue, setFlavourTextBoxValue] = useState(``),
        [isProductFlavourAutoComplete, setIsProductFlavourAutoComplete] = useState(false),
        [productFlavourArray, setProductFlavourArray] = useState([]),
        [productFlavourVariantValues, setproductFlavourVariantValues] = useState([]),
        [selectedProductFlavourId, setSelectedProductFlavourId] = useState(productDetails?.product_flavour || ``),       
        [isProductFlavourError, SetProductFlavourErrorValue] = useState(false),
        [isProductFlavourErrorCheck, setProductFlavourErrorCheckValue] = useState(false),
        [flavourChangeValue, setflavourChangeValue] = useState(productDetails?.productflavourname || ``);
        
    const dispatch = useDispatch(), navigate = useNavigate();
    const moduleApis = [];
    const moduleApiIndex = [];

    useEffect(() => {

        createLocalStorage(`isSaveAndExitClick`, false);

        if (productDetails?.brandcategoryname) {
            let tempArray = [{
                "name": productDetails?.brandcategoryname
            }]

            setCategoryValue(tempArray);
        }

        if (productDetails?.productbrandname) {
            let tempArray = [{
                "name": productDetails?.productbrandname
            }]

            setProductBrandValue(tempArray);
        }

        if (productDetails?.productflavourname) {
            let tempArray = [{
                "name": productDetails?.productflavourname
            }]

            setproductFlavourVariantValues(tempArray);
        }

        setIsLoading(true);

        moduleApis.push(getProductSetting());
        moduleApiIndex.push(`productSettingIndex`);

        moduleApis.push(getBarcodeList());
        moduleApiIndex.push(`barcodeIndex`);

        moduleApis.push(getPackVolumeWeight());
        moduleApiIndex.push(`MEASIndex`);

        moduleApis.push(getCountryList());
        moduleApiIndex.push(`countryIndex`);

        moduleApis.push(getCurrencyList());
        moduleApiIndex.push(`currencyIndex`);

        moduleApis.push(getProductDimensionList());
        moduleApiIndex.push(`dimensionIndex`);

        moduleApis.push(getVolumeList());
        moduleApiIndex.push(`volumeIndex`);

        moduleApis.push(getProductWeightList());
        moduleApiIndex.push(`weightIndex`);

        moduleApis.push(getProductPageSetting());
        moduleApiIndex.push(`productPageSetting`);

        if (brandId && Object.keys(productDetails).length === 0) {
            moduleApis.push(getProductDetails(brandId));
            moduleApiIndex.push(`productDetailIndex`);
        }

        Promise.all(moduleApis)
            .then((result) => {

                let barcodeIndex = moduleApiIndex.indexOf('barcodeIndex');
                if (barcodeIndex !== -1 && result.length) {
                    const dataResult = result[barcodeIndex] ? result[barcodeIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setBarcodeList(dataResult.data.data);
                    }
                }

                let MEASIndex = moduleApiIndex.indexOf('MEASIndex');
                if (MEASIndex !== -1 && result.length) {
                    const dataResult = result[MEASIndex] ? result[MEASIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setMEASList(dataResult.data.data);
                    }
                }

                let countryIndex = moduleApiIndex.indexOf('countryIndex');
                if (countryIndex !== -1 && result.length) {
                    const dataResult = result[countryIndex] ? result[countryIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setCountryList(dataResult.data.data);
                    }
                }

                let currencyIndex = moduleApiIndex.indexOf('currencyIndex');
                if (currencyIndex !== -1 && result.length) {
                    const dataResult = result[currencyIndex] ? result[currencyIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setCurrencyList(dataResult.data.data);
                    }
                }

                let productDetailIndex = moduleApiIndex.indexOf('productDetailIndex');
                if (productDetailIndex !== -1 && result.length) {
                    const dataResult = result[productDetailIndex] ? result[productDetailIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setProductDetails(dataResult.data.data[0]);
                        dispatch(editProductAction.setEditProductObject(dataResult.data.data[0]))
                        setCompanyName(dataResult.data.data[0].name);
                    }
                }

                let dimensionIndex = moduleApiIndex.indexOf('dimensionIndex');
                if (dimensionIndex !== -1 && result.length) {
                    const dataResult = result[dimensionIndex] ? result[dimensionIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setDimensionList(dataResult.data.data);
                    }
                }

                let volumeIndex = moduleApiIndex.indexOf('volumeIndex');
                if (volumeIndex !== -1 && result.length) {
                    const dataResult = result[volumeIndex] ? result[volumeIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setvolumeList(dataResult.data.data);
                    }
                }

                let weightIndex = moduleApiIndex.indexOf('weightIndex');
                if (weightIndex !== -1 && result.length) {
                    const dataResult = result[weightIndex] ? result[weightIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setWeightList(dataResult.data.data);
                    }
                }

                let productSettingIndex = moduleApiIndex.indexOf('productSettingIndex');
                if (productSettingIndex !== -1 && result.length) {
                    const dataResult = result[productSettingIndex] ? result[productSettingIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                        setUserSettings(dataResult?.data?.data);
                    }
                }

                let productPageSettingIndex = moduleApiIndex.indexOf('productPageSetting');
                if (productPageSettingIndex !== -1 && result.length) {
                    const dataResult = result[productPageSettingIndex] ? result[productPageSettingIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                        setProductPageSetting(dataResult?.data?.data);
                    }
                }

                setIsLoading(false);
            })

        return () => {
            removeLocalStorage(`isSaveAndExitClick`);
            // removeLocalStorage(`productEditingStep`);
        }
    }, []);

    useEffect(() => {
        uploadImages && uploadImages.length > 0 && uploadImages.map((img, index) => {
            if (img[`default`]) {
                setPrimaryImageValue(index);
            }
        })
    }, [uploadImages]);

    useEffect(() => {
        setProductName(productDetails?.name);
        setPriceValue(parseFloat(productDetails?.product_price?.[0]?.s_price_from));
        setItemValue(parseFloat(productDetails?.product_price?.[0]?.s_price_to));
        setSelectedPriceCurrency(productDetails?.product_price?.[0]?.s_price_currency || getDefaultCurrency() || ``);
        setMsrpValue(parseFloat(productDetails?.product_price?.[0]?.m_price) || ``);
        setSelectedMSRPType(productDetails?.product_price?.[0]?.m_price_currency || getDefaultCurrency() || ``);
        setSelectedBarcodeType(productDetails?.barcodetype || getDefaultBarcodeType() || ``);
        setSelectedMEASType(productDetails?.ps_meas || getDefaultPackVolumeWeight() || ``);
        setBarcodeValue(productDetails?.barcode || ``);
        setItemCodeValue(productDetails?.sku_id || ``);
        setSelectedCountry(productDetails?.country || "none");
        setPackageSizeFirstValue(parseFloat(productDetails?.ps_unit) || ``);
        setPackageSizeSecondValue(parseFloat(productDetails?.ps_quantity) || ``);
        setPackageSizeThirdValue(parseFloat(productDetails?.ps_meas_value) || ``);
        setSelectedBrandCategoryId(productDetails?.brandcategory || ``);
        setSelectedProductBrandCategoryId(productDetails?.productbrand || ``);
        setSelectedProductFlavourId(productDetails?.product_flavour || ``);
        setProductWidth(productDetails?.product_packaging?.[0]?.pro_width || ``);
        setProductHeight(productDetails?.product_packaging?.[0]?.pro_height || ``);
        setProductDepth(productDetails?.product_packaging?.[0]?.pro_depth || ``);
        setProductVolume(productDetails?.product_packaging?.[0]?.pro_volume || ``);
        setProductWeight(productDetails?.product_packaging?.[0]?.pro_weight || ``);
        setSelectedDimension(productDetails?.product_packaging?.[0]?.dimensiontype || getDefaultDimension() || ``);
        setSelectedVolume(productDetails?.product_packaging?.[0]?.volumetype || getDefaultVolume() || ``);
        setSelectedWeight(productDetails?.product_packaging?.[0]?.weighttype || getDefaultWeight() || ``);
        setContainerLoadability20Ft(productDetails?.product_case_package?.[0]?.container_loadability_20_ft || ``);
        setcontainerLoadability40Ft(productDetails?.product_case_package?.[0]?.container_loadability_40_ft || ``);
        setcontainerLoadability20FtLoose(productDetails?.product_case_package?.[0]?.container_load_20_ft_loose || ``);
        setcontainerLoadability40FtLoose(productDetails?.product_case_package?.[0]?.container_load_40_ft_loose || ``);
        setfullTruckLoad(productDetails?.product_case_package?.[0]?.full_truck_load || ``);
        setproducthscode(productDetails?.hs_code || ``);
        setcasePalaleteQuantity(productDetails?.product_case_package?.[0]?.case_pallete_quantity || ``);
        setunitPerCase(productDetails?.product_price?.[0]?.p_p_case || ``);
        setbrandChangeValue(productDetails?.productbrandname || ``);
        setflavourChangeValue(productDetails?.productflavourname || ``);

        if (productDetails?.brandcategoryname) {
            let tempArray = [{
                "name": productDetails?.brandcategoryname
            }]

            setCategoryValue(tempArray);
        }

        if (productDetails?.productbrandname) {
            let tempArray = [{
                "name": productDetails?.productbrandname
            }]

            setProductBrandValue(tempArray);
        }

        if (productDetails?.productflavourname) {
            let tempArray = [{
                "name": productDetails?.productflavourname
            }]

            setproductFlavourVariantValues(tempArray);
        }

        if (productDetails?.product_usps && productDetails?.product_usps.length > 0) {
            let tempArray = [];
            productDetails?.product_usps.map((usps) => {
                tempArray.push({
                    "name": usps?.name
                })
            })

            setUspsValuesValue(tempArray)
        }

        if (productDetails?.product_img && productDetails?.product_img.length > 0) {
            let tempArray = [];
            productDetails?.product_img.map((img, index) => {
                tempArray.push({
                    "productimg_id": img[`productimg_id`],
                    "image": img[`image`],
                    "name": img[`name`],
                })

                if (img[`default`]) {
                    setPrimaryImageValue(index);
                }
            })
            setUploadImagesValue(tempArray);
        }
    }, [productDetails]);


    useEffect(() => {
        setUserSettings(props?.userSettings || {});
    }, [props?.userSettings])
    useEffect(() => {

        let tempArray = [{
            "component": ProductPreview,
            "buttonId": "product-preview-submit-btn",
            "componentName": "Product Preview",
        }];
        for (var key in productPageSetting) {
            if (productPageSetting.hasOwnProperty(key)) {
                if (productPageSetting[key]) {
                    switch (key) {
                        case 'pro_detail_page':
                            tempArray.push({
                                "component": ProductDetailPage,
                                "buttonId": "product-detailpage-submit-btn",
                                "componentName": "Product Detail",
                            });
                            break;
                        case 'tech_spec_page':
                            tempArray.push({
                                "component": ProductTechnicalSpecification,
                                "buttonId": "technical-specification-submit-btn",
                                "componentName": "Technical Specifications",
                            });
                            break;
                        case 'cert_and_apvl_page':
                            tempArray.push({
                                "component": ProductCertification,
                                "buttonId": "product-certification-submit-btn",
                                "componentName": "Certifications & Approvals",
                            });
                            break;

                        case 'distribution_page':
                            tempArray.push({
                                "component": ProductDistribution,
                                "buttonId": "product-distribution-submit-btn",
                                "componentName": "Distribution"
                            });
                            break;

                        case 'marketing_page':
                            tempArray.push({
                                "component": ProductMarketing,
                                "buttonId": "product-marketing-submit-btn",
                                "componentName": "Marketing"
                            });
                            break;
                    }
                }

            }
        }

        setProductPageSettingArray(tempArray);
    }, [productPageSetting]);

    useEffect(() => {
        if (productPageSettingArray && productPageSettingArray.length > 1) {
            if (productPageSettingArray.length >= productEditStep) {
                setProductEditingStep(productEditStep);
            }
        }
    }, [productPageSettingArray])

    useEffect(() => {
        if (productEditingStep > 1) {
            createLocalStorage(`productEditingStep`, productEditingStep)
        }
    }, [productEditingStep]);


    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onSettingSuccess = (data) => {
        setUserSettings(data);
    }

    const onItemCodeChange = (itemCode) => {
        setItemCodeValue(itemCode);
    }

    const onBarcodeChange = (barcode) => {
        setBarcodeValue(barcode);
    }

    const onInnerPackChange = (innerPack) => {
        setPackageSizeFirstValue(innerPack);
    }

    const onOuterPackChange = (outerPack) => {
        setPackageSizeSecondValue(outerPack);
    }

    const onPackageWeightChange = (weight) => {
        setPackageSizeThirdValue(weight);
    }

    const onMEASTypeChange = (measType) => {
        setSelectedMEASType(measType);
    }

    const onPriceCurrentChange = (currency) => {
        setSelectedPriceCurrency(currency);
    }

    const onPriceChange = (price) => {
        setPriceValue(price);
    }

    const onItemChange = (item) => {
        setItemValue(item);
    }

    const onMSRPTypeChange = (msrpType) => {
        setSelectedMSRPType(msrpType);
    }

    const onMSRPChange = (msrp) => {
        setMsrpValue(msrp);
    }

    const onWidthChange = (width) => {
        setProductWidth(width);
    }

    const onHeightChange = (height) => {
        setProductHeight(height);
    }

    const onDepthChange = (depth) => {
        setProductDepth(depth);
    }

    const onDimensionsChange = (dimensions) => {
        setSelectedDimension(dimensions);
    }

    const onVolumeChange = (volume) => {
        setProductVolume(volume);
    }

    const onSelectedVolumeChange = (selectedVolume) => {
        setSelectedVolume(selectedVolume);
    }

    const onWeightChange = (weight) => {
        setProductWeight(weight);
    }

    const onSelectedWeightChange = (selectedWeight) => {
        setSelectedWeight(selectedWeight);
    }

    const onSelectedBarcodeChange = (selectedBarcode) => {
        setSelectedBarcodeType(selectedBarcode);
    }

    const onUspsChange = (usps) => {
        setUspsValuesValue(usps);
    }

    const onCategoryChange = (category) => {
        setCategoryValue(category);
    }

    const onBrandChange = (brand) => {
        setProductBrandValue(brand);
    }

    const onFlavourChange = (flavour) => {
        setproductFlavourVariantValues(flavour);
    }

    const onUploadImagesChange = (images) => {
        setUploadImagesValue(images);
    }

    const onCountryChange = (country) => {
        setSelectedCountry(country);
    }

    const returnEditProductSection = (productSectionPagination = 1) => {

        let Component = ProductPreview;
        // switch (productSectionPagination) {
        //     case 1:
        //         Component = ProductPreview;
        //         break;
        //     case 2:
        //         Component = ProductDetailPage;
        //         break;
        //     case 3:
        //         Component = ProductTechnicalSpecification;
        //         break;

        //     case 4:
        //         Component = ProductDistribution;
        //         break;

        //     case 5:
        //         Component = ProductMarketing;
        //         break;

        //     case 6:
        //         Component = ProductCertification;
        //         break;
        // }

        if (productPageSettingArray && productPageSettingArray.length > 0) {
            productPageSettingArray.map((setting, index) => {

                if ((index + 1) == productEditingStep) {
                    Component = setting?.component || ProductPreview;
                }
            })
        }
        return <Component
            appData={appData}
            productDetails={productDetails}
            userSettings={userSettings}
            brandId={brandId}
            barcodeList={barcodeList}
            MEASList={MEASList}
            countryList={countryList}
            dimensionList={dimensionList}
            volumeList={volumeList}
            weightList={weightList}
            currencyList={currencyList}
            itemCodeChange={onItemCodeChange}
            barcodeChange={onBarcodeChange}
            innerPackChange={onInnerPackChange}
            outerPackChange={onOuterPackChange}
            packageWeightChange={onPackageWeightChange}
            measTypeChange={onMEASTypeChange}
            priceCurrentChange={onPriceCurrentChange}
            priceChange={onPriceChange}
            itemChange={onItemChange}
            msrpTypeChange={onMSRPTypeChange}
            msrpChange={onMSRPChange}
            widthChange={onWidthChange}
            heightChange={onHeightChange}
            depthChange={onDepthChange}
            dimensionsChange={onDimensionsChange}
            volumeChange={onVolumeChange}
            selectedVolumeChange={onSelectedVolumeChange}
            weightChange={onWeightChange}
            selectedWeightChange={onSelectedWeightChange}
            selectedBarcodeChange={onSelectedBarcodeChange}
            uspsChange={onUspsChange}
            categoryChange={onCategoryChange}
            brandChange={onBrandChange}
            uploadImagesChange={onUploadImagesChange}
            countryChange={onCountryChange}
            onSectionApiCallSuccess={onSectionApiCallSuccess}
            containerLoadability20FtChange={(value) => setContainerLoadability20Ft(value)}
            containerLoadability40FtChange={(value) => setcontainerLoadability40Ft(value)}
            containerLoadability20FtLooseChange={(value) => setcontainerLoadability20FtLoose(value)}
            containerLoadability40FtLooseChange={(value) => setcontainerLoadability40FtLoose(value)}
            fullTruckLoadChange={(value) => setfullTruckLoad(value)}
            hscodeChange={(value) => setproducthscode(value)} 
            casePalaleteQuantityChange={(value) => setcasePalaleteQuantity(value)} 
            unitPerCasechange={(value) => setunitPerCase(value)}  
            brandChangeValueChange={(value) => setbrandChangeValue(value)}  
            flavourChangeValueChange={(value) => setflavourChangeValue(value)} 
            flavourChange={onFlavourChange}        
        />
    }


    const returnComponentSubmitButtonID = (productSectionPagination) => {
        let buttonId = `product-preview-submit-btn`

        if (productPageSettingArray && productPageSettingArray.length > 0) {
            productPageSettingArray.map((setting, index) => {

                if ((index + 1) == productEditingStep) {
                    buttonId = setting?.buttonId || buttonId;
                }
            })
        }

        // switch (productSectionPagination) {
        //     case 1:
        //         buttonId = `product-preview-submit-btn`;
        //         break;
        //     case 2:
        //         buttonId = `product-detailpage-submit-btn`;
        //         break;
        //     case 3:
        //         buttonId = `technical-specification-submit-btn`;
        //         break;

        //     case 4:
        //         buttonId = `product-distribution-submit-btn`;
        //         break;

        //     case 5:
        //         buttonId = `product-marketing-submit-btn`;
        //         break;

        //     case 6:
        //         buttonId = `product-certification-submit-btn`;
        //         break;
        // }

        setSubmitButtonId(buttonId);

        return buttonId;

    }

    const onBackBtnClick = (buttonId) => {
        createLocalStorage(`isSaveAndExitClick`, false);
        setProductEditingStep(productEditingStep - 1);
    }

    const onNextButtonClick = () => {
        createLocalStorage(`isSaveAndExitClick`, false);
        document.getElementById(`${returnComponentSubmitButtonID(productEditingStep)}`).click();

    }
    const onSaveAndExitClick = () => {
        createLocalStorage(`isSaveAndExitClick`, true);
        document.getElementById(`${returnComponentSubmitButtonID(productEditingStep)}`).click();
    }

    const onSectionApiCallSuccess = (response) => {
        if (response) {
            if ((productPageSettingArray.length === productEditingStep) || getLocalStorage(`isSaveAndExitClick`) === `true`) {
                removeLocalStorage(`productEditingStep`);
                navigate(`/myproducts`);
            } else {
                getProductDetails(brandId)
                    .then((response) => {
                        if (response.data && response.status === 200 && response.data && response.data.data && response.data.data.length > 0) {
                            setProductDetails(response.data.data[0]);
                            dispatch(editProductAction.setEditProductObject(response.data.data[0]))
                            setCompanyName(response.data.data[0].name);
                            setProductEditingStep(productEditingStep + 1);
                        }
                    })
                    .catch((error) => {
                        console.log("Checking error", error);
                    });
            }
        }
    }

    return (

        <Box className={`company-profile-wrap`}>
            {isLoading && <Loader />}

            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }

            <HeaderSection productDetails={productDetails} onSettingClick={(value) => { setIsSettingShow(value) }} />

            <div className={`heading-wrap cust-head`}>

                {/* {
                            productEditingStep === 1 &&
                            <div className={`heading`}> Add Product Information </div>
                        } */}

                <div className={`progress-bar-wrapper`}>
                    {
                        productPageSettingArray && productPageSettingArray.length > 0 && productEditingStep > 1 &&
                        <div className={`left-navigation `} style={{ width: `${productPageSettingArray.length === productEditingStep ? '25%' : productEditingStep > 1 ? '25%' : '30%'}` }} onClick={() => { onBackBtnClick() }}>

                            <div className={`previous-btn-wrapper`}>
                                <div className={`previous-btn-arrow`}>
                                    <ArrowBackIosNewIcon />
                                </div>
                                <div className={`text-wrapper`}>
                                    {/* <div className={`previous-btn`}>
                                                Previous
                                            </div> */}

                                    <div className={`text`}>
                                        {
                                            // productPageSettingArray.at(productEditingStep - 2)[`componentName`].length > truncateValue
                                            //     ?
                                            //     productPageSettingArray.at(productEditingStep - 2)[`componentName`].substring(0, truncateValue) + "..."
                                            //     :
                                            productPageSettingArray.at(productEditingStep - 2)[`componentName`]
                                        }
                                    </div>
                                </div>
                            </div>


                        </div>
                    }




                    <div className={`center-navigation`} style={{ width: `${productPageSettingArray.length === productEditingStep ? '80%' : productEditingStep === 1 ? '80%' : '40%'}` }}>
                        <div className={`text-wrapper`}>
                            <div className={`component-name`}>
                                {
                                    productPageSettingArray && productPageSettingArray.length > 0 && productEditingStep > 1
                                        ?
                                        productPageSettingArray.at(productEditingStep - 1)[`componentName`]
                                        :
                                        `Product Preview`
                                }
                            </div>

                            <div className={`steps`}>
                                {
                                    `Step ${productEditingStep}/${productPageSettingArray.length}`
                                }
                            </div>

                        </div>

                        <div className={`progress-bar`}>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress variant="determinate" value={(productEditingStep / productPageSettingArray.length) * 100} />
                            </Box>
                        </div>

                    </div>
                    <div className={`right-navigation`} style={{ width: `${productPageSettingArray.length === productEditingStep ? '25%' : productEditingStep > 1 ? '25%' : '30%'}` }}>
                        <div className={`next-btn-wrapper`} onClick={() => onNextButtonClick()}>
                            {
                                productPageSettingArray && productPageSettingArray.length > 0 && productPageSettingArray.length !== productEditingStep &&
                                <Fragment>
                                    <div>
                                        {/* <div className={`next-btn`}>
                                                    Next
                                                </div> */}
                                        <div className={`text`}>

                                            {
                                                // productPageSettingArray.at(productEditingStep)[`componentName`].length > truncateValue
                                                //     ?
                                                //     productPageSettingArray.at(productEditingStep)[`componentName`].substring(0, truncateValue) + "..."
                                                //     :
                                                productPageSettingArray.at(productEditingStep)[`componentName`]
                                            }
                                        </div>
                                    </div>


                                    <div className={`next-icon`}>
                                        <ArrowForwardIosIcon />
                                    </div>
                                </Fragment>

                            }

                        </div>


                        {
                            productPageSettingArray && productPageSettingArray.length > 0 && productPageSettingArray.length === productEditingStep &&
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                className={`btn-primary-after-login`}
                                onClick={() => onNextButtonClick()}
                            >
                                {`Finish`}
                            </Button>


                        }
                    </div>
                </div>
                {/* <div className={`btn-secondary-after-login only-desktop`} onClick={() => onSettingClick()}>Settings</div> */}
            </div>

            <div className={`edit-product-section fixed-container`}>
                <Box className={`left-panel-wrapper dynamic-container-height scroll-bar-theme hideSc`}>

                    {
                        returnEditProductSection(productEditingStep)
                    }
                </Box>

                <ProductSetting
                    isSettingShow={isSettingShow}
                    onClose={(value) => setIsSettingShow(value)}
                    userSettings={userSettings}
                    onSuccess={(data) => { onSettingSuccess(data) }}
                />
                <RightPanel
                    uploadImages={uploadImages}
                    countryOfOrigin={selectedCountry}
                    countryList={countryList}
                    currencyList={currencyList}
                    productDetails={productDetails}
                    categoryList={categoryValues}
                    dimensionList={dimensionList}
                    weightList={weightList}
                    volumeList={volumeList}
                    uspsList={uspsValues}
                    barcodeValue={barcodeValue}
                    itemCodeValue={itemCodeValue}
                    priceValue={priceValue}
                    selectedPriceCurrency={selectedPriceCurrency}
                    selectedMSRPType={selectedMSRPType}
                    itemValue={itemValue}
                    companyName={productName}
                    packageSizeFirstValue={packageSizeFirstValue}
                    packageSizeSecondValue={packageSizeSecondValue}
                    packageSizeThirdValue={packageSizeThirdValue}
                    measType={selectedMEASType}
                    measList={MEASList}
                    msrpValue={msrpValue}
                    selectedDimesnion={selectedDimesnion}
                    selectedVolume={selectedVolume}
                    selectedWeight={selectedWeight}
                    productWidth={productWidth}
                    productHeight={productHeight}
                    productDepth={productDepth}
                    productVolume={productVolume}
                    productWeight={productWeight}
                    userSettings={userSettings}
                    primaryImageValue={primaryImageValue}
                    onCustomiseProductCardInfoClick={(value) => setIsSettingShow(value)}
                    containerLoadability20Ft={containerLoadability20Ft}
                    containerLoadability40Ft={containerLoadability40Ft}
                    containerLoadability20FtLoose={containerLoadability20FtLoose}
                    containerLoadability40FtLoose={containerLoadability40FtLoose}
                    fullTruckLoad={fullTruckLoad}
                    producthscode={producthscode}
                    casePalaleteQuantity={casePalaleteQuantity}
                    unitPerCase={unitPerCase}
                    brandChangeValue={brandChangeValue}
                    flavourChangeValue={flavourChangeValue}
                />
            </div >

            <div className={`disclaimer-wrapper fixed-footer for-previous-btn`}>
                <div className={`save-btn-wrapper only-desktop`}>
                    {
                        productEditingStep !== 1 &&
                        <Button
                            type="submit"
                            variant="outlined"
                            size="large"
                            className={`button-with-blue-border save-and-exit-btn`}
                            onClick={() => onBackBtnClick()}
                        >
                            {'Previous'}
                        </Button>

                    }
                </div>
                <div className={`text`}>
                    *Don’t worry if your info isn’t perfect, you can always return to make edits.*
                </div>
                <div className={`save-btn-wrapper footer-buttons`}>
                    <div className={`only-mobile`}>
                        {
                            productEditingStep !== 1 &&
                            <Button
                                type="submit"
                                variant="outlined"
                                size="large"
                                className={`button-with-blue-border save-and-exit-btn`}
                                onClick={() => onBackBtnClick()}
                            >
                                {'Previous'}
                            </Button>

                        }
                    </div>
                    {productPageSettingArray.length === productEditingStep ? <></> :
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            className={`button-with-blue-border save-and-exit-btn`}
                            onClick={() => onSaveAndExitClick()}
                        > {'Save and exit'}
                        </Button>}


                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        className={`btn-primary-after-login save-and-continue-btn`}
                        onClick={() => onNextButtonClick()}
                    >
                        {productPageSettingArray.length === productEditingStep ? 'Save and Finish' : 'Save and Continue'}
                    </Button>


                </div>
            </div>
        </Box >
    )
}

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
