
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const catalogueDelete = async (CatalogID) => {

    const headers = await getApiHeader() || {};;

    if(!CatalogID) {
        return {
            error: true,
            message: 'CatalogID mandatory in catalogueDelete function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in catalogueDelete function' 
        }
    }


    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    // var formData = new FormData();
    
    return instance.delete(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-update/${CatalogID}`, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default catalogueDelete;