// React related imports
import React, { useState, Fragment } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';

//Redux related imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Other imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import catalogueAction from '../../../../apis/Supplier/catalogueAction';
//Components related imports
import AlertMessage from "../../AlertMessage";

const Rename = (props) => {

    const { popupData, renamepopupclose, renameSuccess, headingLineText, SubmitbuttonText } = props;
    const [isLoading, setIsLoading] = useState(false),
        [isAlertMessageShow, setAlertMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [name, setName] = useState(popupData?.name || ``);

    const schema = yup
        .object({
            name: yup
                .string()
                .trim()
                .required("Catalogue Name is required")
                .max(60, 'Maximum allowed characters is 60'),
        })
        .required();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const onAlertClose = (value) => {
        setAlertMessageShow(value);
    };

    const onSubmit = (data) => {

        catalogueAction(popupData?.catalogue_web_id, 'RENAME', "", name)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlertMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        renamepopupclose(false);
                        if (renameSuccess) {
                            renameSuccess(response.data);
                        }
                    }, 2000);
                } else {
                    setIsLoading(false);
                    setAlertMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((err) => {
                console.log("we have error on Product Action", err);
            })


    }
    return (
        <Fragment>
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <Box className={`rename-popup-wrapper`}>
                <div className={`add-catalogue-popup`}>
                    <div className={`close-icon`} onClick={() => { if (renamepopupclose) renamepopupclose(false) }}>
                        <CloseIcon />
                    </div>
                    <div className={`first-heading`}>
                        {headingLineText}
                    </div>

                    <div className={`second-heading`}>

                    </div>
                    <form className={`form-input-control`} onSubmit={handleSubmit(onSubmit)}>

                        <Box className={`form-control margin-bottom-28 position-relative`}>
                            <TextField
                                type={"text"}
                                id="name"
                                name="name"
                                className={`input-wrap add-brand-input`}
                                placeholder={`e.g. Corn Flakes`}
                                margin="dense"
                                value={name}
                                {...register("name", {
                                    onChange: (event) => {
                                        setName(event.target.value);
                                    },
                                })}
                                error={errors.name ? true : false}
                            />
                            {
                                errors.name?.message &&
                                <p className={`field-err-msg add-new-pro-custom-style`}>{errors.name?.message}</p>
                            }
                        </Box>

                        <Box className={`form-control margin-bottom-28 button-wrap`}>
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                className={`btn-primary-after-login btn-modal ${isValid ? `` : `disabled`}`}
                                disabled={isValid ? false : true}
                            >
                                {SubmitbuttonText}
                            </Button>
                        </Box>
                    </form>
                </div>
            </Box>
        </Fragment>
    )
}

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Rename);
