
import axios from 'axios';

import getApiHeader from '../helpers/getApiHeader';

import {CONFIG} from '../helpers/getApiConfig';


const userAccountPasswordReset = async (currentpassword = ``, password = ``, confirmpassword = ``) => {

    const headers = await getApiHeader() || {};;

    if(!currentpassword) {
        return {
            error: true,
            message: 'Current Password mandatory in Profile update function' 
        }
    }
    if(!password) {
        return {
            error: true,
            message: 'Password mandatory in Profile update function' 
        }
    }
    if(!confirmpassword) {
        return {
            error: true,
            message: 'Confirm Password mandatory in Profile update function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in Profile update function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();

    if(currentpassword) formData.append("currentpassword", currentpassword);
    if(password) formData.append("password", password);
    if(confirmpassword) formData.append("confirmpassword", confirmpassword);

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/myaccount/resetpassword`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default userAccountPasswordReset;