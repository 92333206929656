import React, { useState, useEffect } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";

//Components related imports
import ProfileSection from "./ProfileSection";
import ChangePasswordSection from "./ChangePasswordSection";
import CardInfoDetailSection from "./CardInfoDetailSection";
import ProductSettingSection from './ProductSettingSection';
import CategorySection from "./CategorySection";
import CompanySection from "./CompanySection";
import BrandSection from "./BrandSection";
import FlavourSection from "./FlavourSection";
import OurTeam from './OurTeamSection';

import * as yup from "yup";

function isValidPhonenumber(message) {
    return this.test("isValidPhonenumber", message, function (value, element) {
        const { path, createError } = this;

        if (!value.match(/^(?:\+971|00971|0)?(?:50|51|52|54|55|56|58|2|3|4|6|7|9)\d{7}$/)) {
            return createError({
                path,
                message: message ?? 'Please enter valid phone number'
            });
        }

        return true;
    });
}
yup.addMethod(yup.string, "isValidPhonenumber", isValidPhonenumber);
const ProductCardSetting = () => {
    return (
        <Box className={`product-card-wrapper`}>
            We are at product card section
        </Box>
    )
}


const RightPanel = (props) => {

    const [userDetail, setUserDetail] = useState(props.userDetail || ``);
    const [selectedMenu, setSelectedMenu] = useState(props.selectedMenu || ``);
    const [countryList, setCountryList] = useState(props.countrylist || ``);

    useEffect(() => {
        setSelectedMenu(props.selectedMenu);
    }, [props.selectedMenu])

    useEffect(() => {
        setUserDetail(props.userDetail);
    }, [props.userDetail])

    useEffect(() => {
        setCountryList(props.countrylist);
    }, [props.countrylist])

    const profileclick = (value) => {
        // console.log('updated')
        if (props.propsupdate) {
            props.propsupdate(true)
        }
    }
    // console.log(userDetail)

    const onChangeMenuReturnComponent = (value) => {

        switch (value) {
            case `Your Profile`:
                return <ProfileSection profileupdate={profileclick} userDetail={userDetail} countryList={countryList} />;
                break;

            case `Change Password`:
                return <ChangePasswordSection />
                break;

            case `My Categories`:
                return <CategorySection />;
                break;
            
            case `My Personas`:
                return <CompanySection />;
                break;
            
            case `My Brands`:
                return <BrandSection />;
                break;
            
            case `My Variants/Flavours`:
                return <FlavourSection />;
                break;

            case `Product card setting`:
                return <ProductCardSetting />
                break;

            case 'Show/hide product details':
                return <CardInfoDetailSection />
                break;
            case 'Product Details':
                return <ProductSettingSection />
                break;

            case `Team Management`:
                return <OurTeam userDetail={userDetail}/>
                break;
                
            default:
                // return <ProfileSection />;
                break;
        }

        // return component;
    }
    return (
        <Box className="right-panel scroll-bar-theme">
            {onChangeMenuReturnComponent(selectedMenu)}
        </Box>
    )
}
export default RightPanel;
