import React from "react";

const twitterWebsiteIcon = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_954_5737)">
                <path d="M4.78163 13.5851C10.0647 13.5851 12.9543 9.20818 12.9543 5.4125C12.9543 5.28818 12.9543 5.16442 12.9459 5.04122C13.508 4.63461 13.9933 4.13115 14.3789 3.55442C13.8547 3.78682 13.2986 3.93916 12.7291 4.00634C13.3288 3.64741 13.7776 3.0828 13.9919 2.41762C13.4281 2.75222 12.8113 2.98802 12.168 3.11482C11.735 2.65432 11.1622 2.34939 10.5384 2.24722C9.91452 2.14504 9.2744 2.25132 8.71705 2.5496C8.15971 2.84788 7.7162 3.32154 7.45517 3.89727C7.19414 4.47301 7.13014 5.11872 7.27307 5.7345C6.13109 5.67721 5.01393 5.38043 3.99409 4.86342C2.97425 4.34641 2.07452 3.62072 1.35331 2.73346C0.985998 3.36579 0.873498 4.11434 1.03871 4.82671C1.20393 5.53907 1.63444 6.16168 2.24259 6.56778C1.78546 6.55438 1.33828 6.43107 0.938906 6.20826V6.24466C0.939088 6.90782 1.16865 7.55051 1.58866 8.06371C2.00868 8.57691 2.59328 8.92903 3.24331 9.06034C2.82044 9.17569 2.37675 9.19254 1.94635 9.10962C2.12996 9.68031 2.48732 10.1794 2.96846 10.537C3.4496 10.8946 4.03047 11.093 4.62987 11.1043C4.03433 11.5724 3.35234 11.9185 2.62292 12.1228C1.89349 12.3271 1.13095 12.3856 0.378906 12.2949C1.69248 13.1378 3.22086 13.5849 4.78163 13.5829" fill="#000100" />
            </g>
            <defs>
                <clipPath id="clip0_954_5737">
                    <rect width="14" height="14" fill="white" transform="translate(0.378906 0.897217)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default twitterWebsiteIcon;
