import React from "react";

const shareIcon = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_102_2442)">
                <path d="M11.0312 13.2805C11.9977 13.2805 12.7812 12.497 12.7812 11.5305C12.7812 10.564 11.9977 9.78052 11.0312 9.78052C10.0648 9.78052 9.28125 10.564 9.28125 11.5305C9.28125 12.497 10.0648 13.2805 11.0312 13.2805Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.03125 9.19727C4.99775 9.19727 5.78125 8.41376 5.78125 7.44727C5.78125 6.48077 4.99775 5.69727 4.03125 5.69727C3.06475 5.69727 2.28125 6.48077 2.28125 7.44727C2.28125 8.41376 3.06475 9.19727 4.03125 9.19727Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.54297 8.32812L9.52714 10.6498" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.0312 5.11401C11.9977 5.11401 12.7812 4.33051 12.7812 3.36401C12.7812 2.39752 11.9977 1.61401 11.0312 1.61401C10.0648 1.61401 9.28125 2.39752 9.28125 3.36401C9.28125 4.33051 10.0648 5.11401 11.0312 5.11401Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.5213 4.24487L5.54297 6.56654" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_102_2442">
                    <rect width="14" height="14" fill="white" transform="translate(0.53125 0.447266)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default shareIcon;
