import React from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import Pagination from '@mui/material/Pagination';

const PaginationComponent = (props) => {

    const { pages, onPageChange, currentPage = 1 } = props;

    const onPaginationChange = (event, value) => {
        if (onPageChange) {
            onPageChange(value);
        }
    };

    return (
        <Box className={`pagination-component-wrapper`}>
            <Pagination
                count={pages}
                variant="outlined"
                shape="rounded"
                size="large"
                onChange={onPaginationChange}
                page={currentPage}
            />
        </Box>
    )
}

export default PaginationComponent;