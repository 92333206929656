import React from "react";

const whatsappIcon = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.86816 39.303L4.56216 29.468C2.90016 26.59 2.02616 23.324 2.02716 19.979C2.03216 9.514 10.5482 1 21.0142 1C26.0932 1.002 30.8592 2.979 34.4442 6.566C38.0282 10.154 40.0022 14.922 40.0002 19.994C39.9962 30.459 31.4782 38.974 21.0142 38.974C21.0132 38.974 21.0142 38.974 21.0142 38.974H21.0062C17.8292 38.973 14.7062 38.176 11.9332 36.663L1.86816 39.303Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.86822 39.803C1.73622 39.803 1.60822 39.751 1.51322 39.655C1.38822 39.528 1.33922 39.343 1.38622 39.172L4.02522 29.536C2.38922 26.63 1.52622 23.33 1.52822 19.98C1.53222 9.238 10.2732 0.5 21.0142 0.5C26.2242 0.502 31.1192 2.531 34.7982 6.213C38.4772 9.896 40.5022 14.79 40.5002 19.994C40.4962 30.735 31.7542 39.474 21.0142 39.474C17.8252 39.473 14.6702 38.686 11.8702 37.197L1.99522 39.786C1.95322 39.798 1.91122 39.803 1.86822 39.803Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21.0141 1C26.0931 1.002 30.8591 2.979 34.4441 6.566C38.0281 10.154 40.0021 14.922 40.0001 19.994C39.9961 30.459 31.4781 38.974 21.0141 38.974H21.0061C17.8291 38.973 14.7061 38.176 11.9331 36.663L1.86809 39.303L4.56209 29.468C2.90009 26.59 2.02609 23.324 2.02709 19.979C2.03209 9.514 10.5481 1 21.0141 1ZM21.0141 0C9.99809 0 1.03209 8.962 1.02709 19.979C1.02609 23.346 1.87609 26.664 3.48809 29.601L0.903087 39.04C0.809087 39.385 0.905087 39.753 1.15709 40.007C1.34709 40.199 1.60409 40.304 1.86809 40.304C1.95309 40.304 2.03809 40.293 2.12209 40.271L11.8091 37.731C14.6371 39.199 17.8071 39.974 21.0061 39.975C32.0301 39.975 40.9961 31.012 41.0011 19.995C41.0031 14.656 38.9261 9.636 35.1531 5.86C31.3781 2.083 26.3571 0.002 21.0141 0Z" fill="#CFD8DC" />
            <path fillRule="evenodd" clipRule="evenodd" d="M32.1759 8.83303C29.1959 5.85103 25.2349 4.20803 21.0189 4.20703C12.3149 4.20703 5.23593 11.283 5.23193 19.981C5.23093 22.962 6.06493 25.864 7.64493 28.377L8.02093 28.974L6.42593 34.795L12.3989 33.229L12.9759 33.571C15.3979 35.009 18.1759 35.769 21.0079 35.77H21.0139C29.7119 35.77 36.7909 28.693 36.7939 19.994C36.7949 15.779 35.1559 11.815 32.1759 8.83303Z" fill="#64B161" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.2682 12.0459C15.9132 11.2559 15.5392 11.2399 15.2002 11.2259C14.9232 11.2139 14.6072 11.2149 14.2912 11.2149C13.9752 11.2149 13.4612 11.3339 13.0262 11.8089C12.5912 12.2839 11.3652 13.4309 11.3652 15.7649C11.3652 18.0989 13.0652 20.3549 13.3022 20.6709C13.5392 20.9869 16.5842 25.9299 21.4062 27.8319C25.4132 29.4119 26.2292 29.0979 27.0992 29.0189C27.9692 28.9399 29.9062 27.8719 30.3012 26.7639C30.6962 25.6559 30.6962 24.7069 30.5782 24.5089C30.4592 24.3109 30.1432 24.1929 29.6692 23.9549C29.1952 23.7169 26.8622 22.5699 26.4272 22.4119C25.9922 22.2539 25.6762 22.1749 25.3592 22.6499C25.0432 23.1239 24.1342 24.1929 23.8572 24.5089C23.5802 24.8259 23.3032 24.8659 22.8292 24.6279C22.3552 24.3899 20.8272 23.8899 19.0142 22.2739C17.6042 21.0169 16.6522 19.4639 16.3752 18.9889C16.0982 18.5149 16.3452 18.2579 16.5832 18.0209C16.7962 17.8079 17.0572 17.4669 17.2952 17.1899C17.5322 16.9129 17.6112 16.7149 17.7692 16.3989C17.9272 16.0819 17.8482 15.8049 17.7292 15.5679C17.6122 15.3299 16.6902 12.9839 16.2682 12.0459Z" fill="white" />
        </svg>
    );
};

export default whatsappIcon;
