


import axios from 'axios';

import {CONFIG} from '../helpers/getApiConfig';

const getIndustryList = async () => {
    
    const instance = axios.create();  

    instance.defaults.timeout = CONFIG.timeout || 15000;
    
    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/master/industry`)
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default getIndustryList