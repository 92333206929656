
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';


const addBrand = async (brandName = ``, tagLine = ``, brandLogo = ``) => {

    const headers = await getApiHeader() || {};;
    
    if(!brandName) {
        return {
            error: true,
            message: 'brandName mandatory in addBrand function' 
        }
    }

    if(!brandLogo) {
        return {
            error: true,
            message: 'brandLogo mandatory in addBrand function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in addBrand function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    let params = {}

    var formData = new FormData();

    if(brandName) formData.append("name", brandName);
    if(tagLine) formData.append("tagline", tagLine);
    if(brandLogo) formData.append("b_logo", brandLogo);

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/brand/brand`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default addBrand;