import axios from 'axios';

import {CONFIG} from '../helpers/getApiConfig';

const login = async (email, password, catalogueId, userType) => {
    if(!email) {
        return {
            error: true,
            message: 'email mandatory in login function' 
        }
    }

    if(!password) {
        return {
            error: true,
            message: 'password mandatory in login function' 
        }
    }

    const instance = axios.create();
    
    instance.defaults.timeout = CONFIG.timeout || 15000;
    
    var formData = new FormData();
    formData.append("email", email)
    formData.append("password", password)
    if(catalogueId) formData.append("catalogue", catalogueId)
    if(userType) formData.append("usertype", userType)

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/login` , formData)
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default login;

