
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import { CONFIG } from '../../helpers/getApiConfig';


const buyerOnboardingThirdStep = async (processType = 3, name = ``, tagline = ``, profile = ``, description = ``) => {

    const headers = await getApiHeader() || {};;

    if (!processType) {
        return {
            error: true,
            message: 'processType mandatory in buyerOnboardingThirdStep function'
        }
    }

    if (!name) {
        return {
            error: true,
            message: 'name mandatory in buyerOnboardingThirdStep function'
        }
    }

    if (!profile) {
        return {
            error: true,
            message: 'profile mandatory in buyerOnboardingThirdStep function'
        }
    }

    if (!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in buyerOnboardingThirdStep function'
        }
    }

    const instance = axios.create();
    instance.defaults.timeout = CONFIG.timeout || 15000;


    var formData = new FormData();

    if(processType) formData.append("processtype", processType);
    if(name) formData.append("company_name", name);
    if(profile) formData.append("user_profile", profile);
    if(tagline) formData.append("company_tagline", tagline);
    if(description) formData.append("company_description", description);


    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/registerOnboardingBuyer`, formData, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default buyerOnboardingThirdStep;