
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const categoryOrderUpdate = async (brandId, categoryIds) => {

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    const headers = await getApiHeader() || {};;

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in categoryOrderUpdate function' 
        }
    }

    if(!brandId) {
        return {
            error: true,
            message: 'brandId mandatory in categoryOrderUpdate function' 
        }
    }

    if(!categoryIds) {
        return {
            error: true,
            message: 'categoryIds mandatory in categoryOrderUpdate function' 
        }
    }

    var formData = new FormData();
    if(categoryIds) formData.append("categoryids", categoryIds);

    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/brand/brandcategory-v2/${brandId}`, formData,  {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default categoryOrderUpdate;