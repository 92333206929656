
import axios from 'axios';

import {CONFIG} from '../helpers/getApiConfig';

const getBusinessType = async (name = `Other`, customerType = `1`) => {

    if(!name) {
        return {
            error: true,
            message: 'name mandatory in getBusinessType function' 
        }
    }

    if(!customerType) {
        return {
            error: true,
            message: 'customerType mandatory in getBusinessType function' 
        }
    }

    const instance = axios.create();
    
    instance.defaults.timeout = CONFIG.timeout || 15000;
    
    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/master/businesstype/${customerType}`)
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default getBusinessType