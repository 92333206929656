import React from "react";

const cataloguePreviewIcon = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="25" height="18" viewBox="0 0 35 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.867188 11.2009C0.867188 7.77082 0.867188 4.33544 0.867188 0.905392C0.867188 0.186359 1.14415 -0.0266876 1.85253 0.133097C6.955 1.29953 12.0521 2.46596 17.1546 3.62174C17.3517 3.66435 17.5807 3.66435 17.7778 3.61641C23.056 2.434 28.3289 1.24627 33.6018 0.0585311C34.438 -0.127885 34.7469 0.117119 34.7469 0.979958C34.7469 7.82941 34.7469 14.6789 34.7469 21.5283C34.7469 22.1621 34.6404 22.3113 34.0226 22.4977C28.787 24.0849 23.546 25.6667 18.3104 27.2539C17.847 27.3924 17.3943 27.3764 16.9362 27.2326C11.8018 25.6348 6.66739 24.0423 1.52763 22.4444C0.936428 22.2633 0.867188 22.1728 0.867188 21.5336C0.867188 18.0929 0.867188 14.6469 0.867188 11.2009ZM2.11884 1.42203C2.10818 1.56051 2.09221 1.65106 2.09221 1.7416C2.09221 8.17561 2.09221 14.6149 2.08155 21.049C2.08155 21.3366 2.22003 21.4058 2.43841 21.4697C7.20533 22.9451 11.9723 24.4311 16.7338 25.9118C16.8563 25.949 16.9842 25.9757 17.1599 26.0183C17.1599 25.8478 17.1599 25.7147 17.1599 25.5815C17.1599 18.8226 17.1546 12.069 17.1706 5.31013C17.1706 4.95328 17.0481 4.8361 16.7232 4.76154C12.6593 3.84011 8.59013 2.89738 4.52094 1.9653C3.72734 1.78421 2.93374 1.60845 2.11884 1.42203ZM18.4009 25.965C23.4714 24.4311 28.4727 22.9131 33.4633 21.4058C33.4633 14.7108 33.4633 8.05844 33.4633 1.39007C33.3515 1.41138 33.2609 1.42736 33.1757 1.44333C28.4195 2.50857 23.6685 3.58445 18.9122 4.63371C18.4968 4.72425 18.3956 4.87871 18.3956 5.28883C18.4116 11.9945 18.4063 18.7001 18.4063 25.4004C18.4009 25.5602 18.4009 25.72 18.4009 25.965Z" fill="#686868"/>
        <path d="M31.3121 7.50985C31.3015 7.82409 31.0618 7.97322 30.7422 8.06909C28.8035 8.62302 26.8648 9.18226 24.9207 9.73618C23.6744 10.093 22.4281 10.4499 21.1817 10.8014C20.745 10.9239 20.4361 10.7908 20.3189 10.4126C20.2017 10.0504 20.3935 9.74151 20.8355 9.61368C24.0206 8.69758 27.211 7.78681 30.396 6.87603C30.918 6.73223 31.3068 6.98256 31.3121 7.50985Z" fill="#686868"/>
        <path d="M31.3106 14.9132C31.3053 15.2168 31.0549 15.3659 30.7354 15.4565C29.3346 15.856 27.9338 16.2607 26.533 16.6602C24.7487 17.1715 22.9592 17.6828 21.1749 18.1941C20.7435 18.3166 20.4292 18.1728 20.312 17.7947C20.2002 17.4325 20.3973 17.1236 20.834 16.9958C24.0084 16.085 27.1828 15.1742 30.3572 14.2688C30.9111 14.1143 31.3053 14.354 31.3106 14.9132Z" fill="#686868"/>
        <path d="M21.0248 14.5776C20.6147 14.583 20.3111 14.3593 20.2845 14.045C20.2525 13.6775 20.4602 13.4751 20.7905 13.3793C24.0021 12.4578 27.2138 11.5417 30.4308 10.6256C30.8463 10.5084 31.1658 10.6576 31.2777 11.0038C31.4002 11.3766 31.2191 11.6855 30.777 11.8134C27.592 12.7295 24.4069 13.6402 21.2166 14.551C21.142 14.5723 21.0621 14.5723 21.0248 14.5776Z" fill="#686868"/>
        <g clipPath="url(#clip0_4284_3279)">
        <path d="M4.99219 13.848C4.99219 13.848 6.59004 10.6523 9.38628 10.6523C12.1825 10.6523 13.7804 13.848 13.7804 13.848C13.7804 13.848 12.1825 17.0437 9.38628 17.0437C6.59004 17.0437 4.99219 13.848 4.99219 13.848Z" stroke="#686868" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.38589 15.0464C10.0477 15.0464 10.5843 14.5099 10.5843 13.848C10.5843 13.1862 10.0477 12.6497 9.38589 12.6497C8.72404 12.6497 8.1875 13.1862 8.1875 13.848C8.1875 14.5099 8.72404 15.0464 9.38589 15.0464Z" stroke="#686868" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_4284_3279">
        <rect width="9.5871" height="9.5871" fill="white" transform="translate(4.59375 9.05444)"/>
        </clipPath>
        </defs>
        </svg>
    );
};

export default cataloguePreviewIcon;
