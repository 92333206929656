//import React from "react";

// React related imports
import React from "react";

// mui or UI related imports
import Box from "@mui/material/Box";



// React route related imports
import { Link } from 'react-router-dom'

//Redux related imports
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


//Components related imports
import AlertMessage from "../AlertMessage";

//Style related imports
import "./style.scss";


const Loader = (props) => {
    const { name = "", size = "s", color = '#44378f', className = "" } = props;
    
    return (
        <Box className={`loader-component-wrap`}>
            <div className="loader-center">
                <div className="three-bounce-spinner">
                    <div style={{ backgroundColor: color }} className="bounce1"></div>
                    <div style={{ backgroundColor: color }} className="bounce2"></div>
                    <div style={{ backgroundColor: color }} className="bounce3"></div>
                </div>
            </div>
        </Box>
    );
}

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
