const initialState = {
    businessType: {},
    loading: true,
    error: false,
  };
  
  function businessTypeReducer(state = initialState, action) {
    switch (action.type) {
      case "LOAD_BUSINESS_TYPE":
        return { ...state, businessType: {}, error: false, loading: true };
      case "GET_BUSINESS_TYPE":
        return state;
      case "SET_BUSINESS_TYPE":
        return { ...state, businessType: action.payload, error: false, loading: false };
      case "ERROR_BUSINESS_TYPE":
        return { ...state, businessType: {}, error: true, loading: false };
      default:
        return state;
    }
  }
  export default businessTypeReducer;
  