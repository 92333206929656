const initialState = {
    countryList: {},
    loading: true,
    error: false,
  };
  
  function countryListReducer(state = initialState, action) {
    switch (action.type) {
      case "LOAD_COUNTRY_LIST":
        return { ...state, countryList: {}, error: false, loading: true };
      case "GET_COUNTRY_LIST":
        return state;
      case "SET_COUNTRY_LIST":
        return { ...state, countryList: action.payload, error: false, loading: false };
      case "ERROR_COUNTRY_LIST":
        return { ...state, countryList: {}, error: true, loading: false };
      default:
        return state;
    }
  }
  export default countryListReducer;
  