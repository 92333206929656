const setAppObject = (appObj) => {
  return {
    type: "SET_APP_OBJECT",
    payload: appObj,
  };
};

const getAppObject = () => {
  return {
    type: "GET_APP_OBJECT",
  };
};

export default {
  setAppObject,
  getAppObject,
};
