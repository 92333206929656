import React, { useState, useEffect, Fragment, useRef } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
// import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';

//React route dom related imports
import { useParams, Link } from "react-router-dom";

//Apis related imports
import getWebsiteBrand from '../../../../apis/websitePreview/getWebsiteBrand';
import getWebsiteProfile from '../../../../apis/websitePreview/getWebsiteProfile';
import getWebsiteProduct from '../../../../apis/websitePreview/getWebsiteProduct';
import emailVerification from '../../../../apis/websitePreview/emailVerification';
import catalogueViewEvent from '../../../../apis/websitePreview/catalogueViewEvent';
import catalogueViewDurationEvent from '../../../../apis/websitePreview/catalogueViewDurationEvent';

//Icons related imports
import MoreIcons from '../../../../icons/svgs/moreIcons';
import InstagramWebsiteIcon from '../../../../icons/svgs/instagramWebsiteIcon';
import FacebookWebsiteIcon from '../../../../icons/svgs/facebookWebsiteIcon';
import TwitterWebsiteIcon from '../../../../icons/svgs/twitterWebsiteIcon';
import NoImageAvailable from '../../../../assets/img/no-img-available.jpg';
import CardNoImageAvailable from '../../../../assets/img/img-n-avai.png'
import NoDataFound from '../../../../icons/svgs/noDataFound';

//Components related imports
import Loader from "../../Loader";
import Slider from '../../Supplier/Slider';
import AlertMessage from "../../AlertMessage";
import SearchIcon from '../../../../icons/svgs/searchIcon';
import WebsitePreviewDetails from "../DetailsPage";
import MetaTagInjector from "../../MetaTagInjector";
import Login from "../../Login";
import Registration from "../../Registration";
import Tooltip from '../../Supplier/Tooltip';

//Redux related imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//React router dom related imports
import { useLocation } from 'react-router-dom';

//Helpers related imports
import createCookie from "../../../../helpers/createCookie";
import readCookie from '../../../../helpers/readCookie';
import numberWithCommas from "../../../../helpers/numberWithCommas";

//Other imports
import VisibilitySensor from 'react-visibility-sensor';
import { get, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useIdleTimer } from 'react-idle-timer'
import imageResizing from "../../../../helpers/imageResizing";

const PreviewExitSction = (props) => {

    const onExitPreviewClick = () => {
        if (props?.onPreviewClose) props.onPreviewClose(false);
    }
    return (
        <Box className={`exit-preview-wrapper`}>
            <div className={`text-wrap`}>
                <div className={`heading only-desktop`}>Brand Catalogue Preview: </div>
                <div className={`description`}>This is what buyers see when they view your catalogue.</div>
            </div>

            <div className={`exit-text skip-btn-white`} onClick={() => { onExitPreviewClick() }}>
                Exit preview
            </div>
        </Box>
    )
}

const LoginSection = (props) => {

    const { onClose, catalogueId } = props;

    const [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isRegistrationShow, setIsRegistrationShow] = useState(false);

    useEffect(() => {
        document.body.classList.add("overflow-hide");

        return () => {
            document.body.classList.remove("overflow-hide");
        }
    }, [])

    const onRegisterClick = () => {
        console.log(`register callback`);
        setIsRegistrationShow(true);
    }

    const onLoginSuccess = (data) => {
        setAlternateMessageShow(true);
        setAlertMessageType(`success`);
        setAlertMessage(data?.message);

        setTimeout(() => {
            if (onClose) onClose(false);
        }, 1000)
    }
    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onRegisterSuccess = (data) => {
        setAlternateMessageShow(true);
        setAlertMessageType(`success`);
        setAlertMessage(data?.message);

        setTimeout(() => {
            if (onClose) onClose(false);
        }, 1000)
    }
    return (
        <Box className={`login-section-wrapper main-wrapper login-container`}>
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`login-wrap container-wrapper scroll-bar-theme`}>
                {
                    <div className={`heading-wrap`}>
                        {
                            isRegistrationShow &&
                            <div className={`arrow-icon`} onClick={() => { setIsRegistrationShow(false) }}>
                                <ArrowBackIcon />
                            </div>
                        }

                        <div className={`close-icon ${!isRegistrationShow ? 'w-100' : ''}`} >
                            <span onClick={() => { if (onClose) onClose(false); }}>
                                <CloseIcon />
                            </span>
                        </div>
                    </div>

                }

                {
                    isRegistrationShow
                        ?
                        <Registration
                            pageName={'webcatalogue'}
                            catalogueId={catalogueId}
                            loginUserType={2}
                            onRegisterSuccess={onRegisterSuccess}
                        />
                        :
                        <Login
                            pageName={'webcatalogue'}
                            onRegisterClick={onRegisterClick}
                            catalogueId={catalogueId}
                            loginUserType={2}
                            onLoginSuccess={onLoginSuccess}
                        />
                }

            </div>
        </Box >
    )
}

const PreviewHeaderSection = (props) => {
    const { brand, catalogueId, isWebCatalogueNotFound } = props;

    return (
        <Box className={`preview-header-wrapper ${isWebCatalogueNotFound && 'blur-background'}`}>
            <div className={`flex flex-items-center logo-wrap`}>
                <div className={`logo`}>
                    <img src={brand?.b_logo} loading="lazy" alt="brand logo" />
                </div>

                <div className={`text-wrap`}>
                    <div className={`flex flex-items-center`}>
                        <div className={`brand-name`}>
                            {brand?.name}
                        </div>

                        <div className={`brand-type display-none`}>
                            Brand Owner
                        </div>
                    </div>

                    <div className={`tagline`}>
                        {brand?.tagline}
                    </div>

                </div>

            </div>



            <div className={`options visibility-hidden display-none`}>
                <MoreIcons />
            </div>
        </Box>
    )
}

const PreviewBanner = (props) => {
    const { brand, isWebCatalogueNotFound } = props;

    return (
        <Box className={`preview-banner-wrapper ${isWebCatalogueNotFound && 'blur-background'}`}>
            {
                brand?.b_banner
                    ?
                    <img src={brand?.b_banner} loading="lazy" alt="product banner image" />
                    :
                    <div className={`no-image-found`}>
                        <img src={NoImageAvailable} />
                    </div>
            }
        </Box>
    )
}

const ProductCard = (props) => {

    const { catalogueId, product, brands, pageType, catalogueWebsitekey, onProductNameClick, productSettings = {} } = props;

    const [isShowUsps, setIsShowUsps] = useState(false),
        [defaultImage, setDefaultImage] = useState(``),
        [productImages, setProductImages] = useState(product?.product_img && product?.product_img?.length > 0 ? product?.product_img : [])

    useEffect(() => {
        let tempArray = productImages;
        if (product?.product_img && product?.product_img?.length > 0) {
            product?.product_img.map((img, index) => {
                if (img[`default`]) {
                    setDefaultImage(index);
                }
            })
        }

        if (productImages && productImages?.length > 0) {
            productImages.map((img, index) => {
                if (img[`default`]) {
                    setDefaultImage(index);
                }
            })
        }
        if (product?.products_detail && product?.products_detail?.length > 0) {
            product?.products_detail.map((detail) => {
                if (detail[`nutritioanl_file`]) {
                    if (!productImages.some(image => image['image'] === detail?.nutritioanl_file)) {
                        tempArray.push({
                            "default": false,
                            "image": detail?.nutritioanl_file,
                            "name": ``
                        })
                    }
                }
            })
        }

        setProductImages(tempArray);
    }, [product?.product_img, product?.products_detail])
    let marginFrom = (parseFloat(product?.product_price?.[0]?.m_price) - parseFloat(product?.product_price?.[0]?.s_price_from)) / parseFloat(product?.product_price?.[0]?.m_price) * 100;
    if (marginFrom === Infinity || marginFrom === -Infinity) {
        marginFrom = 0
    }
    let margiInto = (parseFloat(product?.product_price?.[0]?.m_price) - parseFloat(product?.product_price?.[0]?.s_price_to)) / parseFloat(product?.product_price?.[0]?.m_price) * 100;
    if (margiInto === Infinity || margiInto === -Infinity) {
        margiInto = 0
    }
    const flagemojiToPNG = (flag, code) => {
        var countryCode = Array.from(flag, (codeUnit) => codeUnit.codePointAt()).map(char => String.fromCharCode(char - 127397).toLowerCase()).join('')
        return `https://flagcdn.com/24x18/${countryCode}.png`;
    }

    const onProductCardInfoClick = () => {
        if (pageType !== `cataloguePreview`) {
            if (onProductNameClick) {
                onProductNameClick(product?.products_id);
            }
        } else {
            window.location = `/webcatalogue/details/${catalogueId || catalogueWebsitekey}/${product?.products_id}`;
        }
    }

    return (
        <Box className={`product-card-wrapper`}>
            {/* <div className='show-for-print-pdf'>
                        <div className='product-image'>
                            <div className="slider-wrapper">
                                <div className="slides-warp">
                                    <div className="slides" style={{height:"300px"}}>
                                        <div className="slide show absolute">
                                            <div className="image-wrap">
                                                {
                                                    product?.product_img && product?.product_img?.length > 0 && defaultImage !== ``
                                                    ?
                                                    <img className={`pro-img`} src={imageResizing(product?.product_img?.[0]?.image, 900, 900, `cover`)} alt={`product image`} loading="lazy" />
                                                    // <img src={product?.product_img?.[0]?.image} /> 
                                                    :
                                                    <img src={CardNoImageAvailable} />  
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                </div> */}

            {
                product?.product_img && product?.product_img?.length > 0 && defaultImage !== ``
                    ?
                    <div className={`product-image`}>
                        <Slider
                            autoRotate={false}
                            showNextPrevBtn={true}
                            rotationTiming={5000}
                            backArrowHtml={
                                <div className={`prev-arrow`}>
                                    <WestIcon />
                                </div>
                            }
                            nextArrowHtml={
                                <div className={`next-arrow`}>
                                    <EastIcon />
                                </div>
                            }
                            defaultActiveSlide={defaultImage || 0}
                        >
                            {
                                product?.product_img && product?.product_img?.length > 0 &&
                                product?.product_img?.map((image, index) => {
                                    return (
                                        <div className={`image-wrap`} key={index}>
                                            {
                                                pageType !== `cataloguePreview` ?
                                                    <div className={`pro-img-wrap`} onClick={() => { if (onProductNameClick) onProductNameClick(product?.products_id) }}>
                                                        {/* <img className={`pro-img`} src={image?.image} alt={`product image`} loading="lazy" /> */}
                                                        <img className={`pro-img`} src={imageResizing(image?.image, 500, 500, `contain`, "#ffffff")} alt={`product image`} loading="lazy" />
                                                    </div>
                                                    :
                                                    <a href={`/webcatalogue/details/${catalogueId || catalogueWebsitekey}/${product?.products_id}`}>
                                                        {/* <img className={`pro-img`} src={image?.image} alt={`product image`} loading="lazy" /> */}
                                                        <img className={`pro-img`} src={imageResizing(image?.image, 500, 500, `contain`, "#ffffff")} alt={`product image`} loading="lazy" />
                                                    </a>
                                            }
                                        </div>

                                    )
                                })
                            }
                        </Slider>
                    </div>
                    :
                    // <div className={`no-image-available`}>
                    //     <a href={`/webcatalogue/details/${catalogueId || catalogueWebsitekey}/${product?.products_id}`}>
                    //         <img src={CardNoImageAvailable} />
                    //     </a>
                    // </div>
                    // i change the structure for printing on A4
                    <div className="product-image">
                        <div className="slider-wrapper">
                            <div className="slides-warp">
                                <div className="slides" >
                                    <div className="slide" style={{ display: "block" }}>
                                        <div className="image-wrap">
                                            <div className={`no-image-available`}>
                                                {
                                                    pageType !== `cataloguePreview` ?
                                                        <div className={`pro-img-wrap`} onClick={() => { if (onProductNameClick) onProductNameClick(product?.products_id) }}>
                                                            <img src={CardNoImageAvailable} />
                                                        </div>
                                                        :
                                                        <a href={`/webcatalogue/details/${catalogueId || catalogueWebsitekey}/${product?.products_id}`}>
                                                            <img src={CardNoImageAvailable} />
                                                        </a>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

            <div className={`card-body product-card-`}>

                <div className={`cn-title-wrap ${productSettings?.pro_usps && product && product?.product_usps && product?.product_usps.length > 0 ? 'show-border' : ''}`}>
                    {
                        product?.name &&
                        <div className={`${product?.name.length < 44 ? "margin-bottom-5" : ""} product-name custom-tt`} tooltip={`${product?.name.length < 44 ? "" : product?.name}`}>
                            {
                                pageType !== `cataloguePreview` ?
                                    <div className={`${product?.name.length < 44 ? "no-ellipsis" : 'ellipsis-for-2-line'}`} onClick={() => { if (onProductNameClick) onProductNameClick(product?.products_id) }}>
                                        {product?.name}
                                    </div>
                                    :
                                    <Link className={`${product?.name.length < 44 ? "no-ellipsis" : 'ellipsis-for-2-line'}`} to={`/webcatalogue/details/${catalogueId || catalogueWebsitekey}/${product?.products_id}`}>
                                        {product?.name}
                                    </Link>
                            }
                        </div>
                    }

                    {
                        product?.country_image &&
                        <div className={`flag-img-wrap position-relative`}>
                            <div className={`flag-img tooltip`} tooltip={product?.country_name} flow="left">
                                <span>
                                    <img src={flagemojiToPNG(product?.country_image)} alt="flag image" loading='lazy' />
                                </span>
                            </div>
                        </div>

                    }
                </div>


                {
                    productSettings?.pro_usps && product && product?.product_usps && product?.product_usps.length > 0
                        ?
                        <div className={`view-more-less-wrap `}>
                            <span className={`skip-btn`} onClick={() => { setIsShowUsps(!isShowUsps) }}>
                                {
                                    isShowUsps ?
                                        <Fragment>
                                            Hide USPs <KeyboardArrowUpIcon />
                                        </Fragment>
                                        :
                                        <Fragment>
                                            View USPs <KeyboardArrowDownIcon />
                                        </Fragment>
                                }
                            </span>
                            {/* <Link to={`/webcatalogue/details/${catalogueId || catalogueWebsitekey}/${product?.products_id}`}>   </Link> */}
                        </div>
                        : <></>
                    // <div className={`no-usps-found`}> No USPs found </div>

                }
                {/* <Link to={`/webcatalogue/details/${catalogueId || catalogueWebsitekey}/${product?.products_id}`} target="_self"> */}
                {
                    productSettings?.pro_usps && isShowUsps && product?.product_usps && product?.product_usps?.length > 0 &&
                    <div className={`usps-wrap`}>
                        {
                            product?.product_usps?.map((usps, index) => {
                                return (
                                    <div className={`usps`} key={index}>
                                        {usps?.name}
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {/* {
                        isShowUsps && product?.productbrandname &&
                        <div className={`usps-wrap`}>
                            {
                                <div className={`usps`}>
                                    {product?.productbrandname}
                                </div>
                            }
                        </div>
                    } */}

                <div className={`product-info-wrapper`} onClick={() => onProductCardInfoClick()}>
                    <div className={`product-info ${productSettings?.pro_usps ? 'show-border' : ''}`}>
                        <div>
                            {
                                productSettings?.pro_hs_code &&
                                <div className={`info`}>
                                    <div className={`label`}>HS Code</div>
                                    <div className={`value`}>
                                        {
                                            product?.hs_code
                                                ?
                                                <span className={`available`}>{` ${product?.hs_code}`}</span>
                                                :
                                                <span className={`non-available`}>{` NA`}</span>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                productSettings?.pro_pack_size &&
                                <div className={`info`}>
                                    <div className={`label`}>Case Configuration/Pack Size</div>
                                    <div className={`value`}>
                                        {
                                            product?.ps_unit || product?.ps_quantity || product?.ps_meas_value
                                                ?
                                                <Fragment>

                                                    {
                                                        product?.ps_meas_value
                                                            ?
                                                            <span className={`available`}>{`${product?.ps_meas_value}`}</span>
                                                            :
                                                            <span className={`non-available`}>{`NA`}</span>
                                                    }

                                                    {
                                                        product?.ps_measName
                                                            ?
                                                            <span className={`available`}>{`${product?.ps_measName} `}</span>
                                                            :
                                                            <span className={`non-available`}>{` CM`}</span>
                                                    }                                       
                                                    {
                                                        product?.ps_unit
                                                            ?
                                                            <span className={`available`}>{`x${product?.ps_unit}`}</span>
                                                            : ''
                                                            // <span className={`non-available`}>{`xNA`}</span>
                                                    }
                                                    {
                                                        product?.ps_quantity
                                                            ?
                                                            <span className={`available`}>{`x${product?.ps_quantity}`}</span>
                                                            : ''
                                                            // <span className={`non-available`}>{`xNA`}</span>
                                                    }

                                                </Fragment>
                                                :
                                                <span className={`non-available`}>{`NA`}</span>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                productSettings?.pro_barcode &&
                                <div className={`info`}>
                                    <div className={`label`}>Product Barcode</div>
                                    <div className={`value`}>
                                        {
                                            product?.barcode
                                                ?
                                                <span className={`available`}>{` ${product?.barcode}`}</span>
                                                :
                                                <span className={`non-available`}>{` NA`}</span>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                product?.productbrandname &&
                                <div className={`info`}>
                                    <div className={`label`}>Brand</div>
                                    <div className={`value`}>
                                        {
                                            product?.productbrandname
                                                ?
                                                <span className={`available`}>{` ${product?.productbrandname}`}</span>
                                                :
                                                <span className={`non-available`}>{` NA`}</span>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                productSettings?.pro_flavour &&
                                <div className={`info`}>
                                    <div className={`label`}>Variant/Flavour</div>
                                    <div className={`value`}>
                                        {
                                            product?.productflavourname
                                                ?
                                                <span className={`available`}>{` ${product?.productflavourname}`}</span>
                                                :
                                                <span className={`non-available`}>{` NA`}</span>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                productSettings?.pro_case_pallete_quantity &&
                                <div className={`info`}>
                                    <div className={`label`}>Pallet Quantity</div>
                                    <div className={`value`}>
                                        { 
                                            product?.product_case_package?.[0]?.case_pallete_quantity
                                                ?
                                                <span className={`available`}>{` ${product?.product_case_package?.[0]?.case_pallete_quantity}`}</span>
                                                :
                                                <span className={`non-available`}>{` NA`}</span>
                                        }
                                    </div>
                                </div>
                            }

                            


                            <div className={`info`}>
                                <div className={`label`}>Seller SKU ID</div>
                                <div className={`value information-icon`} style={{ justifyContent: "flex-end" }}>
                                    {
                                        product?.sku_id
                                            ?
                                            // <span className={`available`}>{` ${product?.sku_id}`}</span>
                                            // <span className={`custom-tt-2 available`} tooltip={product?.sku_id.length > 10 ? `${product?.sku_id}` : ``}>{product?.sku_id.length > 10 ? `${product?.sku_id.substring(0, 10)}...` : `${product?.sku_id}`}</span>
                                            <span>{product?.sku_id}</span>
                                            :
                                            <span className={`non-available`}>{` NA`}</span>
                                    }
                                </div>
                            </div>
                            {
                                productSettings?.pro_case_per_unit &&
                                <div className={`info`}>
                                    <div className={`label`}>Units per carton/case</div>
                                    <div className={`value`}>
                                        {
                                            product?.product_price?.[0]?.p_p_case
                                                ?
                                                <span className={`available`}>{` ${product?.product_price?.[0]?.p_p_case}`}</span>
                                                :
                                                <span className={`non-available`}>{` NA`}</span>
                                        }
                                    </div>
                                </div>
                            }


                            {
                                productSettings?.pro_dimensions &&
                                <div className={`info`}>
                                    <div className={`label`}>Product dimensions (wxhxd)</div>
                                    <div className={`value`}>
                                        {
                                            product?.product_packaging?.[0]?.pro_width || product?.product_packaging?.[0]?.pro_height || product?.product_packaging?.[0]?.pro_depth
                                                ?
                                                <Fragment>{
                                                    product?.product_packaging?.[0]?.pro_width
                                                        ?
                                                        <span className={`available`}>{`${product?.product_packaging?.[0]?.pro_width}`}</span>
                                                        :
                                                        <span className={`non-available`}>{`NA`}</span>
                                                }

                                                    {
                                                        product?.product_packaging?.[0]?.pro_height
                                                            ?
                                                            <span className={`available`}>{`x${product?.product_packaging?.[0]?.pro_height}`}</span>
                                                            :
                                                            <span className={`non-available`}>{`xNA`}</span>
                                                    }

                                                    {
                                                        product?.product_packaging?.[0]?.pro_depth
                                                            ?
                                                            <span className={`available`}>{`x${product?.product_packaging?.[0]?.pro_depth}`}</span>
                                                            :
                                                            <span className={`non-available`}>{`xNA`}</span>
                                                    }

                                                    {
                                                        product?.product_packaging?.[0]?.dimensiontypename
                                                            ?
                                                            <span className={`available`}>{` ${product?.product_packaging?.[0]?.dimensiontypename}`}</span>
                                                            :
                                                            <span className={`non-available`}>{` CM`}</span>
                                                    }</Fragment>
                                                :
                                                <span className={`non-available`}>{`NA`}</span>
                                        }

                                    </div>
                                </div>
                            }


                            {
                                productSettings?.pro_volume &&
                                <div className={`info`}>
                                    <div className={`label`}>Product volume</div>
                                    <div className={`value`}>
                                        {
                                            product?.product_packaging?.[0]?.pro_volume
                                                ?
                                                <Fragment>
                                                    <span className={`available`}>{`${product?.product_packaging?.[0]?.pro_volume}`}</span>
                                                    {
                                                        product?.product_packaging?.[0]?.volumetypename
                                                            ?
                                                            <span className={`available`}>{` ${product?.product_packaging?.[0]?.volumetypename}`}</span>
                                                            :
                                                            <span className={`non-available`}>{` ML`}</span>
                                                    }
                                                </Fragment>
                                                :
                                                <span className={`non-available`}>{`NA`}</span>
                                        }
                                    </div>

                                </div>

                            }

                            {
                                productSettings?.pro_weight &&
                                <div className={`info`}>
                                    <div className={`label`}>Product weight</div>
                                    <div className={`value`}>
                                        {
                                            product?.product_packaging?.[0]?.pro_weight
                                                ?
                                                <Fragment>
                                                    <span className={`available`}>{`${product?.product_packaging?.[0]?.pro_weight}`}</span>
                                                    {
                                                        product?.product_packaging?.[0]?.weighttypename
                                                            ?
                                                            <span className={`available`}>{` ${product?.product_packaging?.[0]?.weighttypename}`}</span>
                                                            :
                                                            <span className={`non-available`}>{` G`}</span>
                                                    }
                                                </Fragment>
                                                :
                                                <span className={`non-available`}>{`NA`}</span>
                                        }
                                        {/* {
                                            product?.product_packaging?.[0]?.pro_weight
                                                ?
                                                <span className={`available`}>{`${product?.product_packaging?.[0]?.pro_weight}`}</span>
                                                :
                                                <span className={`non-available`}>{`NA`}</span>
                                        }

                                        {
                                            product?.product_packaging?.[0]?.weighttypename
                                                ?
                                                <span className={`available`}>{` ${product?.product_packaging?.[0]?.weighttypename}`}</span>
                                                :
                                                <span className={`non-available`}>{` G`}</span>
                                        } */}
                                    </div>

                                </div>
                            }

                            {
                                productSettings?.pro_container_20 &&
                                <div className={`info`}>
                                    <div className={`label`}>Container Loadability 20 ft (Cases)(Palletized)</div>
                                    <div className={`value information-icon`} style={{ justifyContent: "flex-end" }}>
                                        {
                                            product?.product_case_package?.[0]?.container_loadability_20_ft
                                                ?
                                                <span>{product?.product_case_package?.[0]?.container_loadability_20_ft}</span>
                                                :
                                                <span className={`non-available`}>{` NA`}</span>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                productSettings?.pro_container_20_loose &&
                                <div className={`info`}>
                                    <div className={`label`}>Container Loadability 20 ft (Cases)(Loose)</div>
                                    <div className={`value information-icon`} style={{ justifyContent: "flex-end" }}>
                                        {
                                            product?.product_case_package?.[0]?.container_load_20_ft_loose
                                                ?
                                                <span>{product?.product_case_package?.[0]?.container_load_20_ft_loose}</span>
                                                :
                                                <span className={`non-available`}>{` NA`}</span>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                productSettings?.pro_container_40 &&
                                <div className={`info`}>
                                    <div className={`label`}>Container Loadability 40 ft (Cases)(Palletized)</div>
                                    <div className={`value information-icon`} style={{ justifyContent: "flex-end" }}>
                                        {
                                            product?.product_case_package?.[0]?.container_loadability_40_ft
                                                ?
                                                <span>{product?.product_case_package?.[0]?.container_loadability_40_ft}</span>
                                                :
                                                <span className={`non-available`}>{` NA`}</span>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                productSettings?.pro_container_40_loose &&
                                <div className={`info`}>
                                    <div className={`label`}>Container Loadability 40 ft (Cases)(Loose)</div>
                                    <div className={`value information-icon`} style={{ justifyContent: "flex-end" }}>
                                        {
                                            product?.product_case_package?.[0]?.container_load_40_ft_loose
                                                ?
                                                <span>{product?.product_case_package?.[0]?.container_load_40_ft_loose}</span>
                                                :
                                                <span className={`non-available`}>{` NA`}</span>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                productSettings?.pro_full_truck_load &&
                                <div className={`info`}>
                                    <div className={`label`}>Full Truck load (Cases)</div>
                                    <div className={`value information-icon`} style={{ justifyContent: "flex-end" }}>
                                        {
                                            product?.product_case_package?.[0]?.full_truck_load
                                                ?
                                                <span className="ellipsis-text">{product?.product_case_package?.[0]?.full_truck_load}</span>
                                                :
                                                <span className={`non-available`}>{` NA`}</span>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            brands?.is_price &&
                            <Fragment>
                                <div>

                                    {
                                        productSettings?.pro_priceitem &&
                                        <div className={`price-info`}>
                                            <div className={`info`}>
                                                <div className={`label`}>Price Range</div>
                                                <div className={`value`}>
                                                    {
                                                        product?.product_price?.[0]?.s_price_from || product?.product_price?.[0]?.s_price_to
                                                            ?
                                                            <Fragment>
                                                                {
                                                                    product?.product_price?.[0]?.s_price_currencyName
                                                                        ?
                                                                        <span className={`available`}>{`${product?.product_price?.[0]?.s_price_currencyName}`}</span>
                                                                        :
                                                                        <span className={`non-available`}>{`NA`}</span>
                                                                }
                                                                {
                                                                    product?.product_price?.[0]?.s_price_from
                                                                        ?
                                                                        <span className={`available`}>{` ${numberWithCommas(product?.product_price?.[0]?.s_price_from)}`}</span>
                                                                        :
                                                                        <span className={`non-available`}>{` NA`}</span>
                                                                }
                                                                {
                                                                    product?.product_price?.[0]?.s_price_currencyName
                                                                        ?
                                                                        <span className={`available`}>{` - ${product?.product_price?.[0]?.s_price_currencyName}`}</span>
                                                                        :
                                                                        <span className={`non-available`}>{` - NA`}</span>
                                                                }
                                                                {
                                                                    product?.product_price?.[0]?.s_price_to
                                                                        ?
                                                                        <span className={`available`}>{` ${numberWithCommas(product?.product_price?.[0]?.s_price_to)}`}</span>
                                                                        :
                                                                        <span className={`non-available`}>{` NA`}</span>
                                                                }
                                                            </Fragment>
                                                            :
                                                            <span className={`non-available`}>{`NA`}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        productSettings?.pro_priceitem &&
                                        <div className={`info`}>
                                            <div className={`label`}>
                                                Margin
                                            </div>
                                            <div className={`value`}>
                                                {
                                                    margiInto || marginFrom
                                                        ?
                                                        <Fragment>
                                                            {
                                                                margiInto
                                                                    ?
                                                                    <span className={`available`}>{`${margiInto.toFixed(2)}`}</span>
                                                                    :
                                                                    <span className={`non-available`}>{`NA`}</span>
                                                            }
                                                            {
                                                                marginFrom
                                                                    ?
                                                                    <span className={`available`}>{` - ${marginFrom.toFixed(2)}%`}</span>
                                                                    :
                                                                    <span className={`non-available`}>{` - NA`}</span>
                                                            }
                                                        </Fragment>
                                                        :
                                                        <span className={`non-available`}>{`NA`}</span>
                                                }
                                            </div>
                                        </div>
                                    }

                                </div>
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
            {/* </Link> */}
        </Box >
    )
}

const CategoryViewSection = (props) => {
    const { category, brands, catalogueWebsitekey, catalogueId, onProductNameClick, pageType, productSettings = {}, productFilterValue } = props;

    const [isProductShow, setIsProductShow] = useState(true);

    useEffect(() => {

        switch (productFilterValue) {
            case `Expand All`:
                setIsProductShow(true);
                break;

            case `Collapse All`:
                setIsProductShow(false);
                break;
        }
    }, [productFilterValue])
    return (
        <div className={`category`}>

            <div className={`category-name-wrap`}>
                <div className={`category-name`}>
                    {category?.name}
                </div>
                <div className={`arrow-icon`} onClick={() => { setIsProductShow(!isProductShow) }}>
                    {
                        isProductShow
                            ?
                            <ArrowDropDownIcon />
                            :
                            <ArrowRightIcon />
                    }
                </div>
            </div>

            {
                isProductShow &&
                <div className={`product-list`}>
                    {
                        category?.product_bc && category?.product_bc?.length > 0 &&
                        category?.product_bc?.map((product, index) => {
                            return (
                                <div className={`pro-items-cards`} key={index}>
                                    <ProductCard
                                        product={product}
                                        brands={brands}
                                        catalogueWebsitekey={catalogueWebsitekey}
                                        catalogueId={catalogueId}
                                        onProductNameClick={onProductNameClick}
                                        pageType={pageType}
                                        productSettings={productSettings}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}
const ProductsSection = (props) => {

    let intervalValue = useRef();

    const timeout = props?.idleTimeout;

    const { catalogueId,
        emailVerificationResponse = ``,
        onProductViewEventResponse = ``,
        isProductViewEventRegister,
        intervalTime,
        brands,
        pageType,
        catalogueWebsitekey,
        onProductNameClick,
        productSettings = {},
        isWebCatalogueNotFound,
        isEmailVerificaionCloseByUser
    } = props;
    const [searchValue, setSearchValue] = useState(``),
        [productList, setProductList] = useState(props?.productList || []),
        [isLoading, setIsLoading] = useState(false),
        [page, setPage] = useState(1),
        [hasNext, setHasNext] = useState(props?.hasNext || false),
        [isUserIdle, setIsUserIdle] = useState(false),
        [productFilterValue, setProductFilterValue] = useState(``),
        [actionText, setActionText] = useState(`Actions`);

    const {
        reset,
        pause,
        resume,
    } = useIdleTimer({
        timeout,
        onActive: () => { setIsUserIdle(false); },
        onIdle: () => { setIsUserIdle(true); }
    })
    useEffect(() => {
        setProductList(props?.productList || []);
    }, [props?.productList])

    useEffect(() => {
        setHasNext(props?.hasNext);
    }, [props?.hasNext]);

    useEffect(() => {
        if (isEmailVerificaionCloseByUser) {
            sendCatalogueEvent();
        }
    }, [isEmailVerificaionCloseByUser])

    useEffect(() => {
        if (emailVerificationResponse && pageType === `cataloguePreview`) {
            intervalValue.current = setInterval(() => {
                productDurationApiCall();
            }, intervalTime)

        }
        return () => {
            clearInterval(intervalValue.current);
        }
    }, [props?.emailVerificationResponse]);

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        if (isUserIdle) {
            clearInterval(intervalValue.current);
        } else {
            if (pageType === `cataloguePreview`) {
                intervalValue.current = setInterval(() => {
                    productDurationApiCall();
                }, intervalTime)
            }
        }
    }, [isUserIdle])

    const productDurationApiCall = () => {
        catalogueViewDurationEvent(catalogueId || catalogueWebsitekey, 1, readCookie(`websitePreviewEmail`) || ``, Math.floor(intervalTime / 1000))
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    console.log("Product view event register successfully");
                }
            })
            .catch((err) => {
                console.log("we have error at catalogueViewDurationEvent", err);
            })
    }
    const sendCatalogueEvent = () => {

        if (emailVerificationResponse && !isProductViewEventRegister && readCookie(`websitePreviewEmail`)) {
            catalogueViewEvent(catalogueId || catalogueWebsitekey, 1, readCookie(`websitePreviewEmail`) || ``)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status) {
                        if (onProductViewEventResponse) onProductViewEventResponse(true);
                    } else {
                        if (onProductViewEventResponse) onProductViewEventResponse(false);
                    }
                })
                .catch((error) => {
                    if (onProductViewEventResponse) onProductViewEventResponse(false);
                    console.log("We have error at catalogue view event");
                });
        }
    }

    const onProductSearchClick = () => {
        setPage(1);
        setIsLoading(true);
        getWebsiteProduct(catalogueId || catalogueWebsitekey, searchValue, 1)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setIsLoading(false);
                    setProductList(response.data.data);
                    setHasNext(response?.data?.next ? true : false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at productsList", error);
            })
    }

    const onSearchRemove = () => {
        setPage(1);
        setIsLoading(true);
        setSearchValue(``);
        document.getElementById(`website-search`).value = '';
        getWebsiteProduct(catalogueId || catalogueWebsitekey, ``, 1)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setIsLoading(false);
                    // console.log('search key'+response.data.data)
                    setProductList(response.data.data);
                    setHasNext(response?.data?.next ? true : false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at productsList", error);
            })
    }

    const onPageScroll = (page) => {
        if (hasNext) {
            getWebsiteProduct(catalogueId || catalogueWebsitekey, searchValue, page)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                        // console.log('scrol top'+response.data.data)
                        setIsLoading(false);
                        setHasNext(response?.data?.next ? true : false);
                        setProductList([...productList, ...response.data.data]);
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("we have error at productsList", error);
                })
        }
    }

    const filterbymoreoptionstatus = [
        {
            "label": "Collapse All",
            "value": "Collapse All"
        },
        {
            "label": "Expand All",
            "value": "Expand All"
        }
    ];

    const onMoreOptionValueChange = (value, data) => {
        switch (value[`value`]) {
            case `Collapse All`:
                setActionText(`Collapse All`);
                setProductFilterValue(`Collapse All`);
                break;
            case `Expand All`:
                setActionText(`Expand All`);
                setProductFilterValue(`Expand All`);
                break;
        }
    }

    return (
        <Box className={`products-section ${isWebCatalogueNotFound && 'blur-background'}`}>
            {isLoading && <Loader />}
            <div className={`search-section`}>
                <div className={`position-relative`}>
                    <TextField
                        autoComplete='off'
                        id="website-search"
                        name="website-search"
                        placeholder={`Search by Category, Brand, Product name or SKU ID`}
                        margin="dense"
                        spellCheck={false}
                        className={`search-product`}
                        inputProps={{
                            onChange: (e) => {
                                setSearchValue(e.target.value);
                                if (!e.target.value) { onSearchRemove() }
                            },
                            onKeyPress: (e) => {
                                if (e.key === "Enter") {
                                    onProductSearchClick();
                                }
                            },
                        }}
                        tooltip="Search by Category, Brand, Product name or SKU ID"
                    />
                    {
                        searchValue &&
                        <span className={`close-icon`} onClick={() => { onSearchRemove() }}>
                            <CloseIcon />
                        </span>
                    }

                </div>

                <button className={`search-icon`} onClick={() => { onProductSearchClick() }}>
                    <SearchIcon />
                </button>
            </div>

            {
                productList && productList?.length > 0 &&
                <div className={`filter-wrapper`}>
                    <span>
                        {
                            searchValue &&
                            <div className={`result-text`}>
                                Results
                            </div>
                        }
                    </span>
                    <Tooltip
                        moreOptions={filterbymoreoptionstatus}
                        onOptionChange={onMoreOptionValueChange}
                        textlable={actionText}
                        icon={1}
                        id={`product`}
                    />
                </div>
            }


            <div className={`product-list-wrapper`}>
                {
                    productList && productList?.length > 0 ?
                        productList?.map((category, index) => {
                            return (
                                <div className='for-page-break' key={index}>
                                    <CategoryViewSection
                                        category={category}
                                        brands={brands}
                                        catalogueWebsitekey={catalogueWebsitekey}
                                        catalogueId={catalogueId}
                                        onProductNameClick={onProductNameClick}
                                        pageType={pageType}
                                        productSettings={productSettings}
                                        productFilterValue={productFilterValue}
                                    />
                                </div>
                            )
                        })
                        :
                        <div className={`no-data-found`}>
                            <div className={`text`}>
                                <NoDataFound />
                                No Data Found
                            </div>
                        </div>
                }
            </div>

            {
                productList && productList.length > 0 && hasNext &&
                <VisibilitySensor
                    onChange={(isVisible) => { if (isVisible) { setPage(page + 1); onPageScroll(page + 1) } }}
                    partialVisibility={true}
                    offset={{ top: 200 }}
                >
                    <div className={`load-more-btn`}>
                        <Loader />
                    </div>
                </VisibilitySensor>

            }
        </Box>
    )
}

const ProfileSection = (props) => {

    let intervalValue = ``;

    const timeout = props?.idleTimeout;

    const { catalogueId,
        onViewMoreClick,
        emailVerificationResponse = ``,
        onProfileViewEventResponse,
        isProfileViewEventRegister,
        brands,
        intervalTime,
        profileDataChange,
        pageType,
        productSettings = {},
        isWebCatalogueNotFound,
        isEmailVerificaionCloseByUser,
        onProductNameClick
    } = props;

    const [profile, setProfile] = useState(props?.profileData || {}),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        // if (profile && Object.keys(profile).length === 0) {
        setIsLoading(true);
        getWebsiteProfile(catalogueId)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setProfile(response.data.data)
                    if (profileDataChange) profileDataChange(response.data.data)
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.response?.data?.message);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error here", error)
            })
        // }
    }, [catalogueId])
    useEffect(() => {
        sendCatalogueEvent();
        // if (emailVerificationResponse) {
        //     intervalValue = setInterval(() => {
        //         profileDurationApiCall();
        //     }, intervalTime)

        // }

        // return () => {
        //     clearInterval(intervalValue);
        // }
    }, [props?.emailVerificationResponse]);

    const profileDurationApiCall = async () => {
        catalogueViewDurationEvent(catalogueId, 2, readCookie(`websitePreviewEmail`) || ``, Math.floor(intervalTime / 1000))
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    console.log("Profile view event register successfully");
                }
            })
            .catch((err) => {
                console.log("we have error at catalogueViewDurationEvent", err);
            })

    }
    const sendCatalogueEvent = () => {

        if (emailVerificationResponse && !isProfileViewEventRegister) {
            catalogueViewEvent(catalogueId, 2, readCookie(`websitePreviewEmail`) || ``)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status) {
                        if (onProfileViewEventResponse) onProfileViewEventResponse(true);
                    } else {
                        if (onProfileViewEventResponse) onProfileViewEventResponse(false);
                    }
                })
                .catch((error) => {
                    if (onProfileViewEventResponse) onProfileViewEventResponse(false);
                    console.log("We have error at catalogue view event");
                });
        }
    }

    const flagemojiToPNG = (flag, code) => {
        var countryCode = Array.from(flag, (codeUnit) => codeUnit.codePointAt()).map(char => String.fromCharCode(char - 127397).toLowerCase()).join('')
        return `https://flagcdn.com/24x18/${countryCode}.png`;
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };
    return (
        <Box className={`profile-section ${isWebCatalogueNotFound && 'blur-background'}`}>
            {isLoading && <Loader />}
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`information-wrap`}>
                <div className={`info-section`}>
                    <div className={`story-wrap`}>
                        <div className={`heading`}>
                            {`Our Story`}
                        </div>

                        <div className={`description`}>
                            {
                                profile?.our_story && profile?.our_story?.replace('<p></p>', '').length !== 0 ?
                                    <div dangerouslySetInnerHTML={{ __html: profile?.our_story }}></div>
                                    :
                                    `NA`
                            }
                        </div>
                    </div>

                    <div className={`mission-wrap`}>
                        <div className={`heading`}>
                            {`Our Mission`}
                        </div>

                        <div className={`description`}>
                            {
                                profile?.our_mission && profile?.our_mission?.replace('<p></p>', '').length !== 0 ?
                                    <div dangerouslySetInnerHTML={{ __html: profile?.our_mission }}></div>
                                    :
                                    `NA`
                            }
                        </div>
                    </div>

                    <div className={`vision-wrap`}>
                        <div className={`heading`}>
                            {`Our Vision`}
                        </div>

                        <div className={`description`}>
                            {
                                profile?.our_vision && profile?.our_vision?.replace('<p></p>', '').length !== 0 ?
                                    <div dangerouslySetInnerHTML={{ __html: profile?.our_vision }}></div>
                                    :
                                    `NA`
                            }
                        </div>
                    </div>
                </div>

                <div className={`overview-section`}>

                    <div className={`overview-text`}>
                        overview
                    </div>

                    <div className={`brand-origin`}>
                        {
                            profile?.country_image
                                ?
                                <div>
                                    <div className={`text`}> Country Origin </div>
                                    <div className={`country-img`} tooltip={profile?.country_name}>
                                        <span>
                                            <img src={flagemojiToPNG(profile?.country_image)} alt="flag image" loading='lazy' />
                                        </span>
                                    </div>
                                </div>
                                :
                                ''
                        }


                        <div className={`year-trading`}>
                            <div className={`text`}>
                                Years Trading
                            </div>
                            <div className={`desc`}>
                                {profile?.yearoftrading || `NA`}
                            </div>
                        </div>

                        <div className={`number-of-skus`}>
                            <div className={`text`}>
                                Number of SKU’s
                            </div>
                            <div className={`desc`}>
                                {profile?.numberofsku || `NA`}
                            </div>
                        </div>
                        {
                            brands?.is_price && productSettings?.pro_priceitem &&
                            <Fragment>
                                <div className={`msrp-range`}>
                                    <div className={`text`}>
                                        Retail Price Range
                                    </div>
                                    <div className={`desc`}>
                                        {`${profile?.msrp_range?.currency || `NA`} ${profile?.msrp_range?.pricemin || `NA`} - ${profile?.msrp_range?.currency || `NA`} ${profile?.msrp_range?.pricemax || `NA`}`}
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </div>

                    {
                        (profile?.facebook_link || profile?.twitter_link || profile?.instagram_link) &&
                        <div className={`social-section`}>
                            <div className={`text`}>
                                Find us on
                            </div>

                            <div className={`social-icons`}>
                                {
                                    profile?.facebook_link &&
                                    <a className={`fb`} href={profile?.facebook_link} target="_blank">
                                        <FacebookWebsiteIcon />
                                    </a>
                                }

                                {
                                    profile?.twitter_link &&
                                    <a className={`twitter`} href={profile?.twitter_link} target="_blank">
                                        <TwitterWebsiteIcon />
                                    </a>

                                }

                                {
                                    profile?.instagram_link &&
                                    <a className={`instagram`} href={profile?.instagram_link} target="_blank">
                                        <InstagramWebsiteIcon />
                                    </a>
                                }

                                {
                                    profile?.linkedin_link &&
                                    <a className={`linkedin`} href={profile?.linkedin_link} target="_blank">
                                        <LinkedInIcon />
                                    </a>
                                }

                                {
                                    profile?.website_link &&
                                    <a className={`website`} href={profile?.website_link} target="_blank">
                                        <LanguageIcon />
                                    </a>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>

            {
                profile?.productlist && profile?.productlist?.length > 0 &&
                <div className={`some-of-product-wrap`}>
                    <div className={`head-wrap`}>
                        <div className={`heading`}>
                            Some of our products
                        </div>
                        <div className={`view-more skip-btn`} onClick={() => { if (onViewMoreClick) onViewMoreClick(`Products`) }}>
                            View More
                        </div>
                    </div>
                    <div className={`product-cards some-of-our-pro`}>
                        {
                            profile?.productlist && profile?.productlist?.length > 0 &&
                            profile?.productlist?.map((product, index) => {
                                return (
                                    <Fragment key={index}>
                                        <ProductCard
                                            product={product}
                                            catalogueId={catalogueId}
                                            pageType={pageType}
                                            brands={brands}
                                            productSettings={productSettings}
                                            onProductNameClick={onProductNameClick}
                                        />
                                    </Fragment>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </Box>
    )
}

const EmailVerification = (props) => {

    const { catalogueId,
        onClose,
        brands,
        isWebCatalogueNotFound,
        catalogueErrorMessage,
        catalogueWebsitekey,
        onProductViewEventResponse } = props;

    const [isWebsitePublic, setIsWebsitePublic] = useState(props?.isPublic),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.body.classList.add("overflow-hide");

        return () => {
            document.body.classList.remove("overflow-hide");
        }
    }, [])

    useEffect(() => {
        setIsWebsitePublic(props?.isPublic);
    }, [props?.isPublic]);
    const schema = yup
        .object({
            email: yup
                .string()
                .required("Email is required")
                .email("Invalid email format")
        })
        .required();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const onSubmit = (data) => {

        createCookie("websitePreviewEmail", data?.email || ``, 1440);
        sendCatalogueEvent(data?.email);
        if (!isWebsitePublic) {
            setIsLoading(true);
            emailVerification(catalogueId, data?.email || ``)
                .then((response) => {
                    if (response.data && response.status === 200 && response.data && response.data.status) {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("success");
                        setAlertMessage(response?.data?.message);
                        setTimeout(() => {
                            if (onClose) {
                                onClose(false, "success");
                            }
                        }, 1000)
                    } else {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        if (response?.message === `Network Error`) {
                            setAlertMessage(`No internet connection`);
                        } else {
                            setAlertMessage(response?.data?.message);
                        }
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log("we have error at emailVerification", err);
                })
        } else {
            if (onClose) onClose(false, "success");
        }
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const sendCatalogueEvent = (email) => {
        console.log(`checking yaar here`);
        if (email) {
            catalogueViewEvent(catalogueId || catalogueWebsitekey, 1, email)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status) {
                        if (onProductViewEventResponse) onProductViewEventResponse(true);
                    } else {
                        if (onProductViewEventResponse) onProductViewEventResponse(false);
                    }
                })
                .catch((error) => {
                    if (onProductViewEventResponse) onProductViewEventResponse(false);
                    console.log("We have error at catalogue view event");
                });
        }
    }

    return (
        <Box className={`email-verification-popup`}>
            {isLoading && <Loader />}

            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`email-verification-wrap`}>
                {
                    isWebsitePublic &&
                    <div className={`close-icon`}>
                        <CloseIcon onClick={() => { if (onClose) onClose(false, "not-success") }} />
                    </div>
                }

                <div className={`brand-information-wrap`}>
                    {
                        brands?.b_logo &&
                        <div className={`brand-logo asdasdasdasd`}>
                            <img src={brands?.b_logo} loading="lazy" alt="brand logo" />
                        </div>
                    }

                    {
                        isWebCatalogueNotFound &&
                        <div className={`catalogue-error-icon`}>
                            <ErrorOutlineIcon />
                        </div>
                    }
                    <div className={`brand-info`}>
                        {
                            brands?.name &&
                            <div className={`name`}>
                                {brands?.name}
                            </div>
                        }

                        {
                            brands?.tagline &&
                            <div className={`tagline`}>
                                {brands?.tagline}
                            </div>
                        }

                    </div>
                </div>
                <div className={`first-heading`}>
                    <Fragment>
                        {
                            !isWebCatalogueNotFound ?
                                isWebsitePublic ? ("Enter your email address") : ("Email verification")
                                :
                                catalogueErrorMessage
                        }
                    </Fragment>
                </div>

                {
                    !isWebCatalogueNotFound &&
                    <div className={`second-heading`}>
                        {
                            isWebsitePublic ? ("This will allow us to serve you better") : ("Start with adding your email")
                        }
                    </div>
                }


                {
                    !isWebCatalogueNotFound &&
                    <form className={`form-input-control`} onSubmit={handleSubmit(onSubmit)}>

                        <Box className={`form-control margin-bottom-28 position-relative`}>
                            <TextField
                                type={"text"}
                                id="email"
                                name="email"
                                className={`input-wrap add-email-input`}
                                placeholder={`eg. johndoe@company.com`}
                                margin="dense"
                                {...register("email")}
                                error={errors.email ? true : false}
                            />
                            {
                                errors.email?.message &&
                                <p className={`field-err-msg add-new-pro-custom-style`}>{errors.email?.message}</p>
                            }
                        </Box>

                        <Box className={`form-control margin-bottom-28 button-wrap`}>
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                className={`btn-primary-after-login ${isValid ? `` : `disabled`}`}
                                disabled={isValid ? false : true}
                            >
                                {
                                    isWebsitePublic ? ("View Catalogue") : ("Verify Email")
                                }
                            </Button>
                        </Box>
                    </form>
                }

            </div>
        </Box >
    )
}
const WebsitePreview = (props) => {
    let catalogueId = useParams()[`catalogueId`] || props?.catalogueId;

    const { appData, onPreviewClose } = props;

    const intervalTime = 60000, location = useLocation(), idleTimeout = 180000;

    const [brands, setBrands] = useState({}),
        [profile, setProfile] = useState([]),
        [productList, setProductList] = useState([]),
        [isLoading, setIsLoading] = useState(false),
        [selectedOption, setSelectedOption] = useState(`Products`),
        [isNext, setIsNext] = useState(false),
        [isEmailVerificationShow, setIsEmailVerificationShow] = useState(appData?.app?.isEmailVerificationShowCatalogue),
        [emailVerificationResponse, setEmailVerificationResponse] = useState(''),
        [isProductViewEventRegister, setIsProductViewEventRegister] = useState(false),
        [isProfileViewEventRegister, setIsProfileViewEventRegister] = useState(false),
        [pageType, setPageType] = useState(props?.pageType || `cataloguePreview`),
        [isPreviewExitSectionShow, setIsPreviewExitSectionShow] = useState(pageType === `catalogue` ? true : false),
        [catalogueWebsitekey, setCatalogueWebsitekey] = useState(props?.catalogueId || ``),
        [catalogueWebsiteProductId, setCatalogueWebsiteProductId] = useState(``),
        [productSettings, setProductSettings] = useState({}),
        [isLoginShow, setIsLoginShow] = useState(false),
        [isWebCatalogueNotFound, setWebCatalogueFound] = useState(false),
        [catalogueErrorMessage, setCatalogueErrorMessage] = useState(``),
        [isEmailVerificaionCloseByUser, setEmailVerificationCloseByUser] = useState(false),
        [productDetailsSelectedMenu, setProductDetailsSelectedMenu] = useState(`Overview`);

    const moduleApis = [];
    const moduleApiIndex = [];

    const optionMenu = [`Products`, `Profile`];

    const onEmailVerificationClose = (value, response) => {
        setIsEmailVerificationShow(value);
        setEmailVerificationResponse(response);
        if (response === `not-success`) {
            setEmailVerificationCloseByUser(true);
        }
    }
    useEffect(() => {

        setSelectedOption("Products");
        window.onbeforeunload = function () {
            // return "Do you really want to close?";
            return;
        };

        setIsLoading(true);
        moduleApis.push(getWebsiteBrand(catalogueId));
        moduleApiIndex.push(`brandIndex`);

        // moduleApis.push(getWebsiteProfile(catalogueId));
        // moduleApiIndex.push(`profileIndex`);

        moduleApis.push(getWebsiteProduct(catalogueId, ``, 1));
        moduleApiIndex.push(`productIndex`);

        Promise.all(moduleApis)
            .then((result) => {
                let brandIndex = moduleApiIndex.indexOf('brandIndex');
                if (brandIndex !== -1 && result.length) {
                    const dataResult = result[brandIndex] ? result[brandIndex] : {};

                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                        setBrands(dataResult.data.data);
                        setProductSettings(dataResult.data.data.product_settings);
                    }

                    if (dataResult?.response?.status === 400) {
                        setWebCatalogueFound(true);
                        setCatalogueErrorMessage(dataResult?.response?.data?.message || ``)
                    }
                }

                // let profileIndex = moduleApiIndex.indexOf('profileIndex');
                // if (profileIndex !== -1 && result.length) {
                //     const dataResult = result[profileIndex] ? result[profileIndex] : {};
                //     if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                //         setProfile(dataResult.data.data);
                //     }
                // }

                let productIndex = moduleApiIndex.indexOf('productIndex');
                if (productIndex !== -1 && result.length) {
                    const dataResult = result[productIndex] ? result[productIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                        setProductList(dataResult.data.data);
                        setIsNext(dataResult?.data?.next ? true : false);
                    }
                }

                setIsLoading(false);
            })
            .catch(function (err) {
                console.log('A promise failed to resolve', err);
            })
    }, [props?.catalogueId])

    useEffect(() => {
        setCatalogueWebsitekey(props?.catalogueId);
    }, [props?.catalogueId])

    const profileDataChange = (data) => {
        setProfile(data);
    }

    const onProductNameClick = (productId) => {
        setCatalogueWebsiteProductId(productId);
    }

    return (
        <Box className={`web-catalogue-wrapper ${catalogueWebsiteProductId ? 'web-catalogue-details-wrapper' : ''}`}>
            {isLoading && <Loader />}

            {
                pageType === `cataloguePreview` && Object.keys(brands).length !== 0 &&
                <MetaTagInjector
                    title={brands?.name}
                    icon={brands?.b_logo}
                    description={`Click here to view our product catalogue`}
                    ogTitle={brands?.name}
                    ogDescription={`Click here to view our product catalogue`}
                    ogImage={brands?.b_logo}
                />
            }

            {
                catalogueWebsiteProductId && pageType !== `cataloguePreview`
                    ?
                    <WebsitePreviewDetails
                        catalogueId={catalogueWebsitekey}
                        productId={catalogueWebsiteProductId}
                        pageType={`catalogue`}
                        onBackClick={() => { setCatalogueWebsiteProductId(``) }}
                        onPreviewClose={() => { if (onPreviewClose) onPreviewClose() }}
                        isWebCatalogueNotFound={isWebCatalogueNotFound}
                        catalogueErrorMessage={catalogueErrorMessage}
                        selectedOptionValue={productDetailsSelectedMenu}
                        onSelectedOptionChange={(value) => setProductDetailsSelectedMenu(value)}
                    />
                    :
                    <Fragment>
                        {
                            isEmailVerificationShow && pageType === `cataloguePreview` &&
                            <EmailVerification
                                onClose={(value, response) => { onEmailVerificationClose(value, response) }}
                                isPublic={brands?.is_public || false}
                                catalogueId={catalogueId}
                                brands={brands}
                                isWebCatalogueNotFound={isWebCatalogueNotFound}
                                catalogueErrorMessage={catalogueErrorMessage}
                                catalogueWebsitekey={catalogueWebsitekey}
                                onProductViewEventResponse={(value) => { setIsProductViewEventRegister(value) }}
                            />
                        }
                        {
                            isPreviewExitSectionShow &&
                            <PreviewExitSction
                                onPreviewClose={onPreviewClose}
                                isWebCatalogueNotFound={isWebCatalogueNotFound}
                            />
                        }

                        <PreviewHeaderSection
                            brand={brands}
                            catalogueId={catalogueId}
                            isWebCatalogueNotFound={isWebCatalogueNotFound}
                        />

                        <PreviewBanner
                            brand={brands}
                            isWebCatalogueNotFound={isWebCatalogueNotFound}
                        />

                        {
                            pageType !== `catalogue` &&
                            <div className={`save-catalogue ${isWebCatalogueNotFound && 'blur-background'}`}>
                                <Button
                                    variant="contained"
                                    onClick={() => { setIsLoginShow(true) }}
                                    className='btn-primary-after-login'
                                >
                                    Save
                                </Button>
                            </div>
                        }

                        {
                            isLoginShow &&
                            <LoginSection
                                onClose={(value) => { setIsLoginShow(value) }}
                                catalogueId={catalogueId}
                                isWebCatalogueNotFound={isWebCatalogueNotFound}
                            />
                        }

                        <Box className={`manage-section ${isWebCatalogueNotFound && 'blur-background'}`}>
                            <Box className={`option-wrapper`}>
                                {
                                    optionMenu && optionMenu.map((menu, index) => {
                                        return (
                                            <div key={index} className={`option ${selectedOption === menu ? 'selected' : ''}`} onClick={() => { setSelectedOption(menu) }}>
                                                {menu}
                                            </div>
                                        )
                                    })
                                }
                            </Box>

                            <div className={`divider`}></div>
                        </Box>

                        {
                            selectedOption.toLocaleLowerCase() === 'products'
                                ?
                                <ProductsSection
                                    productList={productList}
                                    hasNext={isNext}
                                    emailVerificationResponse={emailVerificationResponse}
                                    onProductViewEventResponse={(value) => { setIsProductViewEventRegister(value) }}
                                    isProductViewEventRegister={isProductViewEventRegister}
                                    intervalTime={intervalTime}
                                    idleTimeout={idleTimeout}
                                    brands={brands}
                                    catalogueId={catalogueId}
                                    pageType={pageType}
                                    catalogueWebsitekey={catalogueWebsitekey}
                                    onProductNameClick={onProductNameClick}
                                    productSettings={productSettings}
                                    isWebCatalogueNotFound={isWebCatalogueNotFound}
                                    isEmailVerificaionCloseByUser={isEmailVerificaionCloseByUser}

                                />
                                :
                                <ProfileSection
                                    profileData={profile}
                                    onViewMoreClick={(value) => { setSelectedOption(value) }}
                                    emailVerificationResponse={emailVerificationResponse}
                                    onProfileViewEventResponse={(value) => { setIsProfileViewEventRegister(value) }}
                                    isProfileViewEventRegister={isProfileViewEventRegister}
                                    intervalTime={intervalTime}
                                    brands={brands}
                                    profileDataChange={profileDataChange}
                                    catalogueId={catalogueId}
                                    pageType={pageType}
                                    catalogueWebsitekey={catalogueWebsitekey}
                                    productSettings={productSettings}
                                    isWebCatalogueNotFound={isWebCatalogueNotFound}
                                    isEmailVerificaionCloseByUser={isEmailVerificaionCloseByUser}
                                    onProductNameClick={onProductNameClick}
                                />
                        }
                        <div className={`footer-catalogue-exit ${isWebCatalogueNotFound && 'blur-background'}`}>
                            {
                                isPreviewExitSectionShow &&
                                <PreviewExitSction
                                    onPreviewClose={onPreviewClose}
                                    isWebCatalogueNotFound={isWebCatalogueNotFound}
                                />
                            }
                        </div>

                    </Fragment>
            }
        </Box>
    )
}



//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(WebsitePreview);
