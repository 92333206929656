const setRegistration = (registrationObject) => {
    return {
        type: "SET_REGISTRATION",
        payload: registrationObject
    }
}

const getRegistration = () => {
    return {
        type: "GET_REGISTRATION"
    }
}


export default {
    setRegistration,
    getRegistration
}