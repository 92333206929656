import React from "react";

const copy = (props) => {
  const { name = "", size = "s", color = "#fff", className = "" } = props;

  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.12598" y="0.5" width="39" height="39" rx="5.5" fill="white" stroke="#E6E6E6"/>
        <rect x="7.62598" y="7" width="10" height="10" rx="2" fill="#3357FF"/>
        <rect x="7.62598" y="22" width="10" height="10" rx="2" fill="#3357FF"/>
        <rect x="22.626" y="7" width="10" height="10" rx="2" fill="#3357FF"/>
        <rect x="23.126" y="22.5" width="9" height="9" rx="1.5" fill="white" stroke="#C2C2C2"/>
    </svg>
    
  );
};

export default copy;
