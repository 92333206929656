
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';


const getBrandProfileData = async (brandID = ``) => {

    const headers = await getApiHeader() || {};;

    if(!brandID) {
        return {
            error: true,
            message: 'brandId mandatory to get the detail function' 
        }
    }
    
    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in getBrandProfileData function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-profile/${brandID}`, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default getBrandProfileData;