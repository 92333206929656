import React, { useState, useEffect } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";

//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

//Components related imports
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

import getUserDetail from "../../../../apis/getUserDetail";
//Redux related imports
import countryListActions from "../../../../store/action/countryListAction";
import userDetailAction from "../../../../store/action/userDetailAction";

//Api related imports
import getCountryList from "../../../../apis/getCountryList";
import { useLocation } from 'react-router-dom';

const Profile = (props) => {

    const location = useLocation();
    const [userDetail, setUserDetail] = useState(props?.userDetailData?.user?.data || {});
    const [countryList, setCountryList] = useState(props?.countryListData?.countryList || []);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [selectedMenu, setSelectedMenu] = useState(`Your Profile`);
    const accountSettingsData = [`Your Profile`, `Change Password`];
    const companySettingsData = [`My Companies`, `My Categories`, `My Brands`, `Show/hide product details`, `Product Details`];
    const [selectedAccordionType, setSelectedAccordionType] = useState(`account`);
    const onMenuChange = (menu) => {
        setSelectedMenu(menu);
    }

    useEffect(() => {
        if (countryList && Object.keys(countryList).length === 0) {
            setIsLoading(true);
            getCountryList()
                .then((response) => {
                    if (
                        response &&
                        response.status === 200 &&
                        response.data.status &&
                        response.data.data &&
                        response.data.data.length > 0
                    ) {
                        setIsLoading(false);
                        setCountryList(response.data.data);
                        dispatch(countryListActions.setCountryList(response.data.data));
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("We have error", error);
                });
        }
    }, []);

    const propsupdationclick = (value) => {
        // console.log('props updated')
        getUserDetail()
            .then((response) => {
                if (response && response.status === 200 && response.data) {
                    if (response.data.status) {
                        dispatch(userDetailAction.setUserDetail(response.data));
                        setUserDetail(response.data.data)
                    }
                }
            })
            .catch((error) => {
                console.log("Checking error", error);
            });
    }

    return (
        <Box className={`buyer-profile-wrapper mobile-setting`}>

            <LeftPanel
                onMenuChange={onMenuChange}
                accountSettingsData={accountSettingsData}
                companySettingsData={companySettingsData}
                defaultAccordionType={selectedAccordionType}
                defaultSelectedMenu={selectedMenu}
                isCompanySettingsShow={false}
            />
            <RightPanel propsupdate={propsupdationclick} selectedMenu={selectedMenu} userDetail={userDetail} countrylist={countryList} />
        </Box>
    )
}

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);