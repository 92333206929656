
import axios from 'axios';
import getApiHeader from '../../helpers/getApiHeader';
import { CONFIG } from '../../helpers/getApiConfig';

const getWebsiteProduct = async (catalogueId, search = ``, page = 1) => {

    if (!catalogueId) {
        return {
            error: true,
            message: 'catalogueId mandatory in getWebsiteProduct function'
        }
    }

    const instance = axios.create();

    const params = {};

    if(search) params[`search`] = search;
    if(page) params[`page`] = page;
    
    instance.defaults.timeout = CONFIG.timeout || 15000;
    const headers = await getApiHeader() || {};;
    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-website-product/${catalogueId}`, { params, headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default getWebsiteProduct;