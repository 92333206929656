import React, { useEffect } from 'react';

import { Helmet } from "react-helmet";

// React route related imports
import { useLocation } from "react-router-dom";

const MetaTagInjector = (props) => {

    const { title, icon, description, ogTitle, ogDescription, ogImage } = props;

    const currentPath = useLocation().pathname;

    const isChatbotShow = [`/support`].indexOf(currentPath) !== -1;
    
    if (document.querySelector(`#hubspot-messages-iframe-container`)) {
        document.querySelector(`#hubspot-messages-iframe-container`).style.setProperty(`display`, `${isChatbotShow ? 'block' : 'none'} `, `important`);
    }

    return (
        <Helmet>
            <title>{title || "Discoverrr"}</title>
            <link rel="icon" href={icon || "/discoverrr-fav-icon.ico"} sizes="16x16" />
            <meta
                name="description"
                content={description || "Click here to view our product catalogue"}
            />
            <meta property="title" content={title || "Discoverrr"} />
            <meta property="image" content={ogImage || "/discoverrr-fav-icon.ico"} />
            <meta property="og:title" content={ogTitle || "Discoverrr"} />
            <meta property="og:description" content={ogDescription || "Click here to view our product catalogue"} />
            <meta property="og:image" content={ogImage || "/discoverrr-fav-icon.ico"} />
            <meta property="og:url" content={`${window.location.href}`} />
            <meta property="og:site_name" content="Discoverrr" />
        </Helmet>
    )
}

export default MetaTagInjector;