
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import { CONFIG } from '../../helpers/getApiConfig';

const catalogueViewDurationEvent = async (catalogueWebId, type, email, duration) => {

    if (!catalogueWebId) {
        return {
            error: true,
            message: 'catalogueWebId mandatory in catalogueViewDurationEvent function'
        }
    }

    if (!type) {
        return {
            error: true,
            message: 'type mandatory in catalogueViewDurationEvent function'
        }
    }

    if (!duration) {
        return {
            error: true,
            message: 'duration mandatory in catalogueViewDurationEvent function'
        }
    }

    const instance = axios.create();
    instance.defaults.timeout = CONFIG.timeout || 15000;

    const headers = await getApiHeader() || {};;

    var formData = new FormData();

    formData.append("type", type);
    formData.append("email", email || ``);
    formData.append("timeDuration", duration);

    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/analytics/catalogue-view-duration/${catalogueWebId}`, formData, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default catalogueViewDurationEvent;