
import axios from 'axios';

import {CONFIG} from '../helpers/getApiConfig';

const emailVerify = async (token) => {
    if(!token) {
        return {
            error: true,
            message: 'token mandatory in emailVerify function' 
        }
    }
    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();
    if(token) formData.append("token", token);

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/email-verify`,formData)
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default emailVerify