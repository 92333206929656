
import axios from 'axios';

import { CONFIG } from '../../helpers/getApiConfig';

import getApiHeader from "../../helpers/getApiHeader";

const getStarredCatalogueList = async (search = ``) => {
   
    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    const headers = await getApiHeader() || {};
    let filtervariable = ""
    if (search) {
        filtervariable = `?search=${search}`
    }
    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/savedcatalogue/starredcatalogue${filtervariable}`, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default getStarredCatalogueList