
import axios from 'axios';

import { CONFIG } from '../../helpers/getApiConfig';

import getApiHeader from '../../helpers/getApiHeader';

const ourTeamPasswordSet = async (password, confirmPassword) => {

    const headers = await await getApiHeader() || {};

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in ourTeamPasswordSet function' 
        }
    }

    const instance = axios.create();

    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();
    if (password) formData.append("password", password);
    if (confirmPassword) formData.append("confirmpassword", confirmPassword);

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/team-user-password-set`, formData, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default ourTeamPasswordSet