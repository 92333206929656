
import axios from 'axios';

import {CONFIG} from '../helpers/getApiConfig';

import getApiHeader from '../helpers/getApiHeader';

const getUserOnBoardingInformation = async () => {

    const headers = await getApiHeader() || {};;

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in getUserOnBoardingInformation function' 
        }
    }

    const instance = axios.create();
    
    instance.defaults.timeout = CONFIG.timeout || 15000;
    

    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/registerOnboarding`,{headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default getUserOnBoardingInformation;