import React, { useState, useEffect } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import Switch from '@mui/material/Switch';

// Apis related imports
import updateProductpagesettings from '../../../../apis/Supplier/updateProductpagesettings';

//Components related imports
import Loader from "../../Loader";
import AlertMessage from "../../AlertMessage";

const ProductPageSetting = (props) => {
    const { isSettingShow, onClose, onSuccess, page } = props;

    const [isLoading, setIsLoading] = useState(false),
        [userSetting, setUserSetting] = useState(props?.userSettings || {}),
        [isProDetailShow, setIsProDetailShow] = useState(userSetting?.pro_detail_page || false),
        [isTechSpecificationShow, setIsTechSpecificationShow] = useState(userSetting?.tech_spec_page || false),
        [isCerandapprovalsShow, setIsCerandapprovalsShow] = useState(userSetting?.cert_and_apvl_page || false),
        [isDistributionShow, setIsDistributionShow] = useState(userSetting?.distribution_page || false),
        [isMarketingShow, setIsMarketingShow] = useState(userSetting?.marketing_page || false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [userSettingTrueCount, setUserSettingTrueCount] = useState(0);

    useEffect(() => {
        if (isSettingShow) {
            document.body.classList.add('hide-scroll');
        } else {
            document.body.classList.remove('hide-scroll');
        }

        setUserSetting(props?.userSettings || {});
        setIsProDetailShow(props?.userSettings?.pro_detail_page || false);
        setIsTechSpecificationShow(props?.userSettings?.tech_spec_page || false);
        setIsCerandapprovalsShow(props?.userSettings?.cert_and_apvl_page || false);
        setIsDistributionShow(props?.userSettings?.distribution_page || false);
        setIsMarketingShow(props?.userSettings?.marketing_page || false);

        setUserSettingTrueCount(Object.values(props?.userSettings).reduce((a, item) => a + (item === true ? 1 : 0), 0))
        return () => {
            document.body.classList.remove('hide-scroll');
        }
    }, [isSettingShow])

    const onSettingSave = () => {
        const productSettingObject = {
            'pro_detail_page': isProDetailShow ? 'True' : 'False',
            'tech_spec_page': isTechSpecificationShow ? 'True' : 'False',
            'cert_and_apvl_page': isCerandapprovalsShow ? 'True' : 'False',
            'distribution_page': isDistributionShow ? 'True' : 'False',
            'marketing_page': isMarketingShow ? 'True' : 'False',
        }

        updateProductpagesettings(productSettingObject)
            .then((response) => {
                if (response?.status === 200 && response?.data?.status && response?.data?.data) {
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response?.data?.message);
                    if (onSuccess) onSuccess(response?.data?.data)
                    if (onClose) onClose(false);
                }
            })
            .catch((err) => {
                console.log("we have error at updatePorductSetting", err);
            })
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onSwitchChange = (flag) => {
        if (flag) {
            setUserSettingTrueCount(userSettingTrueCount + 1)
        } else {
            setUserSettingTrueCount(userSettingTrueCount - 1)
        }
    }
    return (
        <Box className={`setting-wrapper ${isSettingShow ? 'show-overlay' : ''}`}>
            <div className={`setting-sidenav ${isSettingShow ? 'show' : 'hide'}`}>
                {isLoading && <Loader />}
                {isAlertMessageShow &&
                    <AlertMessage
                        message={alertMessage}
                        alertType={alertMessageType}
                        isAlertShow={isAlertMessageShow}
                        onClose={onAlertClose}
                    />
                }
                <div className={`setting-wrap`}>
                    <div className={`heading-wrap`}>
                        <div className={`heading`}>
                        Product Details
                        </div>

                        <div className={`close-icon`} onClick={() => { if (onClose) onClose(false) }}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className={`divider`}></div>

                    <div className="progress-bar-wrap">
                        <div className="progress-bar-counter">
                            <span>{userSettingTrueCount}</span> out of <span>{Object.keys(userSetting) && Object.keys(userSetting).length > 1 ? Object.keys(userSetting).length - 1 : 0}</span> are Selected!
                        </div>
                        {/* <div className="progress-bar-container">
                            <div class="progress-bar" style={{ width: "59%" }}></div>
                        </div> */}
                    </div>

                    <div className={`setting scroll-bar-theme cust-setting`}>
                        <div>
                            <label>Show Product details</label>
                            <Switch
                                checked={isProDetailShow}
                                onChange={() => { onSwitchChange(!isProDetailShow); setIsProDetailShow(!isProDetailShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Technical specification</label>
                            <Switch
                                checked={isTechSpecificationShow}
                                onChange={() => { onSwitchChange(!isTechSpecificationShow); setIsTechSpecificationShow(!isTechSpecificationShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Certifications & approvals</label>
                            <Switch
                                checked={isCerandapprovalsShow}
                                onChange={() => { onSwitchChange(!isCerandapprovalsShow); setIsCerandapprovalsShow(!isCerandapprovalsShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Distribution</label>
                            <Switch
                                checked={isDistributionShow}
                                onChange={() => { onSwitchChange(!isDistributionShow); setIsDistributionShow(!isDistributionShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Marketing</label>
                            <Switch
                                checked={isMarketingShow}
                                onChange={() => { onSwitchChange(!isMarketingShow); setIsMarketingShow(!isMarketingShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    </div>


                    <div className={`button-wrapper flex flex-justify-content-end save-btn`}>
                        <Button
                            variant="contained"
                            size="large"
                            className={`w-100 btn-primary-after-login`}
                            onClick={() => { onSettingSave() }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default ProductPageSetting;