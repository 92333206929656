
import axios from 'axios';

import getApiHeader from '../helpers/getApiHeader';

import {CONFIG} from '../helpers/getApiConfig';


const onBoardingSecondStep = async (processType = 2, userIndustryList = []) => {
    
    const headers = await getApiHeader() || {};;

    if(!processType) {
        return {
            error: true,
            message: 'processType mandatory in onBoardingSecondStep function' 
        }
    }

    if(!userIndustryList) {
        return {
            error: true,
            message: 'userIndustryList mandatory in onBoardingSecondStep function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in onBoardingFirstStep function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    let params = {}

    if(processType) params[`processtype`] = processType;
    if(userIndustryList) params[`user_industrys`] = userIndustryList;

    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/registerOnboarding`,params, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default onBoardingSecondStep;