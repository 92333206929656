import React, { useState, useEffect,useLayoutEffect, Fragment } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

//Icons related imports
import CameraIcon from '../../../../icons/svgs/camera'
import CataloguePreview from '../../../../icons/svgs/cataloguePreview';
import SearchIcon from '../../../../icons/svgs/searchIcon';
import IconBrandDefaultImage from "../../../../icons/svgs/branddefaultimage";
import ProductIcon from "../../../../icons/svgs/productIcon";
import EditImageIcon from "../../../../icons/svgs/editImageIcon";
import FacebookIcon from "../../../../icons/svgs/facebookWebsiteIcon";
import TwitterIcon from "../../../../icons/svgs/twitterWebsiteIcon";
import InstagramIcon from "../../../../icons/svgs/instagramWebsiteIcon";
import AddProductPlusIcon from "../../../../icons/svgs/plusIcon"
import EditIcon from "../../../../icons/svgs/editIcon";
import StatusGreenTick from "../../../../icons/svgs/statusgreentick";


//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import supplierBrandListAction from "../../../../store/action/supplier/supplierBrandListAction";
import yearOfEstablishAction from "../../../../store/action/yearOfEstablishAction";
import countryListActions from "../../../../store/action/countryListAction";
import editProductAction from '../../../../store/action/supplier/editProductAction';
import appObjectAction from "../../../../store/action/appObjectAction";
import userDetailAction from '../../../../store/action/userDetailAction';

// React route related imports
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

//import other component related imports
import CreateBrand from '../CreateBrand';
import BulkUpload from '../BulkUpload';
import ProcessVideo from '../ProcessVideo';
import Loader from '../../Loader';
import AlertMessage from "../../AlertMessage";
import PaginationComponent from '../Pagination';
import Slider from '../Slider';
import Tooltip from '../Tooltip';
import TextEditor from '../TextEditor';
import ConformationPopup from "../ConformationPopup";

//Api related imports
import getBrandList from '../../../../apis/Supplier/getBrandList';
import brandUpdate from '../../../../apis/Supplier/brandUpdate';
import productAddDraft from '../../../../apis/Supplier/productAddDraft';
import getProductsList from '../../../../apis/Supplier/getProductsList';
import productAction from '../../../../apis/Supplier/productAction';
import productDelete from '../../../../apis/Supplier/productDelete';
import productMultipleDelete from '../../../../apis/Supplier/productMultipleDelete';
import getYearOfEstablish from "../../../../apis/getYearOfEstablish";
import getCountryList from "../../../../apis/getCountryList";
import BrandProfile from '../../../../apis/Supplier/brandProfile';
import getBrandProfileData from '../../../../apis/Supplier/getBrandProfileData';
import getProductDetails from '../../../../apis/Supplier/getProductDetails';
import productBulkCopy from '../../../../apis/Supplier/productBulkCopy';

//Helpers function related imports
import getDefaultCountry from '../../../../helpers/getDefaultCountry';
import getDefaultYOE from '../../../../helpers/getDefaultYOE';
import createLocalStorage from '../../../../helpers/createLocalStorage';
import getLocalStorage from '../../../../helpers/getLocalStorage';

//Other imports
import { get, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const CopyProduct = (props) => {

    const { onCancel, brandList, selectedBrand, selectedProduct } = props;

    const [brandsList, setBrandsList] = useState(brandList || []),
        [CopyBrand, setCopyBrand] = useState(''),
        [isProductAlreadyExisted, setProductAlreadyExisted] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isLoading, setIsLoading] = useState(false),
        [productAlreadyExistedMessage, setProductAlreadyExistedMessage] = useState(``),
        [copyProductAction, setCopyProductAction] = useState(``);

    const handleChange = (event) => {
        setCopyBrand(event.target.value);
    };


    useEffect(() => {
        document.body.classList.add('hide-scroll');

        return () => {
            document.body.classList.remove('hide-scroll');
        }
    }, [])

    useEffect(() => {
        setBrandsList(brandsList.filter(item => item?.brand_id !== selectedBrand?.brand_id));
    }, [selectedBrand, brandList])

    useEffect(() => {
        if (copyProductAction) {
            onProductCopy()
        }
    }, [copyProductAction]);

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onProductCopy = () => {

        if (!CopyBrand) {
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            setAlertMessage(`Please select a persona`);

            return;
        }
        setIsLoading(true);
        productBulkCopy(selectedProduct.join(), CopyBrand, copyProductAction)
            .then((response) => {
                if (response?.status === 200 && response?.data?.status) {
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response?.data?.message);
                    setIsLoading(false);
                    setProductAlreadyExisted(false);
                    setTimeout(() => {
                        if (onCancel) onCancel();
                    }, 800)
                } else {
                    setIsLoading(false);
                    setProductAlreadyExisted(true);
                    setProductAlreadyExistedMessage(response?.data?.message);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(`we have error at productBulkCopy`, err);
            })
    }

    return (
        <div className={`copy-product-popup-wrapper`}>
            {isLoading && <Loader />}
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`popup-wrap`}>
                <div className={`close-icon`} onClick={() => { if (onCancel) onCancel() }}>
                    <CloseIcon />
                </div>

                {
                    isProductAlreadyExisted ?
                        <Fragment>
                            <div className={`brand-list`}>
                                <BrandCopyReadMore>
                                    {productAlreadyExistedMessage}
                                </BrandCopyReadMore>
                            </div>

                            <div className={`button-wrap`}>
                                <Button
                                    className={`cancel-btn`}
                                    variant="outlined"
                                    onClick={() => { setCopyProductAction(`1`) }}>
                                    Skip
                                </Button>

                                <Button
                                    className={`submit-btn`}
                                    variant="contained"
                                    onClick={() => { setCopyProductAction(`2`) }}>
                                    Overwrite
                                </Button>
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                                {brandsList && brandsList.length === 0 ? (
                                    <div className="no-persona-found">
                                    No persona found
                                    </div>
                                ) : (
                                    <>
                                    <div className={`heading`}>
                                        Select a Persona
                                    </div>

                                    <FormControl className='form-input-control selection-d-arrow' sx={{ m: 1, minWidth: 100 }} style={{ width: "100%" }}>
                                        
                                        <select
                                        value={CopyBrand}
                                        onChange={handleChange}
                                        >
                                            <option value="">Select a Persona</option>
                                        {
                                        brandList && brandList.length > 0 &&
                                        brandsList.map((brand, index) => (
                                            <option value={brand?.brand_id} key={index}>{brand?.name}</option>
                                        ))}
                                        </select>
                                    </FormControl>
                                    <div className={`button-wrap`}>
                                        <Button
                                        className={`cancel-btn`}
                                        variant="outlined"
                                        onClick={() => { if (onCancel) onCancel() }}>
                                        Cancel
                                        </Button>
                                        <Button
                                        className={`submit-btn`}
                                        variant="contained"
                                        onClick={onProductCopy}>
                                        Copy
                                        </Button>
                                    </div>
                                    </>
                                )}
                        </Fragment>
                }

            </div>
        </div>
    )
}

// copy read more read less function
const BrandCopyReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text">
            {
                isReadMore ? text.slice(0, 100) : text // Slicing text to 100 characters 
            }
            {
                text.length >= 100 // checking text length
                    ?
                    <span onClick={toggleReadMore} className="read-or-hide skip-btn">
                        {isReadMore ? " ...read more" : " read less"}
                    </span>
                    :
                    ""
            }
        </p>
    );
};

const AddBrand = (props) => {

    const { parentProduct, isVariantAdd } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isLoading, setIsLoading] = useState(false),
        [isButtonDisabled, setButtonDisabled] = useState(false);

    const schema = yup
        .object({
            brandName: yup
                .string()
                .trim()
                .required("Product Name is required")
                .min(3, "Too short - should be at least 3 characters")
                .max(70, 'Maximum allowed characters is 70'),
        })
        .required();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
    });

    useEffect(() => {
        document.body.classList.add("overflow-hide");

        return () => {
            document.body.classList.remove("overflow-hide");
        }
    }, [])

    useEffect(() => {
        if(isButtonDisabled) {
            if(document.getElementById(`add-new-product-btn`)) {
                document.getElementById(`add-new-product-btn`).classList.add(`disabled`);
                document.getElementById("add-new-product-btn").disabled = true;
            }
        } else {
            if(document.getElementById(`add-new-product-btn`)) {
                document.getElementById(`add-new-product-btn`).classList.remove(`disabled`);
                document.getElementById("add-new-product-btn").disabled = false;
            }
        }
    },[isButtonDisabled])

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onEditProductClick = (productId) => {
        setIsLoading(true);
        getProductDetails(productId)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    dispatch(editProductAction.setEditProductObject(response.data.data[0]))
                    setIsLoading(false);
                    navigate(`/editproduct/${productId}`);
                } else {
                    setIsLoading(false);
                    navigate(`/editproduct/${productId}`);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                navigate(`/editproduct/${productId}`);
            })
    }

    const onSubmit = (data) => {
        setButtonDisabled(true);
        setIsLoading(true);
        productAddDraft(props?.selectedBrand?.brand_id, data['brandName'], parentProduct?.products_id || ``)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    // setIsLoading(false);
                    // setAlternateMessageShow(true);
                    // setAlertMessageType("success");
                    // setAlertMessage(response.data.message);
                    // navigate(`/editproduct?id=${response?.data?.data?.products_id}`)
                    onEditProductClick(response?.data?.data?.products_id)
                    // setTimeout(() => {
                    //     if (props.onAddNewProductCloseClick) {
                    //         props.onAddNewProductCloseClick(false, `success`, {});
                    //     }
                    // }, 2000);
                    setTimeout(() => {
                        setButtonDisabled(false);
                    },700)
                    
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                    setTimeout(() => {
                        setButtonDisabled(false);
                    },700)
                }
            })
            .catch((err) => {
                setTimeout(() => {
                    setButtonDisabled(false);
                },700)
                console.log("we have error on productAddDraft", err);
            })
    }
    return (
        <Fragment>
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <Box className={`add-brand-popup-wrapper`}>
                <div className={`add-brand-popup`}>
                    <div className={`close-icon`} onClick={() => { if (props.onAddNewProductCloseClick) props.onAddNewProductCloseClick(false, `not-success`, {}) }}>
                        <CloseIcon />
                    </div>
                    <div className={`first-heading`}>
                        {`${isVariantAdd ? 'Add new variant' : 'Add new product'}`}
                    </div>

                    <div className={`second-heading`}>
                        {'Start with adding your product’s name'}
                    </div>
                    <form className={`form-input-control`} autoComplete='off' onSubmit={handleSubmit(onSubmit)}>

                        <Box className={`form-control margin-bottom-28 position-relative`}>
                            <TextField
                                type={"text"}
                                id="addBrandName"
                                name="addBrandName"
                                className={`input-wrap add-brand-input`}
                                placeholder={`e.g. Corn Flakes`}
                                margin="dense"
                                {...register("brandName")}
                                error={errors.brandName ? true : false}
                            />
                            {
                                errors.brandName?.message &&
                                <p className={`field-err-msg add-new-pro-custom-style`}>{errors.brandName?.message}</p>
                            }
                        </Box>

                        <Box className={`form-control margin-bottom-28 button-wrap`}>
                            <Button
                                id="add-new-product-btn"
                                type="submit"
                                variant="contained"
                                size="large"
                                className={`btn-primary-after-login ${isValid && !isButtonDisabled ? `` : `disabled`}`}
                                disabled={isValid && !isButtonDisabled ? false : true}
                            >
                                {`${isVariantAdd ? 'Add Variant' : 'Add Product'}`}
                            </Button>
                        </Box>
                    </form>
                </div>
            </Box>
        </Fragment>
    )
}
const BrandDetail = (props) => {

    const { isProductViewPermission, isCompanyPermissionView } = props;
    const schema = yup
        .object({
            tagline: yup
                .string()
        })

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const moreOptions = [
        {
            "label": "Edit persona",
            "value": "Edit Brand"
        }
    ];
    const [selectedBrand, setSelectedBrand] = useState(props.selectedBrand || {}),
        [isTaglineInputVisible, setTagLineInputVisible] = useState(false),
        [tagLineValue, setTagLineValue] = useState();

    useEffect(() => {
        setSelectedBrand(props.selectedBrand || {});
        setTagLineInputVisible(false)
    }, [props.selectedBrand])

    const onTaglineEnterClick = (event) => {
        if (event.key === 'Enter') {
            setTagLineValue(event.target.value);
            if (props.onBrandUpdate) {
                props.onBrandUpdate(event.target.value, ``);
            }
        }
    }

    const onMoreOptionValueChange = (value, data) => {
        if (props.onEditBrand) {
            props.onEditBrand(true, `Edit Persona`, `Edit your Persona information`, `Update`);
        }
    }
    return (
        <Box className={`brand-detail-wrapper`}>
            <div className={`brand-info-wrapper`}>
                {
                    selectedBrand && selectedBrand?.b_logo
                        ?
                        <div className={`brand-img overflow-hide`}>
                            <img src={selectedBrand?.b_logo || ``} loading="lazy" alt="Brand Image" />
                        </div>
                        :
                        <div className={`brand-img`}>
                            <CameraIcon />
                            <div className={`camera-icon`}>
                                <CameraIcon />
                            </div>
                        </div>
                }

                <div className={`brand-information`}>
                    {
                        selectedBrand && selectedBrand?.name &&
                        <h1 className={`brand-name`}>{selectedBrand?.name}</h1>
                    }

                    {
                        selectedBrand && selectedBrand?.tagline
                            ?
                            <div className={`brand-tagline`}>{selectedBrand?.tagline}</div>
                            :
                            <Fragment>
                                {
                                    isTaglineInputVisible
                                        ?
                                        <div className={`flex flex-items-center`}>
                                            <input type="text" placeholder='Enter a tagline' className={`tagline-input`} onBlur={() => { setTagLineInputVisible(false) }} onKeyPress={onTaglineEnterClick} maxlength="30" />
                                            <CloseIcon className={`close-icon`} onClick={() => { setTagLineInputVisible(false) }} />
                                        </div>
                                        :
                                        <div className={`brand-tagline`} onClick={() => { setTagLineInputVisible(true); }}>{`${selectedBrand?.tagline ? selectedBrand?.tagline : 'Click here to add a Tagline'}`}</div>
                                }
                            </Fragment>
                    }
                </div>
            </div>

            <div className={`brand-more-option-wrapper`}>
                <div className={`brand-publish-catalogue visibility-hidden`}>
                    <Button variant="outlined">Publish catalogue</Button>
                </div>
                {/* <div className={`line`}></div>
                <div className={`catalogue-preview`}>
                    <CataloguePreview />
                </div> */}

                {
                    !isCompanyPermissionView &&
                    <div className={`more-option-icon-wrapper`}>
                        <Tooltip moreOptions={moreOptions} onOptionChange={onMoreOptionValueChange} />
                    </div>
                }

            </div>
        </Box>
    )
}

const BrandManageSection = (props) => {

    const defaultmanagesection = props;
    const optionMenu = [`Manage Products`, `Manage Profile`];
    const [selectedOption, setSelectedOption] = useState(`Manage Products`);

    return (
        <Box className={`brand-manage-section`}>
            <Box className={`option-wrapper`}>
                {
                    optionMenu && optionMenu.map((menu, index) => {
                        return (
                            <div key={index} className={`option ${selectedOption === menu ? 'selected' : ''}`} onClick={() => { if (props.onSelectionChange) props.onSelectionChange(menu); setSelectedOption(menu) }}>
                                {menu}
                            </div>
                        )
                    })
                }
            </Box>

            <div className={`divider`}></div>
        </Box>
    )
}

const ProductFilterSection = (props) => {

    const { selectedBrand, brandResponse, isProductViewPermission, isCompanyPermissionView, brandList, pagination } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [productList, setProductList] = useState([]),
        [isLoading, setIsLoading] = useState(false),
        [isbulkuploadswitched, setIsbulkuploadswitched] = useState(selectedBrand?.is_bulk_upload || false),
        [filterText, setFilterText] = useState(`Filter By Status`),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [searchValue, setSearchValue] = useState(``),
        [totalCount, setTotalCount] = useState(0),
        [perPage, setPerPage] = useState(0),
        [isPaginatioShow, setIsPaginatioShow] = useState(false),
        [currentPage, setCurrentPage] = useState(pagination?.brandPagination || 1),
        [isCheckAll, setIsCheckAll] = useState(false),
        [isCheck, setIsCheck] = useState([]),
        [isConfirmationPopupShow, setIsConfirmationPopupShow] = useState(false),
        [moreOptionSelectedData, setMoreOptionSelectedData] = useState({}),
        [conformationPopupSuccessBtnText, setConformationPopupSuccessBtnText] = useState(``),
        [conformationPopupCancelBtnText, setConformationPopupCancelBtnText] = useState(``),
        [conformationPopupText, setConformationPopText] = useState(``),
        [conformationPopupType, setConformationType] = useState(``),
        [userproductlimit, setUserproductlimit] = useState(100),
        [currentproductcount, setcurrentproductcount] = useState(0),
        [isProductButtonDisabled, setIsProductButtonDisabled] = useState(() => Number(currentproductcount) >= Number(userproductlimit)),
        [isCopyProductShow, setCopyProductShow] = useState(false);
        


    // useEffect(() => {
    //     setIsCheckAll(false);
    // }, [isCheckAll])

    useEffect(() => {
        let tempProductIds = [], existAll = false;

        if(productList && productList.length > 0) {
            productList.map((product) => {
                tempProductIds.push(product?.products_id);
            })
        }

        if(tempProductIds.length === isCheck.length) {
            existAll = tempProductIds.every(element => isCheck.includes(element));
        }

        if(existAll) {
            setIsCheckAll(true);
        } else {
            setIsCheckAll(false);
        }

    },[isCheck])
    useEffect(() => {
        getProductListData(1);
        setCurrentPage(1);
    }, [selectedBrand])

    useEffect(() => {
        if (brandResponse === 'success') {
            getProductListData();
        }
    }, [brandResponse])

    useLayoutEffect(() => {
        console.log('currentproductcount',Number(currentproductcount))
        console.log('userproductlimit',Number(userproductlimit))
        setIsProductButtonDisabled(Number(currentproductcount) >= Number(userproductlimit));
    }, [userproductlimit, currentproductcount]);

    useEffect(() => {
        setCurrentPage(pagination?.brandPagination || 1);
    }, [pagination])

    useEffect(() => {
        getProductListData();
    }, [currentPage]);

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onPageChange = (page) => {
        dispatch(userDetailAction.setUserPagiantion({
            "brandPagination": page,
        }))
        setCurrentPage(page);
    }
    const getProductListData = (page) => {
        setIsLoading(true);
        getProductsList(selectedBrand?.brand_id, searchValue, ``, page || currentPage)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setProductList(response?.data?.data);
                    setTotalCount(response?.data?.count);
                    setPerPage(response?.data?.perpage);
                    setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                    setUserproductlimit(response?.data?.productlimit);
                    setcurrentproductcount(response?.data?.user_product_count);
                    // setCurrentPage(1);
                    setIsLoading(false);
                    setIsCheckAll(false);
                    setIsCheck([]);
                    
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at productsList", error);
            });
    }

    const onMoreOptionValueChange = (option, data) => {
        setMoreOptionSelectedData(data);
        switch (option[`value`]) {

            case `Make A Copy`:
                productAction(data?.products_id, 'COPY')
                    .then((response) => {
                        if (response && response.status === 200 && response.data && response.data.status) {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("success");
                            setAlertMessage(response.data.message);
                            setTimeout(() => {
                                getProductListData();
                            }, 500);
                        } else {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("error");
                            if (response?.message === `Network Error`) {
                                setAlertMessage(`No internet connection`);
                            } else {
                                setAlertMessage(response?.data?.message)
                            }
                        }
                    })
                    .catch((err) => {
                        console.log("we have error on Product Action", err);
                    })
                break;
            case `Out of stock`:
                productAction(data?.products_id, 'OUT_OF_STOCK')
                    .then((response) => {
                        if (response && response.status === 200 && response.data && response.data.status) {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("success");
                            setAlertMessage(response.data.message);
                            console.log("Checking data here", response.data.data);
                            setTimeout(() => {
                                getProductListData();
                            }, 500);
                        } else {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("error");
                            if (response?.message === `Network Error`) {
                                setAlertMessage(`No internet connection`);
                            } else {
                                setAlertMessage(response?.data?.message)
                            }
                        }
                    })
                    .catch((err) => {
                        console.log("we have error on Product Action", err);
                    })
                break;
            case `In stock`:
                productAction(data?.products_id, 'IN_STOCK')
                    .then((response) => {
                        if (response && response.status === 200 && response.data && response.data.status) {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("success");
                            setAlertMessage(response.data.message);
                            setTimeout(() => {
                                getProductListData();
                            }, 500);
                        } else {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("error");
                            if (response?.message === `Network Error`) {
                                setAlertMessage(`No internet connection`);
                            } else {
                                setAlertMessage(response?.data?.message)
                            }
                        }
                    })
                    .catch((err) => {
                        console.log("we have error on Product Action", err);
                    })
                break;
            case `Delete Product`:
                // Delete a Product

                setIsConfirmationPopupShow(true);
                setConformationPopText(`Are you sure you want to delete this product?`);
                setConformationPopupSuccessBtnText(`Yes`);
                setConformationPopupCancelBtnText(`No`);
                setConformationType(`Delete Product`);
                break;
            case `Add a Variant`:
                // Add a Variant
                if (props.onAddNewProductClick) {
                    props.onAddNewProductClick(true, `not-success`, data, true)
                }
                break;

            case `Delete Variant`:
                // Delete a Variant

                setIsConfirmationPopupShow(true);
                setConformationPopText(`Are you sure you want to delete this variant?`);
                setConformationPopupSuccessBtnText(`Yes`);
                setConformationPopupCancelBtnText(`No`);
                setConformationType(`Delete Variant`);

                break;

            case `Set as Primary`:
                // Set the primary
                productAction(data?.products_id, 'IS_SET_PRIMARY')
                    .then((response) => {
                        if (response && response.status === 200 && response.data && response.data.status) {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("success");
                            setAlertMessage(response.data.message);
                            setTimeout(() => {
                                getProductListData();
                            }, 500);
                        } else {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("error");
                            if (response?.message === `Network Error`) {
                                setAlertMessage(`No internet connection`);
                            } else {
                                setAlertMessage(response?.data?.message)
                            }
                        }
                    })
                    .catch((err) => {
                        console.log("we have error on Product Action", err);
                    })
                break;
            case `Is Draft`:
                // Set the primary
                setFilterText(`In Draft`)
                getProductsList(selectedBrand?.brand_id, '', "0", ``)
                    .then((response) => {
                        if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                            setProductList(response.data.data);
                            setTotalCount(response?.data?.count);
                            setPerPage(response?.data?.perpage);
                            setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                            setUserproductlimit(response?.data?.productlimit);
                            setcurrentproductcount(response?.data?.user_product_count);
                            setIsLoading(false);
                        } else {
                            setIsLoading(false);
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        console.log("we have error at productsList", error);
                    });
                break;
            case `Completed`:
                // Set the primary
                setFilterText(`Completed`)
                getProductsList(selectedBrand?.brand_id, '', "1", ``)
                    .then((response) => {
                        if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                            setProductList(response.data.data);
                            setTotalCount(response?.data?.count);
                            setPerPage(response?.data?.perpage);
                            setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                            setUserproductlimit(response?.data?.productlimit);
                            setcurrentproductcount(response?.data?.user_product_count);
                            setIsLoading(false);
                        } else {
                            setIsLoading(false);
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        console.log("we have error at productsList", error);
                    });
                break;
            case `Out of stock Filter`:
                // Set the primary
                setFilterText(`Out of stock`)
                getProductsList(selectedBrand?.brand_id, '', "2", ``)
                    .then((response) => {
                        if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                            setProductList(response.data.data);
                            setTotalCount(response?.data?.count);
                            setPerPage(response?.data?.perpage);
                            setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                            setUserproductlimit(response?.data?.productlimit);
                            setcurrentproductcount(response?.data?.user_product_count);
                            setIsLoading(false);
                        } else {
                            setIsLoading(false);
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        console.log("we have error at productsList", error);
                    });
                break;
            case `Filter By ALL`:
                // Set the primary
                setFilterText(`Filter By Status`)
                getProductsList(selectedBrand?.brand_id, '', ``, ``)
                    .then((response) => {
                        if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                            setProductList(response.data.data);
                            setTotalCount(response?.data?.count);
                            setPerPage(response?.data?.perpage);
                            setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                            setUserproductlimit(response?.data?.productlimit);
                            setcurrentproductcount(response?.data?.user_product_count);
                            setIsLoading(false);
                        } else {
                            setIsLoading(false);
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        console.log("we have error at productsList", error);
                    });
                break;
            case `Product Multiple Delete`:
                if (isCheck?.length > 0) {
                    setIsConfirmationPopupShow(true);
                    setConformationPopText(`Are you sure you want to delete these multiple products?`);
                    setConformationPopupSuccessBtnText(`Yes`);
                    setConformationPopupCancelBtnText(`No`);
                    setConformationType(`Product Multiple Delete`);
                }
                else {
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    setAlertMessage(`Please select the product`);
                }
                break;
            case `Product copy`:
                if (isCheck?.length > 0) {
                    setCopyProductShow(true);
                }
                else {
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    setAlertMessage(`Please select the product`);
                }
                break;

        }
    }

    const filterbymoreoptionstatus = [
        {
            "label": "All",
            "value": "Filter By ALL"
        },
        {
            "label": "In Draft",
            "value": "Is Draft"
        },
        {
            "label": "Completed",
            "value": "Completed"
        },
        {
            "label": "Out of stock",
            "value": "Out of stock Filter"
        },
    ];

    const filterbymoreoptionaction = [
        {
            "label": "Delete",
            "value": "Product Multiple Delete"
        },
        {
            "label": "Copy to another persona",
            "value": "Product copy"
        }
    ];

    const moreOptions = (status) => {

        let options = [];
        const obj1 = { "label": "Add a variant", "value": "Add a Variant" };
        options.push(obj1);
        const obj2 = { "label": "Make a copy", "value": "Make A Copy" };
        options.push(obj2);
        if (status !== "2") {
            const obj3 = { "label": "Out of stock", "value": "Out of stock" };
            options.push(obj3);
        }
        if (status === "2") {
            const obj3 = { "label": "In stock", "value": "In stock" };
            options.push(obj3);
        }
        const obj4 = { "label": "Delete", "value": "Delete Product" };
        options.push(obj4);
        return options
    }



    const variantMoreOptions = [
        {
            "label": "Set as Primary",
            "value": "Set as Primary"
        },
        {
            "label": "Delete",
            "value": "Delete Variant"
        },
    ];

    const onProductSearchClick = (event) => {

        if (event.key === "Enter" || event.type === "click" || event.type === "change") {
            setCurrentPage(1);
            setIsLoading(true);
            let searchval = (event.type === "keypress") ? event.target.value : (event.type === "click") ? searchValue : ``
            getProductsList(selectedBrand?.brand_id, searchval, ``, 1)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                        setProductList(response.data.data);
                        setTotalCount(response?.data?.count);
                        setPerPage(response?.data?.perpage);
                        setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                        setUserproductlimit(response?.data?.productlimit);
                        setcurrentproductcount(response?.data?.user_product_count);
                        setCurrentPage(1);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("we have error at productsList", error);
                });
        }
    }

    const onSearchRemove = () => {
        setCurrentPage(1);
        document.getElementById(`search`).value = '';
        setSearchValue(false);
        setIsLoading(true);
        getProductsList(selectedBrand?.brand_id, ``, ``, ``)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setProductList(response.data.data);
                    setTotalCount(response?.data?.count);
                    setPerPage(response?.data?.perpage);
                    setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                    setUserproductlimit(response?.data?.productlimit);
                    setcurrentproductcount(response?.data?.user_product_count);
                    setIsLoading(false);

                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at productsList", error);
            });
    }

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(productList.map(li => li.products_id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    const onConformationPopupClose = (value) => {
        setIsConfirmationPopupShow(value);
    }

    const onConformationPopupSuccess = () => {
        setIsConfirmationPopupShow(false);

        switch (conformationPopupType) {
            case `Delete Product`:
                deleteProduct();
                break;
            case `Delete Variant`:
                deleteVariant();
                break;
            case `Product Multiple Delete`:
                deleteMultipleProduct();
                break;
        }
    }

    const deleteProduct = () => {
        productDelete(moreOptionSelectedData?.products_id)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        getProductListData(isCheckAll ? currentPage - 1 : ``);
                    }, 500);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((err) => {
                console.log("we have error on Product Action", err);
            })
    }

    const deleteVariant = () => {
        productDelete(moreOptionSelectedData?.products_id)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        getProductListData();
                    }, 500);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((err) => {
                console.log("we have error on Product Action", err);
            })
    }

    const deleteMultipleProduct = () => {
        if (isCheck?.length > 0) {
            productMultipleDelete(isCheck)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status) {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("success");
                        setAlertMessage(response.data.message);
                        setTimeout(() => {
                            getProductListData(isCheckAll ? currentPage - 1 : ``);
                        }, 500);
                    } else {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        if (response?.message === `Network Error`) {
                            setAlertMessage(`No internet connection`);
                        } else {
                            setAlertMessage(response?.data?.message)
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("we have error at productsList", error);
                });
        }
        else {
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            setAlertMessage(`Please select the product`);
        }
    }

    const onEditProductClick = (productId) => {
        setIsLoading(true);
        getProductDetails(productId)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    dispatch(editProductAction.setEditProductObject(response.data.data[0]))
                    setIsLoading(false);
                    navigate(`/editproduct/${productId}`);
                } else {
                    setIsLoading(false);
                    navigate(`/editproduct/=${productId}`);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                navigate(`/editproduct/${productId}`);
            })
    }
    return (
        <Fragment>
            {
                isConfirmationPopupShow &&
                <ConformationPopup
                    heading={conformationPopupText}
                    cancelBtnText={conformationPopupCancelBtnText}
                    submitBtnText={conformationPopupSuccessBtnText}
                    onCancel={onConformationPopupClose}
                    onSubmit={onConformationPopupSuccess}
                />
            }
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }

            {
                isCopyProductShow &&
                <CopyProduct
                    onCancel={(value) => { setCopyProductShow(value); }}
                    brandList={brandList}
                    selectedBrand={selectedBrand}
                    selectedProduct={isCheck}
                />
            }
            <Box className={`product-filter-section`}>
                <Box className={`product-add-wrapper`}>
                    <div className={`product-count`}>
                        Products ({totalCount})
                    </div>

                    <div className={`product-add-btn`}>
                        {
                            !isProductViewPermission &&
                            <Stack spacing={2} direction="row">
                                {isbulkuploadswitched &&
                                    <Button className={`btn-secondary-after-login`} variant="outlined" onClick={() => { if (props.bulkUploadClick) props.bulkUploadClick(true) }}>Bulk Upload</Button>
                                }
                                <Button className={`btn-primary-after-login only-desktop`} variant="contained" 
                                onClick={() => {                                 
                                     if (!isProductButtonDisabled) {
                                        if (props.onAddNewProductClick) props.onAddNewProductClick(true, `not-success`, {}, false) }
                                      }
                                }
                                disabled={isProductButtonDisabled}
                                > 
                                <AddProductPlusIcon /> New Product
                                
                                </Button>
                                <Button className={`btn-primary-after-login button-plus only-mobile`} variant="contained" onClick={() => { if (props.onAddNewProductClick) props.onAddNewProductClick(true, `not-success`, {}, false) }}><AddProductPlusIcon /></Button>
                            </Stack>
                        }

                    </div>
                </Box>

                <Box className={`filter-section`}>
                    <div className={`search-bar`}>
                        {/* <span className={`search-icon`}>
                            <SearchIcon />
                        </span> */}
                        <TextField
                            id="search"
                            name="search"
                            placeholder={`Search by Product name or SKU ID`}
                            margin="dense"
                            spellCheck={false}
                            className={`search-input`}
                            inputProps={{
                                onKeyPress: (e) => {
                                    onProductSearchClick(e);
                                },
                                onChange: (e) => {

                                    setSearchValue(e.target.value)
                                    let targetvalue = e.target.value
                                    if (targetvalue === "") onProductSearchClick(e);


                                }
                            }}
                        />
                        {
                            searchValue &&
                            <span className={`close-icon`} onClick={() => onSearchRemove()}>
                                <CloseIcon />
                            </span>
                        }
                        <button className={`search-icon-wrap`} onClick={(e) => { onProductSearchClick(e) }}>
                            <SearchIcon />
                        </button>
                    </div>

                    <div className={`filters`}>
                        <div className={`menu more-filter-option-icon-wrapper`}>

                            <Tooltip moreOptions={filterbymoreoptionstatus} textlable={filterText} icon={1} onOptionChange={onMoreOptionValueChange} id={`filter-tooltip`} />
                        </div>

                        {
                            !isProductViewPermission &&
                            <div className={`menu more-filter-option-icon-wrapper`}>
                                <Tooltip moreOptions={filterbymoreoptionaction} textlable={`Actions`} icon={1} onOptionChange={onMoreOptionValueChange} id={`action-tooltip`} />
                            </div>
                        }
                    </div>
                </Box>

                <Box className={`product-table table-scroll-wrap`}>
                    {isLoading && <Loader />}
                    {
                        productList && productList.length > 0
                            ?
                            <>
                            <table>
                                <thead>
                                    <tr>
                                        <th className={`product`}>
                                            <input type="checkbox" name="selectAll" id="selectAll" onChange={handleSelectAll} checked={isCheckAll} />Product</th>
                                        {/* <th className={`product-draft`}></th> */}
                                        <th className={`brand`}>Brand</th>
                                        <th className={`seller-id`}>Seller SKU ID</th>
                                        <th className={`variant`}>Variants</th>
                                        <th className={`status`}>Status</th>
                                        {
                                            !isProductViewPermission &&
                                            <th width="5%" className={`more-option`}>More option</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        productList && productList.length > 0 &&
                                        productList.map((product, index) => {
                                            let tempClassName = index % 2 == 0 ? 'even' : 'odd';
                                            return (
                                                <Fragment key={index} >
                                                    <tr className={`product-list-main ${tempClassName} ${product?.variant && product?.variant.length > 0 ? `has-variant` : ``}`}>
                                                        <td>
                                                            <span className={`product-name brand-verified-wrap`}>
                                                                <input type="checkbox" id={product?.products_id} onChange={handleClick} checked={isCheck.includes(product?.products_id)} />
                                                                {
                                                                    product?.default_image?.length
                                                                        ?
                                                                        <div className={`pro-image-wrap`}>
                                                                            <img src={product?.default_image[0.].image} alt="Product image" loading="lazy" />
                                                                        </div>
                                                                        :
                                                                        <div className={`pro-image-wrap`}>
                                                                            <div className={`pro-image`}>
                                                                            </div>
                                                                        </div>
                                                                }

                                                                <span className={`brand-verified-icon`}>{product?.name}</span>
                                                            </span>
                                                        </td>
                                                        {/* <td>
                                                            {
                                                                product?.status === "0"
                                                                    ?
                                                                    <span className={`button-status draft`}>In Draft</span>
                                                                    :
                                                                    ''
                                                            }
                                                        </td> */}
                                                        <td>
                                                            <span className={`sku-id information-icon brand`}>
                                                                {/* {product?.sku_id || ` - `} */
                                                                    // console.log(product?.sku_id) {product?.productbrandname || `-`}
                                                                }
                                                                <span className={`custom-tt-2`} tooltip={product?.productbrandname}>{(product?.productbrandname && product?.productbrandname.length > 15) ? `${product?.productbrandname.substring(0, 15)}...` : `${product?.productbrandname || ` - `}`}</span>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className={`sku-id information-icon`}>
                                                                {/* {product?.sku_id || ` - `} */
                                                                    // console.log(product?.sku_id)
                                                                }
                                                                <span className={`custom-tt-2`} tooltip={product?.sku_id ? `${product?.sku_id}` : ``}>{(product?.sku_id && product.sku_id.length > 15) ? `${product.sku_id.substring(0, 15)}...` : `${product?.sku_id || ` - `}`}</span>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className={`variant-count`}>
                                                                {product?.variant && product?.variant.length || 0}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {
                                                                product?.status === "2"
                                                                    ?
                                                                    <span className={`button-status out-of-stock`}>Out of Stock</span>
                                                                    :
                                                                    ''
                                                            }
                                                            {
                                                                product?.status === "1"
                                                                    ?
                                                                    <StatusGreenTick />
                                                                    :
                                                                    ''
                                                            }
                                                            {
                                                                product?.status === "0"
                                                                    ?
                                                                    <span className={`button-status draft`}>In Draft</span>
                                                                    :
                                                                    ''
                                                            }
                                                        </td>
                                                        {
                                                            !isProductViewPermission &&
                                                            <td className={`options`}>
                                                                <div>
                                                                    <span tooltip='Edit' className={`edit-icon`} onClick={() => { onEditProductClick(product?.products_id); }}>
                                                                        <EditIcon />
                                                                    </span>
                                                                    <div className={`more-options-wrapper`}>
                                                                        <Tooltip moreOptions={moreOptions(product?.status)} onOptionChange={onMoreOptionValueChange} className={`product-list-option`} data={product} type={`productList`} id={product?.products_id} />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        }
                                                    </tr>
                                                    {
                                                        product?.variant && product?.variant.length > 0 &&
                                                        product?.variant.map((productvar, index) => {
                                                            return (
                                                                <Fragment key={index} >
                                                                    <tr className={`product-list-main-variant ${tempClassName}`}>
                                                                        <td>
                                                                            <span className={`product-name`}>
                                                                                <input type="checkbox" className={`visibility-hidden`} />
                                                                                {
                                                                                    productvar?.default_image?.length
                                                                                        ?
                                                                                        <div className={`pro-image-wrap`}>
                                                                                            <img src={productvar?.default_image[0.].image} alt="Product image" loading="lazy" />
                                                                                        </div>
                                                                                        :
                                                                                        <div className={`pro-image-wrap`}>
                                                                                            <div className={`pro-image`}>
                                                                                            </div>
                                                                                        </div>
                                                                                }
                                                                                {productvar?.name}
                                                                            </span>
                                                                        </td>
                                                                        {/* <td>
                                                                            
                                                                        </td> */}
                                                                        <td>
                                                                            <span className={`sku-id information-icon`}>
                                                                                {/* {product?.sku_id || ` - `} */
                                                                                    // console.log(product?.sku_id)
                                                                                }
                                                                                <span className={`custom-tt-2`} tooltip={productvar?.productbrandname}>{productvar?.productbrandname || `-`}</span>
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className={`sku-id information-icon`}>
                                                                                {/* {productvar?.sku_id || ` - `} */}
                                                                                <span className={`custom-tt-2`} tooltip={productvar?.sku_id ? `${productvar?.sku_id}` : ``}>{(productvar?.sku_id && productvar.sku_id.length > 15) ? `${productvar.sku_id.substring(0, 15)}...` : `${productvar?.sku_id || ` - `}`}</span>
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className={`variant-count`}>-

                                                                            </span>
                                                                        </td>
                                                                        {/* <td></td> */}
                                                                        <td>
                                                                        
                                                                            {
                                                                                productvar?.status === "2"
                                                                                    ?
                                                                                    <span className={`button-status out-of-stock`}>Out of Stock</span>
                                                                                    :
                                                                                    ''
                                                                            }
                                                                            {
                                                                                productvar?.status === "1"
                                                                                    ?
                                                                                    <StatusGreenTick />
                                                                                    :
                                                                                    ''
                                                                            }
                                                                            {
                                                                                productvar?.status === "0"
                                                                                    ?
                                                                                    <span className={`button-status draft`}>In Draft</span>
                                                                                    :
                                                                                    ''
                                                                            }
                                                                            
                                                                        </td>
                                                                        {
                                                                            !isProductViewPermission &&
                                                                            <td className={`options`}>
                                                                                <div>
                                                                                    <button tooltip='Edit' className={`edit-icon`} onClick={() => { onEditProductClick(productvar?.products_id); }}>
                                                                                        <EditIcon />
                                                                                    </button>
                                                                                    <div className={`more-options-wrapper`}>
                                                                                        <Tooltip moreOptions={variantMoreOptions} onOptionChange={onMoreOptionValueChange} className={`product-list-option`} data={productvar} type={`productList`} id={productvar?.products_id} />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                    isPaginatioShow &&
                    <Box className={`pagination`}>
                        <PaginationComponent
                            pages={Math.ceil(totalCount / perPage)}
                            onPageChange={onPageChange}
                            currentPage={currentPage || 1}
                        />
                    </Box>
                }
                            </>
                            :
                            <div className={`no-data-found`}>
                                <div className={`icon-wrap`}>
                                    <ProductIcon />
                                </div>
                                <div className={`text`}>
                                    You haven’t added any products yet
                                </div>
                                <div onClick={() => { if (props.onAddNewProductClick) props.onAddNewProductClick(true, `not-success`, {}, false) }}>
                                    <Button className={`btn-primary-after-login`} variant="contained">Add New Product</Button>
                                </div>
                            </div>
                    }
                </Box>
                        
                
            </Box>
        </Fragment>
    )
}

const ProfileSection = (props) => {

    const urlValidation = /^https:\/\/[^\s/$.?#].[^\s]*$/;
    const { selectedBrand, isProductViewPermission, isCompanyPermissionView, profiledata } = props;
    const [brandprofiledata, setBrandprofiledata] = useState(profiledata || []);
    const [countryList, setCountryList] = useState(props?.countryListData?.countryList || []),
        [countryCode, setCountyCode] = useState(brandprofiledata?.country || "none" ),
        [yearOfEstablish, setYearOfEstablish] = useState(props?.yearOfEstablishData?.yearOfEstablish || []),
        [selectedYearOfEstablish, setSelectedYearOfEstablish] = useState(getDefaultYOE),
        [our_story, setour_story] = useState(brandprofiledata?.our_story || ``),
        [our_mission, setour_mission] = useState(brandprofiledata?.our_mission || ``),
        [our_vision, setour_vision] = useState(brandprofiledata?.our_vision || ``),
        [facebook_link, setfacebook_link] = useState(brandprofiledata?.facebook_link || ``),
        [twitter_link, settwitter_link] = useState(brandprofiledata?.twitter_link || ``),
        [instagram_link, setinstagram_link] = useState(brandprofiledata?.instagram_link || ``),
        [linkedin_link, setlinkedin_link] = useState(brandprofiledata?.linkedin_link || ``),
        [website_link, setwebsite_link] = useState(brandprofiledata?.website_link || ``),
        [isLoading, setIsLoading] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``);
    const dispatch = useDispatch();

    useEffect(() => {
        getBrandProfile();
    }, [selectedBrand]);

    useEffect(() => {
        setCountyCode(brandprofiledata?.country || "none" );
        setSelectedYearOfEstablish(brandprofiledata?.yearofestablish || getDefaultYOE);
        setour_story(brandprofiledata?.our_story || ``);
        setour_mission(brandprofiledata?.our_mission || ``);
        setour_vision(brandprofiledata?.our_vision || ``);
        setfacebook_link(brandprofiledata?.facebook_link || ``);
        settwitter_link(brandprofiledata?.twitter_link || ``);
        setinstagram_link(brandprofiledata?.instagram_link || ``);
        setlinkedin_link(brandprofiledata?.linkedin_link || ``);
        setwebsite_link(brandprofiledata?.website_link || ``);
    }, [brandprofiledata]);



    const flagemojiToPNG = (flag, code) => {
        var countryCode = Array.from(flag, (codeUnit) => codeUnit.codePointAt()).map(char => String.fromCharCode(char - 127397).toLowerCase()).join('')
        return `https://flagcdn.com/24x18/${countryCode}.png`;
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const schema = yup
        .object({
            our_story: yup
                .string()
                .trim()
                .max(3000, 'Maximum allowed characters is 3000'),
            our_mission: yup
                .string()
                .trim()
                .max(1000, 'Maximum allowed characters is 1000'),
            our_vision: yup
                .string()
                .trim()
                .max(1000, 'Maximum allowed characters is 1000'),
            facebook_link: yup.lazy((value) => {
                if (value && document.getElementById(`facebook_link`)) {
                    return yup.string()
                        .trim()
                        .matches(urlValidation, "Please enter valid facebook url")
                }

                return yup.string().typeError(`Please enter valid facebook url`).notRequired()
            }),
            twitter_link: yup.lazy((value) => {
                if (value && document.getElementById(`twitter_link`)) {
                    return yup.string()
                        .trim()
                        .matches(urlValidation, "Please enter valid twitter url")
                }

                return yup.string().typeError(`Please enter valid twitter url`).notRequired()
            }),
            twitter_link: yup.lazy((value) => {
                if (value && document.getElementById(`twitter_link`)) {
                    return yup.string()
                        .trim()
                        .matches(urlValidation, "Please enter valid twitter url")
                }

                return yup.string().typeError(`Please enter valid twitter url`).notRequired()
            }),
            instagram_link: yup.lazy((value) => {
                if (value && document.getElementById(`instagram_link`)) {
                    return yup.string()
                        .trim()
                        .matches(urlValidation, "Please enter valid instagram url")
                }

                return yup.string().typeError(`Please enter valid instagram url`).notRequired()
            }),
            linkedin_link: yup.lazy((value) => {
                if (value && document.getElementById(`linkedin_link`)) {
                    return yup.string()
                        .trim()
                        .matches(urlValidation, "Please enter valid linkedin url")
                }

                return yup.string().typeError(`Please enter valid linkedin url`).notRequired()
            }),
            website_link: yup.lazy((value) => {
                if (value && document.getElementById(`website_link`)) {
                    return yup.string()
                        .trim()
                        .matches(urlValidation, "Please enter valid website url")
                }

                return yup.string().typeError(`Please enter valid website url`).notRequired()
            })
        });

    const { register, handleSubmit, reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const getBrandProfile = () => {
        setIsLoading(true);
        getBrandProfileData(selectedBrand?.brand_id)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    if (response.data.data.length) {
                        setIsLoading(false);
                        setBrandprofiledata(response.data.data[0]);
                    } else {
                        setIsLoading(false);
                        setBrandprofiledata([]);
                    }

                } else {
                    setBrandprofiledata([]);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at Brandlist profile data", error);
            });
    }

    const onSubmit = (data) => {
        setIsLoading(true);
        BrandProfile(selectedBrand?.brand_id, countryCode, selectedYearOfEstablish, our_story, our_mission, our_vision, facebook_link, twitter_link, instagram_link, linkedin_link, website_link)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.data) {
                    if (response.data.status) {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("success");
                        setAlertMessage(response?.data?.message);

                    }
                    else {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        if (response?.message === `Network Error`) {
                            setAlertMessage(`No internet connection`);
                        } else {
                            setAlertMessage(response?.data?.message)
                        }
                    }
                } else {
                    if (response && response.response && response.response.data && response.response.data.message) {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        if (response?.message === `Network Error`) {
                            setAlertMessage(`No internet connection`);
                        } else {
                            setAlertMessage(response?.response?.data?.message)
                        }
                    }
                }
            })
            .catch((error) => {
                setAlertMessage(false);
                console.log("We have error", error);
            })
    }

    useEffect(() => {
        if (yearOfEstablish && Object.keys(yearOfEstablish).length === 0) {
            setIsLoading(true);
            getYearOfEstablish()
                .then((response) => {
                    if (
                        response &&
                        response.status === 200 &&
                        response.data.status &&
                        response.data.data &&
                        response.data.data.length > 0
                    ) {
                        setIsLoading(false);
                        setYearOfEstablish(response.data.data);
                        dispatch(yearOfEstablishAction.setYearOfEstablish(response.data.data));
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("We have error", error);
                });
        }
        if (countryList && Object.keys(countryList).length === 0) {
            setIsLoading(true);
            getCountryList()
                .then((response) => {
                    if (
                        response &&
                        response.status === 200 &&
                        response.data.status &&
                        response.data.data &&
                        response.data.data.length > 0
                    ) {
                        setIsLoading(false);
                        setCountryList(response.data.data);
                        dispatch(countryListActions.setCountryList(response.data.data));
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("We have error", error);
                });
        }
    }, []);

    return (
        <Fragment>
            {isLoading && <Loader />}
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <Box className={`profile-section-wrapper ${isProductViewPermission ? 'disabled' : ''}`}>
                <Box>
                    <Typography variant="h4" className={`first-heading margin-bottom-28`}>
                        Edit Profile
                    </Typography>
                </Box>

                <Box>
                    <form className={`form-input-control`} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <div className={`brand-origin-section`}>
                            <Box className={`country-brand margin-bottom-28`}>
                                <Typography variant="h6" className={`label`}>
                                    Country Origin
                                </Typography>
                                <Select
                                    labelId="country-code-select-label"
                                    id="country"
                                    className={`form-profile-field`}
                                    value={countryCode}
                                    label="country"
                                    {...register("country", {
                                        onChange: (event) => {
                                            if (event.target.value) setCountyCode(event.target.value);
                                        },
                                    })}
                                    //onChange={handleChange}
                                    fullWidth
                                    disabled={isProductViewPermission ? true : false}
                                >
                                    <MenuItem value="none">
                                        Select country of origin
                                    </MenuItem>
                                    {countryList &&
                                        countryList.length > 0 &&
                                        countryList.map((country, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={country[`country_id`]}
                                                >
                                                    {
                                                        <div className="">
                                                            {<img src={flagemojiToPNG(country[`emoji`])} loading="lazy" />}{'  '}
                                                            {country[`name`]}
                                                        </div>
                                                    }
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </Box>

                            <Box className={`year-establish margin-bottom-28`}>
                                <Typography variant="h6" className={`label`}>
                                    Year Established
                                </Typography>
                                <Select
                                    labelId="yoe-code-select-label"
                                    id="yearofestablish"
                                    className={`form-profile-field`}
                                    value={selectedYearOfEstablish}
                                    label="Yoe"
                                    {...register("yearofestablish", {
                                        onChange: (event) => {
                                            if (event.target.value) setSelectedYearOfEstablish(event.target.value);
                                        },
                                    })}
                                    //onChange={handleChangeyoe}
                                    fullWidth
                                    disabled={isProductViewPermission ? true : false}

                                >
                                    {yearOfEstablish && yearOfEstablish.length > 0 &&
                                        yearOfEstablish.map((year, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={year[`yoe_id`]}
                                                >{year[`name`]}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </Box>
                        </div>
                        <div className={`brand-origin-section`}>
                            <Box className={`story-mission-vision margin-bottom-28`}>
                                <Typography variant="h6" className={`label`}>
                                    Our story (Optional)
                                </Typography>
                                {/* <TextField
                                    id="our_story"
                                    value={our_story}
                                    placeholder="Write your message..."
                                    {...register("our_story", {
                                        onChange: (event) => {
                                            setour_story(event.target.value);
                                        },
                                    })}
                                    multiline
                                    minRows={3}
                                    maxRows={7}
                                    fullWidth
                                    className={`form-profile-field`}
                                    error={errors.our_story ? true : false}
                                /> */}
                                {/* <p className={`field-err-msg`}>{errors.our_story?.message}</p> */}
                                <TextEditor
                                    value={brandprofiledata?.our_story || ``}
                                    onEditorChange={(value) => { setour_story(value); }}
                                    isReadOnly={isProductViewPermission ? true : false}
                                />
                            </Box>
                        </div>
                        <div className={`brand-origin-section`}>
                            <Box className={`story-mission-vision margin-bottom-28`}>
                                <Typography variant="h6" className={`label`}>
                                    Our Mission (Optional)
                                </Typography>
                                {/* <TextField
                                    id="our_mission"
                                    value={our_mission}
                                    {...register("our_mission", {
                                        onChange: (event) => {
                                            setour_mission(event.target.value);
                                        },
                                    })}
                                    placeholder="Write your message..."
                                    multiline
                                    minRows={3}
                                    maxRows={7}
                                    fullWidth
                                    className={`form-profile-field`}
                                    error={errors.our_mission ? true : false}
                                /> */}
                                {/* <p className={`field-err-msg`}>{errors.our_mission?.message}</p> */}
                                <TextEditor
                                    value={brandprofiledata?.our_mission || ``}
                                    onEditorChange={(value) => { setour_mission(value); }}
                                    isReadOnly={isProductViewPermission ? true : false}
                                />
                            </Box>
                        </div>
                        <div className={`brand-origin-section`}>
                            <Box className={`story-mission-vision margin-bottom-28`}>
                                <Typography variant="h6" className={`label`}>
                                    Our Vision (Optional)
                                </Typography>
                                {/* <TextField
                                    id="our_vision"
                                    value={our_vision}
                                    {...register("our_vision", {
                                        onChange: (event) => {
                                            setour_vision(event.target.value);
                                        },
                                    })}
                                    placeholder="Write your message..."
                                    multiline
                                    minRows={3}
                                    maxRows={7}
                                    fullWidth
                                    className={`form-profile-field`}
                                    error={errors.our_vision ? true : false}
                                />
                                <p className={`field-err-msg`}>{errors.our_vision?.message}</p> */}
                                <TextEditor
                                    value={brandprofiledata?.our_vision || ``}
                                    onEditorChange={(value) => { setour_vision(value); }}
                                    isReadOnly={isProductViewPermission ? true : false}
                                />
                            </Box>
                        </div>
                        <div className={`brand-origin-section`}>
                            <Box className={`year-establish margin-bottom-28`}>
                                <Typography variant="h6" className={`label`}>
                                    Connect Social Media (Optional)
                                </Typography>
                            </Box>
                        </div>
                        <div className={`brand-origin-section social-media-section`}>

                            <Box className={`social-links margin-bottom-10`}>
                                <Typography variant="h6" className={`label`}>
                                    <FacebookIcon />   Facebook Link
                                </Typography>
                                <TextField
                                    id="facebook_link"
                                    value={facebook_link}
                                    {...register("facebook_link", {
                                        onChange: (event) => {
                                            setfacebook_link(event.target.value);
                                        },
                                    })}
                                    placeholder="https://www.facebook.com"
                                    className={`form-profile-field`}
                                    error={errors.facebook_link ? true : false}
                                    disabled={isProductViewPermission ? true : false}
                                />
                                <p className={`field-err-msg`}>{errors.facebook_link?.message}</p>
                            </Box>
                            <Box className={`social-links margin-bottom-10`}>
                                <Typography variant="h6" className={`label`}>
                                    <TwitterIcon />    Twitter Link
                                </Typography>
                                <TextField
                                    id="twitter_link"
                                    value={twitter_link}
                                    {...register("twitter_link", {
                                        onChange: (event) => {
                                            settwitter_link(event.target.value);
                                        },
                                    })}
                                    className={`form-profile-field`}
                                    placeholder="https://www.twitter.com"
                                    error={errors.twitter_link ? true : false}
                                    disabled={isProductViewPermission ? true : false}
                                />
                                <p className={`field-err-msg`}>{errors.twitter_link?.message}</p>
                            </Box>
                            <Box className={`social-links margin-bottom-10`}>
                                <Typography variant="h6" className={`label`}>
                                    <InstagramIcon />    Instagram Link
                                </Typography>
                                <TextField
                                    id="instagram_link"
                                    value={instagram_link}
                                    {...register("instagram_link", {
                                        onChange: (event) => {
                                            setinstagram_link(event.target.value);
                                        },
                                    })}
                                    className={`form-profile-field`}
                                    placeholder="https://www.instagram.com"
                                    error={errors.instagram_link ? true : false}
                                    disabled={isProductViewPermission ? true : false}
                                />
                                <p className={`field-err-msg`}>{errors.instagram_link?.message}</p>
                            </Box>
                            <Box className={`social-links linkedIn margin-bottom-10`}>
                                <Typography variant="h6" className={`label`}>
                                    <LinkedInIcon />    LinkedIn Link
                                </Typography>
                                <TextField
                                    id="linkedin_link"
                                    value={linkedin_link}
                                    {...register("linkedin_link", {
                                        onChange: (event) => {
                                            setlinkedin_link(event.target.value);
                                        },
                                    })}
                                    className={`form-profile-field`}
                                    placeholder="https://www.linkedin.com"
                                    error={errors.linkedin_link ? true : false}
                                    disabled={isProductViewPermission ? true : false}
                                />
                                <p className={`field-err-msg`}>{errors.linkedin_link?.message}</p>
                            </Box>
                            <Box className={`social-links website-l margin-bottom-28`}>
                                <Typography variant="h6" className={`label`}>
                                    <LanguageIcon />    Website Link
                                </Typography>
                                <TextField
                                    id="website_link"
                                    value={website_link}
                                    {...register("website_link", {
                                        onChange: (event) => {
                                            setwebsite_link(event.target.value);
                                        },
                                    })}
                                    className={`form-profile-field`}
                                    placeholder="https://www.abc.com"
                                    error={errors.website_link ? true : false}
                                    disabled={isProductViewPermission ? true : false}
                                />
                                <p className={`field-err-msg`}>{errors.website_link?.message}</p>
                            </Box>
                        </div>
                        <div className={`divider`}></div>

                        <div className={`button-section`}>
                            <div className={`text`}>
                                *Don’t worry if your info isn’t perfect, you can always return to make edits.*
                            </div>
                            <div className={`button-wrapper`}>
                                {/* <Button
                                type="submit"
                                variant="outlined"
                                size="large"
                                className={`cancel-btn`}
                            >
                                Save
                            </Button> */}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    className={`btn-primary-after-login`}
                                    disabled={isProductViewPermission ? true : false}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </form>
                </Box>
            </Box>
        </Fragment>
    )
}


const MyBrand = (props) => {

    const { appData } = props,
        dispatch = useDispatch(),
        location = useLocation(),
        getProductSelectedBrand = getLocalStorage(`productSelectedBrand`) !== `undefined` ? JSON.parse(getLocalStorage(`productSelectedBrand`)) : ``;

    const moduleApis = [];
    const moduleApiIndex = [];

    const [isBrandCreate, setBrandCreate] = useState(location && location.state && location.state.isCreateBrand || false),
        [isBrandCreateOnboarding, setBrandCreateOnboarding] = useState(location && location.state && location.state.isCreateBrand || false),
        [isProcessVideoShow, setProcessVideoShow] = useState(false),
        [isBulkUpload, setIsBulkUpload] = useState(false),
        [isAddBrand, setAddBrandFlag] = useState(false),
        [brandList, setBrandList] = useState([]),
        [brandLimit, setBrandLimit] = useState(1 || ``),
        [isButtonDisabled, setIsButtonDisabled] = useState(() => 1 <= brandList.length),
        [isLoading, setIsLoading] = useState(false),
        [selectedBrand, setSelectedBrand] = useState(getProductSelectedBrand || appData?.app?.productSelectedBrand || {}),
        [coverImage, setCoverImage] = useState(``),
        [coverImageUrl, setCoverImageUrl] = useState(selectedBrand?.b_banner || ``),
        [createBrandTitle, setCreateBrandTitle] = useState(`You’re all set, ${props?.userDetailData?.user?.data?.firstname ? props?.userDetailData?.user?.data?.firstname + '!' : ''}`),
        [createBrandSecondTitle, setCreateBrandSecondTitle] = useState(`Let’s get you started with your first persona`),
        [createBrandButtonText, setCreateBrandButtonText] = useState(`Create persona`),
        [editBrandData, setEditBrandData] = useState({}),
        [isAddBrandShow, setIsAddBrandShow] = useState(false),
        [isEditBrand, setIsEditBrand] = useState(false),
        [addBrandResponse, setBrandResponse] = useState(``),
        [productVariantData, setProductVariantData] = useState({}),
        [manageSectionSelectedSection, setManageSectionSelectedSection] = useState(`Manage Products`),
        [isBrandSliderShow, setIsBrandSliderShow] = useState(false),
        [isVariantAdd, setIsVariantAdd] = useState(false),
        [isConfirmationPopupShow, setIsConfirmationPopupShow] = useState(false),
        [conformationPopupSuccessBtnText, setConformationPopupSuccessBtnText] = useState(``),
        [conformationPopupCancelBtnText, setConformationPopupCancelBtnText] = useState(``),
        [conformationPopupText, setConformationPopText] = useState(``),
        [conformationPopupType, setConformationType] = useState(``),
        [permission, SetPermission] = useState(props?.userDetailData?.user?.data?.permission || []),
        [isProductViewPermission, setProductViewPermission] = useState(permission?.[0]?.product_auth_type === `4` ? true : false),
        [isProductPermissionNone, setProductPermissionNone] = useState(permission?.[0]?.product_auth_type === `5` ? true : false),
        [isCompanyPermissionView, setCompanyPermissionView] = useState(permission?.[0]?.brand_auth_type === `4` ? true : false),
        [isCompanyPermissionAdd, setCompanyPermissionAdd] = useState(permission?.[0]?.accesstype === `1` ? true : false),
        [brandprofiledata, setBrandprofiledata] = useState([]),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``);

    useEffect(() => {

        // setBrandCreate(location && location.state && location.state.isCreateBrand || false);
        // setBrandCreateOnboarding(location && location.state && location.state.isCreateBrand || false);
    }, [location.state])

    useEffect(() => {
        setCoverImageUrl(selectedBrand?.b_banner || ``);
        setIsBrandSliderShow(ifBrandSliderAdd());
        let tempObject = appData?.app;
        tempObject[`productSelectedBrand`] = selectedBrand;
        dispatch(appObjectAction.setAppObject(tempObject))
        createLocalStorage(`productSelectedBrand`, JSON.stringify(selectedBrand));
    }, [selectedBrand])

    useLayoutEffect(() => {
        setIsButtonDisabled(brandList.length >= Number(brandLimit));
    }, [brandList, brandLimit]);

    useEffect(() => {
        // getAddedBrands();
        // getBrandProfile();
        getProductAndProfileData();
    }, [])

    useEffect(() => {
        SetPermission(props?.userDetailData?.user?.data?.permission);
        setProductViewPermission(props?.userDetailData?.user?.data?.permission?.[0]?.product_auth_type === `4` ? true : false);
        setProductPermissionNone(props?.userDetailData?.user?.data?.permission?.[0]?.product_auth_type === `5` ? true : false);
        setCompanyPermissionView(props?.userDetailData?.user?.data?.permission?.[0]?.brand_auth_type === `4` ? true : false);
        setCompanyPermissionAdd(props?.userDetailData?.user?.data?.permission?.[0]?.accesstype === `1` ? true : false);
        setCreateBrandTitle(`You’re all set, ${props?.userDetailData?.user?.data?.firstname ? props?.userDetailData?.user?.data?.firstname + '!' : ''}`);
    }, [props?.userDetailData?.user]);

    useEffect(() => {
        var existCondition = setInterval(function() {
            if (document.getElementsByClassName('brand-list-slider-container')[0].getElementsByClassName('selected-brand')[0]) {
               clearInterval(existCondition);
               document.getElementsByClassName('brand-list-slider-container')[0].getElementsByClassName('selected-brand')[0].scrollIntoView({ block: 'center', inline: 'center'});
            }
           }, 100); 
    },[selectedBrand])

    const onBrandCreateResponse = (status) => {
        if (status === `success`) {
            window.history.replaceState(null, '');
            setBrandCreate(false);
            setProcessVideoShow(true);
        }
    }

    const onProcessVideoClose = (value) => {
        setProcessVideoShow(value);
        window.history.replaceState(null, '');
        getAddedBrands();
    }

    const onAddProductClick = (value, isAddBrand) => {
        setBrandCreate(value);
        setAddBrandFlag(isAddBrand);
        setCreateBrandTitle('Add another persona');
        setCreateBrandSecondTitle(`Start by adding the persona name and logo`);
        setCreateBrandButtonText(`Create persona`);
    }

    const onBrandClosePopup = (value, isAddBrand, isClose) => {
        setBrandCreate(value);
        setAddBrandFlag(isAddBrand);
        if (!isClose) {
            getAddedBrands();
        }
        setEditBrandData({});
        setIsBrandSliderShow(ifBrandSliderAdd());
    }

    const onBulkuploadClosePopup = (value, secondvalu = '', status = '') => {
        setIsBulkUpload(value);
        setBrandResponse(status);
    }

    const onEditBrandClick = (value, firstHeading, secondHeading, buttonText) => {
        setBrandCreate(value);
        if (firstHeading) setCreateBrandTitle(firstHeading);
        if (secondHeading) setCreateBrandSecondTitle(secondHeading);
        if (buttonText) setCreateBrandButtonText(buttonText);
        setEditBrandData(selectedBrand);
        setIsEditBrand(true);
    }

    const getAddedBrands = () => {
        setIsLoading(true);
        getBrandList()
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {

                    let selectedBrandAdded = false;
                    dispatch(supplierBrandListAction.setSupplierBrandList(response.data.data))
                    setBrandList(response.data.data);
                    setBrandLimit(response.data.brandlimit);
                    response?.data?.data?.map((data) => {
                        if (data?.brand_id === selectedBrand?.brand_id) {
                            selectedBrandAdded = true;
                            setSelectedBrand(data);
                        }
                    })

                    if (!selectedBrandAdded) {
                        setSelectedBrand(response.data.data[0]);
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("We have error while getBrandList", error);
            })
    }

    const showUploadImage = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (event) => {
            resolve(event.target.result);
        }
        reader.onerror = (error) => {
            reject(error);
        }
    });

    const onCoverImageChange = async (file) => {

        if ([`image/jpeg`, `image/png`, `image/gif`].indexOf(file[`type`]) === -1) {
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage(`File extension not supported!`);
            return;
        }

        setCoverImage(file);
        setCoverImageUrl(await showUploadImage(file));
        updateBrandDetail(``, file);
    }

    const updateBrandDetail = (tagLine, coverImage) => {
        setIsLoading(true);
        brandUpdate(selectedBrand?.brand_id, ``, selectedBrand?.tagline || tagLine, ``, coverImage, 2)
            .then((response) => {
                if (response && response.status === 200 && response.data.status) {
                    setSelectedBrand(response?.data?.data);
                    getAddedBrands();
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log("we have error at PersonaUpdate", err);
                setIsLoading(false);
            });
    }

    const onSelectedBrandChange = (brand) => {
        setSelectedBrand(brand);
        setCoverImageUrl(brand?.b_banner || ``)
        // setManageSectionSelectedSection(`Manage Products`);
    }

    const onAddProudctClick = (value, status, data, isVariantAdd) => {
        setIsAddBrandShow(value);
        setBrandResponse(status);
        setProductVariantData(data);
        setIsVariantAdd(isVariantAdd);
    }

    const bulkUploadEventClick = (value) => {
        setIsBulkUpload(value);
    }

    const onEditBrandPopupClose = (value) => {
        setIsEditBrand(value);
    }

    const onManageSectionChange = (value) => {
        setManageSectionSelectedSection(value);
    }

    const ifBrandSliderAdd = () => {
        let parentWidth = document.getElementsByClassName(`brands-wrapper`)[0].clientWidth;
        let childWidth = document.getElementsByClassName(`brand-list-slider-container`)[0].clientWidth;

        if (childWidth == 0) {
            return false;
        }
        return childWidth >= (parentWidth * 85) / 100 ? true : false;

        // return childWidth >= parentWidth ? true : false;
    }

    const onSliderArrow = (side) => {
        const brandList = document.getElementsByClassName('brand-list-slider-container')[0];
        let slideValue = 120;

        if (side === "left") {
            brandList.scrollLeft -= slideValue;
        }
        else if (side === "right") {
            brandList.scrollLeft += slideValue;
        }
    }

    const onCoverImageDelete = () => {

        setIsConfirmationPopupShow(true);
        setConformationPopText(`Are you sure you want to delete this cover image?`);
        setConformationPopupSuccessBtnText(`Yes`);
        setConformationPopupCancelBtnText(`No`);
        setConformationType(`Delete Cover Image`);

    }
    const onConformationPopupClose = (value) => {
        setIsConfirmationPopupShow(value);
    }

    const onConformationPopupSuccess = () => {
        setIsConfirmationPopupShow(false);

        switch (conformationPopupType) {
            case `Delete Cover Image`:
                setCoverImage(``);
                setCoverImageUrl(``);
                updateBrandDetail(``, ``);
                break;
        }
    }

    const getBrandProfile = () => {
        setIsLoading(true);
        getBrandProfileData(selectedBrand?.brand_id)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    if (response.data.data.length) {
                        setIsLoading(false);
                        setBrandprofiledata(response.data.data[0]);
                    } else {
                        setBrandprofiledata([]);
                    }

                } else {
                    setBrandprofiledata([]);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at Brandlist profile data", error);
            });
    }

    const getProductAndProfileData = () => {
        setIsLoading(false);

        moduleApis.push(getBrandList());
        moduleApiIndex.push(`brandIndex`);

        moduleApis.push(getBrandProfileData(selectedBrand?.brand_id));
        moduleApiIndex.push(`profileIndex`);

        Promise.all(moduleApis)
            .then((result) => {
                let brandIndex = moduleApiIndex.indexOf('brandIndex');
                if (brandIndex !== -1 && result.length) {
                    const dataResult = result[brandIndex] ? result[brandIndex] : {};
                    if (dataResult?.status === 200 && dataResult?.data?.status) {
                        let selectedBrandAdded = false;
                        dispatch(supplierBrandListAction.setSupplierBrandList(dataResult.data.data))
                        setBrandList(dataResult.data.data);
                        setBrandLimit(dataResult.data.brandlimit);
                        
                        dataResult?.data?.data?.map((data) => {
                            if (data?.brand_id === selectedBrand?.brand_id) {
                                selectedBrandAdded = true;
                                setSelectedBrand(data);
                            }
                        })

                        if (!selectedBrandAdded) {
                            setSelectedBrand(dataResult.data.data[0]);
                        }
                    }
                }

                let profileIndex = moduleApiIndex.indexOf('profileIndex');
                if (profileIndex !== -1 && result.length) {
                    const dataResult = result[profileIndex] ? result[profileIndex] : {};
                    if (dataResult?.status === 200 && dataResult.data && dataResult.data.status) {
                        if (dataResult.data.data.length) {
                            setIsLoading(false);
                            setBrandprofiledata(dataResult.data.data[0]);
                        } else {
                            setBrandprofiledata([]);
                        }
                    }
                }
                setIsLoading(false);
            })

    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    return (
        <Box className={`my-brand-wrapper`}>
            {isLoading && <Loader />}
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            {
                isBrandCreate &&
                <CreateBrand
                    onBrandCreateResponse={onBrandCreateResponse}
                    isAddBrandPopup={isAddBrand}
                    onBrandClosePopup={onBrandClosePopup}
                    firstHeading={createBrandTitle}
                    secondHeading={createBrandSecondTitle}
                    buttonText={createBrandButtonText}
                    data={editBrandData}
                    isEditBrandPopup={isEditBrand}
                    isOnboardingprocess={isBrandCreateOnboarding}

                />
            }
            {
                isConfirmationPopupShow &&
                <ConformationPopup
                    heading={conformationPopupText}
                    cancelBtnText={conformationPopupCancelBtnText}
                    submitBtnText={conformationPopupSuccessBtnText}
                    onCancel={onConformationPopupClose}
                    onSubmit={onConformationPopupSuccess}

                />
            }

            {
                isBulkUpload &&
                <BulkUpload selectedBrand={selectedBrand} onBulkuploadClosePopup={onBulkuploadClosePopup} />
            }

            {
                isProcessVideoShow &&
                <ProcessVideo onPopupClose={onProcessVideoClose} />
            }

            {
                isAddBrandShow &&
                <AddBrand onAddNewProductCloseClick={onAddProudctClick} selectedBrand={selectedBrand} parentProduct={productVariantData} isVariantAdd={isVariantAdd} />
            }
            <Box className={`brands-wrapper`}>
                <div className={`flex-basis-80P brand-scroll-wrap ${isBrandSliderShow ? 'flex' : ''}`}>
                    <div className={`added-brand-wrapper scroll-items`}>
                        {
                            <Fragment>
                                {
                                    isBrandSliderShow &&
                                    <div className={`not-mobile prev-btn`} onClick={() => onSliderArrow(`left`)}>
                                        <ArrowBackIosIcon />
                                    </div>
                                }
                                <div className={`brand-list-slider-container`}>
                                    {
                                        brandList && brandList.length > 0 &&
                                        brandList.map((brand, index) => {
                                            return (
                                                <div key={index} onClick={() => { onSelectedBrandChange(brand) }} className={`image-wrap ${brand?.brand_id === selectedBrand?.brand_id ? 'selected-brand' : ''}`}>
                                                    <img className={`img`} src={brand[`b_logo`]} loading="lazy" />
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                                {
                                    isBrandSliderShow &&
                                    <div className={`not-mobile next-btn`} onClick={() => onSliderArrow(`right`)}>
                                        <ArrowForwardIosIcon />
                                    </div>
                                }
                            </Fragment>
                        }
                    </div>
                </div>

                {
                    isCompanyPermissionAdd &&
                    <div className={`add-brand-wrap`}>
                        <div className={`line`} />
                        <Box className={`add-brand-wrapper ${isButtonDisabled ? 'disabled' : ''}`}
                        onClick={() => {
                            if (!isButtonDisabled) {
                                onAddProductClick(true, true);
                            }
                            else{
                                setAlternateMessageShow(true);
                                setAlertMessageType("error");
                                setAlertMessage(`Please contact the administration to add more persona`);
                            }
                          }}
                        disabled={isButtonDisabled}
                        >
                            <div>
                                <AddIcon />
                            </div>
                            <div>
                               Persona
                            </div>
                            

                        </Box>
                    </div>
                }
            </Box>

            <Box className={`upload-cover-wrapper ${coverImageUrl === '' ? 'persona-cover-image' : ''}`}>

                {
                    coverImageUrl === ``
                        ?
                        <Fragment>
                            <div className={`camera-icon-wrapper`} onClick={() => { document.getElementById(`upload-cover-image`).click() }}>
                                <CameraIcon />
                            </div>
                            <div className={`text`}>Add a cover image for your catalogue</div>
                            <p className={`img-info`}>File must be at least 1920 x 400 px. Cover images can be in GIF, JPEG, JPG, or PNG formats.</p>
                            <input type="file" id='upload-cover-image' className={`visibility-hidden`} accept="image/x-png,image/gif,image/jpeg" onChange={(event) => { onCoverImageChange(event.target.files[0]) }} />
                        </Fragment>
                        :

                        <div className={`cover-image-wrapper position-relative`} /*style={{ background: `url(${coverImageUrl})`, backgroundRepeat: 'no-repeat' }}*/>
                            <img src={coverImageUrl} alt="Cover Image" loading='lazy' />
                            {
                                !isCompanyPermissionView &&
                                <div className={`edit-cover-image`}>
                                    <span className='edit-img-icon' onClick={() => { document.getElementById(`edit-cover-image`).click() }}>
                                        <EditImageIcon />
                                    </span>

                                    <span className={`delete-icon`} onClick={() => { onCoverImageDelete() }}>
                                        <DeleteIcon />
                                    </span>
                                </div>
                            }

                            <input type="file" id='edit-cover-image' className={`visibility-hidden`} accept="image/x-png,image/gif,image/jpeg" onChange={(event) => { onCoverImageChange(event.target.files[0]) }} />
                        </div>
                }
            </Box>

            <BrandDetail
                selectedBrand={selectedBrand}
                onBrandUpdate={updateBrandDetail}
                onEditBrand={onEditBrandClick}
                isProductViewPermission={isProductViewPermission}
                isCompanyPermissionView={isCompanyPermissionView}
            />

            {
                !isProductPermissionNone &&
                <Fragment>
                    <BrandManageSection onSelectionChange={onManageSectionChange} defaultmanagesection={manageSectionSelectedSection} />

                    {
                        manageSectionSelectedSection === `Manage Products`
                            ?
                            <ProductFilterSection
                                onAddNewProductClick={onAddProudctClick}
                                bulkUploadClick={bulkUploadEventClick}
                                selectedBrand={selectedBrand}
                                brandResponse={addBrandResponse}
                                isProductViewPermission={isProductViewPermission}
                                isCompanyPermissionView={isCompanyPermissionView}
                                brandList={brandList}
                                pagination={props?.userDetailData?.pagination || {}}
                            />
                            :
                            <ProfileSection
                                selectedBrand={selectedBrand}
                                isProductViewPermission={isProductViewPermission}
                                isCompanyPermissionView={isCompanyPermissionView}
                                profiledata={brandprofiledata}
                            />
                    }
                </Fragment>
            }

        </Box>
    )
}

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBrand);