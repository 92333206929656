import React from "react";

const masterCatalogue = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="60" height="62" viewBox="0 0 60 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="1" width="60" height="60" rx="6" fill="white"/>
            <path d="M8 23C6.4 12.6 11.4455 4.0314 14.6121 1.0314H7.11215C2.31436 0.631401 0 3.71464 0 6L0.00871184 50L0.00871087 50.0097C0.00781169 59.0015 0.00760889 61.0314 5.10899 61.0314L30.0078 61C30.4078 53.4 27.2757 48.8647 25.609 47.5314C11.5 36 8.61539 27 8 23Z" fill="#F2002B"/>
            <path d="M52 39.0273C53.6 49.4273 48.5545 57.9959 45.3879 60.9959L52.8879 60.9959C57.6856 61.3959 60 58.3127 60 56.0273L59.9913 12.0273L59.9913 12.0176C59.9922 3.02588 59.9924 0.995941 54.891 0.995942L29.9922 1.02735C29.5922 8.62734 32.7243 13.1626 34.391 14.4959C48.5 26.0273 51.3846 35.0273 52 39.0273Z" fill="#F2002B"/>
        </svg>

    );
};

export default masterCatalogue;
