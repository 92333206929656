import React, { Fragment, useEffect } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';

const PopupImageViewer = (props) => {

    const { onPopupClose, data } = props;

    const { image } = data;

    useEffect(() => {
        document.body.classList.add('hide-scroll');

        return () => {
            document.body.classList.remove('hide-scroll');
        }
    }, [])
    return (
        <Box className={`image-viewer-wrapper`}>
            <div className={`image-view-popup`}>

                <div className={`close-icon`} onClick={() => { if (onPopupClose) onPopupClose(false) }}>
                    <CloseIcon />
                </div>
                {
                    image &&
                    <Fragment>
                        {
                            image.indexOf(".pdf") !== -1 ?
                                <div>
                                    <embed src={image} className={`pdf-view`} />
                                </div>
                                :
                                <img src={image} loading="lazy" />
                        }
                    </Fragment>

                }
            </div>
        </Box>
    )
}

export default PopupImageViewer;