// React related imports
import React, { useState, useEffect } from "react";
//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import passwordResetAction from "../../../store/action/passwordResetAction";

// mui or UI related imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

//Helpers function related imports
import getQueryParamaterFromUrl from "../../../helpers/getQueryParamaterFromUrl";

// React route related imports
import { useLocation, useNavigate } from "react-router-dom";

//Apis related imports
import resetEmailVerify from "../../../apis/resetEmailVerify";

//Components related imports
import AlertMessage from "../AlertMessage";
import Loader from "../Loader";

const PasswordReset = (props) => {
  const location = useLocation();
  const [verifyEmailApiStatus, setVerifyEmailApiStatus] = useState(``);
  const token = getQueryParamaterFromUrl(`token`) || ``;
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    [isAlertMessageShow, setAlternateMessageShow] = useState(false),
    [alertMessageType, setAlertMessageType] = useState(``),
    [alertMessage, setAlertMessage] = useState(``),
    [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (token) {
      setVerifyEmailApiStatus(`inProgress`);
      setIsLoading(true);
      resetEmailVerify(token)
        .then((response) => {
          if (
            response &&
            response.status === 200 &&
            response.data &&
            response.data.status
          ) {
            setIsLoading(false);
            setVerifyEmailApiStatus(`inProgress`);
            dispatch(passwordResetAction.setpasswordresettype(response.data));
            navigate(`/forgot_password_confirm`);
          } else {
            setVerifyEmailApiStatus(`not-verified`);
            setIsLoading(false);
            if (
              response &&
              response.response &&
              response.response.data &&
              response.response.data.message
            ) {
              setIsLoading(false);
              setAlternateMessageShow(true);
              setAlertMessageType("error");

              if (response?.message === `Network Error`) {
                setAlertMessage(`No internet connection`);
              } else {
                setAlertMessage(response?.response?.data?.message);
              }
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setVerifyEmailApiStatus(`not-verified`);
          console.log("we have error", error);
        });
    }
  }, []);

  const onAlertClose = (value) => {
    setAlternateMessageShow(value);
  }

  const returnHeaderMessages = (status) => {
    switch (status) {
      case `inProgress`:
        return {
          "first-heading": "Verifying email address ...",
          "second-heading": (
            <Typography variant="h4" className={`second-heading`}>
              Verifying your email address
            </Typography>
          ),
          message: <p className={`did-not-email-text`}>Verifying</p>,
        };
        break;
      case "verified":
        return {
          "first-heading": "Thank you for choosing Discoverrr.",
          "second-heading": (
            <Typography variant="h4" className={`second-heading`}>
              We'll notify you by email when we launch.
            </Typography>
          ),
          message: <p className={`did-not-email-text`}></p>,
        };
        break;
      case "not-verified":
        return {
          "first-heading": "Oops!",
          "second-heading": (
            <Typography variant="h4" className={`second-heading`}>
              Your verification link has expired. We have sent the new link,
              kindly check your email and activate your account to continue.
            </Typography>
          ),
          message: (
            <p className={`did-not-email-text`}>
              Remember to check your spam folder if it isn’t there in your main
              inbox.
            </p>
          ),
        };
        break;

      default:
        return {};
        break;
    }
  };
  return (
    <div className={`verification-email-wrapper`}>
      {isLoading && <Loader />}
      {
        isAlertMessageShow &&
        <AlertMessage
          message={alertMessage}
          alertType={alertMessageType}
          isAlertShow={isAlertMessageShow}
          onClose={onAlertClose}
        />
      }

      <div className={`verify-mail-wrapper`}>
        <Box>
          <Typography variant="h4" className={`first-heading`}>
            {returnHeaderMessages(verifyEmailApiStatus)[`first-heading`]}
          </Typography>
          {returnHeaderMessages(verifyEmailApiStatus)[`second-heading`]}
        </Box>
        <Box>
          <Button
            type="submit"
            variant="outlined"
            size="large"
            className={`w-100 gmail-btn`}
            onClick={() => {
              window.open(`https://accounts.google.com/`, `_blank`);
            }}
          >
            Open Gmail
          </Button>
          <Button
            type="submit"
            variant="outlined"
            size="large"
            className={`w-100 outlook-btn`}
            onClick={() => {
              window.open(`https://login.microsoftonline.com/`, `_blank`);
            }}
          >
            Open Outlook
          </Button>
        </Box>

        <Box>{returnHeaderMessages(verifyEmailApiStatus)[`message`]}</Box>
      </div>

      <div className={`verify-mail-box-wrapper`}>
        <Box className={`verify-mail-text-box`}>
          <div className={`email`}>
            <Typography className={`label`}>Email icon</Typography>
          </div>
          <Box>
            {/* <div className={`vertical-center`}></div> */}

            <div className={`vertical-center`}>
              <div className={`checkbox-wrapper`}>
                <Checkbox disabled />
                <Typography className={`label`}>Discoverrrr</Typography>
              </div>

              <div>
                <Typography className={`label`}>
                  Please verify your email address
                </Typography>
              </div>
            </div>
          </Box>

          {[...Array(13)].map((start, index) => {
            return (
              <Box key={index}>
                <div className={`vertical-center`}>
                  <div>
                    <Checkbox disabled />
                    <Typography className={`first-bar`}></Typography>
                  </div>

                  <div>
                    <Typography className={`second-bar`}></Typography>
                  </div>
                </div>
              </Box>
            );
          })}
        </Box>
      </div>
    </div>
  );
};

//map state
const mapStateToProps = (state) => {
  return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
