import React, { Fragment, useState, useEffect, useRef } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl';
import Typography from "@mui/material/Typography";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';

//Apis related imports
import getStorageTypeList from '../../../../../apis/Supplier/getStorageTypeList';
import getDistributionList from '../../../../../apis/Supplier/getDistributionList';
import getRetailersList from '../../../../../apis/getRetailersList';
import productUpdate from "../../../../../apis/Supplier/productUpdate";

//Other common imports
import Loader from "../../../Loader";
import AlertMessage from "../../../AlertMessage";

const NoOfStoreComponent = (props) => {

    const { data, onRemoveRetailerStore, onRetailStoreChange } = props;
    const [checked, setChecked] = useState(data?.all_stores === false ? data?.all_stores : true),
        [inputValue, setInputValue] = useState(data?.stores_count);
    const onStoreInputChange = (value) => {
        let isChecked = value ? false : true;
        setChecked(isChecked);
        setInputValue(value);
    }

    useEffect(() => {
        if (onRetailStoreChange) {
            onRetailStoreChange(data?.retailers_id, checked, inputValue, data?.name || data?.retailersname);
        }
        if (checked) {
            setInputValue(``)
        }
    }, [checked])

    const onInputBlur = () => {
        if (onRetailStoreChange) {
            onRetailStoreChange(data?.retailers_id, checked, inputValue, data?.name || data?.retailersname);
        }
    }

    const inputChange = (event) => {

        onStoreInputChange(Math.round(event.target.value.replace(/\D/g,'')));
       
    }
    return (
        <div className={`no-of-store-wrapper`}>
            <div className={`name`}>
                {data?.name || data?.retailersname}
            </div>

            <div className={`store-checkbox`}>
                <Checkbox
                    checked={checked}
                    onChange={(event) => setChecked(event.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    defaultChecked
                    size="small" />
                <span>All stores</span>
            </div>

            <div className={`store-input`}>
                <input type="number"
                    value={inputValue}
                    onInput={(event) => inputChange(event)}
                    onBlur={() => onInputBlur()} 
                    placeholder={'No. of stores'}/>
            </div>

            <div className={`remove`} onClick={() => onRemoveRetailerStore(data)}>
                <CloseIcon />
            </div>
        </div>
    )
}
const ProductDistribution = (props) => {

    const { onSectionApiCallSuccess } = props
    const [productDetails, setProductDetails] = useState(props?.productDetails || {}),
        [productAvailabel, setProductAvailabel] = useState(productDetails?.products_distribution?.[0]?.pro_availability ? 'yes' : 'no'),
        [productAvailabelViaDistributor, setProductAvailabelViaDistributor] = useState(productDetails?.products_distribution?.[0]?.pro_avail_distributors ? 'yes' : 'no'),
        [productPrivateLabel, setProductPrivateLabel] = useState(productDetails?.products_distribution?.[0]?.pro_private_label ? 'yes' : 'no'),
        [isRequiredColdStorage, setIsRequiredColdStorage] = useState(productDetails?.products_distribution?.[0]?.pro_cold_storage ? 'yes' : 'no'),
        [storageTypeList, setStorageTypeList] = useState([]),
        [storageType, setStorageType] = useState(productDetails?.products_distribution?.[0]?.pro_storage_type || storageTypeList?.[0]?.storage_type_id || ``),
        [distributionList, setDistributionList] = useState([]),
        [selectedDistributionList, setSelectedDistributionList] = useState([]),
        [countryList, setCountryList] = useState(props?.countryList || []),
        [countryValue, setCountryValue] = useState([]),
        [countryInputValue, setCountryInputValue] = useState(''),
        [countryArray, setCountryArray] = useState([]),
        [retailerList, setRetailerList] = useState([]),
        [retailerValue, setRetailerValue] = useState(retailerList[0]),
        [selectedRetailerList, setSelectedRetailerList] = useState([]),
        [retailerListObject, setRetailerListObject] = useState([]),
        [productAvailabelDate, setProductAvailabelDate] = useState(``),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isLoading, setIsLoading] = useState(false),
        [selectedCountry, setSelectedCountry] = useState([]),
        [selecteRetailer, setSelectedRetailer] = useState([]);

    const theme = useTheme();

    const moduleApis = [];
    const moduleApiIndex = [];

    const distributionAutoComplete = useRef(null);

    useEffect(() => {
        moduleApis.push(getStorageTypeList());
        moduleApiIndex.push(`storageTypeIndex`);

        moduleApis.push(getDistributionList());
        moduleApiIndex.push(`distributionIndex`);

        moduleApis.push(getRetailersList());
        moduleApiIndex.push(`retailerIndex`);

        Promise.all(moduleApis)
            .then((result) => {
                let storageTypeIndex = moduleApiIndex.indexOf('storageTypeIndex');
                if (storageTypeIndex !== -1 && result.length) {
                    const dataResult = result[storageTypeIndex] ? result[storageTypeIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setStorageTypeList(dataResult.data.data);
                    }
                }

                let distributionIndex = moduleApiIndex.indexOf('distributionIndex');
                if (distributionIndex !== -1 && result.length) {
                    const dataResult = result[distributionIndex] ? result[distributionIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setDistributionList(dataResult.data.data);
                    }
                }

                let retailerIndex = moduleApiIndex.indexOf('retailerIndex');
                if (retailerIndex !== -1 && result.length) {
                    const dataResult = result[retailerIndex] ? result[retailerIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setRetailerList(dataResult.data.data);
                    }
                }
            })
    }, []);

    useEffect(() => {
        if (props?.productDetails?.products_distributors && props?.productDetails?.products_distributors.length > 0) {
            props?.productDetails?.products_distributors.map((value) => {
                if (distributionList && distributionList.length > 0) {
                    distributionList.map((distribution) => {
                        if (value?.distributors === distribution?.distributors_id) {
                            if (!ifDistributorExist(selectedDistributionList, value?.distributors)) {
                                setSelectedDistributionList((selectedDistributionList) => [...selectedDistributionList, distribution?.name])
                            }
                        }
                    })
                }
            });
        }
    }, [distributionList, props?.productDetails?.products_distributors]);

    useEffect(() => {
        setCountryList(props?.countryList || []);
        let tempCountryArray = [];
        if (props?.productDetails?.products_sold_countries && props?.productDetails?.products_sold_countries.length > 0) {
            props?.productDetails?.products_sold_countries.map((value) => {
                if (props?.countryList && props?.countryList.length > 0) {
                    props?.countryList.map((data) => {
                        if (value?.country == data?.country_id) {
                            if (!ifCountryExist(countryArray, data?.country_id)) {
                                // setCountryArray([...countryArray, data]);
                                tempCountryArray.push(data?.name);
                            }
                        }
                    })
                }
            })
        }
        setSelectedCountry(tempCountryArray);
    }, [props?.countryList, props?.productDetails?.products_sold_countries]);

    useEffect(() => {
        setStorageType(storageTypeList?.[0]?.storage_type_id || ``);
    }, [storageTypeList]);


    useEffect(() => {

        let tempRetailerArray = [], tempSelectedReailerArray = [];

        setProductDetails(props?.productDetails || {});
        setProductAvailabel(props?.productDetails?.products_distribution?.[0]?.pro_availability ? 'yes' : 'no');
        setProductAvailabelViaDistributor(props?.productDetails?.products_distribution?.[0]?.pro_avail_distributors ? 'yes' : 'no');
        setProductPrivateLabel(props?.productDetails?.products_distribution?.[0]?.pro_private_label ? 'yes' : 'no');
        setIsRequiredColdStorage(props?.productDetails?.products_distribution?.[0]?.pro_cold_storage ? 'yes' : 'no');
        setStorageType(props?.productDetails?.products_distribution?.[0]?.pro_storage_type || storageTypeList?.[0]?.storage_type_id || ``);
        // setSelectedRetailerList(props?.productDetails?.products_retailers || [])
        // setRetailerListObject(props?.productDetails?.products_retailers || [])

        if (props?.productDetails?.products_retailers && props?.productDetails?.products_retailers.length > 0) {
            props?.productDetails?.products_retailers.map((retailer) => {

                tempSelectedReailerArray.push(retailer?.retailersname)
                tempRetailerArray = props?.productDetails?.products_retailers.map(({
                    retailers: retailers_id,
                    ...rest
                }) => ({
                    retailers_id,
                    ...rest
                }));
            })

            setSelectedRetailerList(tempRetailerArray)
            setSelectedRetailer(tempSelectedReailerArray);
        }
        if (props?.productDetails?.products_distribution?.[0]?.pro_availability_date) {
            var date = (new Date(props?.productDetails?.products_distribution?.[0]?.pro_availability_date)).toISOString().split('T')[0];
            setProductAvailabelDate(date);
        }

    }, [props?.productDetails])

    const onProductAvailableChange = (event) => {
        setProductAvailabel(event.target.value);
    };

    const onProductAvailableViaDistributor = (event) => {
        setProductAvailabelViaDistributor(event.target.value);
    }

    const onProductPrivateLabelChange = (event) => {
        setProductPrivateLabel(event.target.value);
    }

    const onColdeStorageRequiredChange = (event) => {
        setIsRequiredColdStorage(event.target.value);
    }

    const onStorageTypeChange = (event) => {
        setStorageType(event.target.value);
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onFormSubmit = (event) => {
        event.preventDefault();

        let tempDistributionList = [], tempCountryList = [], tempArray = [];

        if (selectedDistributionList && selectedDistributionList.length > 0) {
            selectedDistributionList.map((list) => {
                if (distributionList && distributionList.length > 0) {
                    distributionList.map((distribution) => {
                        if (list === distribution?.name) {
                            tempDistributionList.push(distribution?.distributors_id)
                        }
                    })
                }
            })
        }

        // if (countryArray && countryArray.length > 0) {
        //     countryArray.map((list) => {
        //         tempCountryList.push(list?.country_id)
        //     })
        // }

        if (selectedCountry && selectedCountry.length > 0) {
            if (countryList && countryList.length > 0) {
                countryList.map((country) => {
                    selectedCountry.map((selectedCountry) => {
                        if (country[`name`] === selectedCountry) {
                            tempCountryList.push(country?.country_id)
                        }
                    })
                })
            }

        }

        if (productDetails?.product_usps && productDetails?.product_usps.length > 0) {
            productDetails?.product_usps.map((data) => {
                tempArray.push(data[`name`]);
            })
        }

        const productDistributionObject = {
            "pro_availability": productAvailabel === "no" ? "False" : "True",
            "pro_availability_date": productAvailabel === "no" && productAvailabelDate ? productAvailabelDate : ``,
            "pro_avail_distributors": productAvailabelViaDistributor === "yes" ? "True" : "False",
            "pro_private_label": productPrivateLabel === "yes" ? "True" : "False",
            "pro_cold_storage": isRequiredColdStorage === "yes" ? "True" : "False",
            "pro_storage_type_id": storageType
        }

        const productDistributionDataObject = {
            "productId": productDetails?.products_id,
            "usps": tempArray.length > 0 ? tempArray.join() : '',
            "productDistribution": productDistributionObject,
            "retailersList": selectedRetailerList,
            "distributorsList": tempDistributionList.join(),
            "countriesSoldList": tempCountryList.join(),
            "productIngredients" : productDetails?.products_ingredients?.[0]?.name || ``
        }

        productUpdate(productDistributionDataObject)
            .then((response) => {
                if (response?.status === 200 && response?.data?.status && response.data.data) {
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response?.data?.message);
                    setIsLoading(false);

                    setTimeout(() => {
                        if (onSectionApiCallSuccess) {
                            onSectionApiCallSuccess(true);
                        }
                    }, 300)
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }

                    setTimeout(() => {
                        if (onSectionApiCallSuccess) {
                            onSectionApiCallSuccess(false);
                        }
                    }, 300)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setTimeout(() => {
                    if (onSectionApiCallSuccess) {
                        onSectionApiCallSuccess(false);
                    }
                }, 300)
                console.log("We have error at productUpdate", error);
            })
    }

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    const onDistributionChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedDistributionList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const onCountryChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCountry(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

        setCountryValue(``);
    }

    const onCountryRemove = (value) => {
        // setCountryArray(countryArray.filter(item => item?.country_id !== value?.country_id));
        setSelectedCountry(selectedCountry.filter(item => item !== value))
    }

    useEffect(() => {
        let tempArray = [];
        if (retailerList && retailerList.length > 0) {
            retailerList.map((retailer) => {
                if (selecteRetailer && selecteRetailer.length > 0) {
                    selecteRetailer.map((data) => {
                        if (retailer[`name`] === data) {
                            tempArray.push({
                                "retailers_id": retailer?.Retailers_id,
                                "all_stores": "True",
                                "stores_count": "",
                                "name": retailer?.name
                            })
                        }
                    })
                }
            })
        }

        if (tempArray && tempArray.length > 0) {
            setSelectedRetailerList(tempArray);
            setRetailerListObject(tempArray);
        }
    }, [selecteRetailer])

    const onRetailerChange = (value) => {
        if (value) {
            setSelectedRetailerList([...selectedRetailerList, {
                "retailers_id": value?.Retailers_id,
                "all_stores": "True",
                "stores_count": "",
                "name": value?.name
            }]);

            setRetailerListObject([...selectedRetailerList, {
                "retailers_id": value?.Retailers_id,
                "all_stores": "True",
                "stores_count": "",
                "name": value?.name
            }])
        }
    }

    const onRemoveRetailerStore = (value) => {
        setSelectedRetailerList(selectedRetailerList.filter(item => item?.retailers_id !== value?.retailers_id));
        setSelectedRetailer(selecteRetailer.filter(item => item !== value?.name))
    }

    const onRetailerDistributionChange = (event) => {
        setRetailerListObject([]);
        setSelectedRetailerList([]);
        const {
            target: { value },
        } = event;
        setSelectedRetailer(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );


    }

    const elementExist = (arr, value) => {
        return arr.some(function (element) {
            return element?.retailers_id === value;
        });
    }

    const ifCountryExist = (arr, value) => {
        return arr.some(function (element) {
            return element?.country_id === value;
        });
    }

    const ifDistributorExist = (arr, value) => {
        return arr.some(function (element) {
            return element?.distributors === value;
        });
    }

    const onRetailsStoreChange = (relailerId, allStores, storeCount, name) => {
        if (elementExist(selectedRetailerList, relailerId)) {
            setSelectedRetailerList(
                selectedRetailerList.map((item) => {
                    return item?.retailers_id == relailerId ? {
                        "all_stores": allStores ? "True" : "False",
                        "stores_count": allStores ? '' : storeCount,
                        "retailers_id": relailerId,
                        "name": name
                    } : item;
                })
            );
        } else {
            setSelectedRetailerList([...selectedRetailerList, {
                "all_stores": allStores ? "True" : "False",
                "stores_count": allStores ? '' : storeCount,
                "retailers_id": relailerId,
                "name": name
            }]);
        }
    }

    return (
        <Box className={`product-distribution-wrapper`}>
            {isLoading && <Loader />}
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`heading`}>
                Distribution
            </div>

            <form className={`distribution-form form-input-control`} onSubmit={onFormSubmit}>
                <Box className={`form-control margin-bottom-28 budget-wrapper`}>

                    <div className={`form-control margin-bottom-28 for-yes-no-1`}>
                        <Typography className={`label margin-btm-15`}>Is this product available now?</Typography>
                        <FormControl sx={{ m: 1, minWidth: 120 }} >
                            <FormControl>
                                <RadioGroup
                                    name="controlled-radio-buttons-group"
                                    value={productAvailabel}
                                    onChange={onProductAvailableChange}
                                    className={`product-availablity-wrapper`}
                                    row
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </FormControl>
                    </div>

                    <div className={`product-available-wrapper`}>
                        {
                            productAvailabel === `yes` &&
                            <Fragment>
                                <Typography className={`label margin-btm-15`}>What retailers currently sell this product?</Typography>
                                {/* <Autocomplete
                                    ref={distributionAutoComplete}
                                    freeSolo
                                    forcePopupIcon={true}
                                    value={retailerValue}
                                    disablePortal
                                    id="product-available"
                                    options={retailerList}
                                    blurOnSelect={true}
                                    getOptionLabel={(option) => (option ? option.name : "")}
                                    getOptionDisabled={(option) => {
                                        if (selectedRetailerList && selectedRetailerList.length > 0) {
                                            if (selectedRetailerList.some((value) => value.retailers_id === option.Retailers_id)) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }}
                                    onChange={(event, newValue) => {
                                        onRetailerChange(newValue);
                                        setRetailerValue(``);
                                    }}
                                    onBlur={() => {
                                        setRetailerValue(``);
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder={`Select Retailers`} />}
                                /> */}
                                <FormControl sx={{ m: 1, width: 300 }}>
                                    <InputLabel id="demo-multiple-checkbox-label">Select Retailers</InputLabel>
                                    <Select
                                        labelId="retailerList"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selecteRetailer}
                                        onChange={(event) => { setRetailerListObject([]); setSelectedRetailerList([]); onRetailerDistributionChange(event) }}
                                        input={<OutlinedInput label="Select Retailers" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {retailerList.map((retailer) => (
                                            <MenuItem key={retailer[`name`]} value={retailer[`name`]}>
                                                <Checkbox checked={selecteRetailer.indexOf(retailer[`name`]) > -1} />
                                                <ListItemText primary={retailer[`name`]} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Fragment>
                        }
                    </div>

                    <div>
                        {
                            productAvailabel === `yes` &&
                            <div className={`store-list-wrap`}>
                                <div className={`text-wrap`}>
                                    {
                                        selectedRetailerList && selectedRetailerList.length > 0 &&
                                        <div className={`store-text`}></div>
                                    }
                                </div>
                                <div className={`retailer-list-wrap`}>
                                    {
                                        selectedRetailerList && selectedRetailerList.length > 0 &&
                                        selectedRetailerList.map((relailer, index) => {
                                            if (relailer) {
                                                return (
                                                    <div key={index}>
                                                        <NoOfStoreComponent
                                                            data={relailer}
                                                            onRemoveRetailerStore={onRemoveRetailerStore}
                                                            onRetailStoreChange={onRetailsStoreChange}
                                                        />
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className={`date-wrapper`}>
                        {
                            productAvailabel === `no` &&
                            <div>
                                <Typography className={`label margin-btm-15`}>When will this product available?</Typography>
                                <div onClick={() => document.getElementById(`date`).showPicker()}>
                                    <input
                                        className={`product-available-date`}
                                        type="date"
                                        id="date"
                                        data-placeholder="dd/mm/yyyy"
                                        value={productAvailabelDate}
                                        onChange={(event) => { setProductAvailabelDate(event.target.value); }}
                                    />
                                </div>

                            </div>

                        }
                    </div>

                </Box>

                <Box className={`form-control margin-bottom-28 for-yes-no-1`}>
                    <Typography className={`label margin-btm-15`}>Is this product available through Distributors?</Typography>
                    <FormControl sx={{ m: 1, minWidth: 120 }} >
                        <FormControl>
                            <RadioGroup
                                name="controlled-radio-buttons-group"
                                value={productAvailabelViaDistributor}
                                onChange={onProductAvailableViaDistributor}
                                className={`product-availablity-wrapper`}
                                row
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </FormControl>
                </Box>

                {
                    productAvailabelViaDistributor === 'yes' &&
                    <Fragment>
                        <Box className={`form-control margin-bottom-28 product-available-wrapper`}>
                            <Typography className={`label margin-btm-15`}>Which Distributor?</Typography>
                            <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Distributor</InputLabel>
                                <Select
                                    labelId="distribution"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={selectedDistributionList}
                                    onChange={onDistributionChange}
                                    input={<OutlinedInput label="Distributor" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {distributionList.map((distribution) => (
                                        <MenuItem key={distribution[`name`]} value={distribution[`name`]}>
                                            <Checkbox checked={selectedDistributionList.indexOf(distribution[`name`]) > -1} />
                                            <ListItemText primary={distribution[`name`]} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* <FormControl sx={{ m: 1, width: 300 }}>
                                <Select
                                    id="distribution-multiselect"
                                    multiple
                                    value={selectedDistributionList}
                                    onChange={onDistributionChange}
                                    input={<OutlinedInput id="select-multiple-chip" />}
                                    displayEmpty
                                    renderValue={selected => selected?.length ? <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box> : 'Select Distributors'}

                                    MenuProps={MenuProps}
                                >

                                    {distributionList && distributionList.length > 0 && distributionList.map((distribution) => (
                                        <MenuItem
                                            key={distribution[`name`]}
                                            value={distribution[`name`]}
                                            style={getStyles(distribution[`name`], selectedDistributionList, theme)}
                                        >
                                            {distribution[`name`]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                        </Box>
                        {/* <div className={`submit-componany margin-btm-15`}>
                            Don’t see a distribution company that you need? <a>Submit the company</a>
                        </div> */}
                    </Fragment>
                }

                <Box className={`form-control margin-bottom-28 country-wrapper`}>
                    <Typography className={`label margin-btm-15`}>In which countries is this product currently sold?</Typography>
                    {/* <Autocomplete
                        value={countryValue}
                        forcePopupIcon={false}
                        id="combo-box-demo"
                        options={countryList}
                        getOptionLabel={(option) => (option ? option.name : "")}

                        onChange={(event, newValue) => {
                            onCountryChange(newValue);
                            setCountryValue(``);
                        }}

                        renderInput={(params) => <TextField {...params} placeholder={`Select product sold countries`} />}
                    /> */}

                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">Country</InputLabel>
                        <Select
                            labelId="Country"
                            id="country-multiple-select"
                            className="country-multiple-select"
                            multiple
                            value={selectedCountry}
                            onChange={onCountryChange}
                            input={<OutlinedInput label="Country" className="country-multiple-input" />}
                            renderValue={(selected) => `Select Country`}
                            MenuProps={MenuProps}
                        >
                            {countryList.map((country) => (
                                <MenuItem key={country[`name`]} value={country[`name`]}>
                                    <Checkbox checked={selectedCountry.indexOf(country[`name`]) > -1} />
                                    <ListItemText primary={country[`name`]} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {
                        <div className={`selected-country-list`}>
                            {/* {
                                countryArray && countryArray.length > 0 &&
                                countryArray.map((country, i) => {
                                    if (country) {
                                        return (
                                            <div key={i} className={`country-wrap`}>
                                                <div className={`country`}>
                                                    <span className={`text`}>
                                                        {country[`name`]}
                                                    </span>
                                                    <span className={`svg`} onClick={() => onCountryRemove(country)}>
                                                        <CloseIcon />
                                                    </span>
                                                </div>
                                            </div>
                                        )

                                    }
                                })
                            } */}

                            {
                                selectedCountry && selectedCountry.length > 0 &&
                                selectedCountry.map((country, i) => {
                                    if (country) {
                                        return (
                                            <div key={i} className={`country-wrap`}>
                                                <div className={`country`}>
                                                    <span className={`text`}>
                                                        {country}
                                                    </span>
                                                    <span className={`svg`} onClick={() => onCountryRemove(country)}>
                                                        <CloseIcon />
                                                    </span>
                                                </div>
                                            </div>
                                        )

                                    }
                                })
                            }
                        </div>

                    }
                </Box>
                <Box className={`form-control margin-bottom-28 for-yes-no-1`}>
                    <Typography className={`label margin-btm-15`}>Is this product available for private label?</Typography>
                    <FormControl sx={{ m: 1, minWidth: 120 }} >
                        <FormControl>
                            <RadioGroup
                                name="controlled-radio-buttons-group"
                                value={productPrivateLabel}
                                onChange={onProductPrivateLabelChange}
                                className={`product-availablity-wrapper`}
                                row
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </FormControl>
                </Box>

                <Box className={`form-control margin-bottom-28 for-yes-no-1`}>
                    <Typography className={`label margin-btm-15`}>Does your product require cold storage?</Typography>
                    <FormControl sx={{ m: 1, minWidth: 120 }} >
                        <FormControl>
                            <RadioGroup
                                name="controlled-radio-buttons-group"
                                value={isRequiredColdStorage}
                                onChange={onColdeStorageRequiredChange}
                                className={`product-availablity-wrapper`}
                                row
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </FormControl>
                </Box>

                {
                    isRequiredColdStorage === 'yes' &&
                    <Box className={`form-control margin-bottom-28 for-yes-no-1`}>
                        <Typography className={`label margin-btm-15`}>What type of storage is required?</Typography>
                        <FormControl sx={{ m: 1, minWidth: 120 }} >
                            <FormControl>
                                <RadioGroup
                                    name="controlled-radio-buttons-group"
                                    value={storageType}
                                    onChange={onStorageTypeChange}
                                    className={`product-availablity-wrapper`}
                                    row
                                >
                                    {
                                        storageTypeList && storageTypeList.length > 0 &&
                                        storageTypeList.map((storage, index) => {
                                            return (
                                                <FormControlLabel value={storage[`storage_type_id`]} control={<Radio />} label={storage[`name`]} key={index} />
                                            )
                                        })
                                    }

                                </RadioGroup>
                            </FormControl>
                        </FormControl>
                    </Box>
                }

                <div className={`button-wrapper`}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        id="product-distribution-submit-btn"
                        className={`w-100 btn-primary-after-login visibility-hidden display-none`}
                    >
                        Sign up
                    </Button>
                </div>
            </form>
        </Box>
    )
}

export default ProductDistribution;