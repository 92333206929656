
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import { CONFIG } from '../../helpers/getApiConfig';

const productBulkCopy = async (copiedProduct, brandId, action = ``) => {
    
    const headers = await getApiHeader() || {};;

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in productBulkCopy function' 
        }
    }

    if(!copiedProduct) {
        return {
            error: true,
            message: 'copiedProduct mandatory in productBulkCopy function' 
        }
    }

    if(!brandId) {
        return {
            error: true,
            message: 'brandId mandatory in productBulkCopy function' 
        }
    }

    const instance = axios.create();
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();
    if (copiedProduct) formData.append(`productids`, copiedProduct || ``);
    if (brandId) formData.append(`copytocompany`, brandId || ``);
    if (action) formData.append(`action`, action || ``);

    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/products/product-clone`, formData, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default productBulkCopy;