import { combineReducers } from "redux";
import userReducer from "./userReducer";
import userDetailReducer from "./userDetailReducer";
import brandReducer from "./brandReducer";
import businessTypeReducer from "./businessTypeReducer";
import appObjectReducer from "./appObjectReducer";
import countryListReducer from "./countryListReducer";
import registrationReducer from "./registrationReducer";
import passwordResetReducer from "./passwordResetReducer";
import yearOfEstablishReducer from "./yearOfEstablishReducer";
import industryListReducer from "./industryListReducer";
import interestListReducers from "./interestListReducers";
import onBoardingReducer from "./onBoardingReducer";
import supplierBrandListReducer from "../reducer/supplier/supplierBrandListReducer";
import editProductReducer from "./supplier/editProductReducer";

// other reducers needs to import here
const rootReducer = combineReducers({
  appData: appObjectReducer,
  usersData: userReducer,
  userDetailData: userDetailReducer,
  brandData: brandReducer,
  businessTypeData: businessTypeReducer,
  countryListData : countryListReducer,
  registrationData : registrationReducer,
  passwordResetData : passwordResetReducer,
  yearOfEstablishData : yearOfEstablishReducer,
  industryListData : industryListReducer,
  interestListData : interestListReducers,
  userOnBoardingData : onBoardingReducer,
  supplierBrandListData : supplierBrandListReducer,
  editProductData : editProductReducer,
  // if there are other reducers , we can add here one by one
});
export default rootReducer;
