import React from "react";

const catalogueLockIcon = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="25" height="34" viewBox="0 0 25 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3341_3217)">
            <path d="M22.3053 34C15.7649 34 9.22449 34 2.68338 34C2.48735 33.9407 2.28993 33.8878 2.09598 33.8222C0.79579 33.3849 0.00611974 32.2048 0.00473435 30.6691C0.000578188 27.0603 0.00334896 23.4515 0.00334896 19.8427C0.00334896 18.9137 -0.00634875 17.9854 0.00611974 17.0564C0.0255152 15.5159 0.869216 14.3428 2.24144 13.9456C2.51436 13.8666 2.80114 13.837 3.12878 13.7756C3.12878 13.6381 3.1267 13.4893 3.12947 13.3411C3.16134 11.6383 3.02557 9.91079 3.2597 8.23759C3.81247 4.27963 6.06719 1.6732 9.78765 0.423237C10.4138 0.211618 11.0844 0.137552 11.7341 0C12.2405 0 12.7475 0 13.2539 0C13.524 0.046556 13.7928 0.096639 14.0636 0.138257C18.2621 0.789336 21.6216 4.55614 21.819 8.8922C21.8876 10.3933 21.8488 11.8993 21.8606 13.4032C21.862 13.5379 21.8745 13.6727 21.8814 13.7996C23.5341 13.9668 24.578 14.833 24.9029 16.2763C24.9362 16.423 24.9673 16.5704 24.9999 16.7171C24.9999 21.5138 24.9999 26.3105 24.9999 31.1072C24.9777 31.1855 24.9493 31.2617 24.9348 31.3414C24.6903 32.6696 23.9331 33.5196 22.6614 33.8935C22.5422 33.9288 22.4238 33.9647 22.3053 34ZM12.5044 15.9391C9.44199 15.9391 6.37959 15.9384 3.31789 15.9398C2.43332 15.9398 2.08697 16.2904 2.08697 17.2017C2.08489 21.6683 2.08489 26.1349 2.08697 30.6007C2.08766 31.5227 2.43955 31.8746 3.34837 31.8746C9.44961 31.8754 15.5515 31.8754 21.6528 31.8746C22.5422 31.8746 22.9017 31.5212 22.9017 30.624C22.9045 26.1461 22.9045 21.6676 22.9017 17.1898C22.901 16.2876 22.5498 15.9405 21.6569 15.9398C18.6063 15.9391 15.5557 15.9391 12.5044 15.9391ZM19.7451 13.7968C19.7555 13.7107 19.7714 13.643 19.7714 13.5753C19.7694 12.118 19.804 10.6585 19.7548 9.20257C19.5996 4.61892 15.3313 1.28734 10.9417 2.29676C7.55994 3.07481 5.29206 5.90768 5.22279 9.47203C5.19647 10.8236 5.21656 12.1758 5.21725 13.5273C5.21725 13.6162 5.23803 13.7044 5.24912 13.7961C10.0938 13.7968 14.9129 13.7968 19.7451 13.7968Z" fill="#686868"/>
            <path d="M11.4556 26.5933C11.4556 26.5461 11.4508 26.4988 11.4563 26.4522C11.536 25.7497 11.4307 25.2615 10.6881 24.8468C9.45307 24.1562 9.061 22.4809 9.59923 21.0849C10.1104 19.7595 11.5173 18.9391 12.9075 19.1556C14.3767 19.3842 15.3867 20.407 15.5883 21.8693C15.7843 23.2921 15.0708 24.5879 13.7408 25.1981C13.5767 25.2735 13.5261 25.3617 13.5289 25.5381C13.5386 26.2075 13.5365 26.8776 13.5316 27.547C13.5261 28.2623 13.1354 28.6862 12.4933 28.6855C11.8512 28.6848 11.464 28.2602 11.4563 27.5456C11.4529 27.2275 11.4563 26.91 11.4556 26.5933ZM12.4954 23.3633C13.1202 23.3626 13.5316 22.9443 13.531 22.3088C13.5303 21.6803 13.1056 21.2464 12.4926 21.2478C11.8768 21.2493 11.4577 21.6795 11.4577 22.3109C11.4577 22.9471 11.8692 23.364 12.4954 23.3633Z" fill="#686868"/>
            </g>
            <defs>
            <clipPath id="clip0_3341_3217">
            <rect width="25" height="34" fill="white"/>
            </clipPath>
            </defs>
        </svg>


    );
};

export default catalogueLockIcon;
