import axios from 'axios';

import {CONFIG} from '../helpers/getApiConfig';

const register = async (firstName = ``, lastName = ``, email = ``, password = ``, country = ``, mobilecountry = ``, mobile = ``, userType = 1, catalogue = ``) => {

    if(!firstName) {
        return {
            error: true,
            message: 'firstName mandatory in register function' 
        }
    }

    if(!lastName) {
        return {
            error: true,
            message: 'lastName mandatory in register function' 
        }
    }

    if(!email) {
        return {
            error: true,
            message: 'email mandatory in register function' 
        }
    }

    if(!password) {
        return {
            error: true,
            message: 'password mandatory in register function' 
        }
    }

    if(!country) {
        return {
            error: true,
            message: 'country mandatory in register function' 
        }
    }

    if(!mobile) {
        return {
            error: true,
            message: 'mobile mandatory in register function' 
        }
    }

    if(!userType) {
        return {
            error: true,
            message: 'userType mandatory in register function' 
        }
    }

    const instance = axios.create();
    
    instance.defaults.timeout = CONFIG.timeout || 15000;;
    
    var formData = new FormData();

    if(firstName) formData.append("firstname", firstName);
    if(lastName) formData.append("lastname", lastName);
    if(email) formData.append("email", email);
    if(password) formData.append("password", password);
    // if(country) formData.append("country", country);
    if(mobilecountry) formData.append("mobilecountry", mobilecountry);
    if(mobile) formData.append("mobile", mobile);
    if(userType) formData.append("usertype", userType);
    if(catalogue) formData.append("catalogue",catalogue);

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/register` , formData)
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default register;