import React, { useState, useEffect, Fragment } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";
import CloseIcon from '@mui/icons-material/Close';
// import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Switch from '@mui/material/Switch';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

//helper functions related functions
import copyToClipboard from '../../../../helpers/copyToClipboard';

//Icons related imports
import ShareProductCopyIcon from '../../../../icons/svgs/shareProductCopyIcon';

//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import supplierBrandListAction from "../../../../store/action/supplier/supplierBrandListAction";
import appObjectAction from "../../../../store/action/appObjectAction";

//Icons related imports
import IconBrandDefaultImage from "../../../../icons/svgs/branddefaultimage";
import ProductIcon from "../../../../icons/svgs/productIcon";
import MoreIcons from '../../../../icons/svgs/moreIcons';
import SearchIcon from '../../../../icons/svgs/searchIcon';
import TableCopyIcon from '../../../../icons/svgs/tableCopyIcon';
import MasterCatalogueIcon from '../../../../icons/svgs/masterCatalogue';
import CatalogueLockIcon from '../../../../icons/svgs/catalogueLockIcon';
import BlackEyeIcon from '../../../../icons/svgs/blackEyeIcon'
import CatPrevIcon from '../../../../icons/svgs/cataloguePreviewIcon'
import ShareIcon from '../../../../icons/svgs/shareIcon';
import DragHandleIcon from '@mui/icons-material/DragHandle';

//import other component related imports
import Loader from '../../Loader';
import AlertMessage from "../../AlertMessage";
import PaginationComponent from '../Pagination';
import ProductShare from '../ProductShare';
import Rename from '../Rename';
import CatalogueWebsitePreview from "../../WebsitePreview/LandingPage";
import Tooltip from '../Tooltip';
import ConformationPopup from "../ConformationPopup";

// React route related imports
import { useNavigate } from "react-router-dom";

//Helpers function related imports
import debounce from '../../../../helpers/debounce';
import createLocalStorage from '../../../../helpers/createLocalStorage';
import getLocalStorage from '../../../../helpers/getLocalStorage';
import downloadFile from '../../../../helpers/downloadFile';

//Api related imports
import getBrandList from '../../../../apis/Supplier/getBrandList';
import getCatalogueList from '../../../../apis/Supplier/getCatalogueList';
import addCatalogue from '../../../../apis/Supplier/addCatalogue';
import getCatalogueProductList from '../../../../apis/Supplier/getCatalogueProductList';
import catalogueUpdate from '../../../../apis/Supplier/catalogueUpdate';
import catalogueAction from '../../../../apis/Supplier/catalogueAction';
import catalogueDelete from '../../../../apis/Supplier/catalogueDelete';
import getCatalogueCustomWebsiteProductsIds from '../../../../apis/Supplier/getCatalogueCustomWebsiteProductsIds';
import getBrandCategoryList from "../../../../apis/Supplier/getBrandCategoryList";
import getProductBrandList from "../../../../apis/Supplier/getProductBrandList";
import categoryOrderUpdate from "../../../../apis/Supplier/categoryOrderUpdate";
import getProductListByCategory from "../../../../apis/Supplier/getProductListByCategory";
import updateProductListByCategory from "../../../../apis/Supplier/updateProductListByCategory";

//Other imports
import { get, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import VisibilitySensor from 'react-visibility-sensor';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const CatalogueSorting = (props) => {

    const { onClose, selectedBrand } = props;

    const [isLoading, setIsLoading] = useState(false),
        [brandList, setBrandList] = useState([]),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``);

    useEffect(() => {
        document.body.classList.add('hide-scroll');

        return () => {
            document.body.classList.remove('hide-scroll');
        }
    }, [])

    useEffect(() => {
        setIsLoading(true);
        getBrandCategoryList(selectedBrand?.brand_id, ``, `v2`)
        .then((response) => {
            if(response?.status === 200 && response?.data?.status &&response?.data?.data) {
                setIsLoading(false);
                setBrandList(response?.data?.data)
            } else {
                setIsLoading(false);
            }
        })
        .catch((err) => {
            console.log(`we have error at getBrandCategoryList in CatalogueSorting component`,err);
            setIsLoading(false);
        })
    },[selectedBrand])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newItems = [...brandList];
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        setBrandList(newItems);
    }

    const onSubmit = () => {
        let tempArray = [];
        if(brandList && brandList.length > 0) {
            brandList.map((brand) => {
                tempArray.push(brand?.brandcat_id);
            })
        }

        if(tempArray && tempArray.length > 0) {
            setIsLoading(true);
            categoryOrderUpdate(selectedBrand?.brand_id, tempArray.toString())
            .then((response) => {
                if(response?.status === 200 && response?.data?.status){
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        if(onClose) onClose(false)
                    }, 800)
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(`we have error at categoryOrderUpdate`,err);
            })
        }
    }   

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    return(
        <Box className={`catalogue-sorting-popup-wrapper`}>
            {isLoading && <Loader />}
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`popup-wrap`}>
                <div className={`heading-wrap`}>
                    <div className={`heading`}>
                    Sorting by category
                    </div>
                    <div className={`close-icon`} onClick={() => { if(onClose) onClose(false)}}>
                        <CloseIcon />
                    </div>
                </div>
                {
                brandList && brandList.length > 0
                ?<Fragment>
             
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" >  
                        {(provided) => ( 
                            <div 
                                className={`brand-list-wrap scroll-bar-theme`}
                                {...provided.droppableProps}  
                                ref={provided.innerRef}  
                            >
                            {
                                brandList && brandList.map((brand, index) => {
                                    return(
                                        <Draggable 
                                            draggableId={brand?.brandcat_id} 
                                            index={index}
                                            key={brand?.brandcat_id}
                                        >
                                            {(provided) => (
                                                <div 
                                                    className={`brand-list-wrapper`}
                                                    ref={provided.innerRef}  
                                                    {...provided.draggableProps}  
                                                    {...provided.dragHandleProps}      
                                                >
                                                    <div className={`wrap`}>
                                                        <div className='brandName'>
                                                            {brand?.name}
                                                        </div>
                
                                                        <div>
                                                            <DragHandleIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}  
                                        </Draggable>
                                    )
                                })
                            }
                         </div>
                        )}  
                    </Droppable>
                </DragDropContext><div className={`footer`}>
                    <Button 
                        variant="outlined"
                        onClick={() => { if(onClose) onClose(false)}}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="contained"
                        onClick={() => {onSubmit()}}
                        className='btn-primary-after-login'
                    >
                        Submit
                    </Button>
                </div></Fragment>
                : <div className={`no-data-found`}>
                    <div className={`text`}>
                    You don't have any category at the moment.
                    </div>
                    </div>
                 }
                
                
            </div>
        </Box>
    ) 
}

const ProductSortingViaCategory = (props) => {

    const { onClose, selectedBrand } = props;
    const [isLoading, setIsLoading] = useState(false),
        [brandList, setBrandList] = useState([]),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [selectedCategory, setSelectedCategory] = useState(``),
        [products, setProducts] = useState([]);

    useEffect(() => {
        document.body.classList.add('hide-scroll');

        return () => {
            document.body.classList.remove('hide-scroll');
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
        getBrandCategoryList(selectedBrand?.brand_id, ``, `v2`)
        .then((response) => {
            if(response?.status === 200 && response?.data?.status &&response?.data?.data) {
                setIsLoading(false);
                setBrandList(response?.data?.data)
            } else {
                setIsLoading(false);
            }
        })
        .catch((err) => {
            console.log(`we have error at getBrandCategoryList in CatalogueSorting component`,err);
            setIsLoading(false);
        })
    },[selectedBrand])

    useEffect(() => {
        setIsLoading(true);
        getProductListByCategory(selectedCategory)
        .then((response) => {
            if(response?.status === 200 && response?.data?.status &&response?.data?.data) {
                setIsLoading(false);
                setProducts(response?.data?.data)
            } else {
                setIsLoading(false);
            }
        })
        .catch((err) => {
            console.log(`we have error at getBrandCategoryList in CatalogueSorting component`,err);
            setIsLoading(false);
        })
    },[selectedCategory])
    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newItems = [...products];
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        setProducts(newItems);
    }

    const onSubmit = () => {
        let tempArray = [];
        if(products && products.length > 0) {
            products.map((brand) => {
                tempArray.push(brand?.products_id);
            })
        }

        if(tempArray && tempArray.length > 0) {
            updateProductListByCategory(selectedCategory, tempArray.toString())
            .then((response) => {
                if(response?.status === 200 && response?.data?.status){
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        if(onClose) onClose(false)
                    }, 800)
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(`we have error at updateProductListByCategory`,err);
            })
        } else {
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            setAlertMessage(`Please select a category first`);
        }
    }
    return(
         <Box className={`catalogue-product-sorting-popup-wrapper`}>
            {isLoading && <Loader />}
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`popup-wrap ${products && products.length > 0 ? "harshad" : "when-no-content"}`}>
                <div className={`heading-wrap`}>
                    <div className={`heading`}>
                        Sorting by Product
                    </div>
                    <div className={`close-icon`} onClick={() => { if(onClose) onClose(false)}}>
                        <CloseIcon />
                    </div>
                </div>

                <div className='sort-product-controls-wrap position-relative mt-25'>
                    <div className='sort-product-controls form-input-control selection-d-arrow'>
                            <select
                                value={selectedCategory}
                                onChange={handleChange}
                                className=''
                            >
                                <option value="">Select Category </option>
                                {
                                    brandList && brandList.length > 0 &&
                                    brandList.map((brand, index) => {
                                        return (
                                             <option key={index} value={brand?.brandcat_id}>{brand?.name}</option>
                                            // <option value="volvo">Volvo</option>
                                        )
                                    })
                                }
                            </select>
                    </div>
                </div>

                <div 
                    className={`product-list-wrap scroll-bar-theme`}
                    >
                    {
                        products && products.length > 0
                        ?
                        <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" >  
                            {(provided) => ( 
                                <div 
                                    className={`brand-list-wrap scroll-bar-theme`}
                                    {...provided.droppableProps}  
                                    ref={provided.innerRef}  
                                >
                                    {
                                         products.map((product, index) => {
                                            return (
                                                <Draggable 
                                                        draggableId={product?.products_id} 
                                                        index={index}
                                                        key={product?.products_id}
                                                    >
                                                        {(provided) => (
                                                            <div 
                                                                className={`brand-list-wrapper`}
                                                                ref={provided.innerRef}  
                                                                {...provided.draggableProps}  
                                                                {...provided.dragHandleProps}      
                                                            >
                                                                <div className={`wrap`}>
                                                                    <div className='brandName'>
                                                                        {product?.name}
                                                                    </div>
                                                                    <div className='brandskuid'>
                                                                        {product?.sku_id}
                                                                    </div>
                            
                                                                    <div>
                                                                        <DragHandleIcon />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}  
                                                    </Draggable>
                                                   
                                            )
                                        }) 
                                    }
                                    </div>
                                     )}
                            </Droppable>
                            </DragDropContext>

                           
                        :
                            <div className={`no-product-found`}>
                                Select a category to see products
                            </div>
                    }
                </div>
                <div className={`footer`}>
                    <Button 
                        variant="outlined"
                        onClick={() => { if(onClose) onClose(false)}}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="contained"
                        onClick={() => {onSubmit()}}
                        className='btn-primary-after-login'
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </Box>
    )
}
const CataloguesFilterSection = (props) => {

    const { selectedBrand, brandResponse, onFilterOptionChange, isCatalougeListUpdated, onShowPreviewClick, isViewPermission, brandCategoryList } = props;
    const [catalogueList, setCatalogueList] = useState([]),
        [isLoading, setIsLoading] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [searchValue, setSearchValue] = useState(``),
        [totalCount, setTotalCount] = useState(0),
        [perPage, setPerPage] = useState(0),
        [isPaginatioShow, setIsPaginatioShow] = useState(false),
        [currentPage, setCurrentPage] = useState(1),
        [isConfirmationPopupShow, setIsConfirmationPopupShow] = useState(false),
        [moreOptionSelectedData, setMoreOptionSelectedData] = useState({}),
        [conformationPopupSuccessBtnText, setConformationPopupSuccessBtnText] = useState(``),
        [conformationPopupCancelBtnText, setConformationPopupCancelBtnText] = useState(``),
        [conformationPopupText, setConformationPopText] = useState(``),
        [conformationPopupType, setConformationType] = useState(``),
        [isSharePopupVisible, setIsSharePopupVisible] = useState(false),
        [shareCatalogueData, setShareCatalogueData] = useState({}),
        [reNameshow, setreNameshow] = useState(false),
        [isCatalogueSortingShow, setCatalogueSortingShow] = useState(false),
        [isProductCategorySortingShow, setProductCategorySortingShow] = useState(false);

    const navigate = useNavigate();
    const returnMoreOptionValues = (catalogue) => {

        let options = [];
        const obj1 = { "label": "Share", "value": "Share" };
        options.push(obj1);
        if (!catalogue?.is_default) {
            const obj2 = { "label": "Edit Products", "value": "Edit Products" };
            options.push(obj2);
            const obj3 = { "label": "Delete", "value": "Delete" };
            options.push(obj3);
        }
        const obj4 = { "label": "Rename", "value": "Rename" };
        options.push(obj4);

        const downloadQROption = { "label": "Download QR", "value": "Download QR" };
        options.push(downloadQROption);

        const obj5 = { "label": "Make A Copy", "value": "Make A Copy" };
        options.push(obj5);
        if (catalogue?.is_public) {
            const obj6 = { "label": "Make Private", "value": "Private" };
            options.push(obj6);
        }
        else {
            const obj6 = { "label": "Make Public", "value": "Public" };
            options.push(obj6);
        }

        if(catalogue?.is_default) {
            const sortObj =  { "label": "Sorting by category", "value": "Sorting" };
            options.push(sortObj);

            const productSortObj = { "label": "Sorting by Product", "value": "Product Sorting" };
            options.push(productSortObj)
        }
        return options

    }
    useEffect(() => {
        getCatalogueListData();
    }, [selectedBrand])

    useEffect(() => {
        if (brandResponse === 'success') {
            getCatalogueListData();
        }
    }, [brandResponse])

    useEffect(() => {
        getCatalogueListData();
    }, [currentPage]);

    useEffect(() => {
        getCatalogueListData();
    }, [isCatalougeListUpdated])

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    }

    const getCatalogueListData = () => {
        setIsLoading(true);
        getCatalogueList(selectedBrand?.brand_id, ``, currentPage)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setCatalogueList(response?.data?.data);
                    setTotalCount(response?.data?.count);
                    setPerPage(response?.data?.perpage);
                    setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                    setCurrentPage(1);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at catalogue List", error);
            });
    }

    const onCatalogueSearchClick = (event) => {
        if (event.key === "Enter" || event.type === "click" || event.type === "change") {

            setIsLoading(true);
            let searchval = (event.type === "keypress") ? event.target.value : (event.type === "click") ? searchValue : ``
            getCatalogueList(selectedBrand?.brand_id, searchval, ``)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                        setCatalogueList(response.data.data);
                        setTotalCount(response?.data?.count);
                        setPerPage(response?.data?.perpage);
                        setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                        setCurrentPage(1);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("we have error at catalogue List", error);
                });
        }
    }

    const onSearchRemove = () => {
        document.getElementById(`search`).value = '';
        setSearchValue(false);
        setIsLoading(true);
        getCatalogueList(selectedBrand?.brand_id, ``, ``)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setCatalogueList(response.data.data);
                    setTotalCount(response?.data?.count);
                    setPerPage(response?.data?.perpage);
                    setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                    setIsLoading(false);

                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at catalogue List", error);
            });
    }

    const onMoreOptionValueChange = (option, data) => {
        switch (option[`value`]) {
            case `Share`:
                onShareClick(data)
                break;
            case `Rename`:
                reNameClick(data)
                break;
            case `Edit Products`:
                if (onFilterOptionChange) {
                    onFilterOptionChange(option, data);
                }
                break;
            case `Make A Copy`:
                catalogueAction(data?.catalogue_web_id, 'COPY')
                    .then((response) => {
                        if (response && response.status === 200 && response.data && response.data.status) {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("success");
                            setAlertMessage(response.data.message);
                            setTimeout(() => {
                                getCatalogueListData();
                            }, 500);
                        } else {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("error");
                            if (response?.message === `Network Error`) {
                                setAlertMessage(`No internet connection`);
                            } else {
                                setAlertMessage(response?.data?.message)
                            }
                        }
                    })
                    .catch((err) => {
                        console.log("we have error on Product Action", err);
                    })
                break;
            case `Private`:
                catalogueAction(data?.catalogue_web_id, 'PRIVATE')
                    .then((response) => {
                        if (response && response.status === 200 && response.data && response.data.status) {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("success");
                            setAlertMessage(response.data.message);
                            setTimeout(() => {
                                getCatalogueListData();
                            }, 500);
                        } else {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("error");
                            if (response?.message === `Network Error`) {
                                setAlertMessage(`No internet connection`);
                            } else {
                                setAlertMessage(response?.data?.message)
                            }
                        }
                    })
                    .catch((err) => {
                        console.log("we have error on Product Action", err);
                    })
                break;
            case `Public`:
                catalogueAction(data?.catalogue_web_id, 'PUBLIC')
                    .then((response) => {
                        if (response && response.status === 200 && response.data && response.data.status) {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("success");
                            setAlertMessage(response.data.message);
                            setTimeout(() => {
                                getCatalogueListData();
                            }, 500);
                        } else {
                            setIsLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType("error");
                            if (response?.message === `Network Error`) {
                                setAlertMessage(`No internet connection`);
                            } else {
                                setAlertMessage(response?.data?.message)
                            }
                        }
                    })
                    .catch((err) => {
                        console.log("we have error on Product Action", err);
                    })
                break;
            case `Delete`:
                // Delete a Catalogue
                setMoreOptionSelectedData(data);
                setIsConfirmationPopupShow(true);
                setConformationPopText(`Are you sure you want to delete this catalogue?`);
                setConformationPopupSuccessBtnText(`Yes`);
                setConformationPopupCancelBtnText(`No`);
                setConformationType(`Delete Catalogue`);
                break;

            case `Download QR`:
                downloadFile(data[`qrcode`], data[`name`])
                break;
            case `Sorting`:
                setCatalogueSortingShow(true);
                break;
            case 'Product Sorting':
                setProductCategorySortingShow(true);
                break;
        }
    }
    const onConformationPopupClose = (value) => {
        setIsConfirmationPopupShow(value);
    }

    const onConformationPopupSuccess = () => {
        setIsConfirmationPopupShow(false);

        switch (conformationPopupType) {
            case `Delete Catalogue`:
                deletecatalogue();
                break;
        }
    }
    const deletecatalogue = () => {
        catalogueDelete(moreOptionSelectedData?.catalogue_web_id)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        getCatalogueListData();
                    }, 500);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((err) => {
                console.log("we have error on Product Action", err);
            })
    }

    const onShareClick = (catalogue) => {
        setShareCatalogueData(catalogue);
        setIsSharePopupVisible(true);
    }

    const reNameClick = (catalogue) => {
        setShareCatalogueData(catalogue);
        setreNameshow(true);
    }


    const onSharePopupClose = (value, response) => {
        setIsSharePopupVisible(value);
        if (response === `success`) {
            getCatalogueListData();
        }
    }

    const reNameclosepopup = (value) => {
        setreNameshow(value);
    }
    const renamePopupSuccess = (value) => {
        getCatalogueListData();
    }

    const onCopyClick = (data) => {
        copyToClipboard(data?.website_url).then((response) => {
            setAlternateMessageShow(true);
            setAlertMessageType("success");
            setAlertMessage("Share link has been copied successfully");
        }).catch((error) => {
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            setAlertMessage("Failed to copy");
        })
    }

    return (
        <Fragment>
            {
                isConfirmationPopupShow &&
                <ConformationPopup
                    heading={conformationPopupText}
                    cancelBtnText={conformationPopupCancelBtnText}
                    submitBtnText={conformationPopupSuccessBtnText}
                    onCancel={onConformationPopupClose}
                    onSubmit={onConformationPopupSuccess}
                />
            }
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }

            {
                isSharePopupVisible &&
                <ProductShare
                    onClose={onSharePopupClose}
                    catalogueData={shareCatalogueData}
                />
            }
            {
                reNameshow &&
                <Rename
                    renamepopupclose={reNameclosepopup}
                    popupData={shareCatalogueData}
                    renameSuccess={renamePopupSuccess}
                    headingLineText={'Edit Catalogue Name'}
                    SubmitbuttonText={'Update'}
                />
            }

            {
                isCatalogueSortingShow &&
                <CatalogueSorting 
                    onClose={(value) => setCatalogueSortingShow(value)}
                    selectedBrand={selectedBrand}
                />
            }

            {
                isProductCategorySortingShow &&
                <ProductSortingViaCategory 
                    onClose={(value) => setProductCategorySortingShow(value)}
                    selectedBrand={selectedBrand}
                />
            }
            <Box className={`catalogue-filter-section`}>
                <Box className={`filter-section`}>
                    <div className={`search-bar`}>
                        {/* <span className={`search-icon`}>
                            <SearchIcon />
                        </span> */}
                        <TextField
                            id="search"
                            name="search"
                            placeholder={`Search Catalogue`}
                            margin="dense"
                            spellCheck={false}
                            className={`search-input`}
                            inputProps={{
                                onKeyPress: (e) => {
                                    onCatalogueSearchClick(e);
                                },
                                onChange: (e) => {

                                    setSearchValue(e.target.value)
                                    let targetvalue = e.target.value
                                    if (targetvalue === "") onCatalogueSearchClick(e);
                                }
                            }}
                        />
                        {
                            searchValue &&
                            <span className={`close-icon`} onClick={() => onSearchRemove()}>
                                <CloseIcon />
                            </span>
                        }
                        <button className={`search-icon-wrap`} onClick={(e) => { onCatalogueSearchClick(e) }}>
                            <SearchIcon />
                        </button>
                    </div>

                    {
                        !isViewPermission &&
                        <div className={`product-add-btn`}>
                            <Stack spacing={2} direction="row">
                                <Button className={`btn-primary-after-login only-desktop`} variant="contained" onClick={() => { if (props.onAddNewCatalogueClick) props.onAddNewCatalogueClick(true) }}>Personalise Catalogue</Button>
                                <Button className={`btn-primary-after-login only-mobile`} variant="contained" onClick={() => { if (props.onAddNewCatalogueClick) props.onAddNewCatalogueClick(true) }}>+</Button>
                            </Stack>
                        </div>
                    }

                </Box>

                <Box className={`product-table table-scroll-wrap catalogue-on-hover-show-detail catalogue-table`}>
                    {isLoading && <Loader />}
                    {
                        catalogueList && catalogueList.length > 0
                            ?
                            <table>
                                <thead>
                                    <tr>
                                        <th className={`catalogue`}>Name</th>
                                        <th className={`catalogue-draft`}></th>
                                        <th className={`contacts`}>Contacts</th>
                                        <th className={`products`}>Products</th>
                                        <th className={`created-date`}>Created Date</th>
                                        <th className={`last-date`}>Last Updated</th>
                                        <th className={`share`}></th>
                                        <th width="5%" className={`more-option`}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        catalogueList && catalogueList.length > 0 &&
                                        catalogueList.map((catalogue, index) => {
                                            let tempClassName = index % 2 == 0 ? 'even' : 'odd';
                                            return (
                                                <Fragment key={index} >
                                                    <tr className={`product-list-main ${tempClassName}`}>
                                                        <td>
                                                            <span className={`catalogue-name brand-verified-wrap`}>
                                                                {catalogue?.is_default ?
                                                                    <span className={`master-cat-alisis flex`}>
                                                                        <MasterCatalogueIcon />
                                                                    </span>
                                                                    :
                                                                    <span className={`name-alisis`}>{catalogue?.name.slice(0, 2)}</span>
                                                                }
                                                                <span className={`brand-verified-icon`}>{catalogue?.name}</span>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span onClick={() => { navigate(`/catalogue/detail`, { state: { catalog_id: catalogue?.catalogue_web_id } }) }} className={`button-status detail`}>Details</span>
                                                        </td>
                                                        <td>
                                                            <span className={`contacts`}>
                                                                {catalogue?.contact}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className={`product-count`}>
                                                                {catalogue?.products}
                                                            </span>
                                                        </td>
                                                        <td className={`c-date`}>
                                                            {catalogue?.created_at}
                                                        </td>
                                                        <td className={`c-date`}>
                                                            {catalogue?.updated_at}
                                                        </td>

                                                        <td className={`icon-copy position-relative`}>

                                                        </td>
                                                        <td className={`options`}>

                                                            <div>
                                                                {catalogue?.is_public ?
                                                                    <></>
                                                                    :
                                                                    <span className={`cat-lock-icon`}>
                                                                        <CatalogueLockIcon />
                                                                    </span>
                                                                }
                                                                <div className='pre-cat' tooltip="Preview Catalogue" onClick={() => { if (onShowPreviewClick) onShowPreviewClick(true, catalogue); }} >
                                                                    <CatPrevIcon />
                                                                </div>
                                                                <div className={`icons-shadow`} onClick={() => { onCopyClick(catalogue) }} tooltip="Copy Link">
                                                                    <ShareProductCopyIcon />
                                                                </div>
                                                                <span className={`icons-shadow share`} onClick={() => { onShareClick(catalogue) }} tooltip="Share Catalogue">
                                                                    <ShareIcon />
                                                                </span>

                                                                {
                                                                    !isViewPermission &&
                                                                    <div className={`more-options-wrapper`}>
                                                                        <Tooltip className={`product-list-option`} moreOptions={returnMoreOptionValues(catalogue)} onOptionChange={onMoreOptionValueChange} data={catalogue} id={catalogue?.catalogue_web_id} />
                                                                    </div>
                                                                }

                                                            </div>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            :
                            <div className={`no-data-found`}>
                                <div className={`icon-wrap`}>
                                    <ProductIcon />
                                </div>
                                <div className={`text`}>
                                    You have not added any brands/products yet
                                </div>
                            </div>
                    }
                </Box>
                {
                    isPaginatioShow &&
                    <Box className={`pagination`}>
                        <PaginationComponent pages={Math.ceil(totalCount / perPage)} onPageChange={onPageChange} />
                    </Box>
                }
            </Box>
        </Fragment>
    )
}

const CreateCatalogue = (props) => {
    const { selectedBrand, onAddNewProductCloseClick, catalogueSuccess } = props;
    const [isAlertMessageShow, setAlertMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isButtonDisabled, setButtonDisabled] = useState(false),
        [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(isButtonDisabled) {
            if(document.getElementById(`personalise-catalogue`)) {
                document.getElementById(`personalise-catalogue`).classList.add(`disabled`);
                document.getElementById("personalise-catalogue").disabled = true;
            }
        } else {
            if(document.getElementById(`personalise-catalogue`)) {
                document.getElementById(`personalise-catalogue`).classList.remove(`disabled`);
                document.getElementById("personalise-catalogue").disabled = false;
            }
        }
    },[isButtonDisabled])
    const schema = yup
        .object({
            catalogueName: yup
                .string()
                .trim()
                .required("Catalogue Name is required")
                .max(60, 'Maximum allowed characters is 60'),
        })
        .required();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const onAlertClose = (value) => {
        setAlertMessageShow(value);
    };

    const onSubmit = (data) => {
        setButtonDisabled(true);
        setIsLoading(true);
        addCatalogue(selectedBrand?.brand_id, data[`catalogueName`])
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setAlertMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response?.data?.message);
                    setTimeout(() => {
                        if (onAddNewProductCloseClick) {
                            onAddNewProductCloseClick(false);
                            if (catalogueSuccess) {
                                catalogueSuccess(response.data.data, `success`);
                                setButtonDisabled(false);
                                setIsLoading(false);
                            }
                        }
                    }, 1000);
                } else {
                    setAlertMessageShow(true);
                    setAlertMessageType("error");
                    setButtonDisabled(false);
                    setIsLoading(false);
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((error) => {
                console.log("we have error at addCatalogue", error);
            })

    }
    return (
        <Fragment>

            {isLoading && <Loader />}

            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }

            <Box className={`add-catalogue-popup-wrapper`}>
                <div className={`add-catalogue-popup`}>
                    <div className={`close-icon`} onClick={() => { if (onAddNewProductCloseClick) onAddNewProductCloseClick(false) }}>
                        <CloseIcon />
                    </div>
                    <div className={`first-heading`}>
                        {'Add new catalogue'}
                    </div>

                    <div className={`second-heading`}>
                        {'Start by adding a name for your catalogue'}
                    </div>
                    <form className={`form-input-control`} autoComplete='off' onSubmit={handleSubmit(onSubmit)}>

                        <Box className={`form-control margin-bottom-28 position-relative`}>
                            <TextField
                                type={"text"}
                                id="addCatalogue"
                                name="addCatalogue"
                                className={`input-wrap add-brand-input`}
                                placeholder={`e.g. F&B Buyers`}
                                margin="dense"
                                {...register("catalogueName")}
                                error={errors.catalogueName ? true : false}
                            />
                            {
                                errors.catalogueName?.message &&
                                <p className={`field-err-msg add-new-pro-custom-style`}>{errors.catalogueName?.message}</p>
                            }
                        </Box>

                        <Box className={`form-control margin-bottom-28 button-wrap`}>
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                id="personalise-catalogue"
                                className={`btn-primary-after-login btn-modal ${isValid ? `` : `disabled`}`}
                                disabled={isValid ? false : true}
                            >
                                {'Personalise Catalogue'}
                            </Button>
                        </Box>
                    </form>
                </div>
            </Box>
        </Fragment>
    )
}

const EditCatalogue = (props) => {

    const { isEditCatalogueShow, selectedBrand, EditCatalogueInfo, onMoreOptionSelectedData, selectedCatalogue, selectedCatalogueProductsId, onClose, hasMoreProduct, isAllProductSelected, productCreated, brandCategoryList, catalogueBrandList } = props;

    const [EditCatalogueData, setEditCatalogueData] = useState(EditCatalogueInfo || []),
        [pricingChecked, setPricingChecked] = useState(selectedCatalogue?.is_price),
        [selectedProduct, setSelectedProduct] = useState([]),
        [name, setName] = useState(selectedCatalogue?.name),
        [isLoading, setIsLoading] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [hasError, setHasError] = useState(false),
        [errorMessage, setErrorMessage] = useState(``),
        [searchValue, setSearchValue] = useState(``),
        [page, setPage] = useState(1),
        [hasNext, setHasNext] = useState(hasMoreProduct ? true : false),
        [isMoreProductLoading, setIsMoreProductLoading] = useState(false),
        [selectedIds, setSelectedIds] = useState(selectedCatalogueProductsId),
        [removedProduct, setRemovedProduct] = useState([]),
        [isSelectAllSelected, setSelectAllSelected] = useState(isAllProductSelected ? true : false),
        [isProductCreated, setProductCreated] = useState(productCreated ? true : false),
        [isFilterApplied, setFilterApplied] = useState(false),
        [filterSelectedProduct, setFiterSelectedProduct] = useState([]),
        [isCreateCatalogueButtonEnable, setCreateCatalogueButtonEnable] = useState(true),
        [brandCategory, setBrandCategory] = useState(brandCategoryList || []),
        [brandList, setBrandList] = useState(catalogueBrandList || []),
        [selectedBrandCategory, setSelectedBrandCategory] = useState([]),
        [selectedBrandList, setSelectedBrandList] = useState([]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    useEffect(() => {
        if(searchValue && searchValue.length > 0) {
            setFilterApplied(true);
        } else {
            setFilterApplied(false);
        }
    },[searchValue])

    useEffect(() => {
        if(selectedBrandCategory && selectedBrandCategory.length > 0) {
            setFilterApplied(true);
        } else {
            setFilterApplied(false);
        }
    },[selectedBrandCategory])

    useEffect(() => {
        if(selectedBrandList && selectedBrandList.length > 0) {
            setFilterApplied(true);
        } else {
            setFilterApplied(false);
        }
    },[selectedBrandList])
    
    const onPriceSwitchChange = (event) => {
        setPricingChecked(event.target.checked);
    };

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };
    useEffect(() => {

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        setEditCatalogueData(EditCatalogueInfo || []);
        // let allCheckbox = document.querySelectorAll('input[type=checkbox]');

        // for (let i = 0; i < allCheckbox.length; i++) {
        //     allCheckbox[i].checked = false;
        // }
        if (isEditCatalogueShow) {
            document.body.classList.add('hide-scroll');
        } else {
            document.body.classList.remove('hide-scroll');
        }

        setHasError(false);
        setErrorMessage(``);

        setPage(1);
        setHasNext(props?.hasMoreProduct ? true : false);
        setSelectedIds(selectedCatalogueProductsId);
        setSelectAllSelected(isAllProductSelected ? true : false);
        setProductCreated(productCreated ? true : false);
        setBrandCategory(brandCategoryList || []);
        setBrandList(catalogueBrandList || []);

        setSelectedBrandList([]);
        setSelectedBrandCategory([]);
        setSelectedProduct([]);
        setRemovedProduct([]);
        return () => {
            setSelectedProduct([]);
            setSelectedIds([]);
            document.body.classList.remove('hide-scroll');
            setSearchValue(``);
            if (document.getElementById("search-catalogue")) document.getElementById("search-catalogue").value = ``;
        }

    }, [isEditCatalogueShow]);

    useEffect(() => {
        setName(selectedCatalogue?.name);
        setPricingChecked(selectedCatalogue?.is_price);
        setSelectAllSelected(false);
    }, [selectedCatalogue]);

    useEffect(() => {

        if (EditCatalogueData && EditCatalogueData.length > 0) {
            EditCatalogueData.map((product) => {
                if (document.getElementById(`product-${product?.products_id}`)) {
                    document.getElementById(`product-${product?.products_id}`).checked = false;
                }
            })
        }

        if (selectedIds && selectedIds.length > 0) {
            selectedIds.map((data) => {

                if (EditCatalogueData && EditCatalogueData.length > 0) {
                    EditCatalogueData.map((product) => {
                        if (data?.product === product?.products_id) {
                            if (document.getElementById(`product-${product?.products_id}`)) {
                                document.getElementById(`product-${product?.products_id}`).click();
                            }
                        }
                    })
                }
            })
        }
    }, [selectedIds, EditCatalogueData])

    useEffect(() => {
        if (selectedProduct.length === EditCatalogueData.length) {
            setSelectAllSelected(true);
        }

        if (selectedProduct && selectedProduct.length > 0) {
            setCreateCatalogueButtonEnable(false);
        }

        if (selectedProduct && selectedProduct.length === 0) {
            setCreateCatalogueButtonEnable(true);
        }
    }, [selectedProduct])

    useEffect(() => {
        if (isFilterApplied && filterSelectedProduct && filterSelectedProduct.length > 0) {
            setCreateCatalogueButtonEnable(false);
        }

        if (isFilterApplied && filterSelectedProduct && filterSelectedProduct.length === 0) {
            setCreateCatalogueButtonEnable(true);
        }
    }, [filterSelectedProduct, isFilterApplied])

    useEffect(() => {

        let tempCategoryArray = [];
        let tempBrandArray = [];

        if (brandCategory && brandCategory.length > 0) {
            brandCategory.map((brand) => {
                if (selectedBrandCategory && selectedBrandCategory.length > 0) {
                    selectedBrandCategory.map((selectedBrand) => {
                        if (brand[`name`] === selectedBrand) {
                            tempCategoryArray.push(brand[`brandcat_id`]);
                        }
                    })
                }
            })
        }

        if (brandList && brandList.length > 0) {
            brandList.map((brand) => {
                if (selectedBrandList && selectedBrandList.length > 0) {
                    selectedBrandList.map((selectedBrand) => {
                        if (brand[`name`] === selectedBrand) {
                            tempBrandArray.push(brand[`product_brand_id`]);
                        }
                    })
                }
            })
        }

        getCatalogueList(tempBrandArray, tempCategoryArray)

    }, [selectedBrandCategory, selectedBrandList])

    // useEffect(() => {

    //     if (isSelectAllSelected) {
    //         onSelectedAllClick(true);
    //     } else {
    //         onSelectedAllClick(false);
    //     }

    //     EditCatalogueData && EditCatalogueData.length > 0 && EditCatalogueData.map((catalogue) => {
    //         catalogue[`product_bc`].map((item) => {
    //             selectedIds.map((productIds) => {
    //                 if (item?.products_id === productIds?.product) {

    //                     if (!document.getElementById(`product-${item?.products_id}`).checked) {
    //                         document.getElementById(`product-${item?.products_id}`).click();
    //                     }
    //                     removedProduct && removedProduct.length > 0 &&
    //                         removedProduct.map((removeProduct) => {
    //                             if (productIds?.product === removeProduct?.products_id) {
    //                                 if (document.getElementById(`product-${item?.products_id}`).checked) {
    //                                     document.getElementById(`product-${item?.products_id}`).click();
    //                                 }
    //                             }

    //                         })
    //                 }
    //             })
    //         })
    //     })

    //     isAllProductChecked();

    // }, [EditCatalogueData]);

    const onProductCheckboxClick = (product, isChecked, catalogue) => {
        let products = document.getElementsByName(`${catalogue[`brandcat_id`]}`);
        let isAllChecked = false;
        if (isChecked) {
            setSelectedProduct((products) => [...products, product]);
        } else {
            // setSelectedProduct(selectedProduct.filter(item => item.products_id !== product.products_id))
            setRemovedProduct((products) => [...products, product])
            setSelectedProduct(prevProducts => {
                return prevProducts.filter(item => item.products_id !== product.products_id);
            });

        }

        for (var i = 0; i < products.length; i++) {
            if (products[i].checked === true) {
                isAllChecked = true
            } else {
                isAllChecked = false
                break;
            }
        }

        if (isAllChecked) {
            document.getElementById(`parent-${catalogue?.brandcat_id}`).checked = true;
        } else {
            if (document.getElementById(`parent-${catalogue?.brandcat_id}`).checked) {
                document.getElementById(`parent-${catalogue?.brandcat_id}`).checked = false;
            }
        }

        isAllProductChecked();
    }

    const onCatalogueCreate = () => {
        // setIsLoading(true);
        let tempArray = [];
        let productRemoveTempArray = [];

        if (filterSelectedProduct && filterSelectedProduct.length > 0) {
            filterSelectedProduct.map((product) => {
                tempArray.push(product);
            })
        } else {
            if (selectedProduct && selectedProduct.length > 0) {
                selectedProduct.map((product) => {
                    tempArray.push(product);
                })
            }
        }

        if (tempArray && tempArray.length > 0) tempArray = [...new Set(tempArray)];

        if (removedProduct && removedProduct.length > 0) {
            removedProduct.map((product) => {
                productRemoveTempArray.push(product);
            })
        }

        catalogueUpdate(selectedCatalogue?.catalogue_web_id, name, tempArray.join(), pricingChecked, isSelectAllSelected ? 'True' : 'False', isFilterApplied, productRemoveTempArray.join())
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    onClose(false, "success");
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at catalogueUpdate", error)
            })
    }

    const onParentCategoryClick = (catalogue, isChecked) => {
        let products = document.getElementsByName(`${catalogue[`brandcat_id`]}`);

        if (isChecked) {

            for (var i = 0; i < products.length; i++) {
                products[i].checked = false;

                EditCatalogueData.map((catalogueData) => {
                    if (catalogueData?.[`brandcat_id`] === catalogue[`brandcat_id`]) {
                        catalogueData?.product_bc?.map((productData) => {
                            setSelectedProduct(prevProducts => {
                                return prevProducts.filter(item => item.products_id !== productData.products_id);
                            });
                        })
                    }
                })
            }
        }
        for (var i = 0; i < products.length; i++) {
            products[i].click();
            products[i].checked = isChecked ? true : false;
        }

        isAllProductChecked();
    }

    const onCatalogueNameChange = (event) => {
        if (!event.target.value) {
            setName(``);
            setHasError(true);
            setErrorMessage(`Catalogue name is required`);
            return;
        }

        if (event.target.value.length < 3) {
            setName(event.target.value);
            setHasError(true);
            setErrorMessage(`Too short - should be at least 3 characters`);
            return;
        }

        if (event.target.value.length > 60) {
            setName(event.target.value);
            setHasError(true);
            setErrorMessage(`Maximum allowed characters is 60`);
            return;
        }

        setHasError(false);
        setErrorMessage(``);
        setName(event.target.value);
    }


    const onCatalogueSearch = debounce(() => getCatalogueList(), 500);

    const getCatalogueList = (brandList = [], categoryList = []) => {

        if (brandList && brandList.length > 0) {
            if (brandCategory && brandCategory.length > 0) {
                brandCategory.map((brand) => {
                    if (selectedBrandCategory && selectedBrandCategory.length > 0) {
                        selectedBrandCategory.map((selectedBrand) => {
                            if (brand[`name`] === selectedBrand) {
                                brandList.push(brand[`brandcat_id`]);
                            }
                        })
                    }
                })
            }
        }

        if (categoryList && categoryList.length > 0) {
            if (brandList && brandList.length > 0) {
                brandList.map((brand) => {
                    if (selectedBrandList && selectedBrandList.length > 0) {
                        selectedBrandList.map((selectedBrand) => {
                            if (brand[`name`] === selectedBrand) {
                                categoryList.push(brand[`product_brand_id`]);
                            }
                        })
                    }
                })
            }
        }

        let searchValue = document.getElementById("search-catalogue").value;

        const moduleApis = [];
        const moduleApiIndex = [];

        moduleApis.push(getCatalogueProductList(selectedBrand?.brand_id, searchValue || ``, 1, 2, brandList, categoryList));
        moduleApiIndex.push(`cataloguesProductListIndex`);

        moduleApis.push(getCatalogueCustomWebsiteProductsIds(selectedCatalogue?.catalogue_web_id));
        moduleApiIndex.push(`cataloguesProductIdsIndex`);

        setIsLoading(true);

        Promise.all(moduleApis)
            .then((result) => {

                let productListIndex = moduleApiIndex.indexOf('cataloguesProductListIndex');
                if (productListIndex !== -1 && result.length) {
                    const dataResult = result[productListIndex] ? result[productListIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setSelectedProduct([]);
                        setSelectedIds([]);
                        setEditCatalogueData(dataResult.data.data);
                        setHasNext(dataResult?.data?.next ? true : false);
                        // setFilterApplied(false);
                        setPage(1);
                    }

                }

                let productListIdsIndex = moduleApiIndex.indexOf('cataloguesProductIdsIndex');
                if (productListIdsIndex !== -1 && result.length) {
                    const dataResult = result[productListIdsIndex] ? result[productListIdsIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                        setSelectedIds(dataResult.data.data.products_list);
                        setSelectAllSelected(dataResult.data.data.catalogue_detail.is_selected_all ? true : false)
                        setProductCreated(dataResult.data.data.catalogue_detail.is_product_created ? true : false);
                    }
                }
                setIsLoading(false);
            })
    }

    const onSearchRemove = () => {
        setSearchValue(``);
        document.getElementById("search-catalogue").value = ``;

        const moduleApis = [];
        const moduleApiIndex = [];

        const tempBrandList = [];
        const tempCategoryList = [];

        if (brandCategory && brandCategory.length > 0) {
            brandCategory.map((brand) => {
                if (selectedBrandCategory && selectedBrandCategory.length > 0) {
                    selectedBrandCategory.map((selectedBrand) => {
                        if (brand[`name`] === selectedBrand) {
                            tempBrandList.push(brand[`brandcat_id`]);
                        }
                    })
                }
            })
        }

        if (brandList && brandList.length > 0) {
            brandList.map((brand) => {
                if (selectedBrandList && selectedBrandList.length > 0) {
                    selectedBrandList.map((selectedBrand) => {
                        if (brand[`name`] === selectedBrand) {
                            tempCategoryList.push(brand[`product_brand_id`]);
                        }
                    })
                }
            })
        }

        moduleApis.push(getCatalogueProductList(selectedBrand?.brand_id, ``, 1, 2, tempBrandList, tempCategoryList));
        moduleApiIndex.push(`cataloguesProductListIndex`);

        moduleApis.push(getCatalogueCustomWebsiteProductsIds(selectedCatalogue?.catalogue_web_id));
        moduleApiIndex.push(`cataloguesProductIdsIndex`);

        setIsLoading(true);
        Promise.all(moduleApis)
            .then((result) => {

                let productListIndex = moduleApiIndex.indexOf('cataloguesProductListIndex');
                if (productListIndex !== -1 && result.length) {
                    const dataResult = result[productListIndex] ? result[productListIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setSelectedProduct([]);
                        setSelectedIds([]);
                        setEditCatalogueData(dataResult.data.data);
                        setHasNext(dataResult?.data?.next ? true : false);
                        // setFilterApplied(false);
                        setPage(1);
                    }

                }

                let productListIdsIndex = moduleApiIndex.indexOf('cataloguesProductIdsIndex');
                if (productListIdsIndex !== -1 && result.length) {
                    const dataResult = result[productListIdsIndex] ? result[productListIdsIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                        setSelectedIds(dataResult.data.data.products_list);
                        setSelectAllSelected(dataResult.data.data.catalogue_detail.is_selected_all ? true : false)
                        setProductCreated(dataResult.data.data.catalogue_detail.is_product_created ? true : false);
                    }
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at getCatalogueProductListData", error);
            })
    }

    const onPageScroll = (page) => {

        if (hasNext) {
            const moduleApis = [];
            const moduleApiIndex = [];

            moduleApis.push(getCatalogueProductList(selectedBrand?.brand_id, ``, page, 2));
            moduleApiIndex.push(`cataloguesProductListIndex`);

            moduleApis.push(getCatalogueCustomWebsiteProductsIds(selectedCatalogue?.catalogue_web_id));
            moduleApiIndex.push(`cataloguesProductIdsIndex`);


            Promise.all(moduleApis)
                .then((result) => {

                    let productListIndex = moduleApiIndex.indexOf('cataloguesProductListIndex');
                    if (productListIndex !== -1 && result.length) {
                        const dataResult = result[productListIndex] ? result[productListIndex] : {};
                        if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                            setHasNext(dataResult?.data?.next ? true : false);
                            setEditCatalogueData([...EditCatalogueData, ...dataResult.data.data]);
                        }
                    }

                    let productListIdsIndex = moduleApiIndex.indexOf('cataloguesProductIdsIndex');
                    if (productListIdsIndex !== -1 && result.length) {
                        const dataResult = result[productListIdsIndex] ? result[productListIdsIndex] : {};
                        if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                            setSelectedIds(dataResult.data.data);
                        }
                    }
                })
                .catch((error) => {
                    console.log("we have error at getCatalogueProductListData", error);
                })
        }
    }

    const onSelectedAllClick = (isChecked) => {

        if (isChecked) {
            setSelectAllSelected(true);

            if (document.getElementsByClassName(`brand-category`)) {
                let brandCategoryArray = document.getElementsByClassName(`brand-category`);

                if (brandCategoryArray && brandCategoryArray.length > 0) {
                    for (let i = 0; i < brandCategoryArray.length; i++) {
                        if (brandCategoryArray[i].getElementsByTagName(`input`)[0]) {
                            if (!brandCategoryArray[i].getElementsByTagName(`input`)[0].checked) {
                                brandCategoryArray[i].getElementsByTagName(`input`)[0].click();
                            }
                        }
                    }
                }
            }
        } else {
            if (document.getElementsByClassName(`brand-category`)) {
                let brandCategoryArray = document.getElementsByClassName(`brand-category`);
                if (brandCategoryArray && brandCategoryArray.length > 0) {
                    for (let i = 0; i < brandCategoryArray.length; i++) {
                        if (brandCategoryArray[i].getElementsByTagName(`input`)[0]) {
                            if (brandCategoryArray[i].getElementsByTagName(`input`)[0].checked) {
                                brandCategoryArray[i].getElementsByTagName(`input`)[0].click();
                            }
                        }
                    }
                }
            }
        }

    }

    const isAllProductChecked = () => {
        if (document.getElementsByClassName(`brand-category`)) {
            let isAllChecked = true;
            let categoryArray = document.getElementsByClassName(`brand-category`);

            if (categoryArray && categoryArray.length > 0) {
                for (let i = 0; i < categoryArray.length; i++) {
                    if (categoryArray[i].getElementsByTagName(`input`)) {
                        if (!categoryArray[i].getElementsByTagName(`input`)[0].checked) {
                            isAllChecked = false;
                        }
                    }
                }
            }

            if (document.getElementById(`selectAll`)) {
                if (isAllChecked) {
                    document.getElementById(`selectAll`).checked = true;
                } else {
                    document.getElementById(`selectAll`).checked = false;;
                }
            }
        }
    }

    const onProductCheckboxChange = (product, isChecked) => {
        if (isFilterApplied) {
            if (isChecked) {
                if (!selectedProduct.indexOf(product) > -1) {
                    setFiterSelectedProduct((products) => [...products, product]);
                }
            } else {
                if (isSelectAllSelected) {
                    setSelectAllSelected(isChecked);
                }
                if (selectedIds && selectedIds.length > 0) {
                    selectedIds.map((data) => {
                        if (data?.product === product) {
                            setRemovedProduct((products) => [...products, product]);
                        }
                    })
                }

                setFiterSelectedProduct(prevProducts => {
                    return prevProducts.filter(item => item !== product);
                })
            }
        } else {
            if (isChecked) {
                setSelectedProduct((products) => [...products, product]);

            } else {
                if (isSelectAllSelected) {
                    setSelectAllSelected(isChecked);
                }
                setSelectedProduct(prevProducts => {
                    return prevProducts.filter(item => item !== product);
                });
            }
        }
    }

    const onAllProductChange = (isChecked) => {
        setSelectAllSelected(isChecked);


        if (isChecked) {
            setSelectedProduct([]);
            if (EditCatalogueData && EditCatalogueData.length > 0) {
                EditCatalogueData.map((product, index) => {

                    if (document.getElementById(`product-${product?.products_id}`)) {
                        document.getElementById(`product-${product?.products_id}`).checked = true;
                        onProductCheckboxChange(product?.products_id, true);
                    }
                })
            }
        } else {
            if (EditCatalogueData && EditCatalogueData.length > 0) {
                EditCatalogueData.map((product, index) => {
                    if (document.getElementById(`product-${product?.products_id}`)) {
                        document.getElementById(`product-${product?.products_id}`).checked = false;
                        onProductCheckboxChange(product?.products_id, false);
                    }
                })
            }
        }
    }

    const onBrandCategoryChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedBrandCategory(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const onBrandListCategoryChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedBrandList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <Box className={`edit-catalogue-wrapper ${isEditCatalogueShow ? 'show-overlay' : ''}`}>
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            {isLoading && <Loader />}
            <div className={`edit-catalogue-slide scroll-bar-theme ${isEditCatalogueShow ? 'show' : 'hide'}`}>
                <div className={`wrap`}>
                    <div className={`head`}>
                        <div className={`title`}>
                            Catalogue name
                        </div>

                        <div className={`close-icon`} onClick={() => { if (onClose) { setSelectedProduct([]); onClose(false); } }}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className={`form-input-control`}>
                        <div className={`form-control margin-bottom-28 position-relative`}>
                            <TextField
                                id="catalogueName"
                                name="catalogueName"
                                autoComplete='off'
                                placeholder={`Enter a name for the catalogue`}
                                margin="dense"
                                spellCheck={false}
                                className={`catalogue-input`}
                                error={hasError}
                                value={name}
                                onInput={(event) => {
                                    onCatalogueNameChange(event)
                                }}
                            />
                            {
                                errorMessage &&
                                <p className={`field-err-msg`}>{errorMessage}</p>
                            }
                        </div>
                    </div>

                    <div className={`divider`}></div>

                    <div className={`edit-catalogue-detail-wrap`}>
                        <div className={`title`}>Products </div>
                        <div className={`search-wrap`}>
                            <div className={`position-relative`}>
                                <TextField
                                    id="search-catalogue"
                                    name="search-catalogue"
                                    placeholder={`Type Product name or SKU ID`}
                                    margin="dense"
                                    autoComplete='off'
                                    spellCheck={false}
                                    className={`search-input`}
                                    onKeyPress={(event) => {
                                        onCatalogueSearch();
                                    }}
                                    onChange={(event) => {
                                        if (event.target.value) {
                                            setSearchValue(event.target.value);
                                        } else {
                                            onSearchRemove();
                                        }
                                    }}
                                />

                                {
                                    searchValue &&
                                    <div className={`close-icon`} onClick={() => onSearchRemove()}>
                                        <CloseIcon />
                                    </div>
                                }
                            </div>

                            <div className={`selected-product-text`}>
                                {`(${selectedProduct?.length > 0 ? selectedProduct?.length : 'No'} Products Selected)`}
                            </div>
                        </div>
                    </div>

                    {
                        ((brandCategory && brandCategory.length > 0) || (brandList && brandList.length > 0)) &&
                        <div className={`filter-wrap`}>
                            <div className={`text`}>Filter By</div>
                            <div className={`filters`}>
                                {
                                    brandCategory && brandCategory.length > 0 &&
                                    <div className='filter-cat'>
                                        <FormControl sx={{ m: 1, width: 300 }}>
                                            <InputLabel id="demo-multiple-checkbox-label">Category</InputLabel>
                                            <Select
                                                labelId="brand-category-label"
                                                id="brand-category-checkbox"
                                                multiple
                                                value={selectedBrandCategory}
                                                onChange={onBrandCategoryChange}
                                                input={<OutlinedInput label="Category" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {brandCategory.map((brand) => (
                                                    <MenuItem key={brand[`name`]} value={brand[`name`]}>
                                                        <Checkbox checked={selectedBrandCategory.indexOf(brand[`name`]) > -1} />
                                                        <ListItemText primary={brand[`name`]} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                }

                                {
                                    brandList && brandList.length > 0 &&
                                    <div className='filter-brand'>
                                        <FormControl sx={{ m: 1, width: 300 }}>
                                            <InputLabel id="demo-multiple-checkbox-label">Brand</InputLabel>
                                            <Select
                                                labelId="brand-category-label"
                                                id="brand-category-checkbox"
                                                multiple
                                                value={selectedBrandList}
                                                onChange={onBrandListCategoryChange}
                                                input={<OutlinedInput label="Brand" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {brandList.map((brand) => (
                                                    <MenuItem key={brand[`name`]} value={brand[`name`]}>
                                                        <Checkbox checked={selectedBrandList.indexOf(brand[`name`]) > -1} />
                                                        <ListItemText primary={brand[`name`]} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    <div className={`catalogue-edit-product-details product-table table-scroll-wrap freeze-table-body-scroll`}>
                        <table>
                            <thead>
                                <tr>
                                    <th className={`product-name`}>
                                        <input
                                            type="checkbox"
                                            name="selectAll"
                                            id="selectAll"
                                            onChange={(event) => { onAllProductChange(event.target.checked) }}
                                            checked={isSelectAllSelected}
                                        />
                                        Product Details
                                    </th>
                                    <th className={`variants`}>Category</th>
                                    <th className={`item-code`}>Seller SKU ID</th>
                                </tr>
                            </thead>
                            <tbody style={{ overflow: "initial" }}>
                                {
                                    EditCatalogueData && EditCatalogueData.length > 0 &&
                                    EditCatalogueData.map((catalogue, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <tr className={`child-cat`}>
                                                    <td className={`product-name information-icon`}>
                                                        <input type="checkbox"
                                                            className={`edit-catalogue-checkbox`}
                                                            onChange={(event) => { onProductCheckboxChange(catalogue?.products_id, event.target.checked); }}
                                                            name={catalogue?.products_id}
                                                            id={`product-${catalogue?.products_id}`}
                                                        />
                                                        {/* {
                                                            catalogue?.product_img?.length
                                                                ?
                                                                <div className={`pro-image-wrap`}>
                                                                    <img src={catalogue?.product_img[0.].image} alt="Product image" loading="lazy" />
                                                                </div>
                                                                :
                                                                <div className={`pro-image-wrap`}>
                                                                    <div className={`pro-image`}>
                                                                    </div>
                                                                </div>
                                                        } */}
                                                        <span className={`custom-tt-2`} tooltip={catalogue?.name.length > 15 ? `${catalogue?.name}` : ``}>{catalogue?.name.length > 15 ? `${catalogue?.name.substring(0, 15)}...` : `${catalogue?.name}`}</span>
                                                    </td>
                                                    <td className={`variants`}>
                                                        {/* {catalogue?.catagoryname} */}
                                                        <span className={`custom-tt-2-1`} tooltip={catalogue?.catagoryname.length > 15 ? `${catalogue?.catagoryname}` : ``}>{catalogue?.catagoryname.length > 15 ? `${catalogue?.catagoryname.substring(0, 15)}...` : `${catalogue?.catagoryname}`}</span>
                                                    </td>
                                                    <td className={`item-code`}>
                                                        {catalogue?.sku_id}
                                                    </td>
                                                </tr>
                                            </Fragment>
                                            // <Fragment key={index}>
                                            //     <tr className={`parent-cat`}>
                                            //         <td className={`brand-category product-name`}>
                                            //             <input type="checkbox"
                                            //                 onChange={(event) => { onParentCategoryClick(catalogue, event.target.checked) }}
                                            //                 id={`parent-${catalogue?.brandcat_id}`}
                                            //             />
                                            //             {catalogue?.name}
                                            //         </td>
                                            //         <td className={`item-code`}></td>
                                            //         <td className={`variants`}></td>
                                            //     </tr>

                                            //     {
                                            //         catalogue.product_bc && catalogue.product_bc.length > 0 &&
                                            //         catalogue.product_bc.map((product, index) => {
                                            //             return (
                                            //                 <tr className={`child-cat`} key={index}>
                                            //                     <td className={`product-name`}>
                                            //                         <input type="checkbox"
                                            //                             onChange={(event) => { onProductCheckboxClick(product, event.target.checked, catalogue); }}
                                            //                             name={catalogue?.brandcat_id}
                                            //                             id={`product-${product?.products_id}`}
                                            //                         />
                                            //                         {
                                            //                             product?.product_img?.length
                                            //                                 ?
                                            //                                 <div className={`pro-image-wrap`}>
                                            //                                     <img src={product?.product_img[0.].image} alt="Product image" loading="lazy" />
                                            //                                 </div>
                                            //                                 :
                                            //                                 <div className={`pro-image-wrap`}>
                                            //                                     <div className={`pro-image`}>
                                            //                                     </div>
                                            //                                 </div>
                                            //                         }
                                            //                         {product?.name.length > 25 ? `${product?.name.substring(0, 25)}...` : `${product?.name}`}
                                            //                     </td>
                                            //                     <td className={`item-code`}>
                                            //                         {product?.sku_id}
                                            //                     </td>
                                            //                     <td className={`variants`}>
                                            //                         {product?.product_variant?.length}
                                            //                     </td>
                                            //                 </tr>
                                            //             )
                                            //         })
                                            //     }
                                            // </Fragment>
                                        )
                                    })
                                }
                            </tbody>

                        </table>

                        {/* {
                            hasNext &&
                            <div>
                                <VisibilitySensor
                                    onChange={(isVisible) => { if (isVisible) { setPage(page + 1); onPageScroll(page + 1) } }}
                                    partialVisibility={true}
                                    offset={{ top: 400 }}
                                >
                                    <div className={`load-more-btn`}>
                                        <Loader />
                                    </div>
                                </VisibilitySensor>
                            </div>
                        } */}
                    </div>
                    <div className={`bottom-wrap`}>
                        <div className={`divider`}></div>

                        <div className={`bottom`}>
                            <div className={`text`}>
                                Show product pricing
                            </div>
                            <div>
                                <Switch
                                    checked={pricingChecked}
                                    onChange={onPriceSwitchChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                            <div>
                                <Button
                                    className={`btn-primary-after-login create-btn ${isCreateCatalogueButtonEnable ? 'disabled' : ''}`}
                                    variant="contained"
                                    onClick={() => { onCatalogueCreate() }}
                                    disabled={isCreateCatalogueButtonEnable ? true : false}
                                >
                                    {isProductCreated ? 'Create' : 'Update'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Box >
    )
}

const CataloguePreview = (props) => {
    const { isCataloguePreviewSliderShow, onClose } = props, dispatch = useDispatch();

    const [catalogueData, setCatalogueData] = useState(props?.previewCatalogueData || {});

    useEffect(() => {
        if (isCataloguePreviewSliderShow) {
            document.body.classList.add('hide-scroll');
        } else {
            document.body.classList.remove('hide-scroll');
        }

        return () => {
            document.body.classList.remove('hide-scroll');
        }
    }, [props?.isCataloguePreviewSliderShow])

    useEffect(() => {
        setCatalogueData(props?.previewCatalogueData || {});
    }, [props?.previewCatalogueData])

    const onPreviewClose = (value) => {
        if (onClose) onClose(value);
    }
    return (
        <Box className={`catalogue-preview-wrapper ${isCataloguePreviewSliderShow ? 'show-overlay' : ''} `}>
            <Box className={`catalogue-preview-slide scroll-bar-theme ${isCataloguePreviewSliderShow ? 'show' : 'hide'}`}>
                <div className={`wrap`}>
                    {
                        // isCataloguePreviewSliderShow &&
                        <CatalogueWebsitePreview
                            pageType={`catalogue`}
                            catalogueId={catalogueData?.website_key}
                            onPreviewClose={onPreviewClose}
                        />
                    }

                </div>
            </Box>
        </Box>
    )
}
const Catalogues = (props) => {

    const { appData } = props,
        dispatch = useDispatch(),
        getProductSelectedBrand = getLocalStorage(`productSelectedBrand`) !== `undefined` ? JSON.parse(getLocalStorage(`productSelectedBrand`)) : ``;

    const [brandList, setBrandList] = useState([]),
        [isLoading, setIsLoading] = useState(false),
        [selectedBrand, setSelectedBrand] = useState(getProductSelectedBrand || {}),
        [addBrandResponse, setBrandResponse] = useState(``),
        [isAddCatalogueShow, setIsAddCatalogueShow] = useState(false),
        [isEditCatalogueShow, setIsEditCatalogueShow] = useState(false),
        [EditCatalogueData, setEditCatalogueData] = useState({}),
        [onMoreOptionSelectedData, setMoreOptionSelectedData] = useState({}),
        [selectedCatalogue, setSelectedCatalogue] = useState({}),
        [selectedCatalogueProductsId, setSelectedCatalogueProductsId] = useState([]),
        [isCatalougeListUpdated, setIsCatalougeListUpdated] = useState(false),
        [isBrandSliderShow, setIsBrandSliderShow] = useState(false),
        [isCataloguePreviewSliderShow, setIsCataloguePreviewSliderShow] = useState(false),
        [previewCatalogueData, setPreviewCatalogueData] = useState({}),
        [hasMoreProduct, setHasMoreProduct] = useState(false),
        [isSelectAllSelected, setSelectAllSelected] = useState(false),
        [isProductCreated, setProductCreated] = useState(false),
        [brandCategoryList, setBrandCategoryList] = useState([]),
        [catalogueBrandList, SetCatalogueBrandList] = useState([]),
        [permission, SetPermission] = useState(props?.userDetailData?.user?.data?.permission || []),
        [isViewPermission, setViewPermission] = useState(permission?.[0]?.catalogue_auth_type === `4` ? true : false);

    const getAddedBrands = () => {
        setIsLoading(true);
        getBrandList()
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    dispatch(supplierBrandListAction.setSupplierBrandList(response.data.data))
                    setBrandList(response.data.data);

                    if (Object.keys(selectedBrand).length === 0) {
                        setSelectedBrand(response.data.data[0]);
                    }

                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("We have error while getBrandList", error);
            })
    }
    useEffect(() => {
        getAddedBrands();
    }, [])

    useEffect(() => {
        setIsCatalougeListUpdated(false);
    }, [isEditCatalogueShow]);

    useEffect(() => {
        let tempObject = appData?.app;
        tempObject[`productSelectedBrand`] = selectedBrand;
        dispatch(appObjectAction.setAppObject(tempObject))
        createLocalStorage(`productSelectedBrand`, JSON.stringify(selectedBrand));
    }, [selectedBrand])

    useEffect(() => {
        setIsBrandSliderShow(ifBrandSliderAdd());
    }, [brandList]);

    useEffect(() => {
        SetPermission(props?.userDetailData?.user?.data?.permission);
        setViewPermission(props?.userDetailData?.user?.data?.permission?.[0]?.catalogue_auth_type === `4` ? true : false)
    }, [props?.userDetailData?.user]);


    useEffect(() => {
        var existCondition = setInterval(function() {
            if (document.getElementsByClassName('brand-list-slider-container')[0].getElementsByClassName('selected-brand')[0]) {
               clearInterval(existCondition);
               document.getElementsByClassName('brand-list-slider-container')[0].getElementsByClassName('selected-brand')[0].scrollIntoView({ block: 'center', inline: 'center'});
            }
           }, 100); 
    },[selectedBrand])
    
    const onSelectedBrandChange = (brand) => {
        setSelectedBrand(brand);
    }

    const onAddCatalogueClick = (value) => {
        setIsAddCatalogueShow(value);
    }

    const onFilterOptionChange = (option, data) => {
        setMoreOptionSelectedData(data);
        switch (option[`value`]) {
            case `Edit Products`:
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });

                setSelectedCatalogue(data);

                getCatalogueProductListData(data?.brand, data?.catalogue_web_id, 1);
                break;
        }
    }

    const getCatalogueProductListData = (brandId, catalogueWebId) => {
        setIsLoading(true);

        const moduleApis = [];
        const moduleApiIndex = [];

        moduleApis.push(getCatalogueProductList(brandId, ``, 1, 2, [], []));
        moduleApiIndex.push(`cataloguesProductListIndex`);

        moduleApis.push(getCatalogueCustomWebsiteProductsIds(catalogueWebId));
        moduleApiIndex.push(`cataloguesProductIdsIndex`);

        moduleApis.push(getBrandCategoryList(brandId, ``));
        moduleApiIndex.push(`brandCategoryIndex`);

        moduleApis.push(getProductBrandList(brandId, ``));
        moduleApiIndex.push(`brandListIndex`);

        Promise.all(moduleApis)
            .then((result) => {

                let productListIndex = moduleApiIndex.indexOf('cataloguesProductListIndex');
                if (productListIndex !== -1 && result.length) {
                    const dataResult = result[productListIndex] ? result[productListIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data && dataResult.data.data.length > 0) {
                        setHasMoreProduct(dataResult?.data?.next ? true : false);
                        setEditCatalogueData(dataResult.data.data);
                    }
                }

                let productListIdsIndex = moduleApiIndex.indexOf('cataloguesProductIdsIndex');
                if (productListIdsIndex !== -1 && result.length) {
                    const dataResult = result[productListIdsIndex] ? result[productListIdsIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                        setSelectedCatalogueProductsId(dataResult.data.data.products_list);
                        setSelectAllSelected(dataResult.data.data.catalogue_detail.is_selected_all ? true : false);
                        setProductCreated(dataResult.data.data.catalogue_detail.is_product_created ? true : false);
                    } else {
                        setSelectedCatalogueProductsId([]);
                    }
                }

                let branCategoryIndex = moduleApiIndex.indexOf('brandCategoryIndex');
                if (branCategoryIndex !== -1 && result.length) {
                    const dataResult = result[branCategoryIndex] ? result[branCategoryIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                        setBrandCategoryList(dataResult.data.data);
                    }
                }

                let brandListIndex = moduleApiIndex.indexOf('brandListIndex');
                if (brandListIndex !== -1 && result.length) {
                    const dataResult = result[brandListIndex] ? result[brandListIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                        SetCatalogueBrandList(dataResult.data.data);
                    }
                }

                setIsEditCatalogueShow(true);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("we have error at getCatalogueProductListData", error);
            })
    }

    const onCatalogueSuccess = (data, response) => {
        setBrandResponse(response);
        setSelectedCatalogue(data);
        getCatalogueProductListData(data?.brand, data?.catalogue_web_id, 1);
        setIsCatalougeListUpdated(response === 'success' ? true : false);
    }

    const onEditCatalogueClose = (value, response) => {
        setIsCatalougeListUpdated(response === 'success' ? true : false);
        setIsEditCatalogueShow(false);
    }

    const ifBrandSliderAdd = () => {
        let parentWidth = document.getElementsByClassName(`brands-wrapper`)[0].clientWidth;
        let childWidth = document.getElementsByClassName(`brand-list-slider-container`)[0].clientWidth;

        if (childWidth == 0) {
            return false;
        }

        return childWidth >= parentWidth ? true : false;
    }

    const onSliderArrow = (side) => {
        const brandList = document.getElementsByClassName('added-brand-wrapper')[0];
        let slideValue = 120;

        if (side === "left") {
            brandList.scrollLeft -= slideValue;
        }
        else if (side === "right") {
            brandList.scrollLeft += slideValue;
        }
    }

    const onShowPreviewClick = (value, catalogue) => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setIsCataloguePreviewSliderShow(value);
        setPreviewCatalogueData(catalogue);
    }

    const onCataloguePreviewClose = (value) => {
        setIsCataloguePreviewSliderShow(value);
    }

    return (
        <Box className={`catalogues-wrapper`}>
            {isLoading && <Loader />}
            <Box className={`brands-wrapper`}>
                <div className={`flex-basis-80P div-scroll-wrap  ${isBrandSliderShow ? 'w-100-slider' : ''}`}>
                    <div className={`added-brand-wrapper`}>

                        <Fragment>

                            {
                                isBrandSliderShow &&
                                <div className={`not-mobile prev-btn`} onClick={() => onSliderArrow(`left`)}>
                                    <ArrowBackIosIcon />
                                </div>
                            }

                            <div className={`abc`}>
                                <div className={`brand-list-slider-container`}>
                                    {
                                        brandList && brandList.length > 0 &&
                                        brandList.map((brand, index) => {
                                            return (
                                                <div key={index} onClick={() => { onSelectedBrandChange(brand) }} className={`${brand?.brand_id === selectedBrand?.brand_id ? 'selected-brand' : ''}`}>
                                                    <img src={brand[`b_logo`]} loading="lazy" />
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>

                            {
                                isBrandSliderShow &&
                                <div className={`not-mobile next-btn`} onClick={() => onSliderArrow(`right`)}>
                                    <ArrowForwardIosIcon />
                                </div>
                            }
                        </Fragment>
                    </div>
                </div>
            </Box>
            <div className={`divider`}></div>
            <Box className={`brand-manage-section`}>
                <Box className={`option-wrapper`}>
                    <div className={`option selected`}>
                        Catalogues
                    </div>
                </Box>
                <div className={`divider`}></div>
            </Box>
            <CataloguesFilterSection
                onAddNewCatalogueClick={onAddCatalogueClick}
                selectedBrand={selectedBrand}
                brandResponse={addBrandResponse}
                onFilterOptionChange={onFilterOptionChange}
                isCatalougeListUpdated={isCatalougeListUpdated}
                onShowPreviewClick={onShowPreviewClick}
                isViewPermission={isViewPermission}
                brandCategoryList={brandCategoryList}
            />

            {
                isAddCatalogueShow &&
                <CreateCatalogue
                    onAddNewProductCloseClick={onAddCatalogueClick}
                    selectedBrand={selectedBrand}
                    catalogueSuccess={onCatalogueSuccess}
                />
            }

            <EditCatalogue
                isEditCatalogueShow={isEditCatalogueShow}
                EditCatalogueInfo={EditCatalogueData}
                onClose={(value, response) => { onEditCatalogueClose(value, response) }}
                onMoreOptionSelectedData={onMoreOptionSelectedData}
                selectedBrand={selectedBrand}
                selectedCatalogue={selectedCatalogue}
                selectedCatalogueProductsId={selectedCatalogueProductsId}
                hasMoreProduct={hasMoreProduct}
                isAllProductSelected={isSelectAllSelected}
                productCreated={isProductCreated}
                brandCategoryList={brandCategoryList}
                catalogueBrandList={catalogueBrandList}
            />

            <CataloguePreview
                isCataloguePreviewSliderShow={isCataloguePreviewSliderShow}
                previewCatalogueData={previewCatalogueData}
                onClose={onCataloguePreviewClose}
            />
        </Box>
    )
}

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Catalogues);