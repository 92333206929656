
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const catalogueUpdate = async (brandId, name, products, is_price, is_selected_all = 'False', isFilterBy, productRemove) => {

    const headers = await getApiHeader() || {};;

    if(!brandId) {
        return {
            error: true,
            message: 'brandId mandatory in catalogueUpdate function' 
        }
    }


    if(!name) {
        return {
            error: true,
            message: 'name mandatory in catalogueUpdate function' 
        }
    }

    if(!products) {
        return {
            error: true,
            message: 'products mandatory in catalogueUpdate function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in catalogueUpdate function' 
        }
    }

    // if(!is_price) {
    //     return {
    //         error: true,
    //         message: 'is_price mandatory in catalogueUpdate function' 
    //     }
    // }
    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();

    if(name) formData.append("name", name);
    if(products) formData.append("products", products);
    // console.log(is_price,'is_price')
    // formData.append("is_price", is_price);
    if(is_price) formData.append("is_price", "1");
    if(!is_price) formData.append("is_price", "2");

    formData.append("is_selected_all",is_selected_all)
    
    if(isFilterBy) formData.append("filterby", isFilterBy ? 'True' : 'False');
    if(isFilterBy && productRemove) formData.append("product_remove", productRemove || '');

    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-update/${brandId}`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default catalogueUpdate;