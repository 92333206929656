
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import { CONFIG } from '../../helpers/getApiConfig';

const updatePorductSetting = async (params) => {

    const headers = await getApiHeader() || {};;

    if (!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in updatePorductSetting function'
        }
    }

    const { pro_usps, pro_dimensions, pro_volume, pro_weight, pro_pack_size, pro_barcode, pro_hs_code, pro_case_pallete_quantity, pro_case_per_unit, pro_priceitem, pro_msrp, pro_container_20, pro_container_40, pro_container_20_loose,pro_container_40_loose,pro_full_truck_load,pro_flavour } = params;

    const instance = axios.create();
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();

    if (pro_usps) formData.append("pro_usps", pro_usps);
    if (pro_dimensions) formData.append("pro_dimensions", pro_dimensions);
    if (pro_volume) formData.append("pro_volume", pro_volume);
    if (pro_weight) formData.append("pro_weight", pro_weight);
    if (pro_pack_size) formData.append("pro_pack_size", pro_pack_size);
    if (pro_barcode) formData.append("pro_barcode", pro_barcode);
    if (pro_hs_code) formData.append("pro_hs_code", pro_hs_code);
    if (pro_case_pallete_quantity) formData.append("pro_case_pallete_quantity", pro_case_pallete_quantity);
    if (pro_case_per_unit) formData.append("pro_case_per_unit", pro_case_per_unit);
    if (pro_priceitem) formData.append("pro_priceitem", pro_priceitem);
    if (pro_msrp) formData.append("pro_msrp", pro_msrp);
    if (pro_flavour) formData.append("pro_flavour", pro_flavour);
    if (pro_container_20) formData.append("pro_container_20", pro_container_20);
    if (pro_container_40) formData.append("pro_container_40", pro_container_40);
    if (pro_container_20_loose) formData.append("pro_container_20_loose", pro_container_20_loose);
    if (pro_container_40_loose) formData.append("pro_container_40_loose", pro_container_40_loose);
    if (pro_full_truck_load) formData.append("pro_full_truck_load", pro_full_truck_load);
    
    
    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/settings/product-setting`, formData, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default updatePorductSetting;