// React related imports
import React, { useState, useEffect, Fragment } from "react";

//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import countryListActions from "../../../store/action/countryListAction";
import registerActions from "../../../store/action/registrationAction";
import useAction from "../../../store/action/userAction";

// mui or UI related imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";

//Apis related imports
import getCountryList from "../../../apis/getCountryList";

//Helpers function related imports
import getDefaultCountry from '../../../helpers/getDefaultCountry';
import getQueryParamaterFromUrl from '../../../helpers/getQueryParamaterFromUrl';
import createLocalStorage from '../../../helpers/createLocalStorage';
import createCookie from "../../../helpers/createCookie";

// React route related imports
import { Link, useNavigate } from "react-router-dom";

//Other imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

//Apis related imports
import registerApi from "../../../apis/register";

//Components related imports
import AlertMessage from "../AlertMessage";
import Loader from "../Loader";

//Style related imports
// import "./style.scss";
import { Icons } from "../../../common/Icons";
import { RightPanel } from "./RightPanel";
import Greentick from "../../../icons/svgs/greentick";
import Graytick from "../../../icons/svgs/graytick";

function isValidPhonenumber(message) {
  return this.test("isValidPhonenumber", message, function (value, element) {
    const { path, createError } = this;

    if (!value.match(/^(?:\+971|00971|0)?(?:50|51|52|54|55|56|58|2|3|4|6|7|9)\d{7}$/)) {
      return createError({
        path,
        message: message ?? 'Please enter valid phone number'
      });
    }

    return true;
  });
}
yup.addMethod(yup.string, "isValidPhonenumber", isValidPhonenumber);
const schema = yup
  .object({
    fname: yup
      .string()
      .trim()
      .required("First name is required")
      .min(3, "Too short - should be at least 3 characters")
      .max(40, 'Maximum allowed characters is 40'),
    lname: yup
      .string()
      .trim()
      .required("Last name is required")
      .min(2, "Too short - should be at least 2 characters")
      .max(40, 'Maximum allowed characters is 40'),
    email: yup
      .string()
      .trim()
      .email("Invalid email format")
      .required("email is required")
      .matches(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/, "Invalid email format"),
    number: yup
      .string()
      .trim()
      .matches(/^[0-9]{6,14}$/, "Please enter valid phone number")
      //.isValidPhonenumber()
      .required("Phone Number is required"),
    password: yup
      .string()
      .trim()
      .required("No password provided.")
      .matches(/([0-9])/, "Password should contain minimum 8 characters with at least one alphabet, one number, and one special character (!@#()$%&)*")
      .matches(/([a-zA-Z])/, "Password should contain minimum 8 characters with at least one alphabet, one number, and one special character (!@#()$%&)*")
      .matches(/([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/, "Password should contain minimum 8 characters with at least one alphabet, one number, and one special character (!@#()$%&)*")
      .min(8, "Password is too short - should be 8 chars minimum."),
  })
  .required();



const Registration = (props) => {

  const { pageName, catalogueId, loginUserType, onRegisterSuccess } = props;
  const [selectedAccount, setSelectedAccount] = useState(loginUserType || 1);
  const [countryList, setCountryList] = useState(
    props?.countryListData?.countryList || []
  );
  const accountTypes = [
    {
      name: "Seller",
      value: "1",
    },
    {
      name: "Buyer",
      value: "2",
    },
  ];

  const [countryCode, setCountyCode] = useState(getDefaultCountry);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isPhoneCodeErrorShow, setPhoneCodeError] = useState(false);
  const dispatch = useDispatch(),
    navigate = useNavigate();
  const [isAlertMessageShow, setAlternateMessageShow] = useState(false),
    [alertMessageType, setAlertMessageType] = useState(``),
    [alertMessage, setAlertMessage] = useState(``),
    [isLoading, setIsLoading] = useState(false),
    [passwordText, setPasswordText] = useState(``),
    [showPasswordValidation, setShowPasswordValidation] = useState(false),
    [isMininumPasswordRegexValid, setIsMininumPasswordRegexValid] = useState(false),
    [isPasswordRegexValid, setIsPasswordRegexValid] = useState(false),
    [checkPasswordRegex, setCheckPasswordRegex] = useState(false),
    [isEmailCheck, setIsEmailCheck] = useState(false),
    [isWebCatalogueRegsiter, setWebCatalogueRegsiter] = useState(pageName === 'webcatalogue' ? true : false);

  const minimumLengthRegex = /^(?=.{8,}$)/;
  const passWordRegex = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<]).*$/;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (countryList && Object.keys(countryList).length === 0) {
      setIsLoading(true);
      getCountryList()
        .then((response) => {
          if (
            response &&
            response.status === 200 &&
            response.data.status &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            setIsLoading(false);
            setCountryList(response.data.data);
            dispatch(countryListActions.setCountryList(response.data.data));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("We have error", error);
        });
    }
  }, []);

  useEffect(() => {
    setWebCatalogueRegsiter(pageName === 'webcatalogue' ? true : false)
  }, [pageName])

  useEffect(() => {
    setIsMininumPasswordRegexValid(minimumLengthRegex.test(passwordText) ? true : false);
    setIsPasswordRegexValid(passWordRegex.test(passwordText) ? true : false)
  }, [passwordText])

  const handleChange = (event) => {
    setCountyCode(event.target.value);
    setPhoneCodeError(false);
  };

  const onAlertClose = (value) => {
    setAlternateMessageShow(value);
  }
  const onSubmit = (data) => {

    if (!countryCode) {
      setPhoneCodeError(true);
      return;
    }
    setIsLoading(true);
    registerApi(data[`fname`], data[`lname`], data[`email`], data[`password`], countryCode, countryCode, data[`number`], selectedAccount, catalogueId)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          if (response.data.status) {
            setIsLoading(false);
            if (selectedAccount === 1) {
              dispatch(registerActions.setRegistration(response.data.data))
              createLocalStorage(`registerEmail`, data[`email`]);
            } else {
              dispatch(useAction.setUser(response.data));

              if (response?.data?.token?.access) {
                createCookie("discoverrrToken", response?.data?.token?.access, 120);
              }

              if (response?.data?.token?.refresh) {
                createCookie("discoverrrRefreshToken", response?.data?.token?.refresh, 1440);
              }
            }
            if (isWebCatalogueRegsiter) {
              if (onRegisterSuccess) {
                onRegisterSuccess(response.data);
              }
            } else {
              navigate(`${selectedAccount === 1 ? '/email_verification' : '/buyer/onboarding'}`);
            }
          }
          else {
            setIsLoading(false);
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            if (response?.message === `Network Error`) {
              setAlertMessage(`No internet connection`);
            } else {
              setAlertMessage(response?.data?.message)
            }
          }
        } else {
          if (response && response.response && response.response.data && response.response.data.message) {
            setIsLoading(false);
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            if (response?.message === `Network Error`) {
              setAlertMessage(`No internet connection`);
            } else {
              setAlertMessage(response.response.data.message)
            }
          }
        }
      })
      .catch((error) => {
        setAlertMessage(false);
        console.log("We have error", error);
      })
  }

  const flagemojiToPNG = (flag, code) => {
    var countryCode = Array.from(flag, (codeUnit) => codeUnit.codePointAt()).map(char => String.fromCharCode(char - 127397).toLowerCase()).join('')
    return `https://flagcdn.com/24x18/${countryCode}.png`;
  }
  return (
    <Fragment>
      <div className={`register-wrapper`}>
        <div className={`register-inner_container`}>
          {isLoading && <Loader />}
          {
            isAlertMessageShow &&
            <AlertMessage
              message={alertMessage}
              alertType={alertMessageType}
              isAlertShow={isAlertMessageShow}
              onClose={onAlertClose}
            />
          }
          <Box>
            <Typography variant="h1" className={`first-heading`}>
              Create your account<span className="dot">.</span>
            </Typography>
            <Typography variant="h2" className={`second-heading`}>
              Simple, fast and no credit card required.
            </Typography>
          </Box>

          <form className={`form-input-control`} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Box className={`form-control`}>

              {
                pageName !== `webcatalogue` &&
                <Fragment>
                  <Typography className={`label`}>I am</Typography>

                  <div className={`account-type-wrapper`}>
                    {accountTypes &&
                      accountTypes.length > 0 &&
                      accountTypes.map((account, index) => {
                        return (
                          <div
                            key={index}
                            className={`${selectedAccount === index + 1 ? "button-primary button-shadow" : ""
                              }`}
                            onClick={() => {
                              setSelectedAccount(index + 1);
                            }}
                          >
                            {account[`name`]}
                          </div>
                        );
                      })}
                  </div>
                </Fragment>

              }


              <div className={`name-wrapper`}>
                <Box className={`fname-wrapper form-control`}>
                  <Typography className={`label`}>First Name</Typography>
                  <TextField
                    id="fname"
                    name="fname"
                    placeholder={`First Name`}
                    margin="dense"
                    spellCheck={false}
                    {...register("fname")}
                    error={errors.fname ? true : false}
                  />
                  <p className={`field-err-msg`}>{errors.fname?.message}</p>
                </Box>
                <Box className={`form-control last-name-style`}>
                  <Typography className={`label`}>Last Name</Typography>
                  <TextField
                    id="lname"
                    name="lname"
                    placeholder={`Last Name`}
                    margin="dense"
                    spellCheck={false}
                    {...register("lname")}
                    error={errors.lname ? true : false}
                  />
                  <p className={`field-err-msg`}>{errors.lname?.message}</p>
                </Box>
              </div>

              <div className={`email-wrapper`}>
                <Typography className={`label`}>Work Email</Typography>
                <Box className={`form-control email-control`}>
                  <TextField
                    id="email"
                    name="email"
                    placeholder={`Work Email`}
                    margin="dense"
                    spellCheck={false}
                    {...register("email")}
                    error={errors.email ? true : false}
                    {...register("email", {
                      onChange: (event) => {
                        if (event.target.value) setIsEmailCheck(true);
                      },
                    })}
                    inputProps={{ maxLength: 64 }}
                  />
                  {
                    !errors.email && isEmailCheck &&
                    <img src={`https://discoverrr-s3-bucket.s3.me-south-1.amazonaws.com/frontend/icon/Check.svg`} alt="verified image" />
                  }
                  <p className={`field-err-msg`}>{errors.email?.message}</p>
                </Box>
              </div>

              <div className={`phone-number-wrapper form-control`}>
                <Typography className={`label`}>Phone Number</Typography>

                <div>
                  <Box className={`country-code-wrapper form-control`}>
                    <FormControl>
                      <Select
                        labelId="country-code-select-label"
                        id="country-code-select"
                        value={countryCode}
                        label="Code"
                        onChange={handleChange}
                      >
                        {countryList &&
                          countryList.length > 0 &&
                          countryList.map((country, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={country[`country_id`]}
                              >
                                {
                                  <div className="flag-wrapper flex flex-items-center">
                                    {<img src={flagemojiToPNG(country[`emoji`])} style={{ "marginRight": "5px", "height": "20px", "width": "20px", "objectFit": "contain" }} loading="lazy" />}
                                    {country[`phonecode`]}
                                  </div>


                                }
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box className={`phone-number-wrap`}>
                    <TextField
                      id="phone-number"
                      name="phone-number"
                      placeholder={`Phone Number`}
                      margin="dense"
                      spellCheck={false}
                      {...register("number")}
                      error={errors.number ? true : false}
                      className={errors.number ? "error" : ""}
                    />
                  </Box>
                </div>
                <p className={`field-err-msg`}>{errors.number?.message}</p>
                <p>{isPhoneCodeErrorShow && `Phone code is required`}</p>

              </div>
              <div className={`password-wrapper ${showPasswordValidation ? "height-160" : ""}`}>
                <Box className={`form-control`}>
                  <div
                    className={`flex flex-items-center flex-justify-content-space-between flex-items-center`}
                  >
                    <Typography className={`label`}>Password</Typography>
                    <Typography
                      className={`label show-label icon-eye`}
                      onClick={() => setPasswordVisible(!isPasswordVisible)}
                    >
                      <span className="flex flex-items-center flex-justify-content-center gap-10" style={{ cursor: "pointer" }}>
                        <Icons iconName={`${isPasswordVisible ? "icon-eye-off" : "icon-eye"}`} />
                        <span className="login-password__hide-show">{`${isPasswordVisible ? "Hide" : "Show"}`}</span>
                      </span>
                    </Typography>
                  </div>
                  <TextField
                    type={`${isPasswordVisible ? "text" : "password"}`}
                    id="password"
                    name="password"
                    placeholder={`Password`}
                    margin="dense"
                    spellCheck={false}
                    inputProps={{
                      onClick: () => setShowPasswordValidation(true),
                      onFocus: () => { setShowPasswordValidation(true); setCheckPasswordRegex(true) },
                    }}
                    {...register("password", {
                      onChange: (event) => {
                        setPasswordText(event.target.value);
                      },
                      onBlur: () => {
                        setShowPasswordValidation(false);
                        // setCheckPasswordRegex(false);
                      }
                    })}
                    error={errors.password && !(isMininumPasswordRegexValid && isPasswordRegexValid) && checkPasswordRegex ? true : false}
                  />
                  {
                    <div className={`password-validation ${showPasswordValidation ? "show" : ""}`}>
                      <p>
                        <span> {minimumLengthRegex.test(passwordText) ? <Greentick /> : <Graytick />} </span>
                        <span> 8 character minimum</span>
                      </p>
                      <p>
                        <span> {passWordRegex.test(passwordText) ? <Greentick /> : <Graytick />} </span>
                        <span> Includes at least 1 uppercase, 1 lowercase, 1 number and 1 special character</span>
                      </p>
                    </div>

                  }
                  {/* <p className={`field-err-msg`}>{errors.password?.message}</p> */}
                </Box>
              </div>

              {/* <div className={`disclaimer-wrapper`}>
                  <p>
                    By creating an account, you agree to our
                    <Link to={`/`}>Terms</Link>
                    and have read and acknowldge our
                    <Link to={`/`}>Privacy Policy</Link>
                  </p>
                </div> */}
              <div className={`cookie-policy-text ${showPasswordValidation ? "margin-top-66" : ""}`}>
                By signing up, you agree to Discoverrr's <a className={`skip-btn`} href={`https://legal.discoverrr.com/general-terms-of-use/`} target="_blank">
                  Terms of Service
                </a> and <a className={`skip-btn`} href={`https://legal.discoverrr.com/privacy-policy/`} target="_blank">
                  Privacy Policy
                </a>.
              </div>
              <div className={`button-wrapper`}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  className={`w-100 button-primary ${isValid && isMininumPasswordRegexValid && isPasswordRegexValid ? `` : `disabled`}`}
                  disabled={isValid && isMininumPasswordRegexValid && isPasswordRegexValid ? false : true}
                >
                  Sign up
                </Button>
              </div>
            </Box>
          </form>
        </div>
      </div>
      <RightPanel />
    </Fragment>
  );
};

//map state
const mapStateToProps = (state) => {
  return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
