const initialState = {
    yearOfEstablish: {},
    loading: true,
    error: false,
  };
  
  function yearOfEstablishReducer(state = initialState, action) {
    switch (action.type) {
      case "LOAD_YEAR_OF_ESTABLISH":
        return { ...state, yearOfEstablish: {}, error: false, loading: true };
      case "GET_YEAR_OF_ESTABLISH":
        return state;
      case "SET_YEAR_OF_ESTABLISH":
        return { ...state, yearOfEstablish: action.payload, error: false, loading: false };
      case "ERROR_YEAR_OF_ESTABLISH":
        return { ...state, yearOfEstablish: {}, error: true, loading: false };
      default:
        return state;
    }
  }
  export default yearOfEstablishReducer;
  