import React, { useState, useEffect, Fragment } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

//Components related imports
import Slider from '../Slider';
import imageResizing from "../../../../helpers/imageResizing";

const ProductView = (props) => {
    
    const {
        flagImage,
        productImages,
        variantInfo,
        companyName,
        categoryList,
        uspsList,
        barcodeValue,
        itemCodeValue,
        priceValue = 0,
        priceCurrency,
        itemValue = 0,
        packageSizeFirstValue,
        packageSizeSecondValue,
        packageSizeThirdValue,
        selectedMeasType,
        msrpValue = 0,
        is_variant,
        dimensionList,
        weightList,
        selectedDimesnion,
        selectedVolume,
        selectedWeight,
        productWidth,
        productHeight,
        productDepth,
        productVolume,
        productWeight,
        dimensionType,
        volumeType,
        weightType,
        userSettings,
        primaryImageValue,
        containerLoadability20Ft,
        containerLoadability40Ft,
        containerLoadability20FtLoose,
        containerLoadability40FtLoose,
        fullTruckLoad,
        // containerLoadability20FtChange,
        // containerLoadability40FtChange,
        // containerLoadability40FtlooseChange,
        // containerLoadability20FtLooseChange,
        producthscode,
        casePalaleteQuantity,
        brandChange,
        unitPerCase,
    } = props;

    const [isViewLess, SetViewLessFlag] = useState(true),
        [marginfrom, setMarginFrom] = useState(``),
        [marginto, setMarginTo] = useState(``),
        [defaultImage, setDefaultImage] = useState(``);

    const onSlideChange = (slide) => {
        console.log("onSlideChange", slide);
    }

    useEffect(() => {

        let marginFrom = (parseFloat(msrpValue) - parseFloat(priceValue)) / parseFloat(msrpValue) * 100;
        let margiInto = (parseFloat(msrpValue) - parseFloat(itemValue)) / parseFloat(msrpValue) * 100;

        setMarginFrom(marginFrom.toFixed(2));
        setMarginTo(margiInto.toFixed(2));
    }, [priceValue, itemValue, msrpValue])

    useEffect(() => {
        setDefaultImage(primaryImageValue);
    }, [primaryImageValue])
    return (
        <Box className={`product-view-container`}>

            {
                flagImage &&
                <div className={`coutry-flag`}>
                    <div className={`image-wrap`}>
                        <img src={flagImage} loading="lazy" />
                    </div>
                </div>
            }

            <div className={`product-images-wrapper`}>
                {
                    productImages && productImages.length > 0
                        ?
                        productImages && productImages.length === 1
                            ?
                            <div className={`product-image-wrap`}>
                                <img src={productImages[0][`image`]} loading="lazy" alt="product-images" />
                            </div>
                            :
                            <Fragment>
                                {
                                    <div className={`product-image-slider`}>
                                        <Slider
                                            autoRotate={false}
                                            showNextPrevBtn={true}
                                            rotationTiming={5000}
                                            backArrowHtml={
                                                <div className={`prev-arrow`}>
                                                    <WestIcon />
                                                </div>
                                            }
                                            nextArrowHtml={
                                                <div className={`next-arrow`}>
                                                    <EastIcon />
                                                </div>
                                            }
                                            onSlideChange={(slide) => { onSlideChange(slide); }}
                                            defaultActiveSlide={defaultImage || 0}
                                        >
                                            {
                                                productImages && productImages.length > 0 &&
                                                productImages.map((image, index) => {
                                                    return (
                                                        <div className={`product-image-wrap`} key={index}>
                                                            <img src={image[`image`]} loading="lazy" alt="product-images" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </div>
                                }
                            </Fragment>


                        :
                        <div className={`no-product-images-found-wrapper`}>
                            No product images found
                        </div>
                }
            </div>

            {
                variantInfo && !is_variant &&
                <div className={`variant-info-wrap`}>
                    <span>
                        {`${variantInfo.length} Variants`}
                    </span>
                </div>
            }

            <div className={`product-info-wrap`}>
                <div className={`product-name-category-wrap`}>
                    {
                        companyName &&
                        <div className={`product-name-wrap`}>
                            <div className={`name`}>{companyName}</div>
                            {
                                uspsList && uspsList.length > 0 && userSettings?.pro_usps &&
                                <div className={`view-text-wrap skip-btn`} onClick={() => { SetViewLessFlag(!isViewLess) }}>
                                    {`${isViewLess ? 'Hide USPs' : 'View USPs'}`}
                                    {
                                        isViewLess
                                            ?
                                            <KeyboardArrowUpIcon />
                                            :
                                            <KeyboardArrowDownIcon />
                                    }

                                </div>
                            }
                        </div>
                    }

                    {
                        isViewLess && userSettings?.pro_usps &&
                        <div className={`usps-wrap`}>
                            {
                                uspsList && uspsList.length > 0 &&
                                uspsList.map((usps, index) => {
                                    return (
                                        <div className={`usps`} key={index}>
                                            {usps[`name`]}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>

            <div className={`product-item-wrap`}>

            {
                    userSettings?.pro_hs_code &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                          HS Code
                        </div>

                        <div className={`value`}>
                           {producthscode || `NA`}
                        </div>
                    </div>
                }

                {
                    userSettings?.pro_pack_size &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                             Case Configuration/Pack Size
                        </div>

                        <div className={`value`}>
                            {
                                packageSizeFirstValue || packageSizeSecondValue || packageSizeThirdValue
                                    ?
                                    <Fragment>{`${selectedMeasType || ''} ${packageSizeThirdValue || 'NA'} X ${packageSizeFirstValue || 'NA'} X ${packageSizeSecondValue || 'NA'}`}</Fragment>
                                    :
                                    'NA'
                            }

                        </div>
                    </div>
                }

                {
                    userSettings?.pro_barcode &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                            Product Barcode
                        </div>

                        <div className={`value`}>
                            {barcodeValue || `NA`}
                        </div>
                    </div>
                }

                {
                    userSettings?.pro_case_pallete_quantity &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                        Pallet Quantity
                        </div>

                        <div className={`value`}>
                            {casePalaleteQuantity || `NA`}
                        </div>
                    </div>
                }

                {/* {
                    brandChange &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                        Brand
                        </div>

                        <div className={`value`}>
                            {brandChange || `NA`}
                        </div>
                    </div>
                } */}

                <div className={`wrap`}>
                    <div className={`label`}>
                        Seller SKU ID
                    </div>

                    <div className={`value information-icon`}>
                        {/* {itemCodeValue || `NA`} */}

                        <span className={`custom-tt-2-1`} tooltip={itemCodeValue.length > 15 ? `${itemCodeValue}` : ``}>{itemCodeValue.length > 15 ? `${itemCodeValue.substring(0, 15)}...` : `${itemCodeValue || `NA`}`}</span>
                    </div>
                </div>

                {
                    userSettings?.pro_case_per_unit &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                        Units per carton/case
                        </div>

                        <div className={`value`}>
                            {unitPerCase || `NA`}
                        </div>
                    </div>
                }


                {
                    userSettings?.pro_dimensions &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                            Product dimension (wxhxd)
                        </div>
                        <div className={`value`}>
                            {
                                productWidth || productHeight || productDepth
                                    ?
                                    <Fragment>{`${productWidth || 'NA'} X ${productHeight || 'NA'} X ${productDepth || ''} ${dimensionType || 'NA'}`}</Fragment>
                                    :
                                    'NA'
                            }

                        </div>
                    </div>
                }

                {
                    userSettings?.pro_volume &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                            Product Volume
                        </div>

                        <div className={`value`}>
                            {
                                productVolume
                                    ?
                                    <Fragment>{`${productVolume || 'NA'} ${volumeType || 'NA'}`}</Fragment>
                                    :
                                    'NA'
                            }
                        </div>
                    </div>
                }

                {
                    userSettings?.pro_weight &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                            Product weight
                        </div>

                        <div className={`value`}>
                            {
                                productWeight
                                    ?
                                    <Fragment>{`${productWeight || 'NA'} ${weightType || 'NA'}`}</Fragment>
                                    :
                                    'NA'
                            }
                        </div>
                    </div>
                }


                {
                    userSettings?.pro_container_20 &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                            Container Loadability 20 ft
                        </div>

                        <div className={`value`}>
                            {
                                containerLoadability20Ft
                                    ?
                                    <Fragment>{`${containerLoadability20Ft || 'NA'}`}</Fragment>
                                    :
                                    'NA'
                            }
                        </div>
                    </div>
                }

                {
                     userSettings?.pro_container_20_loose &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                            Container Loadability 20 ft (Loose)
                        </div>

                        <div className={`value`}>
                            {
                                containerLoadability20FtLoose
                                    ?
                                    <Fragment>{`${containerLoadability20FtLoose || 'NA'}`}</Fragment>
                                    :
                                    'NA'
                            }
                        </div>
                    </div>
                }
                {
                    userSettings?.pro_container_40 &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                            Container Loadability 40 ft
                        </div>

                        <div className={`value`}>
                            {
                                containerLoadability40Ft
                                    ?
                                    <Fragment>{`${containerLoadability40Ft || 'NA'}`}</Fragment>
                                    :
                                    'NA'
                            }
                        </div>
                    </div>
                }
                {
                     userSettings?.pro_container_40_loose &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                            Container Loadability 40 ft (Loose)
                        </div>

                        <div className={`value`}>
                            {
                                containerLoadability40FtLoose
                                    ?
                                    <Fragment>{`${containerLoadability40FtLoose || 'NA'}`}</Fragment>
                                    :
                                    'NA'
                            }
                        </div>
                    </div>
                }
                {
                     userSettings?.pro_full_truck_load &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                        Full Truck load (Cases)
                        </div>

                        <div className={`value`}>
                            {
                                fullTruckLoad
                                    ?
                                    <Fragment>{`${fullTruckLoad || 'NA'}`}</Fragment>
                                    :
                                    'NA'
                            }
                        </div>
                    </div>
                }

            </div>

            <div className={`product-price-wrap`}>
                {
                    userSettings?.pro_priceitem &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                            Price Range:
                        </div>

                        <div className={`price`}>
                            {
                                priceValue || itemValue
                                    ?
                                    <Fragment>{`${priceCurrency || `NA `} ${priceValue || `NA`} - ${priceCurrency || `NA`} ${itemValue || `NA`}`}</Fragment>
                                    :
                                    'NA'
                            }
                        </div>
                    </div>
                }

                {
                    userSettings?.pro_priceitem &&
                    <div className={`wrap`}>
                        <div className={`label`}>
                            Margin:
                        </div>

                        <div className={`value`}>
                            {

                                !isNaN(marginfrom) || !isNaN(marginto)
                                    ?
                                    <Fragment>{`${!isNaN(marginto) && marginto ? marginto : 'NA'} - ${!isNaN(marginfrom) && marginfrom ? marginfrom + `%` : 'NA'}  `}</Fragment>
                                    :
                                    'NA'
                            }
                        </div>
                    </div>
                }

            </div>
        </Box >
    )
}

export default ProductView;