
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import { CONFIG } from '../../helpers/getApiConfig';

const getCatalogueContactList = async (catalogueID, search = ``, page = ``) => {

    const headers = await getApiHeader() || {};;

    if (!catalogueID) {
        return {
            error: true,
            message: 'brandId mandatory in getProductsList function'
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in getProductsList function' 
        }
    }

    const instance = axios.create();
    instance.defaults.timeout = CONFIG.timeout || 15000;

    let filtervariable = ""
    if (search) {
        filtervariable = `?search=${search}`
    }
    else if (page) {
        filtervariable = `?page=${page}`
    }
    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-contact/${catalogueID}${filtervariable}`, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default getCatalogueContactList;