
import getAppDataObject from "../../helpers/getAppDataObject";

const initialState = {
    app: getAppDataObject(),
    loading: false,
    error: false,
  };
  
  function appObjectReducer(state = initialState, action) {
    switch (action.type) {
      case "LOAD_APP_OBJECT":
        return { ...state, app: {}, error: false, loading: true };
      case "GET_APP_OBJECT":
        return state;
      case "SET_APP_OBJECT":
        return { ...state, app: action.payload, error: false, loading: false };
      case "ERROR_APP_OBJECT":
        return { ...state, app: {}, error: true, loading: false };
      default:
        return state;
    }
  }
  export default appObjectReducer;
  