
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import { CONFIG } from '../../helpers/getApiConfig';

const catalogueMultipleDelete = async (isCheck = []) => {
    
    const headers = await getApiHeader() || {};;

    if (!isCheck) {
        return {
            error: true,
            message: 'productids mandatory in multi delete function'
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in multi delete function' 
        }
    }

    const instance = axios.create();
    instance.defaults.timeout = CONFIG.timeout || 15000;

    let params = {};

    if (isCheck) params[`catalogueids`] = isCheck;

    return instance.delete(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/savedcatalogue/starredcatalogueMultidelete`, { data: params }, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default catalogueMultipleDelete;