import { Route, Navigate } from 'react-router-dom';

//Components related imports
import Main from "../../component/common/Buyer/Main";
import BuyerCatalogue from "../../component/common/Buyer/Catalogue";
import Profile from "../../component/common/Buyer/Profile";
import Onboarding from '../../component/common/Buyer/Onboarding';
import EmailVerification from "../../component/common/Buyer/EmailVerification";
//ProtectedRoute routes
import ProtectedRoute from "../protectedRoute";

const buyer = [
   <Route path="/" element={<Main />} key="Main">
   
   <Route
      path="buyer/email_verification"
      element={<EmailVerification route={`emailVerification`} />}
      key="EmailVerification"
    />

   <Route
      element={<ProtectedRoute />}
    >
    <Route
        path="buyer/catalogue"
        element={<BuyerCatalogue route={`buyerCatalouge`} />}
        key="OnBoarding"
      />,
       <Route
        path="buyer/profile"
        element={<Profile route={`buyerProfile`} />}
        key="OnBoarding"
      />,
      <Route
        path="buyer/onboarding"
        element={<Onboarding route={`buyerOnBoarding`} />}
        key="OnBoarding"
      />,
      </Route>
   </Route>,

   <Route
      path="*"
      element={<Navigate to="/login" replace route={`login`} />}
      key="Login"
   />,
   ];
   
export default buyer;