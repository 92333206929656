
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import { CONFIG } from '../../helpers/getApiConfig';

const getCatalogueProductList = async (brandId, searchValue = ``, page = 1, version = 1, brandFilter = [], categoryFilter = []) => {

    const headers = await getApiHeader() || {};;

    if (!brandId) {
        return {
            error: true,
            message: 'brandId mandatory in getCatalogueProductList function'
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in getCatalogueProductList function' 
        }
    }

    const instance = axios.create();
    instance.defaults.timeout = CONFIG.timeout || 15000;

    const params = {};
    if(searchValue) params['search'] = searchValue;
    if(page) params['page'] = page;
    if(brandFilter && brandFilter.length > 0) params['productbrand'] = brandFilter.join();
    if(categoryFilter && categoryFilter.length > 0) params['category'] = categoryFilter.join();
   
    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-brand-productlist${version === 2 ? '-updateversion' : ''}/${brandId}`,{params}, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default getCatalogueProductList;