const initialState = {
    interestsList: {},
    loading: true,
    error: false,
  };
  
  function interestListReducers(state = initialState, action) {
    switch (action.type) {
      case "LOAD_INTERESTS__LIST":
        return { ...state, interestsList: {}, error: false, loading: true };
      case "GET_INTERESTS__LIST":
        return state;
      case "SET_INTERESTS_LIST":
        return { ...state, interestsList: action.payload, error: false, loading: false };
      case "ERROR_INTERESTS__LIST":
        return { ...state, interestsList: {}, error: true, loading: false };
      default:
        return state;
    }
  }
  export default interestListReducers;
  