
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';


const BrandProfile = async (brandID = ``,countryCode =``, yearofestablish = ``, our_story = ``, our_mission = ``, our_vision = ``, facebook_link = ``, twitter_link = ``, instagram_link = ``, linkedin_link = ``, website_link = ``) => {

    const headers = await getApiHeader() || {};;

    if(!brandID) {
        return {
            error: true,
            message: 'brandId mandatory in BrandProfile function' 
        }
    }
    
    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in BrandProfile function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    let params = {}

    var formData = new FormData();

    // if(countryCode) formData.append("country", countryCode);
    if (countryCode) {
        if (countryCode === "none") {
            formData.append("country", ""); // Append null if countryCode is "none"
        } else {
            formData.append("country", countryCode); // Append countryCode otherwise
        }
    }
    
    if(yearofestablish) formData.append("yearofestablish", yearofestablish);
    formData.append("our_story", our_story);
    formData.append("our_mission", our_mission);
    formData.append("our_vision", our_vision);
    formData.append("facebook_link", facebook_link);
    formData.append("twitter_link", twitter_link);
    formData.append("instagram_link", instagram_link);
    formData.append("linkedin_link", linkedin_link);
    formData.append("website_link", website_link);

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-profile/${brandID}`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default BrandProfile;