const initialState = {
    onBoarding: {},
    loading: true,
    error: false,
  };
  
  function onBoardingReducer(state = initialState, action) {
    switch (action.type) {
      case "LOAD_ONBOARDING_OBJECT":
        return { ...state, onBoarding: {}, error: false, loading: true };
      case "GET_ONBOARDING_OBJECT":
        return state;
      case "SET_ONBOARDING_OBJECT":
        return { ...state, onBoarding: action.payload, error: false, loading: false };
      case "ERROR_ONBOARDING_OBJECT":
        return { ...state, onBoarding: {}, error: true, loading: false };
      default:
        return state;
    }
  }
  export default onBoardingReducer;
  