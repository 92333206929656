// React related imports
import React, { useState, useEffect } from "react";

// mui or UI related imports

import Box from "@mui/material/Box";

//Redux related imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Style related imports
import { Icon } from "@mui/material";
import { Icons } from "../../../common/Icons"


// AlertType values can be 1.success, 2.information, 3.error
// Onclose function notify parent component to hide error message
// Message will be shown in alert once we get it from parent component, by default value is Invalid information. Please try again
// alertDisappearTime is used to hide or close alert automatically after particular amount of time, by default value is 5000 milliseconds

const AlertMessage = (props) => {
  const { message = "Invalid information. Please try again.", alertType, alertDisappearTime = 3000, onClose } = props;
  const [isAlertShow, setAlertShow] = useState(props.isAlertShow || false);

  useEffect(() => {
      setTimeout(() =>{
        setAlertShow(false);
        if(onClose) onClose(false);
      },alertDisappearTime)
  })
  return (
    <Box
      className={`alert-message-component-wrap ${
        isAlertShow ? `` : `hide-alert`
      } site-wrap`}
    >
      <Box className={`message-wrapper ${alertType}`}>
        <div className={`message-text`}>{message}</div>

        <div
          className={`close-icon`}
          onClick={() => {
            setAlertShow(false);
            if(onClose) onClose(false);
          }}
        >
          <Icons iconName="icon-close" />
        </div>
      </Box>
    </Box>
  );
};

//map state
const mapStateToProps = (state) => {
  return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);
