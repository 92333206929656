
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const buyerOnboardingFirstStep = async (processType = 1, companyName = '', businessType = '', yearOfEstablish = ``, companyWebsite = ``) => {

    const headers = await getApiHeader() || {};;

    if(!processType) {
        return {
            error: true,
            message: 'processType mandatory in buyerOnboardingFirstStep function' 
        }
    }

    if(!companyName) {
        return {
            error: true,
            message: 'companyName mandatory in buyerOnboardingFirstStep function' 
        }
    }

    if(!businessType) {
        return {
            error: true,
            message: 'businessType mandatory in buyerOnboardingFirstStep function' 
        }
    }

    if(!yearOfEstablish) {
        return {
            error: true,
            message: 'yearOfEstablish mandatory in buyerOnboardingFirstStep function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in buyerOnboardingFirstStep function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();
    if(processType) formData.append("processtype", processType);
    if(companyName) formData.append("company_name", companyName);
    if(businessType) formData.append("businesstype", businessType);
    if(yearOfEstablish) formData.append("yoe", yearOfEstablish);
    if(companyWebsite) formData.append("website_url", companyWebsite);

    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/registerOnboardingBuyer`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default buyerOnboardingFirstStep;