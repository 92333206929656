import React, { useState, useEffect, Fragment } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

//Components related imports
import Loader from "../../../Loader";
import AlertMessage from "../../../AlertMessage";

//Helpers function related imports
import getDefaultCountry from '../../../../../helpers/getDefaultCountry';

//Api related imports
import getCertficationList from '../../../../../apis/getCertficationList';
import productUpdate from '../../../../../apis/Supplier/productUpdate';

//Icons related imports
import EditImageIcon from "../../../../../icons/svgs/editImageIcon";

const OtherCertificationUpload = (props) => {

    const { data, 
            certificationList, 
            onOtherCoutryObjectChange, 
            selectedOtherCountryObject, 
            onRemoveOtherCertification, 
            countryList, 
            isMobile 
        } = props;

    const [selectedCertification, setSelectedCertification] = useState([]),
        [selectedCertificationObject, setSelectedCertificationObject] = useState([]),
        [countryId, setCountryId] = useState(``),
        [countryName, setCountryName] = useState(``),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``);


    const theme = useTheme();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    useEffect(() => {

        if (selectedCertification && selectedCertification.length > 0) {
            let certificationId = ``, certificationName = ``;
            if (selectedCertification && selectedCertification.length > 0) {
                selectedCertification.map((certification) => {
                    if (certificationList && certificationList.length > 0) {
                        certificationList.map((list) => {
                            if (certification === list?.name) {
                                certificationId = list?.certification_type_id;
                                certificationName = list?.name;
                            }
                        })
                    }
                })
            }

            if (!elementExist(certificationId)) {
                setSelectedCertificationObject((selectedCertificationObject) => [...selectedCertificationObject, {
                    "country_id": data?.country_id || countryId || ``,
                    "certificationtype_id": certificationId || ``,
                    "certification": data?.certification || "",
                    "certificationName": certificationName || ``,
                    "countryName": data?.name || countryName || ``
                }])
            }

            if (onOtherCoutryObjectChange) {
                onOtherCoutryObjectChange({
                    "country_id": data?.country_id || countryId || ``,
                    "certificationtype_id": certificationId,
                    "certification": data?.certification || "",
                    "certificationName": certificationName || ``,
                    "countryName": data?.name || countryName || ``
                }, false)
            }

        }

    }, [selectedCertification])

    useEffect(() => {
        if (countryList && countryList.length > 0) {
            countryList.map((country) => {
                if ((data === country.name)) {
                    setCountryId(country?.country_id);
                    setCountryName(country?.name)
                }
            })
        }
    }, [data, countryList])

    useEffect(() => {

        if (selectedCertificationObject.length === 0 && selectedCertification.length === 0) {
            let tempArray = selectedCertification;
            let tempDataArray = selectedCertificationObject;
            if (selectedOtherCountryObject && selectedOtherCountryObject.length > 0) {
                selectedOtherCountryObject.map((value) => {
                    if ((value?.certificationName && data?.certificationtype_id === value?.certificationtype_id) || (value?.certificationName && value?.countryName === data)) {
                        tempArray.push(value?.certificationName);
                        tempDataArray.push(value);
                        setCountryId(value?.country_id);
                        setCountryName(value?.countryName)
                    }
                })

            }

            setSelectedCertification(tempArray);
            setSelectedCertificationObject(tempDataArray);
        }

    }, [selectedOtherCountryObject])

    useEffect(() => {

        if (selectedCertification.length < selectedOtherCountryObject.length) {
            let tempArray = [];
            if (selectedOtherCountryObject && selectedOtherCountryObject.length > 0) {
                selectedOtherCountryObject.map((certificate) => {
                    if (selectedCertification && selectedCertification.length > 0) {
                        selectedCertification.map((value) => {
                            if (value === certificate?.certificationName && data === certificate?.countryName) {
                                tempArray.push(certificate);
                            }
                        })
                    }
                })
            }

            const removedElement = selectedOtherCountryObject.filter(x => !tempArray.includes(x));

            if(tempArray && tempArray.length > 0) setSelectedCertificationObject(tempArray);

            if (removedElement && removedElement.length) {
                removedElement.map((data) => {
                    if (onRemoveOtherCertification) {
                        onRemoveOtherCertification(data)
                    }
                })
            }
        }
    }, [selectedCertification])
    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const onCertificationChange = (event) => {
        const {
            target: { value },
        } = event;

        setSelectedCertification(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

    }

    const showUploadImage = file => new Promise((resolve, reject) => {

        if ([`image/jpeg`, `image/png`, `image/gif`, `application/pdf`].indexOf(file[`type`]) === -1) {
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage(`File extension not supported!`);
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (event) => {
            resolve({
                "file": file,
                "image": event.target.result,
                "fileType": [`application/pdf`].indexOf(file[`type`]) !== -1 ? 'pdf' : 'image'
            })
        }
        reader.onerror = (error) => {
            reject(error);
        }
    });

    const onCertificateChange = async (files, certificationId, countryId) => {
        let tempImage = ``;
        for (const file of files) {
            tempImage = await showUploadImage(file);
            document.getElementById(`certification-img-upload-${certificationId}-${countryId}`).value = null;
            if (elementExist(certificationId)) {

                setSelectedCertificationObject(
                    selectedCertificationObject.map((item) => {
                        return item?.certificationtype_id === certificationId && item?.country_id === countryId ? {
                            "country_id": item?.country_id,
                            "certificationtype_id": item?.certificationtype_id,
                            "certification": tempImage,
                            "certificationName": item?.certificationName,
                            "countryName": item?.countryName || ``
                        } : item;
                    })
                );
            }

            if (selectedCertificationObject && selectedCertificationObject.length > 0) {
                selectedCertificationObject.map((item) => {
                    if (item?.certificationtype_id === certificationId && item?.country_id === countryId) {
                        if (onOtherCoutryObjectChange) {
                            onOtherCoutryObjectChange({
                                "country_id": item?.country_id,
                                "certificationtype_id": item?.certificationtype_id,
                                "certification": tempImage,
                                "certificationName": item?.certificationName,
                                "countryName": item?.countryName || ``
                            }, false)
                        }
                    }
                })
            }
        }
    }

    const elementExist = (value) => {
        return selectedCertificationObject.some(function (element) {
            return element?.certificationtype_id === value;
        });
    }

    const onCertificationRemove = (certificationId, countryId) => {

        if (selectedCertificationObject && selectedCertificationObject.length > 0) {
            selectedCertificationObject.map((item) => {
                if (item?.certificationtype_id === certificationId && item?.country_id === countryId) {
                    if (onOtherCoutryObjectChange) {
                        onOtherCoutryObjectChange({
                            "country_id": item?.country_id,
                            "certificationtype_id": item?.certificationtype_id,
                            "certification": ``,
                            "certificationName": item?.certificationName,
                            "countryName": item?.countryName || ``
                        }, true)
                    }
                }
            })
        }


        setSelectedCertificationObject(
            selectedCertificationObject.map((item) => {
                return item?.certificationtype_id === certificationId && item?.country_id === countryId ? {
                    "country_id": item?.country_id || ``,
                    "certificationtype_id": item?.certificationtype_id,
                    "certification": ``,
                    "certificationName": item?.certificationName,
                    "countryName": item?.countryName || ``
                } : item;
            })
        );
    }

    const isElementDisabled = (certificateId) => {
        return selectedCertificationObject.some(function (element) {
            return element?.certificationtype_id === certificateId;
        });
    }


    const onCertificateDelete = (value) => {

        let dataToRemove, tempArray = [];
        if (selectedCertificationObject && selectedCertificationObject.length > 0) {
            selectedCertificationObject.map((data) => {
                if (data.certificationName === value) {
                    dataToRemove = data;
                    if (onRemoveOtherCertification) {
                        onRemoveOtherCertification(dataToRemove)
                    }
                } else {

                    if (data?.country_id && data?.certificationtype_id) {
                        tempArray.push(data)
                    }
                }
            })
        }

        setSelectedCertificationObject(tempArray);
        setSelectedCertification(selectedCertification.filter(item => item !== value))

    }

    const onEditIconClick = (id) => {
        if (document.getElementById(id)) {
            document.getElementById(id).click();
        }
    }

    const onImageUploadMouseEnter = (id) => {
        if (document.getElementById(id) && document.getElementById(id).getElementsByClassName(`image-upload-icons`)[0]) {
            document.getElementById(id).getElementsByClassName(`image-upload-icons`)[0].classList.remove(`display-none`);
        }
    }

    const onImageUploadMouseLeave = (id) => {
        if (document.getElementById(id) && document.getElementById(id).getElementsByClassName(`image-upload-icons`)[0]) {
            document.getElementById(id).getElementsByClassName(`image-upload-icons`)[0].classList.add(`display-none`);
        }
    }
    return (
        <div className={`margin-bottom-28`}>
            <Typography className={`country-label margin-btm-15`}>{data?.name || data?.countryName || data}</Typography>

            <FormControl sx={{ m: 1, width: 300 }} className={`other-country-multiselect-wrap`}>
                <InputLabel id="demo-multiple-chip-label">Select Certification Type(s)</InputLabel>
                <Select
                    id="other-country-multiselect"
                    className={`other-country-multiselect other-certificate-multiselect`}
                    multiple
                    value={selectedCertification}
                    onChange={onCertificationChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Select Certification Type(s)" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5 }}>
                            {selected.map((value) => (
                                <Chip key={value}
                                    label={value}
                                    clickable
                                    deleteIcon={
                                        <HighlightOffIcon
                                            onMouseDown={(event) => event.stopPropagation()}
                                        />
                                    }
                                    onDelete={(e) => onCertificateDelete(value)}
                                />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {certificationList && certificationList.length > 0 && certificationList.map((distribution) => (
                        // <MenuItem
                        //     key={distribution[`name`]}
                        //     value={distribution[`name`]}
                        //     style={getStyles(distribution[`name`], selectedCertification, theme)}
                        //     disabled={isElementDisabled(distribution?.certification_type_id)}
                        // >
                        //     {distribution[`name`]}
                        // </MenuItem>

                        <MenuItem key={distribution[`name`]} value={distribution[`name`]} className={`multiselect-option-break`}>
                            <Checkbox checked={selectedCertification.indexOf(distribution[`name`]) > -1} />
                            <ListItemText primary={distribution[`name`]} />
                        </MenuItem>
                    ))}

                </Select>
            </FormControl>

            {
                selectedCertificationObject && selectedCertificationObject.length > 0 &&
                selectedCertificationObject.map((certificate, index) => {
                    return (
                        <Fragment>
                            {
                                certificate?.country_id && certificate?.certificationtype_id && certificate?.certificationName &&
                                <div className={`other-image-upload margin-bottom-28`} key={index}>
                                    <div className={`header`}>
                                        <div className={`text`}>{certificate?.certificationName || data?.certificationName}</div>
                                        {/* <div className={`remove-text`} onClick={() => { onCertificationRemove(certificate?.certificationtype_id, certificate?.country_id) }}>
                                            <CloseIcon />
                                        </div> */}
                                    </div>

                                    <div className={`image-upload`} id={`certification-wrap-${certificate?.certificationtype_id}-${certificate?.country_id}`} onMouseEnter={() => { onImageUploadMouseEnter(`certification-wrap-${certificate?.certificationtype_id}-${certificate?.country_id}`) }} onMouseLeave={() => { onImageUploadMouseLeave(`certification-wrap-${certificate?.certificationtype_id}-${certificate?.country_id}`) }}>
                                        {
                                            certificate?.certification?.image ?
                                                <div>
                                                    <div className={`image-upload-icons ${isMobile ? '' : 'display-none'}`}>
                                                        <span className={`edit-icon`} onClick={() => onEditIconClick(`certification-img-upload-${certificate?.certificationtype_id}-${certificate?.country_id}`)}>
                                                            <EditImageIcon />
                                                        </span>

                                                        <span className={`delete-icon`} onClick={() => { onCertificationRemove(certificate?.certificationtype_id, certificate?.country_id) }}>
                                                            <DeleteIcon />
                                                        </span>
                                                    </div>
                                                    {
                                                        certificate?.certification?.fileType === `pdf` || certificate?.certification?.image.indexOf(".pdf") !== -1
                                                            ?
                                                            <div>
                                                                <embed src={certificate?.certification?.image} className={`pdf-view`} />
                                                            </div>
                                                            :
                                                            <img src={certificate?.certification?.image} loading="lazy" />
                                                    }
                                                </div>
                                                :
                                                <div className={`text`} onClick={() => document.getElementById(`certification-img-upload-${certificate?.certificationtype_id}-${certificate?.country_id}`).click()}>
                                                    File Upload
                                                </div>
                                        }

                                    </div>
                                    <input
                                        className={`visibility-hidden position-absolute other-certificate-file-upload`}
                                        type="file"
                                        name={`certification-img-upload-${certificate?.certificationtype_id}-${certificate?.country_id}`}
                                        id={`certification-img-upload-${certificate?.certificationtype_id}-${certificate?.country_id}`}
                                        onChange={(event) => { onCertificateChange(event.target.files, certificate?.certificationtype_id, certificate?.country_id) }}
                                        accept="image/x-png,image/gif,image/jpeg,application/pdf" />
                                </div>
                            }
                        </Fragment>

                    )
                })
            }
        </div>
    )
}
const ProductCertification = (props) => {

    const { onSectionApiCallSuccess, appData } = props;
    const isMobile = appData?.app?.isMobile || false;
    const [productDetails, setProductDetails] = useState(props?.productDetails),
        [countryList, setCountryList] = useState(props?.countryList),
        [selectedCountry, setSelectedCountry] = useState(productDetails?.country || "none"),
        [certificateImage, setCetificationImage] = useState([]),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [otherCeriticateAvailable, setOtherCertificateAvailable] = useState('no'),
        [otherCertificationSelectedCountry, setOtherCerificationSelectedCountry] = useState([]),
        [selectedOtherCountryObject, setSelectedOtherCountryObject] = useState([]),
        [certificationList, setCertificationList] = useState([]),
        [otherCertificateObject, setOtherCertificateObject] = useState([]),
        [isLoading, setIsLoading] = useState(false),
        [certificationImageChange, setCertificationImageChange] = useState(false),
        [isDataAvailableFromApi, setIsDataAvailableFromApi] = useState(false),
        [isUploadIconShow, SetUploadIconShow] = useState(isMobile ? true : false);

    const theme = useTheme();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    useEffect(() => {

        let tempArray = [], tempOtherCertificationArray = [], tempOtherCountryArray = [];

        setProductDetails(props?.productDetails || {});
        setSelectedCountry(props?.productDetails?.country || "none");

        if (props?.productDetails?.products_certification && props?.productDetails?.products_certification.length > 0) {
            props?.productDetails?.products_certification.map((value) => {
                if (value?.origin_certification) {
                    tempArray.push({
                        "image": value?.origin_certification,
                        "fileType": value?.origin_certification.indexOf(".pdf") != -1 ? 'pdf' : `image`
                    })
                }

            });
        }

        if (props?.productDetails?.products_other_certification && props?.productDetails?.products_other_certification.length > 0) {
            props?.productDetails?.products_other_certification.map((value) => {
                tempOtherCertificationArray.push({
                    "country_id": value?.country,
                    "certificationtype_id": value?.certificationtype,
                    "certification": { "image": value?.certification },
                    "certificationName": value?.certificationtypename,
                    "countryName": value?.country_name || ``
                })

                tempOtherCountryArray.push(value?.country_name)
            });
        }

        tempOtherCountryArray = [... new Set(tempOtherCountryArray)]
        setCetificationImage(tempArray);
        setSelectedOtherCountryObject(tempOtherCertificationArray);
        setOtherCertificateObject(tempOtherCertificationArray);
        setOtherCerificationSelectedCountry(tempOtherCountryArray);
        setOtherCertificateAvailable(props?.productDetails?.products_certification?.[0]?.other_certification ? `yes` : `no`);
    }, [props?.productDetails])

    useEffect(() => {
        setIsDataAvailableFromApi(props?.productDetails?.products_other_certification && props?.productDetails?.products_other_certification?.length > 0 ? true : false);
    }, [props?.productDetails?.products_other_certification]);

    useEffect(() => {
        if (otherCertificationSelectedCountry && otherCertificationSelectedCountry.length > 0) {
            otherCertificationSelectedCountry.map((selectedCountry) => {
                if (countryList && countryList.length > 0) {
                    countryList.map((list) => {
                        if (list?.name === selectedCountry) {
                            if (!isCountryDisabled(list?.country_id)) {
                                setSelectedOtherCountryObject(() => [...selectedOtherCountryObject, list])
                            }
                        }
                    })
                }
            })
        }
    }, [otherCertificationSelectedCountry]);

    useEffect(() => {
        setCountryList(props?.countryList);
    }, [props?.countryList])

    useEffect(() => {
        getCertficationList()
            .then((response) => {
                if (response?.status === 200 && response?.data?.status && response.data.data) {
                    setCertificationList(response.data.data);
                }
            })
            .catch((err) => {
                console.log("we have an error at getCertficationList", err);
            })
    }, [getCertficationList]);

    const onCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    const flagemojiToPNG = (flag, code) => {
        var countryCode = Array.from(flag, (codeUnit) => codeUnit.codePointAt()).map(char => String.fromCharCode(char - 127397).toLowerCase()).join('')
        return `https://flagcdn.com/24x18/${countryCode}.png`;
    }

    const showUploadImage = file => new Promise((resolve, reject) => {

        if ([`image/jpeg`, `image/png`, `image/gif`, `application/pdf`].indexOf(file[`type`]) === -1) {
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage(`File extension not supported!`);
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (event) => {
            resolve({
                "file": file,
                "image": event.target.result,
                "fileType": [`application/pdf`].indexOf(file[`type`]) !== -1 ? 'pdf' : 'image'
            })
        }
        reader.onerror = (error) => {
            reject(error);
        }
    });

    const onCertificateChange = async (files) => {
        let tempArray = [];
        if (certificateImage.length + files.length > 3) {
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage(`Upload upto 3 images`);
        } else {
            for (const file of files) {
                tempArray.push(await showUploadImage(file));
                setCetificationImage(tempArray);
                setCertificationImageChange(tempArray && tempArray.length > 0 ? true : false);
                if (document.getElementById(`certificate-upload`)) {
                    document.getElementById(`certificate-upload`).value = null;
                }
            }
        }
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onOtherCertificationChange = (event) => {
        setOtherCertificateAvailable(event.target.value);
    };

    const onOtherCertificationCountryChange = (event) => {
        const {
            target: { value },
        } = event;
        setOtherCerificationSelectedCountry(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const isCountryDisabled = (countryId) => {
        return selectedOtherCountryObject.some(function (element) {
            return element?.country_id === countryId;
        });
    }

    const onOtherCountryDelete = (countryName) => {
        setSelectedOtherCountryObject(selectedOtherCountryObject.filter(item => item?.name !== countryName))
        setOtherCerificationSelectedCountry(otherCertificationSelectedCountry.filter(item => item !== countryName))

        if (countryList && countryList.length > 0) {
            countryList.map((country) => {
                if (country?.name === countryName) {
                    setOtherCertificateObject(otherCertificateObject.filter(item => item?.country_id !== country?.country_id))
                }
            })
        }
    }

    const onOtherCoutryObjectChange = (value, isCertificationDelete) => {

        if (ifOtherCoutryObjectExists(value)) {
            setOtherCertificateObject(
                otherCertificateObject.map((item) => {
                    return item?.certificationtype_id === value?.certificationtype_id && item?.country_id === value?.country_id ? {
                        "country_id": item?.country_id,
                        "certificationtype_id": item?.certificationtype_id,
                        "certification": isCertificationDelete ? '' : value?.certification || item?.certification,
                        "certificationName": item?.certificationName,
                        "countryName": item?.countryName || ``
                    } : item;
                })
            );
        } else {
            if ((value?.country_id !== `` || value?.country_id !== undefined) && value?.certificationtype_id !== `` && value?.certificationName !== ``) {
                setOtherCertificateObject([...otherCertificateObject, value]);
            }
        }

    }

    const ifOtherCoutryObjectExists = (value) => {
        return otherCertificateObject.some(function (element) {
            return element?.country_id === value?.country_id && element?.certificationtype_id === value?.certificationtype_id;
        });
    }

    const onRemoveOtherCertification = (data) => {

        const country_id = data?.country_id, certificationtype_id = data?.certificationtype_id;

        let tempArray = [];
        if (otherCertificateObject && otherCertificateObject.length > 0) {
            otherCertificateObject.map((data) => {

                if (!(data?.country_id === country_id && data?.certificationtype_id === certificationtype_id)) {
                    tempArray.push(data)
                }
            })
        }

        setOtherCertificateObject(tempArray)
    }

    const onFormSubmit = (event) => {
        event.preventDefault();

        let tempArray = [], tempOtherCertificationArray = [], tempCountryList = [];

        if (productDetails?.product_usps && productDetails?.product_usps.length > 0) {
            productDetails?.product_usps.map((data) => {
                tempArray.push(data[`name`]);
            })
        }

        if (productDetails?.products_sold_countries && productDetails?.products_sold_countries.length > 0) {
            productDetails?.products_sold_countries.map((soldCountry) => {
                tempCountryList.push(soldCountry?.country);
            })
        }
        if (otherCertificateObject && otherCertificateObject.length > 0) {
            otherCertificateObject.map((item) => {
                if (item?.country_id && item?.certificationtype_id) {
                    tempOtherCertificationArray.push({
                        "country_id": item?.country_id || ``,
                        "certificationtype_id": item?.certificationtype_id || ``,
                        "certification": item?.certification?.image || ``
                    })
                }

            })
        }

        let certificationObject = {
            "productId": productDetails?.products_id,
            "usps": tempArray.length > 0 ? tempArray.join() : '',
            "country": selectedCountry,
            "certification": {
                "origin_certification": certificateImage && certificateImage?.length > 0 && certificateImage?.[0]?.[`image`] ? certificateImage?.[0]?.[`image`] : '',
                // { origin_certification: certificationImageChange && certificateImage && certificateImage?.length > 0 && certificateImage?.[0]?.[`image`] ? certificateImage?.[0]?.[`image`] : '' }),
                // "origin_certification": certificateImage && certificateImage?.length > 0 && certificateImage?.[0]?.[`image`] ? certificateImage?.[0]?.[`image`] : '',
                "other_certification": otherCeriticateAvailable === 'yes' ? "True" : "False"
            },
            "other_certification": tempOtherCertificationArray,
            "countriesSoldList": tempCountryList.join(),
            "productIngredients" : productDetails?.products_ingredients?.[0]?.name || ``
        }

        setIsLoading(true);
        productUpdate(certificationObject)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response?.data?.message);
                    setTimeout(() => {
                        if (onSectionApiCallSuccess) {
                            onSectionApiCallSuccess(true);
                        }
                    }, 300);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                    setTimeout(() => {
                        if (onSectionApiCallSuccess) {
                            onSectionApiCallSuccess(false);
                        }
                    }, 300);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setTimeout(() => {
                    if (onSectionApiCallSuccess) {
                        onSectionApiCallSuccess(false);
                    }
                }, 300);
                console.log("We have error at productUpdate", error);
            })
    }


    return (
        <Box className={`product-certification-wrapper`}>
            {isLoading && <Loader />}
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }

            <div className={`heading`}>
                Certifications & Approvals
            </div>

            <form className={`certification-form form-input-control`} onSubmit={onFormSubmit}>

                <div className={`certification-wrap`}>
                    <Box className={`form-control margin-bottom-28 country-wrapper`}>
                        <Typography className={`label margin-btm-15`}>Country of Origin</Typography>
                        <FormControl sx={{ minWidth: 200 }}>
                            <Select
                                labelId="country-code-select-label"
                                id="country-select"
                                value={selectedCountry}
                                label="Country"
                                onChange={onCountryChange}
                            >
                                <MenuItem value="none">
                                    Select country of origin
                                </MenuItem>
                                {countryList &&
                                    countryList.length > 0 &&
                                    countryList.map((country, index) => {
                                        return (
                                            <MenuItem
                                                key={index}
                                                value={country[`country_id`]}
                                            >
                                                {
                                                    <div className="flag-wrapper flex flex-items-center">
                                                        {<img src={flagemojiToPNG(country[`emoji`])} style={{ "marginRight": "5px", "height": "20px", "width": "20px", "objectFit": "contain" }} loading="lazy" />}
                                                        {country[`name`]}
                                                    </div>
                                                }
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box className={`certification-of-origin-image-upload-wrap`}>
                        <Typography className={`label margin-btm-15`}>Upload Certificate of Origin (Optional)</Typography>
                        <div className={`image-upload-wrap`} onMouseEnter={() => { SetUploadIconShow(true) }} onMouseLeave={() => SetUploadIconShow(false)}>
                            {

                                certificateImage && certificateImage.length > 0
                                    ?
                                    certificateImage.map((img, index) => {
                                        return (
                                            <div key={index}>
                                                {
                                                    isUploadIconShow &&
                                                    <div className={`image-upload-icons`}>
                                                        <span className={`edit-icon`} onClick={() => { document.getElementById('certificate-upload').click() }}>
                                                            <EditImageIcon />
                                                        </span>

                                                        <span className={`delete-icon`} onClick={() => { setCetificationImage([]) }}>
                                                            <DeleteIcon />
                                                        </span>
                                                    </div>
                                                }
                                                {
                                                    img?.fileType === `pdf`
                                                        ?
                                                        <embed src={img?.image} className={`pdf-view`}></embed>
                                                        :
                                                        <img src={img?.image} loading="lazy" />
                                                }
                                            </div>

                                        )
                                    })

                                    :
                                    <div className={`text`} onClick={() => { document.getElementById('certificate-upload').click() }}>
                                        File Upload
                                    </div>
                            }

                            {/* {
                                    certificate?.certification?.image ?
                                        <img src={certificate?.certification?.image} loading="lazy" />
                                        :
                                        <div className={`text`}>File Upload</div>
                                } */}

                            <input className={`visibility-hidden position-absolute`} type="file" name="certificate-upload" id="certificate-upload" onChange={(event) => { onCertificateChange(event.target.files) }} accept="image/x-png,image/gif,image/jpeg,application/pdf" />
                        </div>
                    </Box>
                </div>

                <div className={`other-certification-wrap for-yes-no`}>
                    <Box className={`form-control margin-bottom-28 certi-form-ctrl`}>
                        <Typography className={`label margin-btm-15`}>Do you have other certifications & Approvals? (Optional)</Typography>
                        <FormControl sx={{ m: 1, minWidth: 120 }} >
                            <FormControl>
                                <RadioGroup
                                    name="controlled-radio-buttons-group"
                                    value={otherCeriticateAvailable}
                                    onChange={onOtherCertificationChange}
                                    className={`product-availablity-wrapper`}
                                    row
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </FormControl>
                    </Box>

                    {
                        otherCeriticateAvailable === `yes` &&
                        <Fragment>
                            <FormControl sx={{ m: 1, width: 300 }} className={`other-country-multiselect-wrap`}>
                                <InputLabel id="demo-multiple-chip-label">Select country</InputLabel>
                                {/* <Select
                                    id="other-country-multiselect"
                                    className={`other-country-multiselect`}
                                    multiple
                                    value={otherCertificationSelectedCountry}
                                    onChange={onOtherCertificationCountryChange}
                                    input={<OutlinedInput id="select-multiple-chip" label={`Select country`} />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5 }}>
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={value}
                                                    deleteIcon={
                                                        <HighlightOffIcon
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                    }
                                                    onDelete={(e) => onOtherCountryDelete(value)}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {countryList && countryList.length > 0 && countryList.map((distribution) => (
                                        <MenuItem
                                            key={distribution[`name`]}
                                            value={distribution[`name`]}
                                            style={getStyles(distribution[`name`], otherCertificationSelectedCountry, theme)}
                                            disabled={isCountryDisabled(distribution?.[`country_id`])}

                                        >
                                            {distribution[`name`]}
                                        </MenuItem>
                                    ))}
                                </Select> */}

                                <Select
                                    labelId="other-country-multiselect"
                                    id="other-country-multiselect"
                                    className={`other-country-multiselect`}
                                    multiple
                                    value={otherCertificationSelectedCountry}
                                    onChange={(event) => { onOtherCertificationCountryChange(event) }}
                                    input={<OutlinedInput label="Select country" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5 }}>
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={value}
                                                    deleteIcon={
                                                        <HighlightOffIcon
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                    }
                                                    onDelete={(e) => onOtherCountryDelete(value)}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {countryList && countryList.length > 0 && countryList.map((country) => (
                                        <MenuItem key={country[`name`]} value={country[`name`]}>
                                            <Checkbox checked={otherCertificationSelectedCountry.indexOf(country[`name`]) > -1} />
                                            <ListItemText primary={country[`name`]} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <div className={`other-certification--upload-wrap`}>

                                {
                                    isDataAvailableFromApi ?
                                        otherCertificationSelectedCountry && otherCertificationSelectedCountry.length > 0 &&
                                        otherCertificationSelectedCountry.map((country, index) => {
                                            return (
                                                <div key={index}>
                                                    <OtherCertificationUpload
                                                        data={country}
                                                        certificationList={certificationList}
                                                        onOtherCoutryObjectChange={onOtherCoutryObjectChange}
                                                        selectedOtherCountryObject={selectedOtherCountryObject}
                                                        onRemoveOtherCertification={onRemoveOtherCertification}
                                                        countryList={countryList}
                                                        isMobile={isMobile}
                                                    />
                                                </div>
                                            )
                                        })
                                        :
                                        selectedOtherCountryObject && selectedOtherCountryObject.length > 0 &&
                                        selectedOtherCountryObject.map((country, index) => {
                                            return (
                                                <div key={index}>
                                                    <OtherCertificationUpload
                                                        data={country}
                                                        certificationList={certificationList}
                                                        onOtherCoutryObjectChange={onOtherCoutryObjectChange}
                                                        selectedOtherCountryObject={selectedOtherCountryObject}
                                                        onRemoveOtherCertification={onRemoveOtherCertification}
                                                        countryList={countryList}
                                                        isMobile={isMobile}
                                                    />
                                                </div>
                                            )
                                        })
                                }
                            </div>

                        </Fragment>

                    }
                </div>

                <div className={`button-wrapper`}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        id="product-certification-submit-btn"
                        className={`w-100 btn-primary-after-login visibility-hidden display-none`}
                    >
                        Sign up
                    </Button>
                </div>
            </form>
        </Box>
    )
}

export default ProductCertification;