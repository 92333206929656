import React, { useEffect, useState, Fragment } from 'react';

//Components related imports
import Loader from '../Loader';

const Support = (props) => {
    
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.setAttribute("id","hs-script-loader")
        script.setAttribute("defer", "defer");
        script.setAttribute("async", "true");
        script.addEventListener('load', () => {
            setIsLoading(false);
        });
        script.src = '//js-na1.hs-scripts.com/9031811.js';
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    }, [])

    return (
        <Fragment>
            {isLoading && <Loader />}
            {/* <div>Support</div> */}
        </Fragment>
    )
}

export default Support;