
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import { CONFIG } from '../../helpers/getApiConfig';

const updateProductpagesettings = async (params) => {

    const headers = await getApiHeader() || {};;

    if (!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in updatePorductSetting function'
        }
    }

    const { pro_detail_page, tech_spec_page, cert_and_apvl_page, distribution_page, marketing_page} = params;

    const instance = axios.create();
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();

    if (pro_detail_page) formData.append("pro_detail_page", pro_detail_page);
    if (tech_spec_page) formData.append("tech_spec_page", tech_spec_page);
    if (cert_and_apvl_page) formData.append("cert_and_apvl_page", cert_and_apvl_page);
    if (distribution_page) formData.append("distribution_page", distribution_page);
    if (marketing_page) formData.append("marketing_page", marketing_page);


    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/settings/product-page-setting`, formData, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default updateProductpagesettings;