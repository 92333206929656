import React from "react";

const editImageIcon = (props) => {
  const { name = "", size = "s", color = "#fff", className = "" } = props;

  return (
    <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17.3711" cy="17" r="16" fill="#3357FF" stroke="white" strokeWidth="2" />
      <g clipPath="url(#clip0_86_2924)">
        <path d="M11.0548 21.1525C10.6171 21.59 10.3712 22.1834 10.3711 22.8022V23.9998H11.5687C12.1875 23.9997 12.7809 23.7537 13.2183 23.3161L21.0018 15.5327L18.8382 13.3691L11.0548 21.1525Z" fill="white" />
        <path d="M23.8714 10.4993C23.7294 10.3571 23.5607 10.2443 23.375 10.1674C23.1894 10.0904 22.9904 10.0508 22.7894 10.0508C22.5884 10.0508 22.3893 10.0904 22.2037 10.1674C22.018 10.2443 21.8493 10.3571 21.7073 10.4993L19.6621 12.5451L21.8257 14.7087L23.8714 12.6635C24.0136 12.5214 24.1264 12.3528 24.2034 12.1671C24.2804 11.9814 24.32 11.7824 24.32 11.5814C24.32 11.3804 24.2804 11.1814 24.2034 10.9957C24.1264 10.8101 24.0136 10.6414 23.8714 10.4993Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_86_2924">
          <rect width="14" height="14" fill="white" transform="translate(10.3711 10)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default editImageIcon;
