
import axios from 'axios';

import getApiHeader from '../helpers/getApiHeader';

import {CONFIG} from '../helpers/getApiConfig';


const onBoardingThirdStep = async (processType = 3, userInterestsList = []) => {
    
    const headers = await getApiHeader() || {};;
    
    if(!processType) {
        return {
            error: true,
            message: 'processType mandatory in onBoardingThirdStep function' 
        }
    }

    if(!userInterestsList) {
        return {
            error: true,
            message: 'userInterestsList mandatory in onBoardingThirdStep function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in onBoardingFirstStep function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    let params = {}

    if(processType) params[`processtype`] = processType;
    if(userInterestsList) params[`user_interest`] = userInterestsList;

    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/registerOnboarding`,params, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default onBoardingThirdStep;