const setCountryList = (countryObj) => {
    return {
        type: "SET_COUNTRY_LIST",
        payload: countryObj
    }
}

const getCountryList = () => {
    return {
        type: "GET_COUNTRY_LIST"
    }
}


export default {
    setCountryList,
    getCountryList
}