//import React from "react";

// React related imports
import React, { useState, useEffect } from "react";

// mui or UI related imports
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

//Other imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// React route related imports
import { useNavigate, Link } from "react-router-dom";

//Redux related imports
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import useAction from "../../../store/action/userAction";
import userDetailAction from "../../../store/action/userDetailAction";

//Components related imports
import AlertMessage from "../AlertMessage";
import Loader from "../Loader";

//Apis related imports
import ourTeamPasswordSet from "../../../apis/OurTeam/ourTeamPasswordSet";
import getUserDetail from "../../../apis/getUserDetail";

//Icons related imports
import Greentick from "../../../icons/svgs/greentick";
import Graytick from "../../../icons/svgs/graytick";
import Passwordsuccess from "../../../icons/svgs/passwordSuccess";
import { Icons } from "../../../common/Icons";

// Helpers related imports
import createCookie from "../../../helpers/createCookie";

const schema = yup
    .object({
        password: yup
            .string()
            .trim()
            .required("No password provided.")
            .matches(/([0-9])/, "Password should contain minimum 8 characters with at least one alphabet, one number, and one special character (!@#()$%&)*")
            .matches(/([a-zA-Z])/, "Password should contain minimum 8 characters with at least one alphabet, one number, and one special character (!@#()$%&)*")
            .matches(/([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/, "Password should contain minimum 8 characters with at least one alphabet, one number, and one special character (!@#()$%&)*")
            .min(8, "Password is too short - should be 8 chars minimum."),
        confirmPassword: yup
            .string()
            .trim()
            .required("No password provided.")
            .matches(/([0-9])/, "Password should contain minimum 8 characters with at least one alphabet, one number, and one special character (!@#()$%&)*")
            .matches(/([a-zA-Z])/, "Password should contain minimum 8 characters with at least one alphabet, one number, and one special character (!@#()$%&)*")
            .matches(/([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/, "Password should contain minimum 8 characters with at least one alphabet, one number, and one special character (!@#()$%&)*")
            .min(8, "Password is too short - should be 8 chars minimum."),
    })
    .required();

const InviteResetPassword = (props) => {
    const ResetUserEmail = props?.passwordResetData?.passwordResetMain?.email || ``;
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    const navigate = useNavigate(), dispatch = useDispatch()

    const { app } = props, [user, setUser] = useState({ password: "" }),
        [isPasswordVisible, setPasswordVisible] = useState(false),
        [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isLoading, setIsLoading] = useState(false),
        [passwordText, setPasswordText] = useState(``),
        [confirmPasswordText, setConfirmPasswordText] = useState(``),
        [showPasswordValidation, setShowPasswordValidation] = useState(false),
        [showConfirmPasswordValidation, setShowConfirmPasswordValidation] = useState(false),
        [isMininumPasswordRegexValid, setIsMininumPasswordRegexValid] = useState(false),
        [isPasswordRegexValid, setIsPasswordRegexValid] = useState(false),
        [checkPasswordRegex, setCheckPasswordRegex] = useState(false),
        [isPasswordResetSuccessfully, setIsPasswordResetSuccessfully] = useState(false),
        [isPwdMatched, setPwdMatched] = useState(false);


    const minimumLengthRegex = /^(?=.{8,}$)/;
    const passWordRegex = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<]).*$/;
    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(user);
    }, [user]);

    useEffect(() => {
        setIsMininumPasswordRegexValid(minimumLengthRegex.test(passwordText) ? true : false);
        setIsPasswordRegexValid(passWordRegex.test(passwordText) ? true : false)
    }, [passwordText])

    useEffect(() => {
        if (confirmPasswordText === passwordText) {
            setPwdMatched(true);
        } else {
            setPwdMatched(false);
        }
    }, [confirmPasswordText])
    const onSubmit = (data) => {
        setIsLoading(true);
        ourTeamPasswordSet(data?.password, data?.confirmPassword)
            .then((response) => {
                if (response?.status === 200 && response?.data?.status) {
                    if (response?.data?.step_level === `dashboard`) {
                        navigate(`/myproducts`);
                    }

                } else {
                    if(response?.error) {
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        setAlertMessage("Authentication is required");
                    } else {
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        setAlertMessage(response?.response?.data?.detail);
                    }
                }
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(`we have error at ourTeamPasswordSet`, err);
            })

    };

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    }

    return (
        <Box className={"invite-reset-password-confirm-component-wrap bg-modal-window"}>
            {isLoading && <Loader />}
            {
                isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }

            {
                !isPasswordResetSuccessfully
                    ?
                    <Box>
                        <Box>
                            <Typography align="center" variant="h1" className={`first-heading`}>
                                Enter new password
                            </Typography>
                            <Typography
                                textAlign={"center"}
                                variant="h2"
                                className={`second-heading`}
                            >
                                Set a password to access your Discoverrr account.
                            </Typography>
                        </Box>
                        <form className={`form-input-control`} onSubmit={handleSubmit(onSubmit)}>
                            <div className={`margin-bottom-28`}>
                                <div className={`flex flex-items-center flex-justify-content-space-between`}>
                                    <Typography className={`label`}>New Password</Typography>
                                    <Typography
                                        className={`label show-label`}
                                        onClick={() => setPasswordVisible(!isPasswordVisible)}
                                    >
                                        <span className="flex flex-items-center flex-justify-content-center gap-10" style={{ cursor: "pointer" }}>
                                            <Icons iconName={`${isPasswordVisible ? "icon-eye-off" : "icon-eye"}`} />
                                            <span className="login-password__hide-show">{`${isPasswordVisible ? "Hide" : "Show"}`}</span>
                                        </span>
                                    </Typography>
                                </div>

                                <div className={`password-wrapper ${showPasswordValidation ? "height-160" : ""}`}>
                                    <Box className={`form-control`}>
                                        <TextField
                                            type={`${isPasswordVisible ? "text" : "password"}`}
                                            id="password"
                                            name="password"
                                            placeholder={`Enter Password`}
                                            margin="dense"
                                            error={errors.password && !(isMininumPasswordRegexValid && isPasswordRegexValid) && checkPasswordRegex ? true : false}
                                            spellCheck={false}
                                            inputProps={{
                                                onClick: () => setShowPasswordValidation(true),
                                                onFocus: () => { setShowPasswordValidation(true); setCheckPasswordRegex(true) },
                                            }}
                                            {...register("password", {
                                                onChange: (event) => {
                                                    setPasswordText(event.target.value.trim());
                                                },
                                                onBlur: () => {
                                                    setShowPasswordValidation(false);
                                                    setCheckPasswordRegex(false);
                                                }
                                            })}
                                        />

                                        {
                                            <div className={`password-validation ${showPasswordValidation ? "show" : ""}`}>
                                                <p>
                                                    <span> {minimumLengthRegex.test(passwordText) ? <Greentick /> : <Graytick />} </span>
                                                    <span> 8 character minimum</span>
                                                </p>
                                                <p>
                                                    <span> {passWordRegex.test(passwordText) ? <Greentick /> : <Graytick />} </span>
                                                    <span> Includes at least 1 uppercase, 1 lowercase, 1 number and 1 special character</span>
                                                </p>
                                            </div>

                                        }
                                    </Box>
                                </div>
                            </div>

                            <div>
                                <div className={`flex flex-items-center flex-justify-content-space-between`}>
                                    <Typography className={`label`}>Confirm Password</Typography>
                                    <Typography
                                        className={`label show-label`}
                                        onClick={() => setConfirmPasswordVisible(!isConfirmPasswordVisible)}
                                    >
                                        <span className="flex flex-items-center flex-justify-content-center gap-10" style={{ cursor: "pointer" }}>
                                            <Icons iconName={`${isConfirmPasswordVisible ? "icon-eye-off" : "icon-eye"}`} />
                                            <span className="login-password__hide-show">{`${isConfirmPasswordVisible ? "Hide" : "Show"}`}</span>
                                        </span>
                                    </Typography>
                                </div>

                                <div className={`password-wrapper`}>
                                    <Box className={`form-control`}>
                                        <TextField
                                            type={`${isConfirmPasswordVisible ? "text" : "password"}`}
                                            id="password"
                                            name="password"
                                            placeholder={`Confirm Password`}
                                            margin="dense"
                                            spellCheck={false}
                                            // inputProps={{
                                            //     onClick: () => setShowPasswordValidation(true),
                                            //     onFocus: () => { setShowPasswordValidation(true); setCheckPasswordRegex(true) },
                                            // }}
                                            {...register("confirmPassword", {
                                                onChange: (event) => {
                                                    setConfirmPasswordText(event.target.value.trim());
                                                },
                                                // onBlur: () => {
                                                //     setShowPasswordValidation(false);
                                                //     setCheckPasswordRegex(false);
                                                // }
                                            })}
                                        />

                                        {
                                            !isPwdMatched &&
                                            <p className={`field-err-msg`}>{`Password does not match`}</p>
                                        }

                                    </Box>
                                </div>
                            </div>

                            <Button type="submit" variant="contained" size="large" className={`w-100 margin-bottom-28 margin-top-28 button-primary ${isValid && isPwdMatched ? `` : `disabled`}`} disabled={isValid && isPwdMatched ? false : true}>
                                CONFIRM PASSWORD
                            </Button>

                        </form>
                    </Box>
                    :
                    <Box className={`password-reset-success-wrapper bg-modal-window form`}>
                        <div> <Passwordsuccess /></div>
                        <div className={`first-heading`}>Password reset successful</div>
                        <div className={`second-heading mb-0`}>
                            You can now use your new password to log in to your account
                        </div>


                        <Link to={`/login`} className={`login-text skip-btn`}>
                            Login now
                        </Link>

                    </Box>
            }

        </Box>
    );
}


//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteResetPassword);