const setUser = (userObj) => {
    return {
        type: "SET_USER",
        payload: userObj
    }
}

const getUser = () => {
    return {
        type: "GET_USER"
    }
}


export default {
    setUser,
    getUser
}