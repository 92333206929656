
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import { CONFIG } from '../../helpers/getApiConfig';

const updateProductListByCategory = async (categoryId, productsIds) => {

    const headers = await getApiHeader() || {};;

    if (!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in updateProductListByCategory function'
        }
    }

    if (!categoryId) {
        return {
            error: true,
            message: 'categoryId mandatory in updateProductListByCategory function'
        }
    }

    if (!productsIds) {
        return {
            error: true,
            message: 'productsIds mandatory in updateProductListByCategory function'
        }
    }

    const instance = axios.create();
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();

    if (productsIds) formData.append("productids", productsIds);
    
    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/products/productlist-by-category/${categoryId}`, formData, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default updateProductListByCategory;