import React from "react";

const facebookWebsiteIcon = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_954_5736)">
                <path d="M14.3789 7.89722C14.3789 4.03122 11.2449 0.897217 7.37891 0.897217C3.51291 0.897217 0.378906 4.03122 0.378906 7.89722C0.378906 11.3911 2.93869 14.287 6.28516 14.8122V9.92066H4.50781V7.89722H6.28516V6.35503C6.28516 4.60065 7.33023 3.63159 8.92916 3.63159C9.69479 3.63159 10.4961 3.76831 10.4961 3.76831V5.49097H9.61344C8.74391 5.49097 8.47266 6.0306 8.47266 6.58472V7.89722H10.4141L10.1037 9.92066H8.47266V14.8122C11.8191 14.287 14.3789 11.3911 14.3789 7.89722Z" fill="#000100" />
                <path d="M10.1037 9.92065L10.4141 7.89722H8.47266V6.58472C8.47266 6.03114 8.74391 5.49097 9.61344 5.49097H10.4961V3.76831C10.4961 3.76831 9.69506 3.63159 8.92916 3.63159C7.33023 3.63159 6.28516 4.60065 6.28516 6.35503V7.89722H4.50781V9.92065H6.28516V14.8122C7.00991 14.9256 7.7479 14.9256 8.47266 14.8122V9.92065H10.1037Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_954_5736">
                    <rect width="14" height="14" fill="white" transform="translate(0.378906 0.897217)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default facebookWebsiteIcon;
