const initialState = {
  brand: {},
  loading: true,
  error: false,
};

function brandReducer(state = initialState, action) {
  switch (action.type) {
    case "LOAD_BRAND":
      return { ...state, brand: {}, error: false, loading: true };
    case "GET_BRAND":
      return state;
    case "SET_BRAND":
      return { ...state, brand: action.payload, error: false, loading: false };
    case "ERROR_BRAND":
      return { ...state, brand: {}, error: true, loading: false };
    default:
      return state;
  }
}
export default brandReducer;
