// React related imports
import React, { useState, useEffect } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import Header from "../Header";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";

//Redux related imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// React route related imports
import { useLocation, useParams } from "react-router-dom";

//Helpers function related imports
import getUrlBasePath from "../../../helpers/getUrlBasePath";

//Components related imports
import MetaTagInjector from "../MetaTagInjector";

const Main = (props) => {
  let currentPath = getUrlBasePath(useLocation(), useParams());
  let pageContainer = "md";
  let cssClassContainer = "";
  const { app } = props?.appData || {};
  const registerUrls = [`/register`, `/email_verification`, `/email_verification_success`];
  const [isRegisterPage, setRegisterPage] = useState(registerUrls.indexOf(currentPath) !== -1);

  useEffect(() => {
    setRegisterPage(registerUrls.indexOf(currentPath) !== -1);
  }, [currentPath])

  app.pagesContainerSize && app.pagesContainerSize.length > 0 &&
    app.pagesContainerSize.map((page) => {
      if (page[`path`] === currentPath) {
        pageContainer = page[`size`];
        cssClassContainer = page[`class`];
      }
    })

  return (
    <Box className={`main-wrapper ${cssClassContainer} ${isRegisterPage ? `white-bg registeration-page` : ``}`}>
      <MetaTagInjector />
      <Header />
      <Container maxWidth={pageContainer} className={`container-wrapper ${isRegisterPage ? `remove-border` : ``}`}>
        <Outlet />
      </Container>
    </Box>
  );
}

//map state
const mapStateToProps = (state) => {
  return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
