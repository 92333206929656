import React, { useState } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Components related imports
import Accordion from '../../../Supplier/Accordion';

//React-router-dom related imports
import { useNavigate } from 'react-router-dom';

const LeftPanel = (props) => {

    const { isCompanySettingsShow } = props;
    const { onMenuChange, accountSettingsData, companySettingsData, defaultAccordionType, defaultSelectedMenu } = props;
    const [selectedAccordionType, setSelectedAccordionType] = useState(defaultAccordionType || ``);
    const [selectedMenu, setSelectedMenu] = useState(defaultSelectedMenu || ``);

    const navigate = useNavigate();

    const onLeftNavigationMenuChange = (value) => {
        setSelectedMenu(value);
        if (onMenuChange) onMenuChange(value);
    }

    const onAccordionTypeChange = (type) => {
        setSelectedAccordionType(type);
    }

    return (
        <Box className={`left-panel`}>

            <div className={`back-icon`} onClick={() => { navigate('/buyer/catalogue'); }}>
                <ArrowBackIcon />
            </div>
            <Accordion title={`Account Settings`}
                data={accountSettingsData}
                // isOpen={selectedAccordionType === "account" ? true : false} // Uncomment this section if we want to open single Accordion at a time and remove below isOpen props
                isOpen={true}
                onMenuChange={onLeftNavigationMenuChange}
                type={"account"}
                onTypeChange={onAccordionTypeChange}
                selectedValue={selectedMenu}
            />

            {
                isCompanySettingsShow &&
                <Accordion
                    title={`Customization`}
                    data={companySettingsData}
                    // isOpen={selectedAccordionType === "company" ? true : false} // Uncomment this section if we want to open single Accordion at a time and remove below isOpen props
                    isOpen={true}
                    onMenuChange={onLeftNavigationMenuChange}
                    type={"product"}
                    onTypeChange={onAccordionTypeChange}
                    selectedValue={selectedMenu}
                />
            }

        </Box>
    )
}

export default LeftPanel;