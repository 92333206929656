import React from "react";

const plusIcon = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4321_3325)">
            <path d="M9.98584 14.978V4.99268" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.9785 9.98535H4.99317" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_4321_3325">
            <rect width="14.1214" height="14.1214" fill="white" transform="translate(19.9707 9.98535) rotate(135)"/>
            </clipPath>
            </defs>
        </svg>


    );
};

export default plusIcon;
