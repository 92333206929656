import React from "react";

export const RightPanel = () => {
    return (
        <div className={`registration__right-panel`}>
            <div className={`registration_right-panel_container`}>
                {/* Right Panel */}
            </div>
        </div>
    )
}