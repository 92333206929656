import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

//Apis related imports
import getOurTeamList from '../../../../../../apis/OurTeam/getOurTeamList';
import getBrandList from "../../../../../../apis/Supplier/getBrandList";
import ourTeamAdd from "../../../../../../apis/OurTeam/ourTeamAdd";
import getOurTeamUserUpdate from "../../../../../../apis/OurTeam/getOurTeamUserUpdate";
import getOurTeamDelete from "../../../../../../apis/OurTeam/getOurTeamDelete";
import getOurTeamInviteResend from "../../../../../../apis/OurTeam/getOurTeamInviteResend";
import ourTeamUpdate from "../../../../../../apis/OurTeam/ourTeamUpdate";

//Components related imports
import Loader from "../../../../Loader";
import AlertMessage from "../../../../AlertMessage";
import Tooltip from "../../../../../common/Supplier/Tooltip";
import ConformationPopup from "../../../ConformationPopup";

const RoleDesciptionSection = (props) => {

    const { teamRole, companySettingRole, companyRole, productRole, catalogueRole } = props;

    return (
        <div className={`role-description-wrapper`}>
            <div className={`heading`}>Role description</div>
            <div className={`description`}><InfoIcon /> The team member(s) will receive the combined set of permission provided by these roles.</div>

            {
                teamRole === `1` &&
                <Fragment>
                    <div className={`role-wrapper`}>
                        <div className={`role-heading`}>
                            Administrator
                        </div>

                        <div className={`role-description`}>
                            This role is for people who need similar access as the account owner. This person can see and manage everything.
                        </div>

                        <div className={`sub-role-wrapper`}>
                            <div className={`sub-role-heading`}>
                                Company Personas
                            </div>

                            <div className={`sub-role-description`}>
                                This person has access to all company personas.
                            </div>
                        </div>

                        <div className={`sub-role-wrapper`}>
                            <div className={`sub-role-heading`}>
                                Company Persona Permissions
                            </div>

                            <div className={`sub-role-description`}>
                                This person can view and edit company persona information.
                            </div>
                        </div>

                        <div className={`sub-role-wrapper`}>
                            <div className={`sub-role-heading`}>
                                Product Permissions
                            </div>

                            <div className={`sub-role-description`}>
                                This person can view and edit product information.
                            </div>
                        </div>

                        <div className={`sub-role-wrapper`}>
                            <div className={`sub-role-heading`}>
                                Catalogue Permissions
                            </div>

                            <div className={`sub-role-description`}>
                                This person can view and edit catalogue information.
                            </div>
                        </div>
                    </div>
                </Fragment>
            }

            {
                teamRole === `2` &&
                <div className={`role-wrapper`}>
                    <div className={`role-heading`}>
                        Custom
                    </div>

                    <div className={`role-description`}>
                        This role is for people who have limited access. You can manage the access for each user with these permissions.
                    </div>

                    <div className={`sub-role-wrapper`}>
                        <div className={`sub-role-heading`}>
                            Company Personas
                        </div>

                        <div className={`sub-role-description`}>
                            {companySettingRole === `1` ? 'This person has access to all company personas.' : 'This person has access to some company personas.'}
                        </div>
                    </div>

                    <div className={`sub-role-wrapper`}>
                        <div className={`sub-role-heading`}>
                            Company Persona Permissions
                        </div>

                        <div className={`sub-role-description`}>
                            {companyRole === `4` ? 'This person can only view company persona information.' : 'This person can view and edit company persona information.'}
                        </div>
                    </div>

                    <div className={`sub-role-wrapper`}>
                        <div className={`sub-role-heading`}>
                            Product Permissions
                        </div>

                        {
                            productRole === `5` &&
                            <div className={`sub-role-description`}>
                                This person does not have access to view or edit product information.
                            </div>
                        }

                        {
                            productRole === `4` &&
                            <div className={`sub-role-description`}>
                                This person can only view product information.
                            </div>
                        }

                        {
                            productRole === `3` &&
                            <div className={`sub-role-description`}>
                                This person can view and edit product information.
                            </div>
                        }


                    </div>

                    <div className={`sub-role-wrapper`}>
                        <div className={`sub-role-heading`}>
                            Catalogue Permissions
                        </div>


                        {
                            catalogueRole === `5` &&
                            <div className={`sub-role-description`}>
                                This person does not have access to view or edit catalogue information.
                            </div>
                        }

                        {
                            catalogueRole === `4` &&
                            <div className={`sub-role-description`}>
                                This person can only view catalogue information.
                            </div>
                        }

                        {
                            catalogueRole === `3` &&
                            <div className={`sub-role-description`}>
                                This person can view and edit catalogue information.
                            </div>
                        }
                    </div>
                </div>
            }

        </div>
    )
}
const AddOurTeam = (props) => {
    
    const { onClose, brandList, onSuccess, isOurTeamEdit, ourTeamEditData } = props;
    const [teamRole, setTeamRole] = useState(isOurTeamEdit && ourTeamEditData?.permission?.[0]?.accesstype || `1`),
        [companySettingRole, setCompanySettingRole] = useState(isOurTeamEdit && ourTeamEditData?.permission?.[0]?.brandtype || `1`),
        [companyRole, setCompanyRole] = useState(isOurTeamEdit && ourTeamEditData?.permission?.[0]?.brand_auth_type || `4`),
        [productRole, setProductRole] = useState(isOurTeamEdit && ourTeamEditData?.permission?.[0]?.product_auth_type || `5`),
        [catalogueRole, setCatalogueRole] = useState(isOurTeamEdit && ourTeamEditData?.permission?.[0]?.catalogue_auth_type || `5`),
        [teamMemberEmail, setTeamMemberEmail] = useState(isOurTeamEdit && ourTeamEditData?.email || ``),
        [isEmailError, setEmailError] = useState(false),
        [emailErrorMessage, setEmailErrorMessage] = useState(``),
        [selectedBrand, setSelectedBrand] = useState([]),
        [isLoading, setLoading] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``);

    useEffect(() => {
        if (isOurTeamEdit && ourTeamEditData?.permission?.[0]?.brandlist?.length > 0) {
            let tempArray = [];

            if (brandList?.length > 0) {
                brandList.map((data) => {
                    ourTeamEditData?.permission?.[0]?.brandlist?.map((brand) => {
                        if (data[`brand_id`] === brand[`brand`]) {
                            tempArray.push(data[`name`])
                        }
                    })
                })
            }


            setSelectedBrand(tempArray);
        }
    }, [ourTeamEditData, isOurTeamEdit])
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        }
    };

    useEffect(() => {
        document.body.classList.add("overflow-hide");

        return () => {
            document.body.classList.remove("overflow-hide");
        }
    }, [])

    useEffect(() => {
        if (teamMemberEmail) {
            const emailTimeout = setTimeout(() => {

                if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(teamMemberEmail)) {
                    setEmailError(false);
                    setEmailErrorMessage(``);
                } else {
                    setEmailErrorMessage(`Invalid email format`);
                    setEmailError(true);
                }
            }, 500)

            return () => clearTimeout(emailTimeout);
        }
    }, [teamMemberEmail])

    const onBrandChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedBrand(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const onSendInviteClick = () => {

        if (!teamMemberEmail) {

            setEmailErrorMessage(`email is required`);
            setEmailError(true);

            document.querySelector('#our-team-email').scrollIntoView({
                behavior: 'smooth'
            });
            return;
        }

        if (isEmailError) {
            setEmailErrorMessage(`Invalid email format`);
            setEmailError(true);

            document.querySelector('#our-team-email').scrollIntoView({
                behavior: 'smooth'
            });
            return;
        }

        if (companySettingRole === '2' && selectedBrand.length === 0) {
            setAlternateMessageShow(true);
            setAlertMessageType(`error`);
            setAlertMessage(`Please select at least one company from company list`);
            return;
        }

        if (teamRole === `2` && productRole === `5` && catalogueRole === `5`) {
            setAlternateMessageShow(true);
            setAlertMessageType(`error`);
            setAlertMessage(`Both the Product Settings and the Catalogue settings cannot be None`);
            return;
        }
        let tempArray = [];
        if (selectedBrand && selectedBrand.length > 0) {
            selectedBrand.map((brand) => {
                if (brandList && brandList.length > 0) {
                    brandList.map((data) => {
                        if (data[`name`] === brand) {
                            tempArray.push(data[`brand_id`]);
                        }
                    })
                }
            })
        }

        setLoading(true);

        if (isOurTeamEdit) {
            ourTeamUpdate(ourTeamEditData?.id, teamRole, companySettingRole, companyRole, productRole, catalogueRole, tempArray)
                .then((xhrResponse) => {
                    setLoading(false);

                    setAlternateMessageShow(true);
                    setAlertMessageType(`success`);
                    setAlertMessage(xhrResponse?.data?.message)

                    setTimeout(() => {
                        if (onClose) onClose(false);
                        if (onSuccess) onSuccess();
                    }, 500)

                })
                .catch((err) => {
                    console.log(`we have error at ourTeamUpdate`, err);
                    setLoading(false);
                })
        } else {
            ourTeamAdd(teamMemberEmail, teamRole, companySettingRole, companyRole, productRole, catalogueRole, tempArray)
                .then((xhrResponse) => {

                    if (xhrResponse && xhrResponse.status === 200 && xhrResponse.data && xhrResponse.data.data) {
                        if (xhrResponse.data.status) {
                            setLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType(`success`);
                            setAlertMessage(xhrResponse?.data?.message)

                            setTimeout(() => {
                                if (onClose) onClose(false);
                                if (onSuccess) onSuccess();
                            }, 500)

                        }
                        else {
                            setLoading(false);
                            setAlternateMessageShow(true);
                            setAlertMessageType(`error`);
                            setAlertMessage(xhrResponse?.data?.message)
                        }
                    }
                    else {
                        setLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType(`error`);
                        setAlertMessage(xhrResponse?.data?.message)
                    }
                })
                .catch((err) => {
                    console.log(`we have error at ourTeamAdd`, err);
                    setLoading(false);
                })
        }
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    }

    const onSubmit = (event) => {
        event.preventDefault();
        onSendInviteClick();
    }
    return (
        <Box className={`add-our-team-popup-wrapper`}>
            {isLoading && <Loader />}
            {
                isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`add-our-team-popup`}>
                <div className={`close-icon`} onClick={() => { if (onClose) onClose(false) }}>
                    <CloseIcon />
                </div>
                <div className={`heading`}>
                    Invite team members
                </div>
                <Box className={`hr`} />

                <div className={`add-our-team-popup-description`}>
                    <div className={`form-wrapper`}>
                        <form className={`form-input-control position-relative`} onSubmit={onSubmit}>
                            <Box className={`form-control margin-bottom-28`}>
                                <Typography className={`label`}>Enter team members email address</Typography>
                                <TextField
                                    type={"email"}
                                    id="our-team-email"
                                    name="our-team-email"
                                    className={`input-wrap`}
                                    placeholder={`abc@example.com`}
                                    margin="dense"
                                    spellCheck={false}
                                    error={isEmailError ? true : false}
                                    value={teamMemberEmail}
                                    disabled={isOurTeamEdit ? 'disabled' : ''}
                                    inputProps={{
                                        onInput: (e) => {
                                            setTeamMemberEmail(e.target.value);
                                        },
                                        maxLength: 50
                                    }}
                                />

                                {
                                    isEmailError &&
                                    <p className={`field-err-msg`}>{emailErrorMessage}</p>
                                }
                            </Box>

                            <Box className={`team-members-role-txt`}>
                                Select team members roles
                            </Box>

                            <Box className={`form-control margin-bottom-28`}>
                                <FormControl>
                                    <FormLabel className={`label padding-b-20px`} id="role-group-label">Role</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="role-group-label"
                                        name="role-buttons-group"
                                        value={teamRole}
                                        className="radio-group"
                                        onChange={(event) => setTeamRole(event.target.value)}
                                    >
                                        <FormControlLabel value="1" control={<Radio />} label="Administrator" />
                                        <FormControlLabel value="2" control={<Radio />} label="Custom" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>

                            {
                                teamRole === `2` &&
                                <Fragment>
                                    <Box className={`form-control margin-bottom-28`}>
                                        <FormControl>
                                            <FormLabel className={`label padding-b-20px`} id="company-group-label">Persona Settings</FormLabel>
                                            <RadioGroup
                                                row
                                                className="radio-group"
                                                aria-labelledby="company-group-label"
                                                name="company-buttons-group"
                                                value={companySettingRole}
                                                onChange={(event) => setCompanySettingRole(event.target.value)}
                                            >
                                                <FormControlLabel value="1" control={<Radio />} label="All" />
                                                <FormControlLabel value="2" control={<Radio />} label="Custom" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>

                                    {
                                        companySettingRole === `2` &&
                                        <Fragment>
                                            <Box className={`form-control margin-bottom-28 company-list`}>
                                                <FormControl sx={{ m: 1, width: 300 }}>
                                                    <InputLabel id="demo-multiple-checkbox-label">Persona List</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        value={selectedBrand}
                                                        onChange={onBrandChange}
                                                        input={<OutlinedInput label="Company List" />}
                                                        renderValue={(selected) => selected.join(', ')}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {
                                                            brandList && brandList.length > 0 &&
                                                            brandList.map((brand, index) => (
                                                                <MenuItem key={brand[`name`]} value={brand[`name`]} >
                                                                    <Checkbox checked={selectedBrand.indexOf(brand[`name`]) > -1} />
                                                                    <ListItemText primary={brand[`name`]} />
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Fragment>


                                    }

                                    <Box className={`form-control margin-bottom-28`}>
                                        <FormControl>
                                            <FormLabel className={`label padding-b-20px`} id="product-group-label">Company Persona Permissions</FormLabel>
                                            <RadioGroup
                                                row
                                                className="radio-group "
                                                aria-labelledby="product-group-label"
                                                name="product-buttons-group"
                                                value={companyRole}
                                                onChange={(event) => setCompanyRole(event.target.value)}
                                            >
                                                {/* <FormControlLabel value="1" control={<Radio />} label="All" /> */}
                                                {/* <FormControlLabel value="1" control={<Radio />} label="None" /> */}
                                                <FormControlLabel value="4" control={<Radio />} label="View" />
                                                {/* <FormControlLabel value="2" control={<Radio />} label="Add" /> */}
                                                <FormControlLabel value="3" control={<Radio />} label="Edit" />
                                                {/* <FormControlLabel value="4" control={<Radio />} label="View" /> */}

                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                    <Box className={`form-control margin-bottom-28 margin-top-28`}>
                                        <FormControl>
                                            <FormLabel className={`label padding-b-20px`} id="product-group-label">Product Settings</FormLabel>
                                            <RadioGroup
                                                row
                                                className="radio-group"
                                                aria-labelledby="product-group-label"
                                                name="product-buttons-group"
                                                value={productRole}
                                                onChange={(event) => setProductRole(event.target.value)}
                                            >
                                                {/* <FormControlLabel value="1" control={<Radio />} label="All" /> */}
                                                <FormControlLabel value="5" control={<Radio />} label="None" />
                                                <FormControlLabel value="4" control={<Radio />} label="View" />
                                                {/* <FormControlLabel value="2" control={<Radio />} label="Add" /> */}
                                                <FormControlLabel value="3" control={<Radio />} label="Edit" />
                                                {/* <FormControlLabel value="4" control={<Radio />} label="View" /> */}

                                            </RadioGroup>
                                        </FormControl>
                                    </Box>

                                    <Box className={`form-control margin-bottom-28`}>
                                        <FormControl>
                                            <FormLabel className={`label padding-b-20px`} id="catalogue-group-label">Catalogue Settings</FormLabel>
                                            <RadioGroup
                                                row
                                                className="radio-group"
                                                aria-labelledby="catalogue-group-label"
                                                name="catalogue-buttons-group"
                                                value={catalogueRole}
                                                onChange={(event) => setCatalogueRole(event.target.value)}
                                            >
                                                {/* <FormControlLabel value="1" control={<Radio />} label="All" /> */}
                                                <FormControlLabel value="5" control={<Radio />} label="None" />
                                                <FormControlLabel value="4" control={<Radio />} label="View" />
                                                {/* <FormControlLabel value="2" control={<Radio />} label="Add" /> */}
                                                <FormControlLabel value="3" control={<Radio />} label="Edit" />
                                                {/* <FormControlLabel value="4" control={<Radio />} label="View" /> */}
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Fragment>
                            }
                        </form>
                    </div>

                    <RoleDesciptionSection
                        teamRole={teamRole}
                        companySettingRole={companySettingRole}
                        companyRole={companyRole}
                        productRole={productRole}
                        catalogueRole={catalogueRole}

                    />
                </div>

                <Box className={`button-wrapper`}>
                    <Button variant="text" onClick={() => { if (onClose) onClose(false) }} >Cancel</Button>
                    <Button className="btn-primary-after-login" variant="contained" onClick={() => onSendInviteClick()}>{isOurTeamEdit ? 'Update invites' : 'Send invites'}</Button>
                </Box>
            </div>
        </Box>
    )
}
const OurTeam = (props) => {

    console.log(props)
    const [teamArray, setTeamArray] = useState([]),
        [userDetail, setUserDetail] = useState(props.userDetail || ``),
        [teamLimit, setteamLimit] = useState(1 || ``),
        [isLoading, setLoading] = useState(false),
        [isAddOurTeamShow, setAddOurTeamShow] = useState(false),
        [brandList, setBrandList] = useState([]),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isOurTeamEdit, setOurTeamEdit] = useState(false),
        [ourTeamEditData, setOurTeamEditData] = useState({}),
        [isConfirmationPopupShow, setIsConfirmationPopupShow] = useState(false),
        [conformationPopupText, setConformationPopText] = useState(``),
        [conformationPopupType, setConformationType] = useState(``),
        [conformationPopupSuccessBtnText, setConformationPopupSuccessBtnText] = useState(``),
        // Set the initial disabled state based on props
        [isButtonDisabled, setIsButtonDisabled] = useState(() => 1 <= teamArray.length),
        [conformationPopupCancelBtnText, setConformationPopupCancelBtnText] = useState(``);
        

    const moduleApis = [];
    const moduleApiIndex = [];

    useEffect(() => {
        getApisData();
    }, [])


    useLayoutEffect(() => {
        setIsButtonDisabled(teamArray.length >= Number(teamLimit));
    }, [teamArray, teamLimit]);
        

    // useEffect(() => {
    //     setteamLimit(userDetail?.team_limit || ``);
    // }, [teamLimit]);

    const getApisData = () => {
        setLoading(true);

        moduleApis.push(getOurTeamList());
        moduleApiIndex.push(`ourTeamListIndex`);

        moduleApis.push(getBrandList());
        moduleApiIndex.push(`brandListIndex`);

        Promise.all(moduleApis)
            .then((result) => {
                let ourTeamListIndex = moduleApiIndex.indexOf('ourTeamListIndex');
                if (ourTeamListIndex !== -1 && result.length) {
                    const dataResult = result[ourTeamListIndex] ? result[ourTeamListIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                        setTeamArray(dataResult.data.data);
                        setteamLimit(dataResult.data.teamlimit);
                    }
                }

                let brandListIndex = moduleApiIndex.indexOf('brandListIndex');
                if (brandListIndex !== -1 && result.length) {
                    const dataResult = result[brandListIndex] ? result[brandListIndex] : {};
                    if (dataResult.data && dataResult.status === 200 && dataResult.data && dataResult.data.data) {
                        setBrandList(dataResult.data.data);
                    }
                }
                setLoading(false);
            })
    }

    const onAddOurTeamSuccess = () => {
        getApisData();
    }

    const getMoreOption = (data) => {

        let tempArray = [
            {
                "label": "Edit",
                "value": "edit"
            },
        ]

        if (data?.is_email === false) {
            tempArray.push({
                "label": "Re-Invite",
                "value": "reinvite"
            })
        }

        if (data?.active) {
            tempArray.push({
                "label": "Deactivate",
                "value": "deactivate"
            })
        } else {
            tempArray.push({
                "label": "Activate",
                "value": "activate"
            })
        }

        tempArray.push({
            "label": "Delete",
            "value": "delete"
        })

        return tempArray;
    }

    const onMoreOptionValueChange = (option, data) => {
        setOurTeamEditData(data);
        switch (option?.value) {
            case "deactivate":
            case "activate":
                setIsConfirmationPopupShow(true);
                setConformationPopText(`Are you sure you want to ${option?.value} this user?`);
                setConformationPopupSuccessBtnText(`Yes`);
                setConformationPopupCancelBtnText(`No`);
                setConformationType(option?.value);
                // onUserActiveDeactive(data?.id, option?.value)
                break;

            case "delete":
                setIsConfirmationPopupShow(true);
                setConformationPopText(`Are you sure you want to delete this user?`);
                setConformationPopupSuccessBtnText(`Yes`);
                setConformationPopupCancelBtnText(`No`);
                setConformationType(`delete`);

                // onUserDelete(data?.id, data?.active ? 'deactivate' : 'activate');
                break;

            case "reinvite":
                onUserInviteResend(data?.email)
                break;

            case "edit":
                setOurTeamEdit(true);
                setAddOurTeamShow(true);
                // setOurTeamEditData(data);
                break;


        }
    }


    const onUserActiveDeactive = (id, action) => {
        setLoading(true);
        getOurTeamUserUpdate(id, action)
            .then((xhrResponse) => {
                if (xhrResponse?.status === 200 && xhrResponse?.data) {
                    setAlternateMessageShow(true);
                    setAlertMessageType(`success`);
                    setAlertMessage(xhrResponse?.data?.message);
                    getApisData();
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(`we have error at getOurTeamUserUpdate`, err);
            })
    }

    const onUserDelete = (id, action) => {
        setLoading(true);
        getOurTeamDelete(id, action)
            .then((xhrResponse) => {
                if (xhrResponse?.status === 200 && xhrResponse?.data) {
                    setAlternateMessageShow(true);
                    setAlertMessageType(`success`);
                    setAlertMessage(xhrResponse?.data?.message);
                    getApisData();
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(`we have error at getOurTeamDelete`, err);
            })
    }

    const onUserInviteResend = (email) => {
        setLoading(true);
        getOurTeamInviteResend(email)
            .then((xhrResponse) => {
                if (xhrResponse?.status === 200 && xhrResponse?.data) {
                    setAlternateMessageShow(true);
                    setAlertMessageType(`success`);
                    setAlertMessage(xhrResponse?.data?.message);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(`we have error at getOurTeamInviteResend`, err);
            })
    }
    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    }

    const onConformationPopupClose = (value) => {
        setIsConfirmationPopupShow(value);
    }

    const onConformationPopupSuccess = () => {
        setIsConfirmationPopupShow(false);
        switch (conformationPopupType) {
            case "deactivate":
            case "activate":
                onUserActiveDeactive(ourTeamEditData?.id, conformationPopupType)
                break;
            case `delete`:
                onUserDelete(ourTeamEditData?.id, ourTeamEditData?.active ? 'deactivate' : 'activate');
                break;
        }
    }
    return (
        <Box className={`our-team-wrapper`}>
            {isLoading && <Loader />}
            {
                isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`our-team-button-wrap`}>
                {
                teamArray.length >= Number(teamLimit) ? <div className={`our-team-limit-warning`}>
                Need more seats for your team? Contact your account manager for assistance
                </div> : (
                    <div className={`our-team-limit-warning`}>
                    </div>
                )
                }

                <div className={`add-our-team-wrap`}>
                    {/* <Button className={`btn-primary-after-login only-desktop`} variant="contained" onClick={() => setAddOurTeamShow(true)} disabled={isButtonDisabled}> <AddIcon /> Add team members </Button> */}
                    <Button
  className={`btn-primary-after-login only-desktop`}
  variant="contained"
  onClick={() => {
    if (!isButtonDisabled) {
      setAddOurTeamShow(true);
    }
  }}
  disabled={isButtonDisabled}
>
  <AddIcon /> Add team members
</Button>

                    <Button className={`btn-primary-after-login button-plus only-mobile`} variant="contained" onClick={() => setAddOurTeamShow(true)} disabled={isButtonDisabled}><AddIcon /></Button>
                </div>
            </div>
            
            <Box className={`product-table table-scroll-wrap`}>

                <table>
                    <thead>
                        <tr>
                            <th width="35%" className={`product-`}>Name</th>
                            <th width="30%" className={`email`}>Email Address</th>
                            <th width="30%" align="center" className={`status`} style={{textAlign:"center"}}>Status</th>
                            <th width="5%" className={`email pr-10`}>Actions</th>
                        </tr>
                    </thead>

                    {
                        teamArray && teamArray.length > 0 &&
                        <tbody>
                            {
                                teamArray.map((data, index) => {
                                    return (
                                        <tr className={`product-list-main`} key={index}>
                                            <td className={`product-name tabel-cell`}>
                                                {data[`firstname`]}
                                            </td>
                                            <td className={`sku-id`}>
                                                {data[`email`]}
                                            </td>

                                            <td className={`status`} style={{textAlign:"center"}}>
                                                {
                                                    data[`active`]
                                                        ?
                                                        <span tooltip="Active" className={`active`}>
                                                            <CheckCircleOutlineIcon />
                                                        </span>
                                                        :
                                                        <span tooltip="Deactivate" className={`deactive`}>
                                                            <HighlightOffIcon />
                                                        </span>

                                                }
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <div className={`more-options-wrapper`}>
                                                    <Tooltip
                                                        moreOptions={getMoreOption(data)}
                                                        onOptionChange={onMoreOptionValueChange}
                                                        data={data}
                                                        type={`productList`}
                                                        id={1}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    }
                </table>

                {
                    teamArray && teamArray.length === 0 &&
                    <div className={`not-data-found`}>
                        No team members found
                    </div>
                }

                {
                    isAddOurTeamShow &&
                    <AddOurTeam
                        onClose={(value) => { setOurTeamEdit(value); setAddOurTeamShow(value) }}
                        brandList={brandList}
                        onSuccess={() => onAddOurTeamSuccess()}
                        isOurTeamEdit={isOurTeamEdit}
                        ourTeamEditData={ourTeamEditData}
                    />
                }

                {
                    isConfirmationPopupShow &&
                    <ConformationPopup
                        heading={conformationPopupText}
                        cancelBtnText={conformationPopupCancelBtnText}
                        submitBtnText={conformationPopupSuccessBtnText}
                        onCancel={onConformationPopupClose}
                        onSubmit={onConformationPopupSuccess}
                    />
                }
            </Box>
        </Box>
    )
}

export default OurTeam;