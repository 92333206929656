import React, { useState, useEffect, Fragment } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MoreIcons from '../../../../icons/svgs/moreIcons';
import { Icons } from '../../../../common/Icons';

const Tooltip = (props) => {
    const [selectedOption, setSelectedOption] = useState({});
    const [isTooltipOpen, setTooltipOpenValue] = useState(false);

    const onTooltipOptionClick = (option) => {
        setSelectedOption(option);
        if (props.onOptionChange) {
            props.onOptionChange(option, props?.data || {});
            setTooltipOpenValue(false);
        }
    }

    useEffect(() => {
        window.addEventListener("mousedown", (event) => {
            if ([`tooltip-icon-${props?.id}`, `tooltip-element-${props?.id}`, `tooltip-label-${props?.id}`].indexOf(event?.target?.parentElement?.className) === -1) {
                if (isTooltipOpen) {
                    setTimeout(() => {
                        setTooltipOpenValue(false);
                        setSelectedOption({})
                    }, 100);
                }
            }
        });
        return () => {
            window.removeEventListener("mousemove", {}, true);
        }
    }, [isTooltipOpen])

    return (
        <Fragment>

            <div className={`tooltip-element-${props?.id}`}>

                {
                    props.icon === 1
                        ?
                        <div onClick={() => { setTooltipOpenValue(!isTooltipOpen) }} style={{ cursor: 'pointer' }} className={`tooltip-icon-${props?.id}`}>
                            {props.textlable ? <span className={`icon-click-text`} >{props.textlable}</span> : ``}

                            {
                                isTooltipOpen
                                    ?
                                    <ExpandLessIcon className={`open-tooltip-icon`}/>
                                    :
                                    <ExpandMoreIcon className={`close-tooltip-icon`}/>
                            }
                        </div>
                        :
                        <div onClick={() => { setTooltipOpenValue(!isTooltipOpen) }} style={{ cursor: 'pointer' }} className={`tooltip-icon-${props?.id}`}>
                            <MoreIcons />
                        </div>
                }
                {
                    isTooltipOpen &&
                    <div className={`tooltip-wrapper ${props.className || ``}`}>
                        <div className={`tooltip-label-${props?.id}`}>
                            {
                                props.moreOptions && props.moreOptions.length > 0 &&
                                props.moreOptions.map((option, index) => {
                                    return (
                                        <span key={index} className={`${option?.value === selectedOption?.value ? 'selected' : ''} ${index}-icon`} onClick={() => { onTooltipOptionClick(option) }}>
                                            {
                                            option[`label`]/* {
                                            (option[`label`] ===  "Share") ? <span onClick={handleClickOpen}>{option[`label`]}</span> : <span>{option[`label`]}</span>
                                        } */}
                                            {/* <span onClick={() => { onTooltipOptionClick(option) }}>{option[`label`]}</span> */}
                                        </span>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </div>
        </Fragment>
    )
}

export default Tooltip;