import React, { useState, useEffect, Fragment } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
// import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import DeleteIcon from '@mui/icons-material/Delete';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ProductIcon from "../../../../icons/svgs/productIcon";
import DescriptionIcon from '@mui/icons-material/Description';

//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import userDetailAction from '../../../../store/action/userDetailAction';

//Apis related imports
import getCatalogueList from "../../../../apis/Buyer/getCatalogueList";
import getStarredCatalogueList from "../../../../apis/Buyer/getStarredCatalogueList";
import getRecentlyUpdateCatalogue from "../../../../apis/Buyer/getRecentlyUpdateCatalogue";
import buyerCatalogueStarUpdate from "../../../../apis/Buyer/buyerCatalogueStarUpdate";
import savecatalogueDelete from '../../../../apis/Buyer/savecatalogueDelete';
import catalogueMultipleDelete from '../../../../apis/Buyer/catalogueMultipleDelete';
import emailnewlink from "../../../../apis/emailnewlink";
import getUserDetail from "../../../../apis/getUserDetail";

//Components related imports
import Tooltip from '../../Supplier/Tooltip';
import Loader from '../../Loader';
import AlertMessage from "../../AlertMessage";
import Accordion from '../../Supplier/Accordion';
import ConformationPopup from "../../../common/Supplier/ConformationPopup";

//Icons related imports
import ShareProductCopyIcon from '../../../../icons/svgs/shareProductCopyIcon';
import WhatsappIcon from '../../../../icons/svgs/whatsappIcon';
import SearchIcon from '../../../../icons/svgs/searchIcon';
import TrashIcon from '../../../../icons/svgs/trashIcon';
import EmailVerificationIcon from '../../../../icons/svgs/emailVerificationIcon';

//helper functions related functions
import copyToClipboard from '../../../../helpers/copyToClipboard';

const LeftNavigation = (props) => {

    const {
        onLeftNavigationMenuChange,
        allCatalogyeCount,
        recentCatalogyeCount,
        starredCatalogyeCount,
        userDetails,
        appData
    } = props;

    const isMobile = appData?.app?.isMobile;

    const subMenu = [`All Catalogues`, `Starred`, `Recently Updated`],
        [selectedMenu, setSelectedMenu] = useState(`All Catalogues`),
        [isEmailVerify, setEmailVerify] = useState(userDetails?.is_email),
        [mobileMenu, setMobileMenu] = useState(subMenu || []),
        [selectedMobileMenu, setSelectedMobileMenu] = useState(``);

    useEffect(() => {

        if (onLeftNavigationMenuChange) {
            onLeftNavigationMenuChange(selectedMenu);
        }

    }, [selectedMenu])

    useEffect(() => {
        let tempArray = [];
        if (mobileMenu && mobileMenu.length > 0) {
            subMenu.map((menu) => {
                tempArray.push(returnSelectedMenuWithCount(menu))
            })
        }
        setMobileMenu(tempArray);
        setSelectedMobileMenu(tempArray && tempArray.length > 0 ? tempArray[0] : ``);
    }, [allCatalogyeCount, recentCatalogyeCount, starredCatalogyeCount])

    const returnSelectedMenuWithCount = (selectedMenu) => {
        switch (selectedMenu) {
            case `All Catalogues`:
                return `${selectedMenu} (${allCatalogyeCount})`
                break;

            case `Starred`:
                return `${selectedMenu} (${starredCatalogyeCount})`
                break;

            case 'Recently Updated':
                return `${selectedMenu} (${recentCatalogyeCount})`
                break;

        }
    }

    const onMobileNavigationChange = (value) => {
        setSelectedMobileMenu(value)
        if (onLeftNavigationMenuChange) {
            onLeftNavigationMenuChange(value.split(` (`)[0]);
        }
    }

    return (
        <Box className={`left-navigation`}>
            <div className={`left-nav-wrap`}>
                <div className={`not-mobile`}>
                    <div className={`heading`} >
                        My Catalogues
                    </div>

                    <div className={`sub-menu`}>

                        {
                            subMenu && subMenu.length > 0 &&
                            subMenu.map((menu) => {
                                return (
                                    <div>
                                        <span
                                            className={`${selectedMenu === menu ? 'selected' : ''} skip-btn`}
                                            onClick={() => setSelectedMenu(menu)}
                                        >
                                            {returnSelectedMenuWithCount(menu)}
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </div>

            <div className={`only-mobile`}>
                <Accordion title={`Catalogue Settings`}
                    data={mobileMenu}
                    isOpen={true}
                    onMenuChange={(value) => onMobileNavigationChange(value)}
                    type={"account"}
                    onTypeChange={{}}
                    selectedValue={selectedMobileMenu}
                />
            </div>
        </Box>
    )
}


const VerifyEmailNotification = (props) => {

    const { userDetails } = props,
        [isLoading, setIsLoading] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``);

    const generateVerificationLink = () => {
        if (!userDetails?.email) {
            return;
        }

        setIsLoading(true);
        emailnewlink(userDetails?.email)
            .then((response) => {
                if (
                    response &&
                    response.status === 200 &&
                    response.data &&
                    response.data.status
                ) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                } else {
                    if (
                        response &&
                        response.response &&
                        response.response.data &&
                        response.response.data.message
                    ) {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        if (response?.message === `Network Error`) {
                            setAlertMessage(`No internet connection`);
                        } else {
                            setAlertMessage(response?.response?.data?.message);
                        }
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("Checking error", error);
            });
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    return (
        <>
        {isLoading && <Loader />}
        <Box className={`verify-email-notification`}>

            
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }

            <div className={`email-notification-wrap`}>
                <div>
                    <EmailVerificationIcon />
                </div>
                <div className={`text`}>
                    Please verify your email
                </div>
            </div>
            <div>
                <Button
                    variant="contained"
                    className={`btn-primary-after-login`}
                    onClick={() => generateVerificationLink()}
                >
                    Verify
                </Button>
            </div>
        </Box>
        </>
    )
}
const RightNavigation = (props) => {

    const {
        selectedMenu,
        onLoadingChange,
        onAllCatalogueCountUpdate,
        onRecentCatalogueCountUpdate,
        onStarredCatalogueCountUpdate,
        allCatalogyeCount,
        recentCatalogyeCount,
        starredCatalogyeCount,
        userDetails,
        appData
    } = props;


    const isMobile = appData?.app?.isMobile;

    const [catalogueList, setCatalogueList] = useState([]),
        [isLoading, setIsLoading] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isConfirmationPopupShow, setIsConfirmationPopupShow] = useState(false),
        [conformationPopupText, setConformationPopText] = useState(``),
        [conformationPopupType, setConformationType] = useState(``),
        [conformationPopupSuccessBtnText, setConformationPopupSuccessBtnText] = useState(``),
        [conformationPopupCancelBtnText, setConformationPopupCancelBtnText] = useState(``),
        [moreOptionSelectedData, setMoreOptionSelectedData] = useState({}),
        [isCheckAll, setIsCheckAll] = useState(false),
        [isCheck, setIsCheck] = useState([]),
        [searchValue, setSearchValue] = useState(``),
        [isSharePopupVisible, setIsSharePopupVisible] = useState(false),
        [shareCatalogueData, setShareCatalogueData] = useState({}),
        [isEmailVerify, setEmailVerify] = useState(userDetails?.is_email);

    const moreOptions = [
        {
            "label": "Delete",
            "value": "delete"
        },
        {
            "label": "Share",
            "value": "share"
        }
    ];

    useEffect(() => {
        document.getElementById(`search`).value = '';
        setSearchValue(``)
        switch (selectedMenu) {
            case `All Catalogues`:
                getAllCatalogueList();
                break;

            case `Starred`:
                getStarredCatalogue();
                break;

            case 'Recently Updated':
                getRecentlyUpdateCatalogueList();
                break;

        }

    }, [selectedMenu])

    const updateAllMenuCount = (allCatalogyeCount, recentCatalogyeCount, starredCatalogyeCount) => {
        if (onAllCatalogueCountUpdate) onAllCatalogueCountUpdate(allCatalogyeCount || 0)
        if (onRecentCatalogueCountUpdate) onRecentCatalogueCountUpdate(recentCatalogyeCount || 0)
        if (onStarredCatalogueCountUpdate) onStarredCatalogueCountUpdate(starredCatalogyeCount || 0);
    }

    const getAllCatalogueList = (searchValue, isSearch = false) => {

        if (isSearch && catalogueList && catalogueList.length === 0) {
            return;
        }

        if (onLoadingChange) {
            onLoadingChange(true);
        }

        getCatalogueList(searchValue)
            .then((response) => {
                if (response && response.status === 200 && response.data) {
                    if (onLoadingChange) {
                        onLoadingChange(false);
                    }
                    setCatalogueList(response?.data?.data);
                    updateAllMenuCount(response?.data?.all_catalogue, response?.data?.all_recent_catalogue, response?.data?.all_starred_catalogue);
                }
            })
            .catch((error) => {
                if (onLoadingChange) {
                    onLoadingChange(false);
                }
                console.log("we have error at getCatalogueList", error);
            });
    }

    const getStarredCatalogue = (searchValue) => {
        if (onLoadingChange) {
            onLoadingChange(true);
        }

        getStarredCatalogueList(searchValue)
            .then((response) => {
                if (response && response.status === 200 && response.data) {
                    if (onLoadingChange) {
                        onLoadingChange(false);
                    }
                    setCatalogueList(response?.data?.data);
                    updateAllMenuCount(response?.data?.all_catalogue, response?.data?.all_recent_catalogue, response?.data?.all_starred_catalogue);
                }
            })
            .catch((error) => {
                if (onLoadingChange) {
                    onLoadingChange(false);
                }
                console.log("we have error at getCatalogueList", error);
            });
    }

    const getRecentlyUpdateCatalogueList = (searchValue) => {
        if (onLoadingChange) {
            onLoadingChange(true);
        }
        getRecentlyUpdateCatalogue(searchValue)
            .then((response) => {
                if (response && response.status === 200 && response.data) {
                    if (onLoadingChange) {
                        onLoadingChange(false);
                    }
                    setCatalogueList(response?.data?.data);
                    updateAllMenuCount(response?.data?.all_catalogue, response?.data?.all_recent_catalogue, response?.data?.all_starred_catalogue);
                }
            })
            .catch((error) => {
                if (onLoadingChange) {
                    onLoadingChange(false);
                }
                console.log("we have error at getCatalogueList", error);
            });
    }

    const onShareClick = (catalogue) => {
        setShareCatalogueData(catalogue);
        setIsSharePopupVisible(true);
        document.body.style.overflow = 'hidden';
    }

    const closeSharePopup = () => {
        setIsSharePopupVisible(false)
        document.body.style.overflow = 'unset'; // BG scroll show
    }

    const onMoreOptionValueChange = (value, data) => {

        switch (value['value']) {
            case `delete`:
                // Delete a Catalogue
                setMoreOptionSelectedData(data);
                setIsConfirmationPopupShow(true);
                setConformationPopText(`Are you sure you want to delete this catalogue?`);
                setConformationPopupSuccessBtnText(`Yes`);
                setConformationPopupCancelBtnText(`No`);
                setConformationType(`Delete Catalogue`);
                break;
            case `share`:
                onShareClick(data)
                break;
        }
    }

    const onConformationPopupClose = (value) => {
        setIsConfirmationPopupShow(value);
    }

    const onConformationPopupSuccess = () => {
        setIsConfirmationPopupShow(false);

        switch (conformationPopupType) {
            case `Delete Catalogue`:
                deletecatalogue();
                break;
            case `Catalogue Multiple Delete`:
                multideletecatalogue();
                break;
        }
    }
    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const deletecatalogue = () => {
        savecatalogueDelete(moreOptionSelectedData?.cat_saved_id)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        getAllCatalogueList();
                    }, 500);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((err) => {
                console.log("we have error on Product Action", err);
            })
    }

    const multideletecatalogue = () => {

        if (isCheck?.length > 0) {
            catalogueMultipleDelete(isCheck)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status) {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("success");
                        setAlertMessage(response.data.message);
                        setTimeout(() => {
                            getAllCatalogueList();
                        }, 500);
                    } else {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        if (response?.message === `Network Error`) {
                            setAlertMessage(`No internet connection`);
                        } else {
                            setAlertMessage(response?.data?.message)
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("we have error at productsList", error);
                });
        }
        else {
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            setAlertMessage(`Please select the product`);
        }
    }

    const onStarChange = (isStar, catalogueId) => {

        if (onLoadingChange) {
            onLoadingChange(true);
        }
        buyerCatalogueStarUpdate(catalogueId)
            .then((response) => {
                if (response && response.status === 200 && response.data) {
                    if (onLoadingChange) {
                        onLoadingChange(false);
                    }
                    switch (selectedMenu) {
                        case `All Catalogues`:
                            getAllCatalogueList();
                            break;

                        case `Starred`:
                            getStarredCatalogue();
                            break;

                        case 'Recently Updated':
                            getRecentlyUpdateCatalogueList();
                            break;

                    }

                }
            })
            .catch((error) => {
                if (onLoadingChange) {
                    onLoadingChange(false);
                }
                console.log("we have error at getCatalogueList", error);
            });
    }

    const returnSelectedMenuWithCount = (selectedMenu) => {
        switch (selectedMenu) {
            case `All Catalogues`:
                return `${selectedMenu} (${allCatalogyeCount})`
                break;

            case `Starred`:
                return `${selectedMenu} (${starredCatalogyeCount})`
                break;

            case 'Recently Updated':
                return `${selectedMenu} (${recentCatalogyeCount})`
                break;

        }
    }

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(catalogueList.map(li => li?.cat_saved_id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const DeleteAll = e => {
        if (isCheck?.length > 0) {
            setIsConfirmationPopupShow(true);
            setConformationPopText(`Are you sure you want to delete these multiple catalogues?`);
            setConformationPopupSuccessBtnText(`Yes`);
            setConformationPopupCancelBtnText(`No`);
            setConformationType(`Catalogue Multiple Delete`);
        }
        else {
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            setAlertMessage(`Please select the catalogue`);
        }
    };

    const onCatalogueSearchClick = (event) => {

        if (event.key === "Enter" || event.type === "click" || event.type === "change") {
            setIsLoading(true);
            let searchval = (event.type === "keypress") ? event.target.value : (event.type === "click") ? searchValue : ``
            getAllCatalogueList(searchval, true)

        }
    }

    const onSearchRemove = () => {
        document.getElementById(`search`).value = '';
        setSearchValue(``);
        setIsLoading(true);
        getAllCatalogueList()
    }



    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    return (
        <Box className={`right-navigation`}>
            {
                isConfirmationPopupShow &&
                <ConformationPopup
                    heading={conformationPopupText}
                    cancelBtnText={conformationPopupCancelBtnText}
                    submitBtnText={conformationPopupSuccessBtnText}
                    onCancel={onConformationPopupClose}
                    onSubmit={onConformationPopupSuccess}
                />
            }
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            {
                isSharePopupVisible &&
                <CatalogueShare
                    onClose={closeSharePopup}
                    catalogueData={shareCatalogueData}
                />
            }
            <div className={`heading`}>
                {returnSelectedMenuWithCount(selectedMenu)}
            </div>

            {
                userDetails?.is_email === false &&
                <VerifyEmailNotification userDetails={userDetails} />
            }

            <div className={`search-delete-wrap`}>
                <div className={`search-wrap`}>
                    <div className={`search-input-close`}>
                        <TextField
                            id="search"
                            name="search"
                            className={`search-input`}
                            placeholder={`Search`}
                            variant="outlined"
                            InputProps={{
                                // startAdornment: (
                                //     <InputAdornment position="start">
                                //         <SearchIcon />
                                //     </InputAdornment>
                                // ),
                                onKeyPress: (e) => {
                                    onCatalogueSearchClick(e);
                                },
                                onChange: (e) => {

                                    setSearchValue(e.target.value)
                                    let targetvalue = e.target.value
                                    if (targetvalue === "") onCatalogueSearchClick(e);
                                }
                            }}
                        />
                        {
                            searchValue &&
                            <span className={`close-icon`} onClick={() => onSearchRemove()}>
                                <CloseIcon />
                            </span>
                        }
                    </div>
                    <button className={`search-icon-wrap`} onClick={(e) => { onCatalogueSearchClick(e) }}>
                        <SearchIcon />
                    </button>
                </div>
                {
                    selectedMenu == "All Catalogues" ?
                        <div className={`delete-wrap`} onClick={DeleteAll} tooltip="Delete">
                            <div>
                                <TrashIcon />
                            </div>
                        </div> : <></>
                }


            </div>

            <div className={`catalogue-list-wrap product-table table-scroll-wrap bata-sodu`}>
                {
                    catalogueList && catalogueList.length > 0 ?
                        <table>
                            <thead>
                                <tr>
                                    <th className={`product`}>

                                        {
                                            selectedMenu == "All Catalogues" ?
                                                <input type="checkbox" name="selectAll" id="selectAll" onChange={handleSelectAll} checked={isCheckAll} /> : <></>
                                        }Catalogue Details</th>
                                    <th className={`product-draft`}></th>
                                    <th className={`seller-id`}>Products</th>
                                    <th className={`variant`}>Last Updated</th>
                                    <th className={`status`}>Status</th>
                                    <th className={`more-option`}></th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    catalogueList && catalogueList.length > 0 &&
                                    catalogueList.map((list, index) => {
                                        let tempClassName = index % 2 !== 0 ? 'even' : 'odd';
                                        return (
                                            <tr className={`product-list-main ${tempClassName} buyer-catalgoue-list`} key={index}>
                                                <td>
                                                    <div className={`product-name brand-verified-wrap`}>
                                                        {
                                                            selectedMenu == "All Catalogues" ?
                                                                <input type="checkbox" id={list?.cat_saved_id} onChange={handleClick} checked={isCheck.includes(list?.cat_saved_id)} /> : <></>
                                                        }
                                                        {
                                                            list?.brandlogo
                                                                ?
                                                                <div className={`pro-image-wrap`}>
                                                                    <img src={list?.brandlogo} alt="Product image" loading="lazy" />
                                                                </div>
                                                                :
                                                                <div className={`pro-image-wrap`}>
                                                                    <div className={`pro-image`}>
                                                                    </div>
                                                                </div>
                                                        }
                                                        <div className='cat-buy-pro-name'>
                                                            <div className={`brand-verified-icon brand-name`}>{list?.brandname || ``}</div>
                                                            <div className={`save-date`}>{`Saved: ${list?.created_at || ''}`}</div>
                                                            <div className={`last-view`}>{`Last viewed: ${list?.last_viewed_by || ''}`}</div>
                                                        </div>

                                                    </div>
                                                </td>
                                                <td>
                                                    {
                                                        <span className={`button-status detail`} onClick={() => { if (list?.website_url) window.open(list?.website_url) }}>{`View`}</span>
                                                    }
                                                </td>
                                                <td>
                                                    {list?.products_count || 0}
                                                </td>

                                                <td className={`last-updated`}>
                                                    {list?.last_updated_by || ''}
                                                </td>
                                                <td>
                                                    {
                                                        <span className={`button-status draft`}>{list?.status || ''}</span>
                                                    }
                                                </td>

                                                <td className={`options`}>
                                                    <div>
                                                        <div className={`star-icon`}>
                                                            {
                                                                list?.is_starred
                                                                    ?
                                                                    <div tooltip='Starred' onClick={() => { onStarChange(true, list?.cat_saved_id) }}>
                                                                        <StarIcon className={`fill`} />
                                                                    </div>
                                                                    :
                                                                    <div tooltip='Not starred' onClick={() => { onStarChange(false, list?.cat_saved_id) }}>
                                                                        <StarIcon className={`default-star`} />
                                                                    </div>

                                                            }
                                                        </div>
                                                        {
                                                            selectedMenu == "All Catalogues" ?
                                                                <div className={`more-options-wrapper`}>
                                                                    <Tooltip moreOptions={moreOptions} onOptionChange={onMoreOptionValueChange} className={`product-list-option`} data={list} type={`productList`} id={list?.cat_saved_id} />
                                                                </div> : <></>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        :
                        <div className={`no-data-found`}>
                            <div className={`icon-wrap`}>
                                <ProductIcon />
                            </div>
                            <div className={`text`}>
                                {
                                    selectedMenu == "All Catalogues" ?
                                        "You have not saved any catalogues yet" : selectedMenu == "Starred" ? "You have not Starred any catalogues yet" : "You have no recently updated catalogues yet"
                                }
                            </div>
                        </div>
                }
            </div>
        </Box>
    )
}



const CatalogueShare = (props) => {

    const { onClose, catalogueData } = props;

    const [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isLoading, setIsLoading] = useState(false);

    const onCopyClick = () => {
        copyToClipboard(catalogueData?.website_url).then((response) => {
            setAlternateMessageShow(true);
            setAlertMessageType("success");
            setAlertMessage("Share link has been copied successfully");
        }).catch((error) => {
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            setAlertMessage("Failed to copy");
        })
    }

    const onWhatsappClick = () => {
        copyToClipboard(catalogueData?.website_url).then((response) => {
            var whatsappweblink = 'whatsapp://send?text=' + catalogueData?.website_url
            window.open(whatsappweblink, '_blank');

        }).catch((error) => {
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            setAlertMessage("Failed to copy");
        })
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    return (
        <Fragment>
            {isLoading && <Loader />}
            {isAlertMessageShow && (
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            )}
            <div class="image-viewer-wrapper read-more-popup MuiBox-root css-0">

                <div class="image-view-popup">
                    <div class="close-icon" onClick={onClose}>
                        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" style={{width:"25px", height:"25px"}}> 
                            <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill='#000'></path>
                        </svg>
                    </div>
                    <div className="share-content">
                        <div className={`header-wrap w-100 w-90-popup-content mt-0`}>
                            <div className={`text`}>
                                Share catalogue with contact
                            </div>

                            <div className={`share-icons-wrap`}>
                                <div className={`icons-shadow`} onClick={() => { onCopyClick() }}>
                                    <ShareProductCopyIcon />
                                </div>
                                <div className={`icons-shadow`} onClick={() => { onWhatsappClick() }}>
                                    <WhatsappIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}


const BuyerCatalogue = (props) => {

    const { appData } = props
    const [selectedMenu, setSelectedMenu] = useState(``),
        [isLoading, setIsLoading] = useState(false),
        [allCatalogyeCount, setAllCatalogyeCount] = useState(0),
        [recentCatalogyeCount, setRecentCatalogyeCount] = useState(0),
        [starredCatalogyeCount, setStarredCatalogyeCount] = useState(0);

    const dispatch = useDispatch();

    const onLeftNavigationMenuChange = (menu) => {
        setSelectedMenu(menu)
    }
    
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }

    useEffect(() => {
        if (!props?.userDetailData?.user?.data) {
            getUserDetail()
                .then((response) => {
                    if (response && response.status === 200 && response.data) {
                        if (response.data.status) {
                            dispatch(userDetailAction.setUserDetail(response.data));
                        }
                    }
                })
                .catch((error) => {
                    console.log("Checking error", error);
                });
        }
    }, [])
    return (
        <>
            {/* <div style={{height: "59px"}}></div> */}
            <Box className={`catalogue-buyer-wrapper`}>

            {isLoading && <Loader />}
            <LeftNavigation
                onLeftNavigationMenuChange={(menu) => onLeftNavigationMenuChange(menu)}
                allCatalogyeCount={allCatalogyeCount}
                recentCatalogyeCount={recentCatalogyeCount}
                starredCatalogyeCount={starredCatalogyeCount}
                userDetails={props?.userDetailData?.user?.data || {}}
                appData={appData}
            />

            <RightNavigation
                selectedMenu={selectedMenu}
                onLoadingChange={(value) => { setIsLoading(value) }}
                onAllCatalogueCountUpdate={(value) => setAllCatalogyeCount(value)}
                onRecentCatalogueCountUpdate={(value) => setRecentCatalogyeCount(value)}
                onStarredCatalogueCountUpdate={(value) => setStarredCatalogyeCount(value)}
                allCatalogyeCount={allCatalogyeCount}
                recentCatalogyeCount={recentCatalogyeCount}
                starredCatalogyeCount={starredCatalogyeCount}
                userDetails={props?.userDetailData?.user?.data || {}}
                appData={appData}
            />
            </Box>
        </>
    )

}

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(BuyerCatalogue);