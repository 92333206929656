import React, { useState, useEffect } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";

//react-draft-wysiwyg editor related imports
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, EditorState, ContentState, convertFromHTML } from 'draft-js';

const TextEditor = (props) => {

    const [textEditorState, setTextEditorState] = useState(EditorState.createEmpty());

    const { value, onEditorChange, isReadOnly } = props;

    useEffect(() => {
        if (value) {
            setTextEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value))))
        } else {
            setTextEditorState(EditorState.createEmpty());
        }
    }, [value])

    useEffect(() => {
        if (onEditorChange) {
            onEditorChange(draftToHtml(convertToRaw(textEditorState.getCurrentContent())));
        }
    }, [textEditorState])
    const toolBarOptions = {
        options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'history'],
        inline: {
            options: ['bold', 'italic', 'underline'],
            bold: { className: 'demo-option-custom' },
            italic: { className: 'demo-option-custom' },
            underline: { className: 'demo-option-custom' }
        },
        blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
        },
        fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
        },
        list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered', 'indent', 'outdent'],
            unordered: { className: undefined },
            ordered: { className: undefined },
            indent: { className: undefined },
            outdent: { className: undefined },
        },
        textAlign: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['left', 'center', 'right', 'justify'],
            left: { className: undefined },
            center: { className: undefined },
            right: { className: undefined },
            justify: { className: undefined },
        },
        link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_self',
            options: ['link', 'unlink'],
            link: { className: undefined },
            unlink: { className: undefined },
            linkCallback: undefined
        },
        history: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['undo', 'redo'],
            undo: { className: undefined },
            redo: { className: undefined },
        },
    }

    const wrapperStyle = {
        border: '1px solid #969696',
    }
    const editorStyle = {
        height: '7rem',
        padding: '1rem'
    }

    const onTextEditorStateChange = (editorState) => {
        setTextEditorState(editorState);
    }

    return (
        <Box className={`text-editor-wrapper`}>
            <Editor
                editorState={textEditorState}
                wrapperStyle={wrapperStyle}
                editorStyle={editorStyle}
                toolbar={toolBarOptions}
                onEditorStateChange={onTextEditorStateChange}
                readOnly={isReadOnly}
            />
        </Box>
    )
}

export default TextEditor;