// React related imports
import React, { useState, useEffect } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';

//Redux related imports
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Other imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Icons related imports
import EditImageIcon from "../../../../icons/svgs/editImageIcon";

import bulkUpload from "../../../../apis/Supplier/bulkUpload";

//Components related imports
import Loader from "../../Loader";
import AlertMessage from "../../AlertMessage";

//Icons related imports
import Greentick from "../../../../icons/svgs/greentick";
import Gridicon from "../../../../icons/svgs/grid";
import { Icons } from "../../../../common/Icons"

const BulkUpload = (props) => {
    
    const { data } = props;
    const [uploadImage, setUploadImage] = useState(``),
        [isLoading, setIsLoading] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [isImageChange, setImageChange] = useState(false),
        [alertMessage, setAlertMessage] = useState(``);
        
    const schema = yup
        .object({})
        .required();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const onSubmit = (data) => {
                bulkUpload(props?.selectedBrand?.brand_id,uploadImage)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                        // setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("success");
                        setAlertMessage(response.data.message);
                        setTimeout(() => {
                            if (props.onBulkuploadClosePopup) {
                                props.onBulkuploadClosePopup(false, false, 'success');
                            }
                        }, 800);
                    } else {

                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        if (response?.message === `Network Error`) {
                            setAlertMessage(`No internet connection`);
                        } else {
                            setAlertMessage(response?.data?.message);
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("we have error at addPersona", error);
                })
    };

    useEffect(() => {
        document.body.classList.add("overflow-hide");

        return () => {
            document.body.classList.remove("overflow-hide");
        }
    }, [])

    
    const onFileChange = (file) => {
        setImageChange(true);
        setUploadImage(file);
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    return (
        <Box className={`bulk-upload-wrapper`}>
            {isLoading && <Loader />}

            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`popup-wrapper`}>              
                    <div className={`close-icon`}>
                        <CloseIcon onClick={() => { if (props.onBulkuploadClosePopup) props.onBulkuploadClosePopup(false, false, 'success') }}/>
                    </div>              
                <div className={`first-heading`}>
                    {'Bulk Product Upload via CSV'}
                </div>

                <div className={`second-heading`}>
                    {'Please download below given sample file and remove sample data and upload your data.'}
                </div>

                <form className={`form-input-control`} onSubmit={handleSubmit(onSubmit)} style={{ position: "relative" }}>

                    <Box className={`form-control brand-logo-section-wrap`}>
                        {/* <Typography className={`label`}>+ Upload csv file</Typography> */}
                        {
                            !uploadImage &&
                            <div>
                                <Typography className={`label`}>+ Upload csv file</Typography>
                                {/* <div onClick={() => { document.getElementById(`file-upload`).click(); }} className={`upload-image-div`}>+ Upload csv file</div> */}
                                <input type="file" id="file-upload" onChange={(event) => { onFileChange(event.target.files[0]) }} accept=".csv" />
                            </div>
                        }

                        {
                            uploadImage &&
                            <div className={`image-uploaded-wrapper`}>
                                <div className={`file-name-wrapper`}>
                                    <span className={`svg-wrapper`}><Greentick /></span>
                                    <span className={`file-name`}>
                                        {
                                            uploadImage[`name`].length > 50
                                                ?
                                                uploadImage[`name`].slice(0, 50) + `...`
                                                :
                                                uploadImage[`name`]
                                        }
                                    </span>
                                </div>
                                <div className={`remove-file-wrapper`} onClick={(event) => { setImageChange(false); setUploadImage(``) }}>
                                    <span>
                                        <Icons iconName="icon-close" />
                                    </span>
                                </div>
                            </div>
                        }
                    </Box>
                    <Box className={`hr`} />
                    <Box className={`form-control button-wrap`}>
                        <Button
                            type="submit"
                            variant="contained" 
                            size="large"
                            className={`btn-primary-after-login ${isValid && uploadImage !== `` ? `` : `disabled`}`}
                            disabled={isValid && uploadImage !== `` ? false : true}
                        >
                            {'Upload'}
                        </Button>
                    </Box>

                </form>
            </div>
        </Box>
    );
}

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(BulkUpload);
