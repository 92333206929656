
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import { CONFIG } from '../../helpers/getApiConfig';

const getProductsList = async (brandId, search = ``, statusby = ``, page = ``) => {

    const headers = await getApiHeader() || {};;

    if (!brandId) {
        return {
            error: true,
            message: 'brandId mandatory in getProductsList function'
        }
    }

    if (!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in getProductsList function'
        }
    }

    const instance = axios.create();
    instance.defaults.timeout = CONFIG.timeout || 15000;

    const params = {};
    if(search) params['search'] = search;
    if(statusby) params['statusby'] = statusby;
    if(page) params[`page`] = page;
    
    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/products/productlist/${brandId}`, {params}, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default getProductsList;