import React from "react";

const NoDataFound = (props) => {
  const { name = "", size = "s", color = "#fff", className = "" } = props;

  return (
	<svg width="32" height="37" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_5676_1471)">
		<path d="M22.156 0C22.6743 0 23.192 0 23.7104 0C23.9391 0.0441502 24.1671 0.095538 24.3966 0.13245C28.0393 0.71943 30.9803 3.57037 31.784 7.29636C32.6678 11.3965 30.7855 15.5698 27.1548 17.5196C26.8971 17.6579 26.8121 17.8005 26.8128 18.09C26.8213 23.6761 26.8199 29.2621 26.8185 34.8475C26.8185 36.1937 26.048 36.9848 24.7429 36.9848C17.1947 36.9848 9.64646 36.9703 2.09756 37C0.88945 37.0051 -0.00211429 36.096 0.000718316 34.8699C0.0233792 25.5644 0.0247955 16.2581 1.01638e-05 6.95256C-0.0035306 5.69103 0.918484 4.80368 2.08623 4.81526C6.25441 4.85652 10.4233 4.82974 14.5922 4.83625C14.8259 4.83625 14.9583 4.77835 15.0893 4.55615C16.2351 2.60848 17.8525 1.23041 19.9671 0.513155C20.6738 0.272139 21.4245 0.167192 22.156 0ZM25.194 18.331C21.7622 19.0598 18.7434 18.2992 16.3251 15.6979C13.901 13.0902 13.3104 9.95912 14.1999 6.50238C14.1149 6.49297 14.0689 6.48356 14.0236 6.48356C10.0317 6.48284 6.04055 6.48139 2.0487 6.48428C1.67125 6.48428 1.62026 6.55304 1.62026 7.00902C1.61956 16.2777 1.61956 25.5463 1.62026 34.8149C1.62026 35.2876 1.66275 35.3295 2.12518 35.3295C9.63726 35.3295 17.1493 35.3303 24.6614 35.3295C25.1748 35.3295 25.194 35.3085 25.194 34.7744C25.194 29.4178 25.194 24.0611 25.194 18.7045C25.194 18.585 25.194 18.4663 25.194 18.331ZM27.2164 7.06764C26.4814 6.31564 25.8426 5.66208 25.1975 5.002C24.4207 5.77281 23.6304 6.55666 22.8337 7.3463C22.066 6.58054 21.2857 5.80176 20.5124 5.0295C19.8892 5.68017 19.2518 6.34532 18.5331 7.09587C19.3078 7.83846 20.1137 8.61073 21.0003 9.46044C20.1512 10.2711 19.3446 11.0419 18.5586 11.7917C19.2568 12.5061 19.8948 13.1596 20.5456 13.8248C21.3027 13.0656 22.0887 12.2774 22.8896 11.474C23.6658 12.2614 24.4469 13.0532 25.2074 13.8248C25.8547 13.164 26.4948 12.5111 27.1966 11.7953C26.3978 11.0375 25.5877 10.2682 24.7096 9.43438C25.5976 8.59698 26.4091 7.8305 27.2164 7.06764Z" fill="#CCCCCC"/>
		<path d="M13.0553 29.4005C11.2191 29.4005 9.38283 29.4012 7.54659 29.4005C6.97652 29.4005 6.62174 29.098 6.6097 28.6065C6.59695 28.0977 6.95811 27.7481 7.53313 27.7474C11.229 27.7431 14.9248 27.7431 18.6207 27.7474C19.1936 27.7481 19.5547 28.102 19.5399 28.6109C19.5257 29.1023 19.1709 29.4005 18.5987 29.4005C16.7512 29.4005 14.9029 29.4005 13.0553 29.4005Z" fill="#CCCCCC"/>
		<path d="M13.0594 23.5585C11.1997 23.5585 9.34014 23.56 7.48053 23.5571C7.04714 23.5564 6.7915 23.3783 6.66049 23.0092C6.54365 22.6806 6.6265 22.3028 6.91543 22.1095C7.08255 21.9981 7.30491 21.917 7.50249 21.9163C11.2217 21.9054 14.9416 21.9054 18.6608 21.9105C19.1955 21.9112 19.5446 22.2536 19.5446 22.7313C19.5446 23.2422 19.2054 23.5564 18.6389 23.5571C16.7786 23.56 14.919 23.5585 13.0594 23.5585Z" fill="#CCCCCC"/>
		<path d="M10.3796 17.7243C9.42647 17.7243 8.47401 17.7279 7.52083 17.7228C6.94935 17.7199 6.61511 17.4131 6.60944 16.905C6.60378 16.3969 6.96493 16.0589 7.53995 16.0574C9.42222 16.0531 11.3045 16.0516 13.1875 16.0581C13.8737 16.0603 14.2688 16.5387 14.101 17.1358C13.9962 17.5064 13.7058 17.7207 13.2739 17.7228C12.3094 17.7272 11.3441 17.7243 10.3796 17.7243Z" fill="#CCCCCC"/>
		</g>
		<defs>
		<clipPath id="clip0_5676_1471">
		<rect width="32" height="37" fill="white"/>
		</clipPath>
		</defs>
	</svg>
  );
};

export default NoDataFound;
