
const sizeArray = [
    {
        "path": "/",
        "size": "sm",
        "class": "login-container for-auth-container",
    },
    {
        "path": "/register",
        "size": "100%",
        "class": "register-container",
    },
    {
        "path": "/login",
        "size": "sm",
        "class": "login-container for-auth-container",
    },
    {
        "path": "/password_reset",
        "size": "sm",
        "class": "password-reset-container for-auth-container",
    },
    {
        "path": "/email_verification",
        "size": "100%",
        "class": "email-verification-container",
    },
    {
        "path": "/email_verification_success",
        "size": "100%",
        "class": "email-verification-success-container",
    },
    {
        "path": "/onboarding",
        "size": "md",
        "class": "onboarding-container",
    },
    {
        "path": "/forgot_password_confirm",
        "size": "sm",
        "class": "login-container for-auth-container",
    },
    {
        "path": "/myproducts",
        "size": "100%",
        "class": "brand-container",
    },
    {
        "path": "/catalogues",
        "size": "100%",
        "class": "catalogues-container",
    },  
    {
        "path": "/profile",
        "size": "100%",
        "class": "profile-container",
    },
    {
        "path": "/editproduct",
        "size": "100%",
        "class": "edit-product-container",
    },
    {
        "path": "/catalogue/detail",
        "size": "100%",
        "class": "catalogue-detail-container",
    },
    {
        "path": "/support",
        "size": "100%",
        "class": "support-container",
    },
    {
        "path": "/buyer/catalogue",
        "size": "100%",
        "class": "buyer-catalogue-container",
    },
    {
        "path": "/buyer/profile",
        "size": "100%",
        "class": "buyer-profile-container",
    },
    {
        "path": "/buyer/onboarding",
        "size": "md",
        "class": "buyer-onboarding-container",
    },
    {
        "path": "/buyer/email_verification",
        "size": "100$",
        "class": "email-verification-container",
    },
    {
        "path": "/buyer/email_verification",
        "size": "100$",
        "class": "email-verification-container",
    },
    {
        "path": "/invite_reset_link",
        "size": "100$",
        "class": "email-verification-container",
    },
    {
        "path": "/invite_reset_password",
        "size": "sm",
        "class": "login-container for-auth-container",
    },
]


const getPageContainerSize = () => {

    return sizeArray;
}

export default getPageContainerSize;