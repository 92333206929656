
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const getCatalogueDetails = async (catalogueID) => {

    const headers = await getApiHeader() || {};;

    if (!catalogueID) {
        return {
            error: true,
            message: 'productId mandatory in getProductDetails function'
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in getProductDetails function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-detail/${catalogueID}`, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default getCatalogueDetails;