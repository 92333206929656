
import axios from 'axios';

import {CONFIG} from '../helpers/getApiConfig';

const emailnewlink = async (email) => {
    if(!email) {
        return {
            error: true,
            message: 'email mandatory in emailnewlink function' 
        }
    }
    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;
    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/email-new-link`, { email: email })
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default emailnewlink