// React related imports
import React, { useState, useEffect } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';

// React route related imports
import { useNavigate, Link, useLocation } from "react-router-dom";

//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import useAction from "../../../../store/action/userAction";
import appObjectAction from "../../../../store/action/appObjectAction";

//Svgs or image related imports
import Logo from "../../../../assets/logo/white-text-logo.svg";
//Apis related imports
import logout from "../../../../apis/logout";
import getUserDetail from "../../../../apis/getUserDetail";

//Helpers related imports
import removeCookie from "../../../../helpers/removeCookie";
import removeLocalStorage from "../../../../helpers/removeLocalStorage";

//Icons related imports
import IconBrandSearch from "../../../../icons/svgs/brandsearch";
import IconBrandEmail from "../../../../icons/svgs/brandemail";
import IconBrandNotification from "../../../../icons/svgs/brandnotification";

//Components related imports
import ConformationPopup from "../../Supplier/ConformationPopup";

const SliderMenu = (props) => {

    const { isSliderMenuShow, headerMenu, onClose } = props;
    const [selectedMenu, setSelectedMenu] = useState(headerMenu && headerMenu.length > 0 && headerMenu[0]);
    const dispatch = useDispatch(), navigate = useNavigate();

    const mobileHeadermenuoptionchange = (event) => {
        
        const { myValue } = event.currentTarget.dataset;
        // console.log(myValue)
        switch (myValue) {
            case `My Products`:
                navigate('/myproducts');
                break;
            case `My Catalogues`:
                navigate('/buyer/catalogue');
                break;
        }
        if (onClose) onClose(false);
    }
    return (
        <Box className={`slider-menu-wrap ${isSliderMenuShow ? 'show-overlay' : ''}`}>
            <div className={`slider-wrap ${isSliderMenuShow ? 'show' : 'hide'}`}>
                <div className={`menu-content`}>
                    <div className={`close-icon`} onClick={() => { if (onClose) onClose(false); }}>
                        <CloseIcon />
                    </div>
                    <div className={`menu-icon`}>
                        {
                            headerMenu && headerMenu.length > 0 &&
                            headerMenu.map((header, index) => {
                                return (
                                    <div className={`menu ${selectedMenu === header ? 'selected' : ''}`} onClick={mobileHeadermenuoptionchange} data-my-value={header} key={index}>
                                        {header}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </Box>
    )
}
const Header = (props) => {

    const tooltipMenu = ['Settings', 'Logout'];
    const headerMenu = ['My Catalogues'];

    const { app } = props?.appData || {};

    const [userDetail, setUserDetail] = useState(props?.userDetailData || {}),
        [userDetailProfile, setUserDetailProfile] = useState(userDetail?.user_profile || ``),
        [selectedMenu, setSelectedMenu] = useState(headerMenu && headerMenu.length > 0 && headerMenu[0]),
        [isTooltipOpen, setToolTipValue] = useState(null),
        [isLoading, setIsLoading] = useState(false),
        [isSliderMenuShow, setIsBrandSliderShow] = useState(false),
        [isConformationPopupShow, setConformationPopup] = useState(false),
        dispatch = useDispatch(), navigate = useNavigate(), location = useLocation();

    const isMenuShow = location?.pathname === `/buyer/onboarding` ? false : true;

    const onUserTooltipOpen = (event) => {
        setToolTipValue(event.currentTarget);
    };

    useEffect(() => {
        setUserDetailProfile(props?.userDetailData?.user?.data?.user_profile)
    }, [props?.userDetailData]);


    const headermenuoptionchange = (event) => {


        const { myValue } = event.currentTarget.dataset;
        // console.log(myValue)
        switch (myValue) {
            case `My Catalogues`:
                setSelectedMenu(myValue)
                navigate('/buyer/catalogue');
                break;
        }
    };
    const onUserTooltipClose = (event) => {
        const { myValue } = event.currentTarget.dataset;
        switch (myValue) {
            case `Settings`:
                //Profile page redirect
                navigate('/buyer/profile');
                // navigate(`/profile`,{state: {"profileheaderupdate" : profileheaderupdate,}})
                break;
            case `Logout`:
                setConformationPopup(true);
                break;
        }
        setToolTipValue(null);
    };


    const onMobileSliderClose = (value) => {
        setIsBrandSliderShow(value);
    }

    const onLogout = () => {
        logout()
            .then((response) => {
                if (response && response.status === 200 && response.data) {
                    setIsLoading(false);
                    dispatch(useAction.setUser({}));
                    removeCookie(`discoverrrToken`);
                    removeCookie(`discoverrrRefreshToken`);
                    // let obj = app;
                    // obj[`isTokenRefreshed`] = false;
                    // dispatch(appAction.setAppObject(obj))
                    let tempObject = app;
                    tempObject[`productSelectedBrand`] = {};
                    dispatch(appObjectAction.setAppObject(tempObject))
                    removeLocalStorage(`productSelectedBrand`);
                    removeLocalStorage(`productEditingStep`);
                    navigate('/login');
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("Checking error", error);
            });
    }
    return (
        <Box className={`buyer-header-wrapper`}>
            {
                isMenuShow &&
                <div className={`only-mobile mobile-menu`}>
                    <div className={`hamburger-wrap`} onClick={() => { setIsBrandSliderShow(!isSliderMenuShow) }}>
                        <SliderMenu
                            isSliderMenuShow={isSliderMenuShow}
                            headerMenu={headerMenu}
                            headermenuoptionchange={headermenuoptionchange}
                            onClose={(value) => onMobileSliderClose(value)}
                        />
                        <MenuIcon />
                    </div>
                </div>
            }

            <div className={`logo-wrapper`}>
                <img className={`logo`} src={Logo} alt="Discoverrr logo" />
            </div>

            <div className="right-menu">
                {
                    isMenuShow &&
                    <div className={`menu-wrapper not-mobile`}>
                        {
                            headerMenu && headerMenu.length > 0 &&
                            headerMenu.map((header, index) => {
                                return (
                                    <div className={`menu ${selectedMenu === header ? 'selected' : ''}`} onClick={headermenuoptionchange} data-my-value={header} key={index}>
                                        {header}
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                <div className={`user-icon-wrapper flex flex-justify-content-end align-items-center`}>
                    <div className={`icon-navigation`}>
                        <ul className={`menu-wrapper`}>
                            <li>
                                <Link to={`/support`} tooltip="Support" flow="left" className={`support`} target="_blank">
                                    <HeadsetMicIcon />
                                </Link>
                            </li>
                            {/*  <li>
                                <a href="#">
                                    <IconBrandEmail />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <IconBrandNotification />
                                    <span className={`bubble-count`}>4</span>
                                </a>
                            </li> */}
                        </ul>
                    </div>

                    {/* <div className={`vertical-line not-mobile`}></div> */}
                    <Tooltip className={`brand-head-profile`} title="Open settings">
                        <IconButton onClick={onUserTooltipOpen} sx={{ p: 0 }}>
                            {
                                userDetailProfile === ``
                                    ?
                                    <Avatar className={`avatar`} />
                                    :
                                    <Avatar src={userDetailProfile} />
                            }
                            <ChevronRightIcon className={`right-arrow`} />
                        </IconButton>
                    </Tooltip>

                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={isTooltipOpen}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(isTooltipOpen)}
                        onClose={onUserTooltipClose}
                    >
                        {
                            tooltipMenu && tooltipMenu.length > 0 &&
                            tooltipMenu.map((menu, index) => (
                                <MenuItem key={index} onClick={onUserTooltipClose} data-my-value={menu}>
                                    <Typography textAlign="center">{menu}</Typography>
                                </MenuItem>
                            ))
                        }
                    </Menu>
                </div>
            </div>


            {
                isConformationPopupShow &&
                <ConformationPopup
                    heading={`Are you sure you want to log out?`}
                    cancelBtnText={`No`}
                    submitBtnText={`Yes`}
                    onCancel={() => setConformationPopup(false)}
                    onSubmit={() => onLogout()}
                />
            }

        </Box>
    )
};

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
