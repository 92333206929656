import React, { useState, useEffect, Fragment } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

//Redux related imports
import { useDispatch } from "react-redux";
import useAction from "../../../../../../store/action/userAction";
import appObjectAction from "../../../../../../store/action/appObjectAction";

//Api related imports
import userAccountPasswordReset from "../../../../../../apis/userAccountPasswordReset";

//Icons related imports
import Greentick from "../../../../../../icons/svgs/greentick";
import Graytick from "../../../../../../icons/svgs/graytick";

//Components related imports
import AlertMessage from "../../../../AlertMessage";
import Loader from "../../../../Loader";
import { Icons } from "../../../../../../common/Icons";

// Icons related imports
import logout from "../../../../../../apis/logout";

//Helpers related imports
import removeCookie from "../../../../../../helpers/removeCookie";
import removeLocalStorage from "../../../../../../helpers/removeLocalStorage";

//Other imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schemapassword = yup
    .object({
        currentpassword: yup
            .string()
            .trim()
            .required("Current Password is required"),
        password: yup
            .string()
            .trim()
            .required("No password provided.")
            .matches(/([0-9])/, "Password should contain minimum 8 characters with at least one alphabet, one number, and one special character (!@#()$%&)*")
            .matches(/([a-zA-Z])/, "Password should contain minimum 8 characters with at least one alphabet, one number, and one special character (!@#()$%&)*")
            .matches(/([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/, "Password should contain minimum 8 characters with at least one alphabet, one number, and one special character (!@#()$%&)*")
            .min(8, "Password is too short - should be 8 chars minimum."),
        confirmpassword: yup
            .string()
            .trim()
            .required("Confirm Password is required"),
    })
    .required();

const ChangePasswordSection = (props) => {

    const { app } = props?.appData || {};

    const [currentpassword, setCurrentpassword] = useState(``),
        [iscurrentPasswordVisible, setCurrentpasswordVisible] = useState(false),
        [isPasswordVisible, setPasswordVisible] = useState(false),
        [isrepeatPasswordVisible, setRepeatpasswordVisible] = useState(false),
        [password, setPassword] = useState(``),
        [confirmpassword, setConfirmpassword] = useState(``),
        [showPasswordValidation, setShowPasswordValidation] = useState(false),
        [isMininumPasswordRegexValid, setIsMininumPasswordRegexValid] = useState(false),
        [isPasswordRegexValid, setIsPasswordRegexValid] = useState(false),
        [checkPasswordRegex, setCheckPasswordRegex] = useState(false),
        [isPasswordResetSuccessfully, setIsPasswordResetSuccessfully] = useState(false),
        [isLoading, setIsLoading] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``);

    const minimumLengthRegex = /^(?=.{8,}$)/;
    const passWordRegex = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<]).*$/;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    }
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schemapassword),
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        setIsMininumPasswordRegexValid(minimumLengthRegex.test(password) ? true : false);
        setIsPasswordRegexValid(passWordRegex.test(password) ? true : false)
    }, [password])

    const onSubmit = (data) => {
        // console.log(data)
        setIsLoading(true);
        userAccountPasswordReset(currentpassword, password, confirmpassword)
            .then((response) => {
                if (response && response.status === 200 && response.data) {
                    if (response.data.status) {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("success");
                        setAlertMessage(response.data.message);
                        logout()
                            .then((response) => {
                                if (response && response.status === 200 && response.data) {
                                    setIsLoading(false);
                                    dispatch(useAction.setUser({}));
                                    removeCookie(`discoverrrToken`);
                                    removeCookie(`discoverrrRefreshToken`);
                                    // let obj = app;
                                    // obj[`isTokenRefreshed`] = false;
                                    // dispatch(appAction.setAppObject(obj))
                                    let tempObject = app;
                                    tempObject[`productSelectedBrand`] = {};
                                    dispatch(appObjectAction.setAppObject(tempObject))
                                    removeLocalStorage(`productSelectedBrand`);
                                    removeLocalStorage(`productEditingStep`);
                                    navigate('/login');
                                } else {
                                    setIsLoading(false);
                                }
                            })
                            .catch((error) => {
                                setIsLoading(false);
                                console.log("Checking error", error);
                            });
                    }
                    else {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        setAlertMessage(response.data.message)
                    }
                } else {
                    if (response && response.response && response.response.data && response.response.data.message) {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        setAlertMessage(response.response.data.message)
                    }
                }
            })
            .catch((error) => {
                setAlertMessage(false);
                console.log("We have error", error);
            })
    }

    return (
        <Fragment>
            {isLoading && <Loader />}
            {
                isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <Box className={`change-password-wrapper`}>
                <h1 className={`heading`}>Change Password</h1>
                <div className={`divider`}></div>
                <div className={`password-inner-container`}>
                    <form className={`form-input-control`} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <Box className={`form-control`}>
                            <div className={`password-items`}>
                                <div className={`flex flex-items-center flex-justify-content-space-between`}>
                                    <Typography className={`label`}>Enter Current password</Typography>
                                    <Typography
                                        className={`label show-label`}
                                        onClick={() => setCurrentpasswordVisible(!iscurrentPasswordVisible)}
                                    >
                                        <span className="flex flex-items-center flex-justify-content-center gap-10" style={{ cursor: "pointer" }}>
                                            <Icons iconName={`${iscurrentPasswordVisible ? "icon-eye-off" : "icon-eye"}`} />
                                            <span className="login-password__hide-show">{`${iscurrentPasswordVisible ? "Hide" : "Show"}`}</span>
                                        </span>
                                    </Typography>
                                </div>
                                <div className={`name-wrapper`}>
                                    <Box className={`fname-wrapper form-control`}>
                                        <TextField
                                            id="currentpassword"
                                            name="currentpassword"
                                            type={`${iscurrentPasswordVisible ? "text" : "password"}`}
                                            value={currentpassword}
                                            placeholder={`Enter Current password`}
                                            {...register("currentpassword", {
                                                onChange: (event) => {
                                                    setCurrentpassword(event.target.value);
                                                },
                                            })}
                                            className={`form-profile-field`}
                                            error={errors.currentpassword ? true : false}
                                        />
                                        <p className={`field-err-msg`}>{errors.currentpassword?.message}</p>
                                    </Box>
                                </div>
                            </div>
                            <div className={`password-items`}>
                                <div className={`flex flex-items-center flex-justify-content-space-between`}>
                                    <Typography className={`label`}>Enter New Password</Typography>
                                    <Typography
                                        className={`label show-label`}
                                        onClick={() => setPasswordVisible(!isPasswordVisible)}
                                    >
                                        <span className="flex flex-items-center flex-justify-content-center gap-10" style={{ cursor: "pointer" }}>
                                            <Icons iconName={`${isPasswordVisible ? "icon-eye-off" : "icon-eye"}`} />
                                            <span className="login-password__hide-show">{`${isPasswordVisible ? "Hide" : "Show"}`}</span>
                                        </span>
                                    </Typography>
                                </div>
                                <div className={`name-wrapper password-wrapper ${showPasswordValidation ? "height-160" : ""}`}>
                                    <Box className={`fname-wrapper form-control`}>
                                        <TextField
                                            type={`${isPasswordVisible ? "text" : "password"}`}
                                            id="password"
                                            name="password"
                                            placeholder={`Enter New Password`}
                                            margin="dense"
                                            className={`form-profile-field`}
                                            error={errors.password && !(isMininumPasswordRegexValid && isPasswordRegexValid) && checkPasswordRegex ? true : false}
                                            spellCheck={false}
                                            inputProps={{
                                                onClick: () => setShowPasswordValidation(true),
                                                onFocus: () => { setShowPasswordValidation(true); setCheckPasswordRegex(true) },
                                            }}
                                            {...register("password", {
                                                onChange: (event) => {
                                                    setPassword(event.target.value);
                                                },
                                                onBlur: () => {
                                                    setShowPasswordValidation(false);
                                                    setCheckPasswordRegex(false);
                                                }
                                            })}
                                        />
                                        {
                                            <div className={`password-validation ${showPasswordValidation ? "show" : ""}`}>
                                                <p>
                                                    <span> {minimumLengthRegex.test(password) ? <Greentick /> : <Graytick />} </span>
                                                    <span> 8 character minimum</span>
                                                </p>
                                                <p>
                                                    <span> {passWordRegex.test(password) ? <Greentick /> : <Graytick />} </span>
                                                    <span> Includes at least 1 uppercase, 1 lowercase, 1 number and 1 special character</span>
                                                </p>
                                            </div>
                                        }
                                    </Box>
                                </div>
                            </div>
                            <div className={`password-items`}>
                                <div className={`flex flex-items-center flex-justify-content-space-between`}>
                                    <Typography className={`label`}>Repeat New Password</Typography>
                                    <Typography
                                        className={`label show-label`}
                                        onClick={() => setRepeatpasswordVisible(!isrepeatPasswordVisible)}
                                    >
                                        <span className="flex flex-items-center flex-justify-content-center gap-10" style={{ cursor: "pointer" }}>
                                            <Icons iconName={`${isrepeatPasswordVisible ? "icon-eye-off" : "icon-eye"}`} />
                                            <span className="login-password__hide-show">{`${isrepeatPasswordVisible ? "Hide" : "Show"}`}</span>
                                        </span>
                                    </Typography>
                                </div>
                                <div className={`name-wrapper`}>
                                    <Box className={`fname-wrapper form-control`}>
                                        <TextField
                                            id="confirmpassword"
                                            placeholder={`Repeat New Password`}
                                            value={confirmpassword}
                                            type={`${isrepeatPasswordVisible ? "text" : "password"}`}
                                            {...register("confirmpassword", {
                                                onChange: (event) => {
                                                    setConfirmpassword(event.target.value);
                                                },
                                            })}
                                            className={`form-profile-field`}
                                            error={errors.confirmpassword ? true : false}
                                        />
                                        <p className={`field-err-msg`}>{errors.confirmpassword?.message}</p>
                                    </Box>
                                </div>
                            </div>
                            <div className={`button-section`}>
                                <div className={`divider`}></div>
                                <div className={`button-wrapper`}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        className={`btn-primary-after-login`}
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </div>
                        </Box>
                    </form>
                </div>
            </Box>
        </Fragment>
    )
}

export default ChangePasswordSection;