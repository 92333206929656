
import axios from 'axios';
import getApiHeader from '../../helpers/getApiHeader';
import { CONFIG } from '../../helpers/getApiConfig';

const emailVerification = async (catalogueId, email) => {

    if (!catalogueId) {
        return {
            error: true,
            message: 'catalogueId mandatory in emailVerification function'
        }
    }

    if (!email) {
        return {
            error: true,
            message: 'email mandatory in emailVerification function'
        }
    }

    const instance = axios.create();

    instance.defaults.timeout = CONFIG.timeout || 15000;
    const headers = await getApiHeader() || {};;

    var formData = new FormData();
    if(email) formData.append("email", email);

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-website-email-auth/${catalogueId}`, formData, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default emailVerification;