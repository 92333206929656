import React, { useState, useEffect, Fragment } from 'react';


// mui or UI related imports
import Box from "@mui/material/Box";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import TextField from "@mui/material/TextField";
// import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

//icons related imports
import { Icons } from "../../../../common/Icons";

//import other component related imports
import Loader from '../../Loader';
import AlertMessage from "../../AlertMessage";
import PaginationComponent from '../Pagination';
import Tooltip from '../Tooltip';
import ConformationPopup from "../ConformationPopup";

// React route related imports
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

//Icons related imports
import IconBrandDefaultImage from "../../../../icons/svgs/branddefaultimage";
import MoreIcons from '../../../../icons/svgs/moreIcons';
import SearchIcon from '../../../../icons/svgs/searchIcon';
import MasterCatalogueIcon from '../../../../icons/svgs/masterCatalogue';
import AnonymousIcon from '../../../../icons/svgs/anonymousIcon';
import ProductImage from '../../../../assets/img/pro-img.png';

import imageResizing from "../../../../helpers/imageResizing";

//Apis related imports
import getCatalogueContactList from '../../../../apis/Supplier/getCatalogueContactList';
import getCatalogueContactLeadGenList from '../../../../apis/Supplier/getCatalogueContactLeadGenList';
import getCatalogueDetails from '../../../../apis/Supplier/getCatalogueDetails';
import getCatalogueContactCount from '../../../../apis/Supplier/getCatalogueContactCount';
import catalogueAction from '../../../../apis/Supplier/catalogueAction';
import catalogueContactDelete from '../../../../apis/Supplier/catalogueContactDelete';
import catalogueContactInviteResend from '../../../../apis/Supplier/catalogueContactInviteResend';
import getContactDetailAnalytics from '../../../../apis/Supplier/getContactDetailAnalytics';


const CatalogueInfromationSection = (props) => {

    const [pricingChecked, setPricingChecked] = useState(false);
    const [catalogueID, setcatalogueID] = useState(props?.catalogueID);
    const [catalogueDetail, setcatalogueDetail] = useState(props?.catalogueID),
        [isLoading, setIsLoading] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``);
    const onPriceSwitchChange = (event) => {
        setIsLoading(true);
        setPricingChecked(event.target.checked);
        let priceupdate = "1"
        if (event.target.checked === true) {
            priceupdate = "1"
        }
        else {
            priceupdate = "2"
        }

        catalogueAction(catalogueID, 'PRICE', priceupdate)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((err) => {
                console.log("we have error on Product Action", err);
            })
    };
    useEffect(() => {
        getCatalogueDetail()
    }, [catalogueID]);

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const getCatalogueDetail = () => {
        setIsLoading(true);
        getCatalogueDetails(catalogueID, ``)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setcatalogueDetail(response?.data?.data?.[0]);
                    setPricingChecked(response?.data?.data?.[0]?.is_price)
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at catalogue List", error);
            });
    }

    return (
        <Fragment>
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <Box className={`catalogue-information-section`}>
                <div className={`information-wrap`}>
                    <div className={`img-section`}>
                        <Icons iconName={`icon-eye`} />
                    </div>

                    <div className={`info-wrap`}>
                        <div className={`heading-text`}>
                            <div className={`text`}>
                                {catalogueDetail?.name}
                            </div>
                        </div>

                        <div className={`pricing-wrap`}>
                            <div className={`pricing-text`}>
                                Show product pricing
                            </div>

                            <div className={`pricing-switch`}>
                                <Switch
                                    checked={pricingChecked}
                                    onChange={onPriceSwitchChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                        </div>

                        <div className={`view-wrap`}>
                            <div>
                                <div className={`label`}>
                                    Contacts
                                </div>
                                <div className={`value`}>
                                    {catalogueDetail?.contact}
                                </div>
                            </div>

                            <div>
                                <div className={`label`}>
                                    Views
                                </div>

                                <div className={`value`}>
                                    {catalogueDetail?.views}
                                </div>
                            </div>

                            <div>
                                <div className={`label`}>
                                    Products
                                </div>

                                <div className={`value`}>
                                    {catalogueDetail?.products}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    {/* <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    className={`btn-primary-after-login`}
                >
                    {'Share catalogue'}
                </Button> */}
                </div>
            </Box>
        </Fragment>
    )
}

const ContactManageSection = (props) => {

    const defaultmanagesection = props;
    const optionMenu = [`Contacts`, `Lead Gen`];
    const [selectedOption, setSelectedOption] = useState(`Contacts`),
        [catalogueID, setcatalogueID] = useState(props?.catalogueID),
        [contactCount, setcontactCount] = useState(''),
        [contactLeadCount, setcontactLeadCount] = useState(''),
        [contactrefresh, setcontactrefresh] = useState(props?.contactrefresh),
        [isLoading, setIsLoading] = useState(false);
        
        
        useEffect(() => {
            console.log('refreshcount triggered')
            getCatalogueContactCountlist()
        }, [catalogueID, contactrefresh]);
        

    const getCatalogueContactCountlist = () => {
        setIsLoading(true);
        getCatalogueContactCount(catalogueID)
            .then((response) => {

                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setcontactCount(response?.data?.data?.contact_count);
                    setcontactLeadCount(response?.data?.data?.contact_lead_count);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at catalogue List", error);
            });
    }

    return (
        <Box className={`contacts-manage-section`}>
            <Box className={`option-wrapper`}>
                {
                    optionMenu && optionMenu.map((menu, index) => {
                        return (
                            <div key={index} className={`option ${selectedOption === menu ? 'selected' : ''}`} onClick={() => { if (props.onSelectionChange) props.onSelectionChange(menu); setSelectedOption(menu) }}>
                                {menu}
                                <span className={`contacts-count`}>({menu === `Contacts` ? contactCount : contactLeadCount})</span>
                            </div>
                        )
                    })
                }
            </Box>

            <div className={`divider`}></div>
        </Box>
    )
}

const ContactsListSection = (props) => {
    const { onDetailClick } = props;
    const { onContactCountRefresh } = props;
    const [catalogueID, setcatalogueID] = useState(props?.catalogueID);
    const [catalogueContactList, setcatalogueContactList] = useState([]),
        [isLoading, setIsLoading] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [searchValue, setSearchValue] = useState(``),
        [totalCount, setTotalCount] = useState(0),
        [perPage, setPerPage] = useState(0),
        [isPaginatioShow, setIsPaginatioShow] = useState(false),
        [currentPage, setCurrentPage] = useState(1),
        [isConfirmationPopupShow, setIsConfirmationPopupShow] = useState(false),
        [moreOptionSelectedData, setMoreOptionSelectedData] = useState({}),
        [conformationPopupSuccessBtnText, setConformationPopupSuccessBtnText] = useState(``),
        [conformationPopupCancelBtnText, setConformationPopupCancelBtnText] = useState(``),
        [conformationPopupText, setConformationPopText] = useState(``),
        [conformationPopupType, setConformationType] = useState(``);

    const navigate = useNavigate();

    useEffect(() => {
        setcatalogueID(props?.catalogueID)
    }, [catalogueID]);

    useEffect(() => {
        getCatalogueContactListData();
    }, [currentPage]);

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    }

    const returnMoreOptionValues = (catalogue) => {

        let options = [];

        const obj1 = { "label": "Delete", "value": "Remove" };
        options.push(obj1);
        const obj2 = { "label": "Resend Email", "value": "Email" };
        options.push(obj2);

        return options

    }
    const onMoreOptionValueChange = (option, data) => {

        switch (option[`value`]) {
            case `Remove`:
                // Delete a Contact Catalogue
                setMoreOptionSelectedData(data);
                setIsConfirmationPopupShow(true);
                setConformationPopText(`Are you sure you want to delete this contact?`);
                setConformationPopupSuccessBtnText(`Yes`);
                setConformationPopupCancelBtnText(`No`);
                setConformationType(`Delete Catalogue Contact`);
                break;
            case `Email`:
                // Catalogue invite link to this contact
                setMoreOptionSelectedData(data);
                setIsConfirmationPopupShow(true);
                setConformationPopText(`Resend catalogue to this contact?`);
                setConformationPopupSuccessBtnText(`Yes`);
                setConformationPopupCancelBtnText(`No`);
                setConformationType(`Resend Email`);
                break;
        }
    }
    const getCatalogueContactListData = () => {
        setIsLoading(true);
        getCatalogueContactList(catalogueID, ``, currentPage)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setcatalogueContactList(response?.data?.data);
                    setTotalCount(response?.data?.count);
                    setPerPage(response?.data?.perpage);
                    setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                    setCurrentPage(1);
                    setIsLoading(false);
                    onContactCountRefresh(true)
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at catalogue List", error);
            });
    }
    const onConformationPopupClose = (value) => {
        setIsConfirmationPopupShow(value);
    }

    const onConformationPopupSuccess = () => {
        setIsConfirmationPopupShow(false);
        switch (conformationPopupType) {
            case `Delete Catalogue Contact`:
                deletecataloguecontact();
                break;
            case `Resend Email`:
                invide_resend_email();
                break;
        }
    }

    const deletecataloguecontact = () => {
        catalogueContactDelete(moreOptionSelectedData?.cat_contact_id)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        getCatalogueContactListData();
                    }, 500);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((err) => {
                console.log("we have error on Product Action", err);
            })
    }

    const invide_resend_email = () => {
        catalogueContactInviteResend(moreOptionSelectedData?.cat_contact_id)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        getCatalogueContactListData();
                    }, 500);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((err) => {
                console.log("we have error on Product Action", err);
            })
    }

    const onCatalogueSearchClick = (event) => {

        if (event.key === "Enter" || event.type === "click" || event.type === "change") {
            setIsLoading(true);
            let searchval = (event.type === "keypress") ? event.target.value : (event.type === "click") ? searchValue : ``
            getCatalogueContactList(catalogueID, searchval, currentPage)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                        setcatalogueContactList(response?.data?.data);
                        setTotalCount(response?.data?.count);
                        setPerPage(response?.data?.perpage);
                        setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                        setCurrentPage(1);
                        setIsLoading(false);

                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("we have error at catalogue List", error);
                });

        }
    }

    const onSearchRemove = () => {
        document.getElementById(`search`).value = '';
        setSearchValue(false);
        setIsLoading(true);
        getCatalogueContactList(catalogueID, ``, ``)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setcatalogueContactList(response.data.data);
                    setTotalCount(response?.data?.count);
                    setPerPage(response?.data?.perpage);
                    setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                    setIsLoading(false);

                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at catalogue List", error);
            });
    }
    const oncontactclick = (contact) => {
        onDetailClick(contact)
    }

    return (
        <Fragment>
            {
                isConfirmationPopupShow &&
                <ConformationPopup
                    heading={conformationPopupText}
                    cancelBtnText={conformationPopupCancelBtnText}
                    submitBtnText={conformationPopupSuccessBtnText}
                    onCancel={onConformationPopupClose}
                    onSubmit={onConformationPopupSuccess}
                />
            }
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <Box className={`contacts-list-section`}>
                <Box className={`filter-section`}>
                    <div className={`text`}>
                        {/* Contacts */}
                    </div>
                    <div className={`search-wrap`}>
                        <TextField
                            id="search"
                            name="search"
                            placeholder={`Search Contacts`}
                            margin="dense"
                            spellCheck={false}
                            className={`search-input`}
                            inputProps={{
                                onKeyPress: (e) => {
                                    onCatalogueSearchClick(e);
                                },
                                onChange: (e) => {
                                    setSearchValue(e.target.value);
                                    let targetvalue = e.target.value
                                    if (targetvalue === "") onCatalogueSearchClick(e);
                                }
                            }}
                        />
                        {
                            searchValue &&
                            <span className={`close-icon`} onClick={() => onSearchRemove()}>
                                <CloseIcon />
                            </span>
                        }
                        <button className={`search-icon-wrap`} onClick={(e) => { onCatalogueSearchClick(e) }}>
                            <SearchIcon />
                        </button>
                    </div>
                </Box>
                <Box className={`product-table table-scroll-wrap catalogue-on-hover-show-detail catalogue-contacts-table cat-detail`}>
                    {isLoading && <Loader />}
                    {
                        catalogueContactList && catalogueContactList.length > 0
                            ?
                            <table>
                                <thead>
                                    <tr>
                                        <th width="30%" className={`catalogue`}>Name</th>
                                        <th className={`catalogue-draft`}></th>
                                        <th className={`contacts`}>Shared Date</th>
                                        <th className={`contacts`}>Last Viewed</th>
                                        <th className={`contacts`}>Catalogue Opens</th>
                                        <th className={`contacts`}>Profile Opens</th>
                                        <th className={`contacts`}>Product Views</th>
                                        <th className={`contacts`}>Avg.Duration</th>
                                        <th className={`more-option`}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        catalogueContactList && catalogueContactList.length > 0 &&
                                        catalogueContactList.map((catalogue, index) => {
                                            let tempClassName = index % 2 == 0 ? 'even' : 'odd';
                                            return (
                                                <Fragment key={index} >
                                                    <tr className={`product-list-main ${tempClassName}`}>
                                                        <td>
                                                            <span className={`catalogue-name brand-verified-wrap`}>
                                                                {catalogue?.is_default ?
                                                                    <span className={`master-cat-alisis flex`}>
                                                                        <AnonymousIcon />
                                                                    </span>
                                                                    :
                                                                    <span className={`name-alisis`}>{catalogue?.name.slice(0, 2)}</span>
                                                                }
                                                                <span className={`contact-wrap`}>
                                                                    <span className={`contact-name`}>{catalogue?.name}</span>
                                                                    <span className={`contact-email`}>{catalogue?.email}</span>
                                                                </span>
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <span onClick={() => { oncontactclick(catalogue?.cat_contact_id) }} className={`button-status detail`}>Details</span>
                                                        </td>
                                                        <td>
                                                            <span className={`contacts`}>
                                                                {catalogue?.created_at}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className={`product-count`}>
                                                                {catalogue?.updated_at}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {catalogue?.catviewcount}
                                                        </td>
                                                        <td>
                                                            {catalogue?.catprofileviewcount}
                                                        </td>
                                                        <td>
                                                            {catalogue?.productviewcount}
                                                        </td>
                                                        <td className={`contacts`}>
                                                            <div>
                                                                {catalogue?.catviewdurationAverage}
                                                            </div>
                                                        </td>
                                                        <td className={`options more-options-wrapper position-relative`}>
                                                            {
                                                                catalogue?.is_default ? <></> :
                                                                    <Tooltip moreOptions={returnMoreOptionValues(catalogue)} onOptionChange={onMoreOptionValueChange} data={catalogue} id={catalogue?.created_at} />

                                                            }
                                                        </td>


                                                    </tr>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            :
                            <div className={`no-data-found`}>
                                <div className={`icon-wrap`}>
                                    <IconBrandDefaultImage />
                                </div>
                                <div className={`text`}>
                                    you didn't have a Contact list right now
                                </div>
                            </div>
                    }
                </Box>
            </Box>
        </Fragment>
    )
}

const ContactsLeadGenerationListSection = (props) => {
    const { onDetailClick } = props;
    const [catalogueID, setcatalogueID] = useState(props?.catalogueID);
    const [catalogueContactList, setcatalogueContactList] = useState([]),
        [isLoading, setIsLoading] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [searchValue, setSearchValue] = useState(``),
        [totalCount, setTotalCount] = useState(0),
        [perPage, setPerPage] = useState(0),
        [isPaginatioShow, setIsPaginatioShow] = useState(false),
        [currentPage, setCurrentPage] = useState(1),
        [isConfirmationPopupShow, setIsConfirmationPopupShow] = useState(false),
        [moreOptionSelectedData, setMoreOptionSelectedData] = useState({}),
        [conformationPopupSuccessBtnText, setConformationPopupSuccessBtnText] = useState(``),
        [conformationPopupCancelBtnText, setConformationPopupCancelBtnText] = useState(``),
        [conformationPopupText, setConformationPopText] = useState(``),
        [conformationPopupType, setConformationType] = useState(``);

    const navigate = useNavigate();

    useEffect(() => {
        setcatalogueID(props?.catalogueID)
    }, [catalogueID]);

    useEffect(() => {
        getCatalogueContactListData();
    }, [currentPage]);

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    }

    const returnMoreOptionValues = (catalogue) => {

        let options = [];

        const obj1 = { "label": "Remove/Unshare", "value": "Remove" };
        options.push(obj1);
        const obj2 = { "label": "Resend Email", "value": "Email" };
        options.push(obj2);

        return options

    }
    const onMoreOptionValueChange = (option, data) => {

        switch (option[`value`]) {
            case `Remove`:
                // Delete a Contact Catalogue
                setMoreOptionSelectedData(data);
                setIsConfirmationPopupShow(true);
                setConformationPopText(`Are you sure you want to delete this contact?`);
                setConformationPopupSuccessBtnText(`Yes`);
                setConformationPopupCancelBtnText(`No`);
                setConformationType(`Delete Catalogue Contact`);
                break;
            case `Email`:
                // Catalogue invite link to this contact
                setMoreOptionSelectedData(data);
                setIsConfirmationPopupShow(true);
                setConformationPopText(`Resend catalogue to this contact?`);
                setConformationPopupSuccessBtnText(`Yes`);
                setConformationPopupCancelBtnText(`No`);
                setConformationType(`Resend Email`);
                break;
        }
    }
    const getCatalogueContactListData = () => {
        setIsLoading(true);
        getCatalogueContactLeadGenList(catalogueID, ``, currentPage)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setcatalogueContactList(response?.data?.data);
                    setTotalCount(response?.data?.count);
                    setPerPage(response?.data?.perpage);
                    setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                    setCurrentPage(1);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at catalogue List", error);
            });
    }
    const onConformationPopupClose = (value) => {
        setIsConfirmationPopupShow(value);
    }

    const onConformationPopupSuccess = () => {
        setIsConfirmationPopupShow(false);
        switch (conformationPopupType) {
            case `Delete Catalogue Contact`:
                deletecataloguecontact();
                break;
            case `Resend Email`:
                invide_resend_email();
                break;
        }
    }

    const deletecataloguecontact = () => {
        catalogueContactDelete(moreOptionSelectedData?.cat_contact_id)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        getCatalogueContactListData();
                    }, 500);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((err) => {
                console.log("we have error on Product Action", err);
            })
    }

    const invide_resend_email = () => {
        catalogueContactInviteResend(moreOptionSelectedData?.cat_contact_id)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        getCatalogueContactListData();
                    }, 500);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((err) => {
                console.log("we have error on Product Action", err);
            })
    }

    const onCatalogueSearchClick = (event) => {

        if (event.key === "Enter" || event.type === "click" || event.type === "change") {
            setIsLoading(true);
            let searchval = (event.type === "keypress") ? event.target.value : (event.type === "click") ? searchValue : ``
            getCatalogueContactLeadGenList(catalogueID, searchval, currentPage)
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                        setcatalogueContactList(response?.data?.data);
                        setTotalCount(response?.data?.count);
                        setPerPage(response?.data?.perpage);
                        setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                        setCurrentPage(1);
                        setIsLoading(false);

                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("we have error at catalogue List", error);
                });

        }
    }

    const onSearchRemove = () => {
        document.getElementById(`search`).value = '';
        setSearchValue(false);
        setIsLoading(true);
        getCatalogueContactList(catalogueID, ``, ``)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setcatalogueContactList(response.data.data);
                    setTotalCount(response?.data?.count);
                    setPerPage(response?.data?.perpage);
                    setIsPaginatioShow(Math.ceil(response?.data?.count / response?.data?.perpage) > 1 ? true : false);
                    setIsLoading(false);

                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at catalogue List", error);
            });
    }
    const oncontactclick = (contact) => {
        onDetailClick(contact)
    }

    return (
        <Fragment>
            {
                isConfirmationPopupShow &&
                <ConformationPopup
                    heading={conformationPopupText}
                    cancelBtnText={conformationPopupCancelBtnText}
                    submitBtnText={conformationPopupSuccessBtnText}
                    onCancel={onConformationPopupClose}
                    type={conformationPopupType}
                    onSubmit={onConformationPopupSuccess}
                />
            }
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <Box className={`contacts-list-section`}>
                <Box className={`filter-section`}>
                    <div className={`text`}>
                        {/* Lead Generation */}
                    </div>
                    <div className={`search-wrap`}>
                        <TextField
                            id="search"
                            name="search"
                            placeholder={`Search Contacts`}
                            margin="dense"
                            spellCheck={false}
                            className={`search-input`}
                            inputProps={{
                                onKeyPress: (e) => {
                                    onCatalogueSearchClick(e);
                                },
                                onChange: (e) => {
                                    setSearchValue(e.target.value);
                                    let targetvalue = e.target.value
                                    if (targetvalue === "") onCatalogueSearchClick(e);
                                }
                            }}
                        />
                        {
                            searchValue &&
                            <span className={`close-icon`} onClick={() => onSearchRemove()}>
                                <CloseIcon />
                            </span>
                        }
                        <button className={`search-icon-wrap`} onClick={(e) => { onCatalogueSearchClick(e) }}>
                            <SearchIcon />
                        </button>
                    </div>
                </Box>
                <Box className={`product-table table-scroll-wrap catalogue-on-hover-show-detail catalogue-contacts-table cat-detail`}>
                    {isLoading && <Loader />}
                    {
                        catalogueContactList && catalogueContactList.length > 0
                            ?
                            <table>
                                <thead>
                                    <tr>
                                        <th width="30%" className={`catalogue`}>Name</th>
                                        <th className={`catalogue-draft`}></th>
                                        <th className={`contacts`}>Shared Date</th>
                                        <th className={`contacts`}>Last Viewed</th>
                                        <th className={`contacts`}>Catalogue Opens</th>
                                        <th className={`contacts`}>Profile Opens</th>
                                        <th className={`contacts`}>Product Views</th>
                                        <th className={`contacts`}>Avg.Duration</th>
                                        <th className={`more-option`}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        catalogueContactList && catalogueContactList.length > 0 &&
                                        catalogueContactList.map((catalogue, index) => {
                                            let tempClassName = index % 2 == 0 ? 'even' : 'odd';
                                            return (
                                                <Fragment key={index} >
                                                    <tr className={`product-list-main ${tempClassName}`}>
                                                        <td>
                                                            <span className={`catalogue-name brand-verified-wrap`}>
                                                                {catalogue?.is_default ?
                                                                    <span className={`master-cat-alisis flex`}>
                                                                        <AnonymousIcon />
                                                                    </span>
                                                                    :
                                                                    <span className={`name-alisis`}>{catalogue?.name.slice(0, 2)}</span>
                                                                }
                                                                <span className={`contact-wrap`}>
                                                                    <span className={`contact-name`}>{catalogue?.name}</span>
                                                                    <span className={`contact-email`}>{catalogue?.email}</span>
                                                                </span>
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <span onClick={() => { oncontactclick(catalogue?.cat_contact_id) }} className={`button-status detail`}>Details</span>
                                                        </td>
                                                        <td>
                                                            <span className={`contacts`}>
                                                                {catalogue?.created_at}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className={`product-count`}>
                                                                {catalogue?.updated_at}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {catalogue?.catviewcount}
                                                        </td>
                                                        <td>
                                                            {catalogue?.catprofileviewcount}
                                                        </td>
                                                        <td>
                                                            {catalogue?.productviewcount}
                                                        </td>
                                                        <td className={`contacts`}>
                                                            <div>
                                                                {catalogue?.catviewdurationAverage}
                                                            </div>
                                                        </td>
                                                        <td className={`options more-options-wrapper position-relative`}>
                                                            {
                                                                catalogue?.is_default ? <></> :
                                                                    <Tooltip moreOptions={returnMoreOptionValues(catalogue)} onOptionChange={onMoreOptionValueChange} data={catalogue} id={catalogue?.created_at} />

                                                            }
                                                        </td>


                                                    </tr>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            :
                            <div className={`no-data-found`}>
                                <div className={`icon-wrap`}>
                                    <IconBrandDefaultImage />
                                </div>
                                <div className={`text`}>
                                    you didn't have a Lead Gen Contact list right now
                                </div>
                            </div>
                    }
                </Box>
            </Box>
        </Fragment>
    )
}

const ContactAnalytics = (props) => {

    const { isContactAnalyticsShow, contactID, onClose } = props;
    const [contactAnalyticsData, setcontactAnalyticsData] = useState([]),
        [filterText, setFilterText] = useState(`Most viewed first`),
        [isLoading, setIsLoading] = useState(false);

    const moreOptions = [
        {
            "label": "Most viewed first",
            "value": "Most viewed first"
        },
        {
            "label": "Last viewed first",
            "value": "Last viewed first"
        },
    ];

    useEffect(() => {
        getContactAnalytics(contactID, 1)
    }, [contactID]);

    const getContactAnalytics = (contactId, sortBy) => {
        setIsLoading(true);
        getContactDetailAnalytics(contactId, sortBy)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setcontactAnalyticsData(response.data.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log("we have error at getCatalogueProductList", err);
            })
    }

    const onMoreOptionValueChange = (option, data) => {
        setFilterText(option?.label);
        let filterOption = 1;

        switch (option?.label) {
            case `Most viewed first`:
                filterOption = 1;
                break;

            case `Last viewed first`:
                filterOption = 2;
                break;
        }

        getContactAnalytics(contactID, filterOption);
    }
    return (
        <Box className={`contact-analytics-wrapper ${isContactAnalyticsShow ? 'show-overlay' : ''}`}>
            {isLoading && <Loader />}
            <div className={`contact-analytics-slide scroll-bar-theme ${isContactAnalyticsShow ? 'show' : 'hide'}`}>
                <div className={`wrap`}>
                    <div className={`head`}>
                        <div className={`title`}>
                            {contactAnalyticsData.name}({contactAnalyticsData.email})
                        </div>
                        <div className={`close-icon`} onClick={() => { if (onClose) { onClose(false); } }}>
                            <CloseIcon />
                        </div>
                    </div>
                    <div className={`divider opacity-20P`}></div>
                    <div className={`contact-analytics-overview`}>
                        <div className={`product-overview`}>
                            <div className={`title`}>
                                {`Catalogue (Profile + Products)`}
                            </div>

                            <div className={`text item-start`}>
                                <span className={`count opens`}>{contactAnalyticsData.catviewcountoverall}
                                    <span className={`internal-text`}> opens </span>
                                </span>
                                <span className={`count time`}>
                                    {contactAnalyticsData.catviewdurationAverage}
                                    <span className={`internal-text`}> Avg. time spent </span>
                                </span>
                            </div>
                        </div>
                        <div className={`divider custom-divider`}></div>
                        <div className={`product-overview`}>
                            <div className={`title`}>
                                Products
                            </div>
                            <div className={`text item-start`}>
                                <span className={`count opens`}>
                                    {contactAnalyticsData.productviewcount}
                                    <span className={`internal-text`}> opens </span>
                                </span>
                                <span className={`count time`}>
                                    {contactAnalyticsData.productviewDurationAverage}
                                    <span className={`internal-text`}> Avg. time spent </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={`divider custom-divider`}></div>
                    {
                        contactAnalyticsData?.productlist && contactAnalyticsData?.productlist.length > 0
                            ?
                            <div className={`contact-analytics-overview`}>

                                <div className={`product-overview`}>
                                    <div className={`product-area title-section`}>
                                        <div className={`product-details title-wrap`}>
                                            <h3>
                                                Products
                                            </h3>

                                            <div className={`more-option-icon-wrapper`}>
                                                <Tooltip moreOptions={moreOptions} onOptionChange={onMoreOptionValueChange} textlable={filterText} icon={1} id={`filter-tooltip`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Product section*/}
                                {
                                    contactAnalyticsData?.productlist && contactAnalyticsData?.productlist.length > 0 &&
                                    contactAnalyticsData?.productlist.map((plist, index) => {
                                        return (
                                            <Fragment key={index} >
                                                <div className={`product-overview pro-gap-20 pro-margin-bottom-20`}>
                                                    <div className={`product-area`}>
                                                        {
                                                            plist?.productimage?.length
                                                                ?
                                                                <div className={`product-img`}>
                                                                    <img src={imageResizing(plist?.productimage[0.].image, 900, 900, `contain`, "#ffffff")} alt={plist?.productimage[0.].name} />
                                                                </div>
                                                                :
                                                                <div className={`pro-image-wrap`}>
                                                                    <div className={`pro-image`}>
                                                                    </div>
                                                                </div>
                                                        }
                                                        <div className={`product-details`}>
                                                            <div className={`product-title`}>
                                                                {plist.productname}
                                                            </div>
                                                            <div className={`product-item-code information-icon`}>
                                                                <span>Item code:</span>
                                                                {/* <span>{plist.productitemcode}</span> */}
                                                                <span className={`custom-tt-2`} tooltip={plist.productitemcode ? `${plist.productitemcode}` : ``}>{plist.productitemcode ? `${plist.productitemcode.substring(0, 15)}...` : `${plist.productitemcode || ` - `}`}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`text`}>
                                                        <span className={`count opens`}>
                                                            {plist.productviewcount}
                                                            <span className={`internal-text`}> Views </span>
                                                        </span>
                                                        <span className={`count time`}>
                                                            {plist.productviewdurationAverage}
                                                            <span className={`internal-text`}> Avg. time spent </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )
                                    })
                                }
                                {/* End Product section*/}
                            </div>
                            : <></>
                    }
                </div>
            </div>
        </Box >
    )
}

const CatalogueDetail = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [catalogueID, setcatalogueID] = useState(location.state.catalog_id);
    const [isContactAnalyticsShow, setisContactAnalyticsShow] = useState(false);
    const [contactID, setcontactID] = useState(``);
    const [manageSectionSelectedSection, setManageSectionSelectedSection] = useState(`Contacts`);
    const [contactrefresh, setcontactrefresh] = useState(false);

    const onContactAnalyticsClose = (value, response) => {
        setisContactAnalyticsShow(false);
    }

    const onDetailClick = (data) => {
        console.log('after that triger ondetailcick')
        setcontactID(data)
        setisContactAnalyticsShow(true);
    }
    
    const onContactCountRefresh = (data) => {
        console.log('onContactCountRefresh click')
        setcontactrefresh(true)
    }

    const onManageSectionChange = (value) => {
        setManageSectionSelectedSection(value);
    }

    return (
        <Box className={`catalogue-detail-wrapper`}>
            <div className={`back-btn-wrap`}>
                <span onClick={() => { navigate(`/catalogues`) }}>
                    <KeyboardBackspaceIcon />
                    Back
                </span>
            </div>

            <CatalogueInfromationSection catalogueID={catalogueID} />

            <div className={`divider`}></div>
            <ContactManageSection catalogueID={catalogueID} contactrefresh={contactrefresh}  onSelectionChange={onManageSectionChange} defaultmanagesection={manageSectionSelectedSection} />
            {
                manageSectionSelectedSection === `Contacts`
                    ?
                    <ContactsListSection
                        catalogueID={catalogueID}
                        onDetailClick={onDetailClick}
                        onContactCountRefresh={onContactCountRefresh}
                    />
                    :
                    <ContactsLeadGenerationListSection
                        catalogueID={catalogueID}
                        onDetailClick={onDetailClick}
                    />
            }


            <ContactAnalytics
                isContactAnalyticsShow={isContactAnalyticsShow}
                contactID={contactID}
                onClose={(value, response) => { onContactAnalyticsClose(value, response) }}
            />
        </Box>
    )
}

export default CatalogueDetail;
