import React from "react";

const facebook = (props) => {
  const { name = "", size = "s", color = "#fff", className = "" } = props;

  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      fill={color}
    >
      <circle cx="15.1962" cy="15" r="14.5" fill="white" stroke="#A6B4C1" />
      <g clipPath="url(#clip0_1240_2639)">
        <path
          d="M23.1962 15C23.1962 10.5817 19.6144 7 15.1962 7C10.7779 7 7.19617 10.5817 7.19617 15C7.19617 18.993 10.1216 22.3027 13.9462 22.9028V17.3125H11.9149V15H13.9462V13.2375C13.9462 11.2325 15.1405 10.125 16.9679 10.125C17.8429 10.125 18.7587 10.2813 18.7587 10.2813V12.25H17.7499C16.7562 12.25 16.4462 12.8667 16.4462 13.5V15H18.6649L18.3102 17.3125H16.4462V22.9028C20.2707 22.3027 23.1962 18.993 23.1962 15Z"
          fill="#3357FF"
        />
        <path
          d="M18.3102 17.3125L18.6649 15H16.4462V13.5C16.4462 12.8673 16.7562 12.25 17.7499 12.25H18.7587V10.2813C18.7587 10.2813 17.8432 10.125 16.9679 10.125C15.1405 10.125 13.9462 11.2325 13.9462 13.2375V15H11.9149V17.3125H13.9462V22.9028C14.7745 23.0324 15.6179 23.0324 16.4462 22.9028V17.3125H18.3102Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1240_2639">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(7.19617 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default facebook;
