
//fit : cover,contain,fill,inside,outside

const imageResizing = (imageUrl, height, width, fit, bgColor=null) => {
    var BucketName = process.env.REACT_APP_S3_BUCKET;
    var ImagePath = imageUrl.split(`.com/`)[1];
    var Cloudfronturl = "https://d2t6lev22f5ufh.cloudfront.net";
    const imageRequest = JSON.stringify({
        bucket: BucketName,
        key: ImagePath,
        edits: {
            resize: {
                "width": width,
                "height": height,
                "fit": fit,
                "background":bgColor,
            }
        }
    })
    return Cloudfronturl + '/' + window.btoa(imageRequest);
}

export default imageResizing;


// Request Body

// {
//     "bucket": "discoverrr-s3-bucket",
//     "key": "media/product_gallery/download_15_YXwCTrI.jpg",
//     "edits": {
//       "resize": {
//         "width": 900,
//         "height": 900,
//         "fit": "contain",
//         "background": {
//           "r": 255,
//           "g": 255,
//           "b": 255,
//           "alpha": 1
//         }
//       }
//     }
//   }