const initialState = {
    editProduct: {},
    loading: true,
    error: false,
  };
  
  function editProductReducer(state = initialState, action) {
    switch (action.type) {
      case "LOAD_EDIT_PRODUCT_OBJECT":
        return { ...state, editProduct: {}, error: false, loading: true };
      case "GET_EDIT_PRODUCT_OBJECT":
        return state;
      case "SET_EDIT_PRODUCT_OBJECT":
        return { ...state, editProduct: action.payload, error: false, loading: false };
      case "ERROR_EDIT_PRODUCT_OBJECT":
        return { ...state, editProduct: {}, error: true, loading: false };
      default:
        return state;
    }
  }
  export default editProductReducer;
  