
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const catalogueContactDelete = async (ContactID) => {
    
    const headers = await getApiHeader() || {};;
    
    if(!ContactID) {
        return {
            error: true,
            message: 'CatalogID mandatory in productAction function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in productAction function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    // var formData = new FormData();
    
    return instance.delete(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-contact-detail/${ContactID}`, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default catalogueContactDelete;