import axios from 'axios';

import {CONFIG, getDiscoverrrAPIBase} from '../helpers/getApiConfig';

const resetPasswordLink = async (email) => {

    if(!email) {
        return {
            error: true,
            message: 'email mandatory in login function' 
        }
    }

    const instance = axios.create();
    
    instance.defaults.timeout = CONFIG.timeout || 15000;
    
    var formData = new FormData();
    formData.append("email", email)

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/resetPasswordlink` , formData)
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default resetPasswordLink;