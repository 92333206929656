import React, { useState, useEffect } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";

//Api related imports
import getProductSetting from '../../../../../../apis/Supplier/getProductSetting';

//Components related imports
import Loader from "../../../../Loader";
import ProductSetting from '../../../ProductSetting';

const CardInfoDetailSection = (props) => {

    const [isSettingShow, setIsSettingShow] = useState(false),
        [userSettings, setUserSettings] = useState({}),
        [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        getProductSetting()
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status && response.data.data) {
                    setUserSettings(response.data.data);
                    setIsSettingShow(true);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }

            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have error at getProductSetting", error);
            })
    }, [])
    return (
        <Box className={`setting-section-wrapper`}>
            {isLoading && <Loader />}
            <ProductSetting
                isSettingShow={isSettingShow}
                onClose={(value) => setIsSettingShow(value)}
                userSettings={userSettings}
                onSuccess={(data) => { setUserSettings(data) }}
            />
        </Box>
    )
}

export default CardInfoDetailSection;