
import axios from 'axios';

import { CONFIG } from '../../helpers/getApiConfig';

import getApiHeader from '../../helpers/getApiHeader';

const ourTeamAdd = async (email, accessType, brandType, brandAuthType, productAuthType, catalogueAuthType, brandList) => {

    if (!email) {
        return {
            error: true,
            message: 'email mandatory in ourTeamAdd function'
        }
    }

    const headers = await await getApiHeader() || {};

    const instance = axios.create();

    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();
    if (email) formData.append("email", email);
    if (accessType) formData.append("accesstype", accessType);
    if (accessType !== `1` && brandType) formData.append("brandtype", brandType);
    if (accessType !== `1` && brandAuthType) formData.append("brand_auth_type", brandAuthType);
    if (accessType !== `1` && productAuthType) formData.append("product_auth_type", productAuthType);
    if (accessType !== `1` && catalogueAuthType) formData.append("catalogue_auth_type", catalogueAuthType);
    if (accessType !== `1` && brandList) formData.append("brandlist", brandList);

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/our-teams`, formData, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default ourTeamAdd