import React from "react";

const statusgreentick = (props) => {
  const { name = "", size = "s", color = "#fff", className = "" } = props;

  return (
    <svg
      width="23"
      height="30"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      fill={color}
    >
        <circle cx="9" cy="9" r="8.5" stroke="#008000"/>
        <path d="M12.3337 6.5L7.75033 11.0833L5.66699 9" stroke="#008000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default statusgreentick;
