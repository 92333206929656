// React related imports
import React, { useState, useEffect, Fragment } from "react";

// mui or UI related imports
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';

//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import useAction from "../../../store/action/userAction";
import appObjectAction from "../../../store/action/appObjectAction";

//Apis related imports
import logout from "../../../apis/logout";
import LogoWhiteText from '../../../assets/logo/white-text-logo.svg';
import IconLogo from '../../../assets/logo/logo-arrow.svg';
import LogoBlackText from '../../../assets/logo/black-text-logo.svg';

//Helpers related imports
import removeCookie from "../../../helpers/removeCookie";
import removeLocalStorage from "../../../helpers/removeLocalStorage";

//Components related imports
import Loader from "../Loader";

// React route related imports
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = (props) => {
  let currentPath = useLocation().pathname;
  const { app } = props?.appData || {};
  const registerUrls = [`/register`, `/email_verification`, `/email_verification_success`,`/invite_reset_link`];
  const [isRegisterPage, setRegisterPage] = useState(
    registerUrls.indexOf(currentPath) !== -1
  );

  const [user, setUser] = useState(props?.usersData?.user || {});
  const [auth, setAuth] = useState(user?.token ? true : false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch(),
    navigate = useNavigate();
  useEffect(() => {
    setAuth(props?.usersData?.user?.token ? true : false);
    setUser(props?.usersData?.user);
  }, [props?.usersData?.user?.token]);

  useEffect(() => {
    setRegisterPage(registerUrls.indexOf(currentPath) !== -1);
  }, [currentPath]);

  const handleLogout = () => {
    setIsLoading(true);
    logout()
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          removeCookie(`discoverrrToken`);
          removeCookie(`discoverrrRefreshToken`);
          // let obj = app;
          // obj[`isTokenRefreshed`] = false;
          // dispatch(appAction.setAppObject(obj))
          setIsLoading(false);
          dispatch(useAction.setUser({}));
          navigate("/");

          let tempObject = app;
          tempObject[`productSelectedBrand`] = {};
          dispatch(appObjectAction.setAppObject(tempObject))
          removeLocalStorage(`productSelectedBrand`);
          removeLocalStorage(`productEditingStep`);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("Checking error", error);
      });
  };

  if (!isRegisterPage) {
    return (
      <Box sx={{ flexGrow: 1 }} className={`header-wrap`}>
        {isLoading && <Loader />}
        <AppBar position="static" className={`header`}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link className={`text`} to={`/`}>
                <img className={`logo-img`} src={LogoWhiteText} alt=""></img>
              </Link>
            </Typography>
            {!auth ? (
              <div className={`flex flex-items-center`}>
                <Typography align="center" className={`text`}>
                  Don’t have an account?
                </Typography>
                <Link className={``} to={`/register`}>
                  <Button
                    type="submit"
                    variant="outlined"
                    size="medium"
                    className={`button-with-white-border`}
                  >
                    Get Started
                  </Button>
                </Link>
              </div>
            ) : (

              <Fragment>
                <Link className={`support-icon`} to={`/support`} target="_blank">
                  <HeadsetMicIcon />
                </Link>
                <Button
                  type="submit"
                  variant="outlined"
                  size="medium"
                  className={`button-with-white-border`}
                  onClick={handleLogout}
                >
                  Sign Out
                </Button>
              </Fragment>

            )}
          </Toolbar>
        </AppBar>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{ flexGrow: 1 }}
        className={`header-wrap register-header-wrapper`}
      >
        {isLoading && <Loader />}
        <AppBar position="static" className={`header`}>
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              className={`logo ${[`/email_verification_success`].indexOf(currentPath) !== -1 ? 'center-logo' : ''}`}
            >
              <Link className={`text`} to={`/`}>
                <img className={`logo-img`} src={LogoWhiteText} alt=""></img>
                <img className={`register-logo-img`} src={LogoBlackText} alt=""></img>
              </Link>
            </Typography>

            {[`/email_verification`, `/email_verification_success`].indexOf(currentPath) === -1 && (
              <div className={`flex flex-items-center`}>
                <Typography align="center" className={`text`}>
                  Already have an account?
                </Typography>
                <Link to={`/login`}>
                  <Button
                    type="submit"
                    variant="outlined"
                    size="medium"
                    className={`button-with-blue-border`}
                  >
                    Login
                  </Button>
                </Link>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
};

//map state
const mapStateToProps = (state) => {
  return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
