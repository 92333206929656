const paginationObject = {
  "brandPaginatio": 1
}

const initialState = {
  user: {},
  loading: true,
  error: false,
  pagination: paginationObject
};

function userDetailReducer(state = initialState, action) {
  switch (action.type) {
    case "LOAD_USERDETAIL":
      return { ...state, user: {}, error: false, loading: true};
    case "GET_USERDETAIL":
      return state;
    case "SET_USERDETAIL":
      return { ...state, user: action.payload, error: false, loading: false };
    case "ERROR_USERDETAIL":
      return { ...state, user: {}, error: true, loading: false };
    case "SET_PAGINATION" :
      return {...state, pagination : action.payload}
    default:
      return state;
  }
}
export default userDetailReducer;
