
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const catalogueContactCreate = async (CatalogID, contact ) => {

    const headers = await getApiHeader() || {};;

    if(!CatalogID) {
        return {
            error: true,
            message: 'productID mandatory in catalogueContactCreate function' 
        }
    }
    if(!contact) {
        return {
            error: true,
            message: 'contact mandatory in catalogueContactCreate function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in catalogueContactCreate function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;
   
    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-contact-create/${CatalogID}`,{contacts: contact}, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}
export default catalogueContactCreate;