import React from "react";

const linkedIn = (props) => {
  const { name = "", size = "s", color = "#fff", className = "" } = props;

  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      fill={color}
    >
      <circle cx="15.1962" cy="15" r="14.5" fill="white" stroke="#A6B4C1" />
      <g clipPath="url(#clip0_1240_2638)">
        <path
          d="M22.0118 7H8.37742C7.72429 7 7.19617 7.51563 7.19617 8.15313V21.8438C7.19617 22.4813 7.72429 23 8.37742 23H22.0118C22.6649 23 23.1962 22.4813 23.1962 21.8469V8.15313C23.1962 7.51563 22.6649 7 22.0118 7ZM11.943 20.6344H9.56804V12.9969H11.943V20.6344ZM10.7555 11.9563C9.99304 11.9563 9.37742 11.3406 9.37742 10.5812C9.37742 9.82188 9.99304 9.20625 10.7555 9.20625C11.5149 9.20625 12.1305 9.82188 12.1305 10.5812C12.1305 11.3375 11.5149 11.9563 10.7555 11.9563ZM20.8305 20.6344H18.4587V16.9219C18.4587 16.0375 18.443 14.8969 17.2243 14.8969C15.9899 14.8969 15.8024 15.8625 15.8024 16.8594V20.6344H13.4337V12.9969H15.7087V14.0406H15.7399C16.0555 13.4406 16.8305 12.8063 17.9837 12.8063C20.3868 12.8063 20.8305 14.3875 20.8305 16.4438V20.6344V20.6344Z"
          fill="#3357FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1240_2638">
          <path d="M7.19617 7H23.1962V23H7.19617V7Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default linkedIn;
