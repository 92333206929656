
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const productAction = async (productID, ProductAction,extraParam="") => {

    const headers = await getApiHeader() || {};;

    if(!productID) {
        return {
            error: true,
            message: 'productID mandatory in productAction function' 
        }
    }
    if(!ProductAction) {
        return {
            error: true,
            message: 'ProductAction mandatory in productAction function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in productAction function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();

    if(ProductAction) formData.append("action", ProductAction);
    if(extraParam) formData.append("price_enable", extraParam);
    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/products/productActionUpdate/${productID}`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default productAction;