
import axios from 'axios';

import getApiHeader from '../helpers/getApiHeader';

import {CONFIG} from '../helpers/getApiConfig';


const userUpdate = async (firstname = ``, lastname = ``, jobtitle = ``, phonenumber = ``, country = ``,uploadImage = ``) => {

    if(!firstname) {
        return {
            error: true,
            message: 'First Name mandatory in Profile update function' 
        }
    }
    if(!lastname) {
        return {
            error: true,
            message: 'Last Name mandatory in Profile update function' 
        }
    }
    // if(!jobtitle) {
    //     return {
    //         error: true,
    //         message: 'Job title mandatory in Profile update function' 
    //     }
    // }
    if(!phonenumber) {
        return {
            error: true,
            message: 'Phone number mandatory in Profile update function' 
        }
    }
    if(!country) {
        return {
            error: true,
            message: 'Country mandatory in Profile update function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;
    const headers = await getApiHeader() || {};;

    var formData = new FormData();

    if(firstname) formData.append("firstname", firstname);
    if(lastname) formData.append("lastname", lastname);
    if(phonenumber) formData.append("mobile", phonenumber);
    // if(country) formData.append("country", country);
    if (country) {
        if (country === "none") {
            formData.append("country", ""); // Append null if countryCode is "none"
        } else {
            formData.append("country", country); // Append countryCode otherwise
        }
    }
    if(uploadImage) formData.append("user_profile", uploadImage);
    formData.append("jobtitle", jobtitle);
    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/myaccount/profile`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default userUpdate;