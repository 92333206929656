import React, { useState, useEffect } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";

//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

//Components related imports
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

import getUserDetail from "../../../../apis/getUserDetail";
//Redux related imports
import countryListActions from "../../../../store/action/countryListAction";
import userDetailAction from "../../../../store/action/userDetailAction";

//Api related imports
import getCountryList from "../../../../apis/getCountryList";
import { useLocation } from 'react-router-dom';

const Profile = (props) => {

    const location = useLocation(),dispatch = useDispatch();

    const isMobile = props?.appData?.app?.isMobile || false;
    const [userDetail, setUserDetail] = useState(props?.userDetailData?.user?.data || {}),
        [countryList, setCountryList] = useState(props?.countryListData?.countryList || []),
        [isLoading, setIsLoading] = useState(false),
        [selectedMenu, setSelectedMenu] = useState(`Your Profile`),
        [accountSettingsData, setAccountSettingsData] = useState([`Your Profile`, `Change Password`, `Team Management`]),
        companySettingsData = [`My Personas`, `My Categories`, `My Brands`,`My Variants/Flavours`,`Show/hide product details`, `Product Details`],
        [selectedAccordionType, setSelectedAccordionType] = useState(`account`),
        [permission, setPermission] = useState(props?.userDetailData?.user?.data?.permission || []),
        [isAllTabClose, setAllTabClose] = useState(false);

    const onMenuChange = (menu) => {
        setSelectedMenu(menu);
        setAllTabClose(isMobile ? true : false);
    }

    useEffect(() => {
        if (countryList && Object.keys(countryList).length === 0) {
            setIsLoading(true);
            getCountryList()
                .then((response) => {
                    if (
                        response &&
                        response.status === 200 &&
                        response.data.status &&
                        response.data.data &&
                        response.data.data.length > 0
                    ) {
                        setIsLoading(false);
                        setCountryList(response.data.data);
                        dispatch(countryListActions.setCountryList(response.data.data));
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("We have error", error);
                });
        }
    }, []);


    useEffect(() => {
        setPermission(props?.userDetailData?.user?.data?.permission || []);
    }, [props?.userDetailData?.user?.data])

    useEffect(() => {
        if (permission?.[0]?.accesstype === `2`) {
            let tempArray = accountSettingsData;

            if (tempArray && tempArray.length > 0) {
                tempArray = tempArray.filter((data) => {
                    return data !== `Team Management`;
                })
            }
            setAccountSettingsData(tempArray);
        }
    }, [permission])
    const propsupdationclick = (value) => {
        // console.log('props updated')
        getUserDetail()
            .then((response) => {
                if (response && response.status === 200 && response.data) {
                    if (response.data.status) {
                        dispatch(userDetailAction.setUserDetail(response.data));
                        setUserDetail(response.data.data)
                    }
                }
            })
            .catch((error) => {
                console.log("Checking error", error);
            });
    }

    return (
        <Box className={`profile-wrapper mobile-setting`}>
            <LeftPanel
                onMenuChange={onMenuChange}
                accountSettingsData={accountSettingsData}
                companySettingsData={companySettingsData}
                defaultAccordionType={selectedAccordionType}
                defaultSelectedMenu={selectedMenu}
                isCustomizationTabShow = {permission?.[0]?.accesstype === `2` ? false : true}
                permission={permission}
                isAllTabClose={isAllTabClose}
                onAccordionChange={(value) => setAllTabClose(value)}
            />
            <RightPanel
                propsupdate={propsupdationclick}
                selectedMenu={selectedMenu}
                userDetail={userDetail}
                countrylist={countryList} />
        </Box>
    )
}

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);