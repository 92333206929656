import React from "react";

const moreIcons = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="7" height="29" viewBox="0 0 7 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="3.09766" cy="3.70117" r="3" fill="#262626" />
            <circle cx="3.09766" cy="14.7012" r="3" fill="#262626" />
            <circle cx="3.09766" cy="25.7012" r="3" fill="#262626" />
        </svg>

    );
};

export default moreIcons;
