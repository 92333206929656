
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const catalogueAction = async (CatalogID, CatalogAction ,priceupdate="",name="") => {

    const headers = await getApiHeader() || {};;

    if(!CatalogID) {
        return {
            error: true,
            message: 'productID mandatory in productAction function' 
        }
    }
    if(!CatalogAction) {
        return {
            error: true,
            message: 'ProductAction mandatory in productAction function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in productAction function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();

    if(CatalogAction) formData.append("action", CatalogAction);
    if(priceupdate) formData.append("price_enable", priceupdate);
    if(name) formData.append("name", name);
    
    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-action/${CatalogID}`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}
export default catalogueAction;