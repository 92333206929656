
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import { CONFIG } from '../../helpers/getApiConfig';

const getDynamicFields = async (industryId) => {

    const headers = await await getApiHeader() || {};;

    if (!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in getDynamicFields function'
        }
    }

    if (!industryId) {
        return {
            error: true,
            message: 'industryId mandatory in getDynamicFields function'
        }
    }

    const instance = axios.create();

    instance.defaults.timeout = CONFIG.timeout || 15000;

    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/techspecification/tech-specification/${industryId}`, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default getDynamicFields