
import axios from 'axios';

import { CONFIG } from '../../helpers/getApiConfig';

import getApiHeader from "../../helpers/getApiHeader";

const buyerCatalogueStarUpdate = async (brandId) => {

    if (!brandId) {
        return {
            error: true,
            message: 'brandId mandatory in buyerCatalogueStarUpdate'
        }
    }

    const instance = axios.create();
    instance.defaults.timeout = CONFIG.timeout || 15000;

    const headers = await getApiHeader() || {};

    console.log(`heards`,headers);
    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/savedcatalogue/starredcatalogue/${brandId}`, {}, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default buyerCatalogueStarUpdate