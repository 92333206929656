import React, { useEffect } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';

const ConformationPopup = (props) => {

    const { heading, desciption, cancelBtnText, submitBtnText, onCancel, onSubmit } = props;

    useEffect(() => {
        document.body.classList.add('hide-scroll');

        return () => {
            document.body.classList.remove('hide-scroll');
        }
    }, [])
    return (
        <Box className={`conformation-popup-wrapper`}>
            <div className={`popup-wrap`}>
                <div className={`close-icon`} onClick={() => {if(onCancel) onCancel()}}>
                    <CloseIcon />
                </div>
                {
                    heading &&
                    <div className={`heading`}>{heading}</div>
                }

                {
                    desciption &&
                    <div className={`description`}>
                        {desciption}
                    </div>
                }

                <div className={`button-wrap`}>
                    <button
                        className={`cancel-btn`}
                        onClick={() => { if (onCancel) onCancel() }}
                    >
                        {cancelBtnText}
                    </button>

                    <button
                        className={`submit-btn`}
                        onClick={() => { if (onSubmit) onSubmit() }}
                    >
                        {submitBtnText}
                    </button>
                </div>
            </div>
        </Box>
    )
}

export default ConformationPopup;