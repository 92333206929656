import React from "react";

const helpQuestionIcon = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0007 25.6668C20.444 25.6668 25.6673 20.4435 25.6673 14.0002C25.6673 7.55684 20.444 2.3335 14.0007 2.3335C7.55733 2.3335 2.33398 7.55684 2.33398 14.0002C2.33398 20.4435 7.55733 25.6668 14.0007 25.6668Z" stroke="#3357FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 19.8335H14.0117" stroke="#3357FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.6055 10.5002C10.8798 9.72046 11.4211 9.06297 12.1337 8.64416C12.8464 8.22536 13.6842 8.07227 14.4988 8.21201C15.3135 8.35174 16.0524 8.77529 16.5847 9.40763C17.117 10.04 17.4084 10.8403 17.4071 11.6668C17.4071 14.0002 13.9071 15.1668 13.9071 15.1668" stroke="#3357FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default helpQuestionIcon;
