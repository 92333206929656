const initialState = {
    passwordResetMain: {},
    loading: true,
    error: false,
  };
  
  function passwordResetReducer(state = initialState, action) {
    switch (action.type) {
      case "LOAD_PASSWORD_RESET_TYPE":
        return { ...state, passwordResetMain: {}, error: false, loading: true };
      case "GET_PASSWORD_RESET_TYPE":
        return state;
      case "SET_PASSWORD_RESET_TYPE":
        return { ...state, passwordResetMain: action.payload, error: false, loading: false };
      case "ERROR_PASSWORD_RESET_TYPE":
        return { ...state, passwordResetMain: {}, error: true, loading: false };
      default:
        return state;
    }
  }
  export default passwordResetReducer;
  