import React, { useState, useEffect } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Accordion = (props) => {
    const { title, data, isOpen, onMenuChange, type, onTypeChange, selectedValue } = props;

    const [selectedMenu, setSelectedMenu] = useState(selectedValue || ``);
    const [isMenuOpen, setIsMenuOpen] = useState(isOpen || false);

    const onSelectionChange = (tab) => {
        setSelectedMenu(tab); 
        if(onMenuChange) onMenuChange(tab);
    }

    const onAccordionChange = () => {
        setIsMenuOpen(!isMenuOpen);
        if(onTypeChange) onTypeChange(type);
    }

    useEffect(() => {
        setIsMenuOpen(isOpen || ``);
    },[isOpen])

    useEffect(() => {
        setSelectedMenu(selectedValue || ``)
    },[selectedValue])
    return (
        <Box className={`accordion-wrapper`}>
            <div className={`header-section`} onClick={() => onAccordionChange()}>
                <div className={`title`}>
                    {title}
                </div>
                <div className={`header-icon`}>
                    {
                        isMenuOpen ? <ArrowDropDownIcon/> :  <ArrowRightIcon />
                    }
                   
                </div>
            </div>

            {
                isMenuOpen &&
                <div className={`tabs-wrapper`}>
                {
                    data && data.length > 0 &&
                    data.map((tab, index) => {
                        return (
                            <div className={`tab ${selectedMenu === tab  ? 'selected' : ''}`} key={index} onClick={() => { onSelectionChange(tab) }}>
                                {tab}
                            </div>
                        )
                    })
                }
            </div>
            }
           
        </Box>
    )
}

export default Accordion;