import React, { useEffect, useState } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button";
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

//apis related imports
import getUserIndustryList from "../../../../../apis/getUserIndustryList";
import getDynamicFields from "../../../../../apis/TechSpecification/getDynamicFields";
import productUpdate from "../../../../../apis/Supplier/productUpdate";

//Components related imports
import AlertMessage from "../../../AlertMessage";
import Loader from "../../../Loader";

const GenerateTextField = (props) => {
    const { fieldData, onTextFieldChange, technicalSpecification, isUserIndustryChange } = props;

    const { name, type, dynofields_id } = fieldData;
    const [textFieldValue, setTextFieldValue] = useState(``);

    useEffect(() => {
        if (technicalSpecification && technicalSpecification.length > 0) {
            technicalSpecification.some((element) => {
                if (element?.dynofields === dynofields_id) {
                    setTextFieldValue(element?.dynovalues || ``);
                }
            })
        }

    }, [technicalSpecification])

    useEffect(() => {
        if (isUserIndustryChange) {
            setTextFieldValue(``);
        }
    }, [isUserIndustryChange])

    const onTextFieldBlur = (value) => {
        if (onTextFieldChange) {
            onTextFieldChange(value, dynofields_id);
        }
    }

    return (
        <Box className={`form-control margin-bottom-28 textfield-wrapper`}>
            <Typography className={`label margin-btm-15`}>{name}</Typography>
            <TextField
                type={"text"}
                id={`textfield-${type}`}
                name={`textfield-${type}`}
                className={`input-wrap`}
                placeholder={name}
                margin="dense"
                spellCheck={false}
                value={textFieldValue}
                inputProps={{
                    onInput: (event) => {
                        setTextFieldValue(event.target.value);
                        // generatedTextFieldChange(event, dynofields_id)

                    },
                    onBlur: (event) => {
                        onTextFieldBlur(event.target.value);
                    }
                }}
            />
        </Box>
    )
}

const GenerateSelectBox = (props) => {
    const { fieldData, onSelectBoxChange, technicalSpecification, isUserIndustryChange } = props;

    const { name, type, dynofields_id, subvalues } = fieldData;

    const [selectBoxValue, setSelectBoxValue] = useState(subvalues?.[0]?.subfields_id);

    useEffect(() => {
        if (technicalSpecification && technicalSpecification.length > 0) {
            technicalSpecification.some((element) => {
                if (element?.dynofields === dynofields_id) {
                    setSelectBoxValue(element?.dynovalues || subvalues?.[0]?.subfields_id || ``);
                }
            })
        }

    }, [technicalSpecification])

    useEffect(() => {
        if (isUserIndustryChange) {
            setSelectBoxValue(``);
        }
    }, [isUserIndustryChange])

    // useEffect(() => {
    //     setSelectBoxValue(subvalues?.[0]?.subfields_id);
    // },[subvalues]);

    const generatedSelectBoxChange = (event, dynofields_id) => {
        setSelectBoxValue(event.target.value);
        if (onSelectBoxChange) onSelectBoxChange(event.target.value, dynofields_id)
    }
    return (
        <Box className={`form-control margin-bottom-28 select-box-wrapper`}>
            <Typography className={`label margin-btm-15`}>{name}</Typography>
            <FormControl sx={{ m: 1, minWidth: 120 }} >
                <Select
                    value={selectBoxValue}
                    onChange={(event) => generatedSelectBoxChange(event, dynofields_id)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="" disabled>
                        <em>{name}</em>
                    </MenuItem>
                    {
                        subvalues && subvalues.length > 0 &&
                        subvalues.map((value, index) => {
                            return (
                                <MenuItem key={index} value={value?.subfields_id}>{value?.name}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        </Box>
    )
}

const GenerateMultiSelectBox = (props) => {

    const { fieldData, onMultiSelectBoxChange, technicalSpecification, isUserIndustryChange } = props;
    const { name, subvalues, dynofields_id } = fieldData;

    const [selectMultiBoxValue, setSelectMultiBoxValue] = useState([]);

    const theme = useTheme();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    useEffect(() => {
        let elementsValues = [];
        let multilSelectValue = [];

        if (subvalues && subvalues.length > 0) {
            subvalues.map((values) => {
                if (technicalSpecification && technicalSpecification.length > 0) {
                    technicalSpecification.some((element) => {
                        if (element?.dynofields === dynofields_id) {
                            elementsValues = element?.dynovalues?.split(",");
                            if (elementsValues && elementsValues.length > 0) {
                                elementsValues.map((element) => {
                                    if (element == values?.subfields_id) {
                                        multilSelectValue.push(values?.name)
                                    }
                                })
                            }
                        }
                    })
                }
            })
        }

        setSelectMultiBoxValue(multilSelectValue);
    }, [technicalSpecification])

    useEffect(() => {
        if (isUserIndustryChange) {
            setSelectMultiBoxValue([]);
        }
    }, [isUserIndustryChange])
    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }


    useEffect(() => {

        let fieldList = [];
        let tempArray = [];
        if (subvalues && subvalues.length > 0) {
            subvalues.map((subValue) => {
                if (selectMultiBoxValue && selectMultiBoxValue.length > 0) {
                    selectMultiBoxValue.map((selected) => {
                        if (selected === subValue[`name`]) {
                            fieldList.push({
                                "dynofields_id": dynofields_id,
                                "dynovalues": subValue?.subfields_id
                            })
                        }
                    })
                }
            })
        }


        if (fieldList && fieldList.length > 0) {
            fieldList.map((field) => {
                tempArray.push(field?.dynovalues);
            });
            if (onMultiSelectBoxChange) onMultiSelectBoxChange(tempArray.join(), dynofields_id);
        } else {
            if (onMultiSelectBoxChange) onMultiSelectBoxChange(tempArray.join(), dynofields_id);
        }
    }, [selectMultiBoxValue]);
    const generatedMultiSelectChange = (event, dynofields_id) => {

        const {
            target: { value },
        } = event;
        setSelectMultiBoxValue(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    return (
        <Box className={`form-control margin-bottom-28 multiselect-wrapper`}>
            <Typography className={`label margin-btm-15`}>{name}</Typography>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-chip-label">{name}</InputLabel>
                <Select
                    id="multiselect"
                    multiple
                    value={selectMultiBoxValue}
                    onChange={(event) => generatedMultiSelectChange(event, dynofields_id)}
                    input={<OutlinedInput id="select-multiple-chip" label={name} />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {subvalues && subvalues.length > 0 && subvalues.map((value, index) => (
                        <MenuItem
                            key={value[`name`]}
                            value={value[`name`]}
                            style={getStyles(value[`name`], subvalues, theme)}
                        >
                            <Checkbox checked={selectMultiBoxValue.indexOf(value[`name`]) > -1} />
                            <ListItemText primary={value[`name`]} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

        </Box>
    )
}

const GenerateMultiSelectBoxWithTextField = (props) => {
    const { fieldData, onMultiSelectWithTextFieldChange, technicalSpecification, isUserIndustryChange } = props;

    const { name, subvalues, dynofields_id } = fieldData;
    const [selectedSubValue, setSelectedSubValue] = useState(subvalues?.[0]?.subfields_id),
        [textBoxValue, setTextBoxValue] = useState(``);

    useEffect(() => {

        if (technicalSpecification && technicalSpecification.length > 0) {
            technicalSpecification.map((item) => {
                if (item?.dynofields === dynofields_id) {
                    setSelectedSubValue(item?.dynovalues || subvalues?.[0]?.subfields_id || ``);
                    setTextBoxValue(item?.dyno_text_value || ``);
                }
            })
        }
    }, [technicalSpecification]);

    // useEffect(() => {
    //     setSelectedSubValue(subvalues?.[0]?.subfields_id)
    // },[subvalues])

    useEffect(() => {
        if (isUserIndustryChange) {
            setSelectedSubValue(``);
            setTextBoxValue(``);
        }
    }, [isUserIndustryChange])

    const onTextBlur = (value) => {
        if (onMultiSelectWithTextFieldChange) {
            onMultiSelectWithTextFieldChange(selectedSubValue || subvalues?.[0]?.subfields_id, dynofields_id, value)
        }
    }

    const onSubValueChange = (value) => {
        setSelectedSubValue(value)
        onMultiSelectWithTextFieldChange(value, dynofields_id, textBoxValue)
    }

    return (
        <Box className={`form-control margin-bottom-28 mutltiselect-with-textfield-wrapper`}>
            <Typography className={`label margin-btm-15`}>{name}</Typography>
            <div className={`multiselect-with-textfield-wrap custom-black-select-option`}>

                <div className={`multiselect-dropdown`}>
                    <select id="multiselect-dropdown" onChange={(event) => { onSubValueChange(event.target.value) }}>
                        {
                            subvalues && subvalues.length > 0 &&
                            subvalues.map((list, index) => {
                                return (
                                    <option
                                        value={list[`subfields_id`]}
                                        key={index}
                                        selected={selectedSubValue === list[`subfields_id`] ? 'selected' : ''}
                                    >
                                        {list[`name`]}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
                <div>
                    <TextField
                        type={"text"}
                        id={`multiselect-textbox-${dynofields_id}`}
                        name={`multiselect-textbox-${dynofields_id}`}
                        className={`input-wrap multiselect-input`}
                        placeholder={`${name}`}
                        margin="dense"
                        spellCheck={false}
                        value={textBoxValue}
                        inputProps={{
                            onInput: (event) => {
                                setTextBoxValue(event.target.value);
                                onTextBlur(event.target.value);
                            }
                        }}
                    />

                </div>
            </div>
        </Box>
    )
}
const ProductTechnicalSpecification = (props) => {

    const { onSectionApiCallSuccess } = props;
    const [productDetails, setProductDetails] = useState(props?.productDetails || {}),
        [userIndustry, setUserIndustry] = useState(productDetails?.user_industry || ``),
        [industryList, setIndustryList] = useState([]),
        [fieldList, setFieldList] = useState([]),
        [textFieldValueObject, setTextFieldValueObject] = useState([]),
        [selectBoxValueObject, setSelectBoxValueObject] = useState([]),
        [multiselectBoxValueObject, setMultiselectBoxValueObject] = useState([]),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isLoading, setIsLoading] = useState(false),
        [technicalSpecification, setTechnicalSpecification] = useState(productDetails?.products_tech_specification || {}),
        [isUserIndustryChange, setIsUserIndustryChange] = useState(false),
        [multiSelectWithTextBoxObject, setMultiSelectWithTextBoxObject] = useState([]);

    useEffect(() => {
        setProductDetails(props?.productDetails || {});
        setUserIndustry(props?.productDetails?.user_industry || ``);
        setTechnicalSpecification(props?.productDetails?.products_tech_specification || {});
    }, [props?.productDetails])

    useEffect(() => {
        setIsLoading(true);
        getUserIndustryList()
            .then((response) => {
                if (response?.status === 200 && response?.data?.status && response?.data?.data) {
                    setIndustryList(response?.data?.data);

                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have errot at getUserIndustryList", error);
            });
    }, []);

    useEffect(() => {
        setIsLoading(true);
        if (userIndustry) {
            resetFieldValues();
            getDynamicFields(userIndustry)
                .then((response) => {
                    if (response?.status === 200 && response?.data?.status && response?.data?.data) {
                        setFieldList(response?.data?.data);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("we have errot at getDynamicFields", error);
                });
        }
    }, [userIndustry])

    useEffect(() => {
        let tempTextFieldsArray = [], tempSelectBoxArray = [], tempMultiSelectWithTextFieldsArray = [];
        if (technicalSpecification && technicalSpecification.length > 0) {
            technicalSpecification.map((field) => {
                switch (field?.dynofieldstype) {
                    case 1:
                        tempTextFieldsArray.push({
                            "dynofields_id": field?.dynofields,
                            "dynovalues": field?.dynovalues
                        })
                        break;

                    case 2:
                        tempSelectBoxArray.push({
                            "dynofields_id": field?.dynofields,
                            "dynovalues": field?.dynovalues
                        })
                        break;

                    case 4:
                        tempMultiSelectWithTextFieldsArray.push({
                            "dynofields_id": field?.dynofields,
                            "dynovalues": field?.dynovalues,
                            "dyno_text_value": field?.dyno_text_value,
                        })
                        break;
                }
            })
        }

        setTextFieldValueObject(tempTextFieldsArray);
        setSelectBoxValueObject(tempSelectBoxArray);
        setMultiSelectWithTextBoxObject(tempMultiSelectWithTextFieldsArray);
    }, [technicalSpecification]);

    const onUserIndustryChange = (event) => {
        setIsUserIndustryChange(true);
        setUserIndustry(event.target.value);
    }

    const elementExist = (arr, value) => {
        return arr.some(function (element) {
            return element?.dynofields_id === value;
        });
    }

    const onTextFieldChange = (value, dynofields_id) => {
        setIsUserIndustryChange(false);
        if (textFieldValueObject && textFieldValueObject.length > 0) {
            if (elementExist(textFieldValueObject, dynofields_id)) {
                setTextFieldValueObject(
                    textFieldValueObject.map((item) => {
                        return item?.dynofields_id === dynofields_id ? {
                            "dynofields_id": dynofields_id,
                            "dynovalues": value
                        } : item;
                    })
                );
            } else {
                if (value.trim()) {
                    setTextFieldValueObject([...textFieldValueObject, {
                        "dynofields_id": dynofields_id,
                        "dynovalues": value
                    }]);
                }
            }
        } else {
            setTextFieldValueObject([...textFieldValueObject, {
                "dynofields_id": dynofields_id,
                "dynovalues": value
            }]);
        }
    }

    const onSelectBoxChange = (value, dynofields_id) => {
        setIsUserIndustryChange(false);
        if (selectBoxValueObject && selectBoxValueObject.length > 0) {
            if (elementExist(selectBoxValueObject, dynofields_id)) {
                setSelectBoxValueObject(
                    selectBoxValueObject.map((item) => {
                        return item?.dynofields_id === dynofields_id ? {
                            "dynofields_id": dynofields_id,
                            "dynovalues": value
                        } : item;
                    })
                );
            } else {
                if (value.trim()) {
                    setSelectBoxValueObject([...selectBoxValueObject, {
                        "dynofields_id": dynofields_id,
                        "dynovalues": value
                    }]);
                }
            }
        } else {
            setSelectBoxValueObject([...selectBoxValueObject, {
                "dynofields_id": dynofields_id,
                "dynovalues": value
            }]);
        }
    }

    const onMultiSelectBoxChange = (value, dynofields_id) => {
        setIsUserIndustryChange(false);
        if (multiselectBoxValueObject && multiselectBoxValueObject.length > 0) {
            if (elementExist(multiselectBoxValueObject, dynofields_id)) {
                setMultiselectBoxValueObject(
                    multiselectBoxValueObject.map((item) => {
                        return item?.dynofields_id === dynofields_id ? {
                            "dynofields_id": dynofields_id,
                            "dynovalues": value
                        } : item;
                    })
                );
            } else {
                if (value.trim()) {
                    setMultiselectBoxValueObject([...multiselectBoxValueObject, {
                        "dynofields_id": dynofields_id,
                        "dynovalues": value
                    }]);
                }
            }
        } else {
            if (value.trim()) {
                setMultiselectBoxValueObject([...multiselectBoxValueObject, {
                    "dynofields_id": dynofields_id,
                    "dynovalues": value
                }]);
            }

        }
    }

    const onMultiSelectWithTextFieldChange = (value, dynofields_id, textBoxValue) => {
        setIsUserIndustryChange(false);
        if (multiSelectWithTextBoxObject && multiSelectWithTextBoxObject.length > 0) {
            if (elementExist(multiSelectWithTextBoxObject, dynofields_id)) {
                setMultiSelectWithTextBoxObject(
                    multiSelectWithTextBoxObject.map((item) => {
                        return item?.dynofields_id === dynofields_id ? {
                            "dynofields_id": dynofields_id,
                            "dynovalues": value,
                            "dyno_text_value": textBoxValue
                        } : item;
                    })
                );
            } else {
                setMultiSelectWithTextBoxObject([...multiSelectWithTextBoxObject, {
                    "dynofields_id": dynofields_id,
                    "dynovalues": value,
                    "dyno_text_value": textBoxValue
                }]);
            }
        } else {
            setMultiSelectWithTextBoxObject([...multiSelectWithTextBoxObject, {
                "dynofields_id": dynofields_id,
                "dynovalues": value,
                "dyno_text_value": textBoxValue
            }]);


        }

    }

    const generateDynamicField = (fieldType, fieldData) => {
        let field;
        switch (fieldType) {
            case 1:
                field = <GenerateTextField
                    fieldData={fieldData}
                    onTextFieldChange={onTextFieldChange}
                    technicalSpecification={technicalSpecification}
                    isUserIndustryChange={isUserIndustryChange}
                />
                break;

            case 2:
                field = <GenerateSelectBox
                    fieldData={fieldData}
                    onSelectBoxChange={onSelectBoxChange}
                    technicalSpecification={technicalSpecification}
                    isUserIndustryChange={isUserIndustryChange}
                />
                break;

            case 3:
                field = <GenerateMultiSelectBox
                    fieldData={fieldData}
                    onMultiSelectBoxChange={onMultiSelectBoxChange}
                    technicalSpecification={technicalSpecification}
                    isUserIndustryChange={isUserIndustryChange}
                />
                break;

            case 4:
                field = <GenerateMultiSelectBoxWithTextField
                    fieldData={fieldData}
                    onMultiSelectWithTextFieldChange={onMultiSelectWithTextFieldChange}
                    technicalSpecification={technicalSpecification}
                    isUserIndustryChange={isUserIndustryChange}
                />
        }

        return field;
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const resetFieldValues = () => {
        setTextFieldValueObject([])
        setSelectBoxValueObject([])
        setMultiselectBoxValueObject([])
        setMultiSelectWithTextBoxObject([])
    }
    const onFormSubmit = (event) => {

        event.preventDefault();

        let specificationArray = [];
        let tempArray = [], tempCountryList = [];
        if (textFieldValueObject && textFieldValueObject.length > 0) {
            textFieldValueObject.map((item) => {
                specificationArray.push(item);
            });
        }

        if (selectBoxValueObject && selectBoxValueObject.length > 0) {
            selectBoxValueObject.map((item) => {
                specificationArray.push(item);
            });
        }

        if (multiselectBoxValueObject && multiselectBoxValueObject.length > 0) {
            multiselectBoxValueObject.map((item) => {
                if (item?.dynovalues.trim()) {
                    specificationArray.push(item);
                }
            });
        }

        if (multiSelectWithTextBoxObject && multiSelectWithTextBoxObject.length > 0) {
            multiSelectWithTextBoxObject.map((item) => {
                if (item[`dynovalues`] || item[`dyno_text_value`].trim()) {
                    specificationArray.push(item)
                }
            });
        }

        // if (productDetails?.product_usps && productDetails?.product_usps.length > 0) {
        //     productDetails?.product_usps.map((data) => {
        //         tempArray.push(data[`name`]);
        //     })
        // }

        // if (productDetails?.products_sold_countries && productDetails?.products_sold_countries.length > 0) {
        //     productDetails?.products_sold_countries.map((soldCountry) => {
        //         tempCountryList.push(soldCountry?.country);
        //     })
        // }

        const productTechnicalspecificationObject = {
            "productId": productDetails?.products_id,
            // "usps": tempArray.length > 0 ? tempArray.join() : '',
            "userIndustrys": userIndustry,
            "productTechSpecification": specificationArray,
            // "countriesSoldList": tempCountryList.join()
            "productIngredients" : productDetails?.products_ingredients?.[0]?.name || ``
        }

        setIsLoading(true);
        productUpdate(productTechnicalspecificationObject)
            .then((response) => {
                if (response?.status === 200 && response?.data?.status && response.data.data) {
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response?.data?.message);
                    setIsLoading(false);

                    setTimeout(() => {
                        if (onSectionApiCallSuccess) {
                            onSectionApiCallSuccess(true);
                        }
                    }, 300);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }

                    setTimeout(() => {
                        if (onSectionApiCallSuccess) {
                            onSectionApiCallSuccess(false);
                        }
                    }, 300);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setTimeout(() => {
                    if (onSectionApiCallSuccess) {
                        onSectionApiCallSuccess(false);
                    }
                }, 300);
                console.log("We have error at productUpdate", error);
            })
    }
    return (
        <Box className={`product-technical-specification`}>
            {isLoading && <Loader />}
            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`heading`}>
                Technical specifications
            </div>

            <form className={`techincal-specification-form form-input-control`} onSubmit={onFormSubmit}>

                <div className="fields-container">
                    <Box className={`form-control margin-bottom-28 industry-wrapper`}>
                        <Typography className={`label margin-btm-15`}>Industry</Typography>
                        <FormControl sx={{ m: 1, minWidth: 120 }} >
                            <Select
                                value={userIndustry}
                                onChange={onUserIndustryChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="" disabled>
                                    <em>Select industry</em>
                                </MenuItem>
                                {
                                    industryList && industryList.length > 0 &&
                                    industryList.map((industry, index) => {
                                        return (
                                            <MenuItem key={index} value={industry?.main_industry_id}>{industry?.industryname}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                {
                    fieldList && fieldList.length > 0 &&
                    <div className="fields-container techhnical-fields-wrap">
                        {
                            fieldList && fieldList.length > 0 &&
                            fieldList.map((field, index) => {
                                return (
                                    <div key={index}>
                                        {generateDynamicField(field?.organic, field)}
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                <div className={`button-wrapper`}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        id="technical-specification-submit-btn"
                        className={`w-100 btn-primary-after-login visibility-hidden display-none`}
                    >
                        Sign up
                    </Button>
                </div>
            </form>
        </Box>
    )
}

export default ProductTechnicalSpecification;