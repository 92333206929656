const initialState = {
    registration: {},
    loading: true,
    error: false,
  };
  
  function registrationReducer(state = initialState, action) {
    switch (action.type) {
      case "LOAD_REGISTRATION":
        return { ...state, registration: {}, error: false, loading: true };
      case "GET_REGISTRATION":
        return state;
      case "SET_REGISTRATION":
        return { ...state, registration: action.payload, error: false, loading: false };
      case "ERROR_REGISTRATION":
        return { ...state, registration: {}, error: true, loading: false };
      default:
        return state;
    }
  }
  export default registrationReducer;
  