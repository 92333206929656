const createCookie = (cookieName, cookieValue, expiryTime) => {
    if (expiryTime) {
        var date = new Date();
        date.setTime(date.getTime() + (expiryTime * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else {
        var expires = "";
    }
    document.cookie = cookieName + "=" + cookieValue + expires + "; path=/";
}

export default createCookie;