// React router dom related imports
import { Route, Navigate } from "react-router-dom";

//Components related imports
import Login from "../../component/common/Login";
import Main from "../../component/common/Main";
import ForgotPassword from "../../component/common/ForgotPassword";
import OnBoarding from "../../component/common/OnBoarding";
import Registration from "../../component/common/Registration";
import EmailVerification from "../../component/common/EmailVerification";
import PasswordReset from "../../component/common/PasswordReset";
import ForgotPasswordConfirm from "../../component/common/ForgotPasswordConfirm";
import PreLaunchEmailSuccess from "../../component/common/PreLaunchEmailSuccess";
import BuyerEmailVerification from "../../component/common/Buyer/EmailVerification";
import InviteResetLink from "../../component/common/InviteResetLink";
import InviteResetPassword from "../../component/common/InviteResetPassword";

//ProtectedRoute routes
import ProtectedRoute from "../protectedRoute";

const common = [
  // <Route path="/" element={<Login />} key="Login" />,
  <Route path="/" element={<Main />} key="Main">
    <Route index element={<Login route={`login`} />} key="Login" />
    <Route path="login" element={<Login route={`login`} />} key="Login" />
    <Route
      path="password_reset"
      element={<ForgotPassword route={`password_reset`} />}
      key="ForgotPassword"
    />,
    <Route
      path="register"
      element={<Registration route={`registration`} />}
      key="Registration"
    />,
    <Route
      path="password_reset_link"
      element={<PasswordReset route={`passwordReset`} />}
      key="PasswordReset"
    />
    <Route
      path="forgot_password_confirm"
      element={<ForgotPasswordConfirm route={`forgotPasswordConfirm`} />}
      key="ForgotPasswordConfirm"
    />,
    <Route
      path="email_verification_success"
      element={<PreLaunchEmailSuccess route={`preLaunchEmailSuccess`} />}
      key="PreLaunchEmailSuccess"
    />,
    <Route
      path="email_verification"
      element={<EmailVerification route={`emailVerification`} />}
      key="EmailVerification"
    />,
    <Route
      path="invite_reset_link"
      element={<InviteResetLink route={`inviteResetLink`} />}
      key="InviteResetLink"
    />,
    <Route
      path="invite_reset_password"
      element={<InviteResetPassword route={`inviteResetPassword`} />}
      key="InviteResetPassword"
    />,
    <Route
      path="buyer/email_verification"
      element={<BuyerEmailVerification route={`emailVerification`} />}
      key="EmailVerification"
    />,
    <Route
      path="buyer/email_verification"
      element={<BuyerEmailVerification route={`emailVerification`} />}
      key="EmailVerification"
    />,
    <Route
      element={<ProtectedRoute />}
    >
      <Route
        path="onboarding"
        element={<OnBoarding route={`onboarding`} />}
        key="OnBoarding"
      />
    </Route>
  </Route>,

  <Route
    path="*"
    element={<Navigate to="/login" replace route={`login`} />}
    key="Login"
  />,
];

export default common;
