import React, { useState, useEffect, Fragment } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

//Redux related imports
import { useDispatch } from "react-redux";
import userDetailAction from "../../../../../../store/action/userDetailAction";
import countryListActions from "../../../../../../store/action/countryListAction";

//Api related imports
import userUpdate from "../../../../../../apis/userUpdate";
import getUserDetail from "../../../../../../apis/getUserDetail";
import getCountryList from "../../../../../../apis/getCountryList";

//Components related imports
import AlertMessage from "../../../../AlertMessage";
import Loader from "../../../../Loader";

// Icons related imports
import EditImageIcon from "../../../../../../icons/svgs/editImageIcon";

//Other imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
    .object({
        firstname: yup
            .string()
            .trim()
            .required("First name is required")
            .min(3, "Too short - should be at least 3 characters")
            .max(40, 'Maximum allowed characters is 40'),
        lastname: yup
            .string()
            .trim()
            .required("Last name is required")
            .min(2, "Too short - should be at least 2 characters")
            .max(40, 'Maximum allowed characters is 40'),
        phonenumber: yup
            .string()
            .required("Phone Number is required")
    })
    .required();

const ProfileSection = (props) => {
    const [userDetail, setUserDetail] = useState(props.userDetail || ``),
        [countryList, setcountryList] = useState(props.countryList || []),
        [firstname, setFirstname] = useState(userDetail?.firstname || ``),
        [lastname, setLastname] = useState(userDetail?.lastname || ``),
        [jobtitle, setJobtitle] = useState(userDetail?.jobtitle || ``),
        [email, setEmail] = useState(userDetail?.email || ``),
        [phonenumber, setPhonenumber] = useState(userDetail?.mobile || ``),
        [country, setCountry] = useState(userDetail?.country || ``),
        [uploadedImagePath, setUploadedImagePath] = useState(userDetail?.user_profile || ``),
        [uploadImage, setUploadImage] = useState(``),
        [isLoading, setIsLoading] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``);

    const dispatch = useDispatch();
    useEffect(() => {
        if (countryList && Object.keys(countryList).length === 0) {
            setIsLoading(true);
            getCountryList()
                .then((response) => {
                    if (
                        response &&
                        response.status === 200 &&
                        response.data.status &&
                        response.data.data &&
                        response.data.data.length > 0
                    ) {
                        setIsLoading(false);
                        setcountryList(response.data.data);
                        dispatch(countryListActions.setCountryList(response.data.data));
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("We have error", error);
                });
        }

        if (userDetail && Object.keys(userDetail).length === 0) {
            setIsLoading(true);
            getUserDetail()
                .then((response) => {
                    if (response && response.status === 200 && response.data) {
                        if (response.data.status) {
                            setUserDetail(response.data.data)
                            dispatch(userDetailAction.setUserDetail(response.data));
                            setIsLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("Checking error", error);
                });
        }
    }, []);

    useEffect(() => {
        setFirstname(userDetail?.firstname || ``);
        setLastname(userDetail?.lastname || ``);
        setJobtitle(userDetail?.jobtitle || ``);
        setEmail(userDetail?.email || ``);
        setPhonenumber(userDetail?.mobile || ``);
        setCountry(userDetail?.country || ``);
        setUploadedImagePath(userDetail?.user_profile || ``);
    }, [userDetail]);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    }
    const showUploadImage = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (event) => {
            resolve(event.target.result);
        }
        reader.onerror = (error) => {
            reject(error);
        }
    });
    const onImageUpload = async (file) => {
        setUploadImage(file);
        setUploadedImagePath(await showUploadImage(file));
    }
    const flagemojiToPNG = (flag, code) => {
        var countryCode = Array.from(flag, (codeUnit) => codeUnit.codePointAt()).map(char => String.fromCharCode(char - 127397).toLowerCase()).join('')
        return `https://flagcdn.com/24x18/${countryCode}.png`;
    }

    const onSubmit = (data) => {
        // console.log(data)
        setIsLoading(true);
        userUpdate(firstname, lastname, jobtitle, phonenumber, country, uploadImage)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.data) {
                    if (response.data.status) {
                        //User Detail
                        if (props.profileupdate) {
                            props.profileupdate(true)
                        }
                        // getUserDetail()
                        // .then((response) => {
                        // if (response && response.status === 200 && response.data) {
                        //     if (response.data.status) {
                        //         setUserDetail(response.data)
                        //     dispatch(userDetailAction.setUserDetail(response.data));                
                        //     } 
                        // }
                        // })
                        // .catch((error) => {
                        // console.log("Checking error", error);
                        // });
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("success");
                        setAlertMessage(response.data.message);
                    }
                    else {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        setAlertMessage(response.data.message)
                    }
                } else {
                    if (response && response.response && response.response.data && response.response.data.message) {
                        setIsLoading(false);
                        setAlternateMessageShow(true);
                        setAlertMessageType("error");
                        setAlertMessage(response.response.data.message)
                    }
                }
            })
            .catch((error) => {
                setAlertMessage(false);
                console.log("We have error", error);
            })
    }

    return (
        <Fragment>
            {isLoading && <Loader />}
            {
                isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <Box className={`profile-section-wrapper`}>
                <h1 className={`heading`}>Your Profile</h1>
                <div className={`divider`}></div>
                <div className={`profile-inner-container`}>
                    <form className={`form-input-control`} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <div className={`profile-form-wrapper`}>
                            <div className={`profile-left-inner-container`}>
                                <Box className={`form-control`}>
                                    <Box className={`form-control user-profile-logo-section-wrap`}>
                                        {
                                            uploadedImagePath === ``
                                                ?
                                                <div className={`logo-wrap`} onClick={() => { if (document.getElementById("imageupload")) document.getElementById("imageupload").click() }}>
                                                    <div className={`logo`}>
                                                        +Update Profile
                                                        <p className={`img-info`}>Image must be at least 300 x 300 px.</p>
                                                    </div>
                                                    <div className={`edit-icon`}>
                                                        <EditImageIcon />
                                                    </div>
                                                </div>
                                                :
                                                <div className={`user-edit-sec`} onClick={() => { if (document.getElementById("imageupload")) document.getElementById("imageupload").click() }}>
                                                    <img src={uploadedImagePath} alt="Profile" loading="lazy" />
                                                    <div className={`edit-icon`}>
                                                        <EditImageIcon />
                                                    </div>
                                                </div>
                                        }
                                        <input className={`visibility-hidden display-none`} type='file' name='imageupload' id='imageupload' onChange={(event) => { onImageUpload(event.target.files[0]) }} accept="image/x-png,image/gif,image/jpeg" multiple />
                                    </Box>
                                    <Typography className={`user-combine-name`}>{firstname} {lastname}</Typography>
                                </Box>
                            </div>
                            <div className={`profile-right-inner-container`}>
                                <Box className={`form-control`}>
                                    <div className={`name-wrapper two-columns`}>
                                        <Box className={`fname-wrapper form-control`}>
                                            <Typography className={`label`}>First Name</Typography>
                                            <TextField
                                                id="firstname"
                                                name="firstname"
                                                value={firstname}
                                                {...register("firstname", {
                                                    onChange: (event) => {
                                                        setFirstname(event.target.value);
                                                    },
                                                })}
                                                placeholder=""
                                                fullWidth
                                                className={`form-profile-field`}
                                                error={errors.firstname ? true : false}
                                                inputProps={{ maxLength: 15 }}
                                            />
                                            <p className={`field-err-msg`}>{errors.firstname?.message}</p>
                                        </Box>
                                        <Box className={`form-control last-name-style`}>
                                            <Typography className={`label`}>Last Name</Typography>
                                            <TextField
                                                id="lastname"
                                                value={lastname}
                                                {...register("lastname", {
                                                    onChange: (event) => {
                                                        setLastname(event.target.value);
                                                    },
                                                })}
                                                placeholder=""
                                                fullWidth
                                                className={`form-profile-field`}
                                                error={errors.lastname ? true : false}
                                                inputProps={{ maxLength: 15 }}
                                            />
                                            <p className={`field-err-msg`}>{errors.lastname?.message}</p>
                                        </Box>
                                    </div>
                                    <div className={`title-email-wrapper two-columns`}>
                                        
                                        <Box className={`form-control`}>
                                            <Typography className={`label`}>Email Address</Typography>
                                            <TextField
                                                id="email"
                                                value={email}
                                                {...register("email", {
                                                    onChange: (event) => {
                                                        if (event.target.value) setEmail(event.target.value);
                                                    },
                                                })}
                                                placeholder=""
                                                fullWidth
                                                className={`form-profile-field`}
                                                error={errors.email ? true : false}
                                                disabled={true}
                                            />
                                            <p className={`field-err-msg`}>{errors.email?.message}</p>
                                        </Box>
                                    </div>
                                    <div className={`phone-country-wrapper two-columns`}>
                                        <Box className={`fname-wrapper form-control`}>
                                            <Typography className={`label`}>Phone Number</Typography>
                                            <TextField
                                                id="phonenumber"
                                                value={phonenumber}
                                                {...register("phonenumber", {
                                                    onChange: (event) => {
                                                        setPhonenumber(event.target.value);
                                                    },
                                                })}
                                                placeholder=""
                                                fullWidth
                                                className={`form-profile-field`}
                                                error={errors.phonenumber ? true : false}
                                            />
                                            <p className={`field-err-msg`}>{errors.phonenumber?.message}</p>
                                        </Box>
                                        <Box className={`form-control last-name-style profile-country`}>
                                            <Typography className={`label`}>Country</Typography>
                                            <Select
                                                labelId="country-code-select-label"
                                                id="country"
                                                className={`form-profile-field pro-cn`}
                                                value={country}
                                                label="country"
                                                {...register("country", {
                                                    onChange: (event) => {
                                                        if (event.target.value) setCountry(event.target.value);
                                                    },
                                                })}
                                                fullWidth
                                            >
                                                {countryList &&
                                                    countryList.length > 0 &&
                                                    countryList.map((country, index) => {
                                                        return (
                                                            <MenuItem
                                                                key={index}
                                                                value={country[`country_id`]}
                                                            >
                                                                {
                                                                    <div className="">
                                                                        {<img src={flagemojiToPNG(country[`emoji`])} loading="lazy" />}{'  '}
                                                                        {country[`name`]}
                                                                    </div>
                                                                }
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                            <p className={`field-err-msg`}>{errors.country?.message}</p>
                                        </Box>
                                    </div>

                                </Box>

                            </div>
                        </div>
                        <div className={`button-section`}>
                            <div className={`divider`}></div>
                            <div className={`button-wrapper`}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    className={`btn-primary-after-login ${isValid ? `` : `disabled`}`}
                                    disabled={isValid ? false : true}
                                >
                                    Save Changes
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Box>
        </Fragment>
    )
}

export default ProfileSection;