const setInterestsList = (interestsObj) => {
    return {
        type: "SET_INTERESTS_LIST",
        payload: interestsObj
    }
}

const getInterestsList = () => {
    return {
        type: "GET_INTERESTS_LIST"
    }
}


export default {
    setInterestsList,
    getInterestsList
}