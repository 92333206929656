// React related imports
import React, { useState, useEffect } from "react";
//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import useAction from "../../../store/action/userAction";
import userDetailAction from "../../../store/action/userDetailAction";

// mui or UI related imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

//Helpers function related imports
import getQueryParamaterFromUrl from "../../../helpers/getQueryParamaterFromUrl";
import getLocalStorage from "../../../helpers/getLocalStorage";
import createCookie from "../../../helpers/createCookie";

// React route related imports
import { useLocation, useNavigate, Link } from "react-router-dom";

//Apis related imports
import emailnewlink from "../../../apis/emailnewlink";
import emailVerify from "../../../apis/emailVerify";
import getUserDetail from "../../../apis/getUserDetail";

//Components related imports
import AlertMessage from "../AlertMessage";
import Loader from "../Loader";

import IconGmail from "../../../assets/icons/icon-gmail.svg";
import IconOutlook from "../../../assets/icons/icon-outlook.svg";
import IconLogo from '../../../assets/logo/black-text-logo.svg';
import VerificationImage from '../../../assets/img/verification-img-v1.jpg';

const EmailVerification = (props) => {

  const { pageName } = props;
  const registerEmail =
    props?.registrationData?.registration?.email ||
    props?.registrationData?.registration?.UserData?.email ||
    getLocalStorage(`registerEmail`) ||
    ``;
  const location = useLocation();
  const [verifyEmailApiStatus, setVerifyEmailApiStatus] = useState(``);
  const token = getQueryParamaterFromUrl(`token`) || ``;
  const navigate = useNavigate();

  const [isAlertMessageShow, setAlternateMessageShow] = useState(false),
    [alertMessageType, setAlertMessageType] = useState(``),
    [alertMessage, setAlertMessage] = useState(``),
    [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch()

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      setVerifyEmailApiStatus(`inProgress`);
      emailVerify(token)
        .then((response) => {
          if (
            response &&
            response.status === 200 &&
            response.data &&
            response.data.status
          ) {
            setVerifyEmailApiStatus(`verified`);
            setIsLoading(false);
            dispatch(useAction.setUser(response.data));
            createCookie("discoverrrToken", response?.data?.token?.access || ``, 120);
            createCookie("discoverrrRefreshToken", response?.data?.token?.refresh || ``, 1440);
            const timer = setTimeout(() => {
              //User Detail
              getUserDetail()
                .then((response1) => {
                  if (response1 && response1.status === 200 && response1.data) {
                    if (response1.data.status) {
                      dispatch(userDetailAction.setUserDetail(response1.data));
                    }
                  }
                })
                .catch((error) => {
                  console.log("Checking error", error);
                });

              if (pageName === `inviteResetLink`) {

                if (response?.data?.token?.access && response?.data?.token?.refresh) {
                  navigate(`/invite_reset_password`);
                } else {
                  navigate(`/login`);
                }
              } else {
                if (response.data.step) {
                  navigate('/onboarding');
                } else {
                  navigate('/brand');
                }
              }

            }, 100);
          } else {
            if (
              response &&
              response.response &&
              response.response.data &&
              response.response.data.message
            ) {
              setIsLoading(false);
              setAlternateMessageShow(true);
              setAlertMessageType("error");
              setAlertMessage(response.response.data.message);
            }
            setVerifyEmailApiStatus(`not-verified`);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setVerifyEmailApiStatus(`not-verified`);
          console.log("we have error", error);
        });
    }
  }, []);
  const handleSendAgain = () => {
    setIsLoading(true);
    emailnewlink(registerEmail)
      .then((response) => {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.status
        ) {
          setIsLoading(false);
          setAlternateMessageShow(true);
          setAlertMessageType("success");
          setAlertMessage(response.data.message);
        } else {
          if (
            response &&
            response.response &&
            response.response.data &&
            response.response.data.message
          ) {
            setIsLoading(false);
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            if (response?.message === `Network Error`) {
              setAlertMessage(`No internet connection`);
            } else {
              setAlertMessage(response?.response?.data?.message);
            }
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("Checking error", error);
      });
  };

  const returnHeaderMessages = (status) => {
    switch (status) {
      case `inProgress`:
        return {
          "first-heading": "Verifying email address ...",
          "second-heading": (
            <Typography variant="h2" className={`second-heading`}>
              Verifying your email address
            </Typography>
          ),
          message: <p className={`did-not-email-text`}>Verifying</p>,
        };
        break;

      case "verified":
        return {
          "first-heading": "Thank you for choosing Discoverrr.",
          "second-heading": (
            <Typography variant="h2" className={`second-heading`}>
              We'll notify you by email when we launch.
            </Typography>
          ),
          message: <p className={`did-not-email-text`}></p>,
        };
        break;

      case "not-verified":
        return {
          "first-heading": "Oops!",
          "second-heading": (
            <Typography variant="h2" className={`second-heading`}>
              This verification link has expired. No worries!
              <span className={"link"} onClick={handleSendAgain}>
                Get a new link
              </span>
            </Typography>
          ),
          message: (
            <p className={`did-not-email-text`}>
              Remember to check your spam folder if it isn’t there in your main
              inbox.
            </p>
          ),
        };
        break;

      default:
        return {
          "first-heading": "Check your inbox to verify email address",
          "second-heading": (
            <Typography variant="h2" className={`second-heading`}>
              To confirm it's yours, click on the link we sent to:{" "}
              <span className={`email`}>{registerEmail}</span>
            </Typography>
          ),
          message: (
            <p className={`did-not-email-text`}>
              Didn’t get the email? Check your spam folder or <span className="skip-btn" onClick={handleSendAgain}> send it again</span>
            </p>
          ),
        };
        break;
    }
  };

  const onAlertClose = (value) => {
    setAlternateMessageShow(value);
  };

  return (
    <>
      {isLoading && <Loader />}
      {isAlertMessageShow && (
        <AlertMessage
          message={alertMessage}
          alertType={alertMessageType}
          isAlertShow={isAlertMessageShow}
          onClose={onAlertClose}
        />
      )}
      <div className={`verification-email-wrapper`}>

        <div className={`verify-mail-wrapper`}>
          <Link className={`verification-page_logo`} to={`/`}>
            <img className={`register-logo-img`} src={IconLogo} alt=""></img>
          </Link>
          <div className="mail-deatils">
            <Box>
              <Typography variant="h1" className={`first-heading`}>
                {returnHeaderMessages(verifyEmailApiStatus)[`first-heading`]}
              </Typography>
              <Typography variant="h2" className={`second-heading`}>
                {returnHeaderMessages(verifyEmailApiStatus)[`second-heading`]}
              </Typography>
            </Box>
            <Box>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                className={`w-100 gmail-btn skip-btn`}
                onClick={() => {
                  window.open(`https://mail.google.com/mail/u/0/?tab=mm#inbox`, `_blank`);
                }}
              >
                <img src={IconGmail} /> Open Gmail
              </Button>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                className={`w-100 outlook-btn skip-btn`}
                onClick={() => {
                  window.open(`https://login.microsoftonline.com/`, `_blank`);
                }}
              >
                <img src={IconOutlook} /> Open Outlook
              </Button>
            </Box>

            <Box>{returnHeaderMessages(verifyEmailApiStatus)[`message`]}</Box>
          </div>
        </div>

        <div className={`verify-mail-box-wrapper`} style={{ background: `url(${VerificationImage})`, backgroundRepeat: 'no-repeat' }}>
          {/* <img src={VerificationImage} /> */}
          {/* <Box className={`verify-mail-text-box`}>
            <div className={`email`}>
              <Typography className={`label`}>Email icon</Typography>
            </div>
            <Box>
              

              <div className={`vertical-center`}>
                <div className={`checkbox-wrapper`}>
                  <Checkbox disabled />
                  <Typography className={`label`}>Discoverrrr</Typography>
                </div>

                <div>
                  <Typography className={`label`}>
                    Please verify your email address
                  </Typography>
                </div>
              </div>
            </Box>

            {[...Array(16)].map((start, index) => {
              return (
                <Box key={index}>
                  <div className={`vertical-center`}>
                    <div>
                      <Checkbox disabled />
                      <Typography className={`first-bar`}></Typography>
                    </div>

                    <div>
                      <Typography className={`second-bar`}></Typography>
                    </div>
                  </div>
                </Box>
              );
            })}
          </Box> */}
        </div>
      </div>
    </>
  );
};

//map state
const mapStateToProps = (state) => {
  return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
