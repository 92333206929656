const setBrand = (brandObj) => {
    return {
        type: "SET_BRAND",
        payload: brandObj
    }
}

const getBrand = () => {
    return {
        type: "GET_BRAND",
    }
}


export default {
    setBrand,
    getBrand
}