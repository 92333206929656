//import React from "react";

// React related imports
import React, { useState, useEffect } from "react";

// mui or UI related imports
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";

//Other imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// React route related imports
import { Link, useNavigate } from "react-router-dom";

//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import useAction from "../../../store/action/userAction";
import userDetailAction from "../../../store/action/userDetailAction";
import appObjectAction from "../../../store/action/appObjectAction";

//Apis related imports
import login from "../../../apis/login";
import logout from "../../../apis/logout";
import getUserDetail from "../../../apis/getUserDetail";

//Helpers function related imports
import createCookie from "../../../helpers/createCookie";
import removeCookie from "../../../helpers/removeCookie";
import removeLocalStorage from "../../../helpers/removeLocalStorage";

//Components related imports
import AlertMessage from "../AlertMessage";
import Loader from "../Loader";

import { Icons } from "../../../common/Icons";
import Greentick from "../../../icons/svgs/greentick";

const schema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum."),
  })
  .required();

const Login = (props) => {

  const { pageName, onRegisterClick, catalogueId, loginUserType, onLoginSuccess } = props;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const dispatch = useDispatch(),
    navigate = useNavigate(),
    { app } = props?.appData || {},
    [user, setDetails] = useState({ email: "", password: "" }),
    [isPasswordVisible, setPasswordVisible] = useState(false),
    [isAlertMessageShow, setAlternateMessageShow] = useState(false),
    [alertMessageType, setAlertMessageType] = useState(``),
    [alertMessage, setAlertMessage] = useState(``),
    [isLoading, setIsLoading] = useState(false),
    [isEmailCheck, setIsEmailCheck] = useState(false),
    [isWebCatalogueLogin, setWebCatalogueLogin] = useState(pageName === 'webcatalogue' ? true : false);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(user);
  }, [user]);

  useEffect(() => {
    if (props?.usersData?.user?.token) {
      setIsLoading(true);
      logout()
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            removeCookie(`discoverrrToken`);
            removeCookie(`discoverrrRefreshToken`);
            setIsLoading(false);
            // let obj = app;
            // obj[`isTokenRefreshed`] = false;
            // dispatch(appAction.setAppObject(obj))
            dispatch(useAction.setUser({}));
            let tempObject = app;
            tempObject[`productSelectedBrand`] = {};
            dispatch(appObjectAction.setAppObject(tempObject))
            removeLocalStorage(`productSelectedBrand`);
            removeLocalStorage(`productEditingStep`);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("Checking error", error);
        });
    }
  }, [])
  const onSubmit = (data) => {
    setIsLoading(true);

    login(data?.email, data?.password.trim(), catalogueId, loginUserType)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          if (response.data.status) {
            dispatch(useAction.setUser(response.data));

            if (response?.data?.token?.access) {
              createCookie("discoverrrToken", response?.data?.token?.access, 120);
            }

            if (response?.data?.token?.refresh) {
              createCookie("discoverrrRefreshToken", response?.data?.token?.refresh, 1440);
            }
            setIsLoading(false);
            // let obj = app;
            // obj[`isTokenRefreshed`] = false;
            // dispatch(appAction.setAppObject(obj))
            // dispatch(useAction.setUser({}));
            //User Detail
            getUserDetail()
              .then((response) => {
                if (response && response.status === 200 && response.data) {
                  if (response.data.status) {
                    dispatch(userDetailAction.setUserDetail(response.data));
                    // if(response?.data?.data?.usertype == `2`) {
                    //   console.log(`data is here`,response?.data?.data);
                    //   navigate('/buyer/catalogue');
                    // }
                  }
                }
              })
              .catch((error) => {
                console.log("Checking error", error);
              });
            //post lanch

            if (!isWebCatalogueLogin) {
              if (response?.data?.usertype === "2") {
                if (response?.data?.step) {
                  navigate('/buyer/onboarding');
                } else {
                  navigate('/buyer/catalogue');
                }
              } else {
                if (response.data.step) {
                  if (response.data.step_level == "4") {
                    navigate(`/myproducts`, { state: { "isCreateBrand": true, } })
                  }
                  else {
                    navigate('/onboarding');
                  }
                } else {
                  navigate('/myproducts');
                }
              }
            } else {
              if (onLoginSuccess) onLoginSuccess(response.data);
            }

          } else {
            setIsLoading(false);
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            setAlertMessage(response.data.message);
          }
        } else {
          if (
            response &&
            response.response &&
            response.response.data &&
            response.response.data.message
          ) {
            setIsLoading(false);
            setAlternateMessageShow(true);
            setAlertMessageType("error");
            setAlertMessage(response.response.data.message);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("Checking error", error);
      });
  };
  const onAlertClose = (value) => {
    setAlternateMessageShow(value);
  };
  return (
    <Box className={`login-component-wrap site-wrap bg-modal-window`}>
      {isLoading && <Loader />}
      {isAlertMessageShow && (
        <AlertMessage
          message={alertMessage}
          alertType={alertMessageType}
          isAlertShow={isAlertMessageShow}
          onClose={onAlertClose}
        />
      )}
      <Box>
        <Typography align="center" variant="h1" className={`first-heading`}>
          Welcome back!
        </Typography>

        <Typography
          textAlign={"center"}
          variant="h2"
          className={`second-heading`}
        >
          {
          isWebCatalogueLogin
            ?`Login using your buyer account`:`Login to your account`
          }
        </Typography>

      </Box>

      <form className={`form-input-control`} autoComplete="off" onSubmit={handleSubmit(onSubmit)} style={{ position: "relative" }}>
        <Typography className={`label`}>Email Address</Typography>
        <Box className={`form-control margin-bottom-28 email-control`}>
          <TextField
            id="email"
            name="email"
            placeholder={`e.g johndoe@company.com`}
            margin="dense"
            error={errors.email ? true : false}
            {...register("email", {
              onChange: (event) => {
                if (event.target.value) setIsEmailCheck(true);
              },
            })}
          />
          {
            !errors.email && isEmailCheck &&
            <img src={`https://discoverrr-s3-bucket.s3.me-south-1.amazonaws.com/frontend/icon/Check.svg`} alt="verified image" />
          }
          {/* <input {...register("email")} /> */}
          <p className={`field-err-msg`}>{errors.email?.message}</p>
        </Box>

        <div className={`flex flex-items-center flex-justify-content-space-between`}>
          <Typography className={`label`}>Password</Typography>
          <Typography
            className={`label show-label`}
            onClick={() => setPasswordVisible(!isPasswordVisible)}
          >
            <span className="flex flex-items-center flex-justify-content-center gap-10" style={{ cursor: "pointer" }}>
              <Icons iconName={`${isPasswordVisible ? "icon-eye-off" : "icon-eye"}`} />
              <span className="login-password__hide-show">{`${isPasswordVisible ? "Hide" : "Show"}`}</span>
            </span>

          </Typography>
        </div>
        <Box className={`form-control margin-bottom-28`}>
          <TextField
            type={`${isPasswordVisible ? "text" : "password"}`}
            id="password"
            name="password"
            placeholder={`Enter Password`}
            margin="dense"
            {...register("password")}
            error={errors.password ? true : false}
            spellCheck={false}
          />
          {/* <input {...register("password")} /> */}
          <p className={`field-err-msg`}>{errors.password?.message}</p>
        </Box>
        {/* <input type="submit" /> */}
        <Button
          type="submit"
          variant="contained"
          size="large"
          className={`w-100 button-primary ${isValid ? `` : `disabled`}`}
          disabled={isValid ? false : true}
        >
          Login
        </Button>

        {
          isWebCatalogueLogin
            ?
            <div className={`text-center forgot-password-label`}>
              <span className={`forgot-password-label skip-btn`} onClick={() => { if (onRegisterClick) onRegisterClick() }}>
                Register here
              </span>
            </div>
            :
            <div className={`text-center`}>
              <Link
                className={`forgot-password-label skip-btn ${isValid ? `` : `disabled`}`}
                to={`/password_reset`}
                disabled={isValid ? false : true}
              >
                Forgot Password?
              </Link>
            </div>
        }
      </form>
    </Box>
  );
};

//map state
const mapStateToProps = (state) => {
  return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
