
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';


const addCatalogue = async (brandId, catelogueName = ``) => {

    const headers = await getApiHeader() || {};;

    if(!brandId) {
        return {
            error: true,
            message: 'brandId mandatory in addCatalogue function' 
        }
    }

    if(!catelogueName) {
        return {
            error: true,
            message: 'catelogueName mandatory in addCatalogue function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in addCatalogue function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    let params = {}

    var formData = new FormData();

    if(catelogueName) formData.append("name", catelogueName);

    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-create/${brandId}`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default addCatalogue;