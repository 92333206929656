// React related imports
import React, { useState, useEffect } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import Header from "../Header";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";

//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import userAction from "../../../../store/action/userAction";
import appAction from "../../../../store/action/appObjectAction";
import MetaTagInjector from "../../MetaTagInjector";

// React route related imports
import { useLocation, useParams } from "react-router-dom";

//Helpers function related imports
import createCookie from "../../../../helpers/createCookie";
import readCookie from "../../../../helpers/readCookie";
import getUrlBasePath from "../../../../helpers/getUrlBasePath";

//Apis related imports
import tokenRefresh from "../../../../apis/tokenRefresh";

//Components related imports
import Loader from "../../Loader";

// React route related imports
import { useNavigate } from "react-router-dom";

const Main = (props) => {
    let currentPath = getUrlBasePath(useLocation(), useParams());
    let pageContainer = "md";
    let cssClassContainer = "";
    const { app } = props?.appData || {};
    const dispatch = useDispatch();
    const navigate = useNavigate();

    app.pagesContainerSize && app.pagesContainerSize.length > 0 &&
        app.pagesContainerSize.map((page) => {
            if (page[`path`] === currentPath) {
                pageContainer = page[`size`];
                cssClassContainer = page[`class`];
            }
        })
    return (
        <Box className={`main-wrapper buyer-main-wrapper ${cssClassContainer}`}>
            <MetaTagInjector />
            <Header />
            <Container maxWidth={pageContainer} className={`container-wrapper`}>
                {
                    props?.usersData?.user?.token &&
                    <Outlet />
                }

                {
                    !props?.usersData?.user?.token && readCookie(`discoverrrRefreshToken`) &&
                    <Loader />
                }

                {
                    !props?.usersData?.user?.token && !readCookie(`discoverrrRefreshToken`) &&
                    <Outlet />
                }

            </Container>
        </Box>
    );
}

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
