
import React from "react";

const editIcon = (props) => {
  const { name = "", size = "s", color = "#fff", className = "" } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4.11414C0.0766202 3.90673 0.125378 3.6828 0.235536 3.49499C0.545371 2.96536 1.02521 2.68364 1.63843 2.67926C3.10015 2.66894 4.56187 2.67487 6.02358 2.67668C6.36334 2.67719 6.60817 2.91763 6.60404 3.23572C6.59991 3.54788 6.35586 3.778 6.02075 3.77825C4.58921 3.77929 3.15742 3.77877 1.72589 3.77877C1.31931 3.77877 1.10338 3.9916 1.10338 4.39612C1.10287 6.91994 1.10287 9.44376 1.10338 11.9676C1.10338 12.3677 1.31596 12.5826 1.71428 12.5829C4.24223 12.5836 6.76992 12.5836 9.29787 12.5829C9.69129 12.5829 9.90645 12.3618 9.9067 11.9635C9.90696 10.5275 9.90593 9.09136 9.90748 7.65544C9.90799 7.25273 10.2679 6.98624 10.6355 7.11059C10.8669 7.18901 11.0078 7.39566 11.0091 7.67066C11.0111 8.16521 11.0098 8.6595 11.0098 9.15405C11.0098 10.0699 11.0098 10.9857 11.0098 11.9015C11.0098 12.8068 10.5878 13.378 9.72147 13.646C9.71373 13.6483 9.70754 13.6555 9.70058 13.6607C6.89711 13.6607 4.09389 13.6607 1.29042 13.6607C1.25998 13.6481 1.23005 13.6323 1.19858 13.6228C0.762848 13.491 0.423346 13.2317 0.211802 12.8298C0.115575 12.6471 0.0693967 12.4384 0.000515961 12.2416C0 9.53225 0 6.8232 0 4.11414Z" fill="#000000"/>
        <path d="M13.2086 2.02458C13.1377 2.46495 12.8743 2.78459 12.5585 3.07507C12.3849 3.2345 12.2244 3.40813 12.0699 3.56343C11.4214 2.91487 10.7787 2.27198 10.125 1.61826C10.1389 1.60381 10.1725 1.56718 10.2076 1.53209C10.417 1.3221 10.6252 1.11107 10.8368 0.903394C11.232 0.515908 11.7025 0.37763 12.2322 0.5399C12.7605 0.701912 13.0784 1.07366 13.187 1.61929C13.1901 1.63477 13.2012 1.64896 13.2086 1.66366C13.2086 1.78362 13.2086 1.9041 13.2086 2.02458Z" fill="#000000"/>
        <path d="M9.50319 2.24316C10.1538 2.89405 10.7972 3.53745 11.4448 4.18498C11.4239 4.20691 11.3952 4.23838 11.3656 4.26831C9.9704 5.66398 8.57601 7.06043 7.17673 8.45198C7.09701 8.53118 6.97989 8.59464 6.86999 8.61837C6.26993 8.74788 5.66703 8.8632 5.06464 8.98239C4.81698 9.0314 4.65523 8.87507 4.70399 8.62973C4.82756 8.00645 4.95268 7.38342 5.0827 6.76143C5.09766 6.68997 5.1343 6.6118 5.18512 6.56098C6.61743 5.12351 8.05309 3.68914 9.48823 2.25452C9.49391 2.24832 9.50165 2.2442 9.50319 2.24316Z" fill="#000000"/>
    </svg>
  );
};

export default editIcon;
