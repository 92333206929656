const setIndustryList = (industryObj) => {
    return {
        type: "SET_INDUSTRY_LIST",
        payload: industryObj
    }
}

const getIndustryList = () => {
    return {
        type: "GET_COUNTRY_LIST"
    }
}


export default {
    setIndustryList,
    getIndustryList
}