
import axios from 'axios';
import getApiHeader from '../helpers/getApiHeader';
import {CONFIG} from '../helpers/getApiConfig';

const getUserDetail = async () => {

    const headers = await await getApiHeader() || {};;

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in getUserDetail function' 
        }
    }

    const instance = axios.create();
    
    instance.defaults.timeout = CONFIG.timeout || 15000;
    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/user`, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default getUserDetail