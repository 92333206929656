import React from "react";

const camera = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="108" height="107" viewBox="0 0 108 107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_i_1426_2661)">
                <circle cx="54.0004" cy="53.1214" r="50" transform="rotate(13.2384 54.0004 53.1214)" fill="#6981F1" fillOpacity="0.2"/>
            </g>
            <circle cx="54.0004" cy="53.1214" r="51.5" transform="rotate(13.2384 54.0004 53.1214)" stroke="#3357FF" strokeWidth="3"/>
                <path d="M45 13.6211L20.25 38.3711L9 27.1211" transform="translate(30, 25)" stroke="#3357FF" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
            <defs>
            <filter id="filter0_i_1426_2661" x="0.988525" y="0.109375" width="106.024" height="106.023" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-9"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.411765 0 0 0 0 0.505882 0 0 0 0 0.945098 0 0 0 0.5 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1426_2661"/>
            </filter>
            </defs>
        </svg>

    );
};

export default camera;
