const setpasswordresettype = (businessTypeObj) => {
    return {
        type: "SET_PASSWORD_RESET_TYPE",
        payload: businessTypeObj
    }
}

const getpasswordresettype = () => {
    return {
        type: "GET_PASSWORD_RESET_TYPE",
    }
}


export default {
    setpasswordresettype,
    getpasswordresettype
}