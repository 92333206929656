const setEditProductObject = (productObject) => {
    return {
        type: "SET_EDIT_PRODUCT_OBJECT",
        payload: productObject
    }
}

const getEditProductObject = () => {
    return {
        type: "GET_EDIT_PRODUCT_OBJECT"
    }
}


export default {
    setEditProductObject,
    getEditProductObject
}