import React from "react";

const searchIcon = (props) => {
    const { name = "", size = "s", color = "#fff", className = "" } = props;

    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.19531 14.8906C12.509 14.8906 15.1953 12.2043 15.1953 8.89062C15.1953 5.57692 12.509 2.89062 9.19531 2.89062C5.8816 2.89062 3.19531 5.57692 3.19531 8.89062C3.19531 12.2043 5.8816 14.8906 9.19531 14.8906Z" stroke="#686868" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.6954 16.3914L13.4329 13.1289" stroke="#686868" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default searchIcon;
