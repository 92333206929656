
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import { CONFIG } from '../../helpers/getApiConfig';

const productUpdate = async (productObject) => {

    const {
        productId,
        name,
        sku_id,
        barcodetype,
        barcode,
        hs_code,
        image_set_primary,
        galaryImages,
        country,
        usps,
        brandcategory,
        userIndustrys,
        price,
        ps_unit,
        ps_quantity,
        ps_meas,
        ps_meas_value,
        galleryDelete,
        productbrand,
        product_flavour,
        pageDimension,
        pageCaseDimension,
        pageInnerDimension,
        productDetailObject,
        productIngredients,
        productMarketing,
        additionalgallery,
        additionalgalleryDelete,
        additionalimage_set_primary,
        productTechSpecification,
        productDistribution,
        retailersList,
        distributorsList,
        countriesSoldList,
        certification,
        other_certification,
        planogramgallery,
        planogramgalleryDelete,
        planogram_image_set_primary,
        planogramlist,
        p_description,
        containerlist
    } = productObject;

    const headers = await getApiHeader() || {};;

    if (!productId) {
        return {
            error: true,
            message: 'productId mandatory in productUpdate function'
        }
    }

    if (!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in productUpdate function'
        }
    }

    const instance = axios.create();

    instance.defaults.timeout = CONFIG.timeout || 15000;
    // console.log(barcode,'barcode',typeof barcode)
    var formData = new FormData();
    if (name) formData.append(`name`, name || ``);
    if (sku_id) formData.append(`sku_id`, sku_id || ``);
    if (barcodetype) formData.append(`barcodetype`, barcodetype || ``);
    if (typeof barcode !== 'undefined') formData.append(`barcode`, barcode || ``);
    if (typeof hs_code !== 'undefined') formData.append(`hs_code`, hs_code || ``);
    galaryImages && galaryImages.length > 0 && galaryImages.map((image) => {
        formData.append('gallery', image);
    })
    if (galleryDelete) formData.append('galleryDelete', galleryDelete || ``);
    if (country) formData.append(`country`, country || "none");
    // formData.append(`country`, country || ``);
    if (typeof usps !== 'undefined') formData.append(`usps`, usps ? usps : '');
    if (brandcategory) formData.append(`brandcategory`, brandcategory || ``);
    if (price) formData.append(`price`, JSON.stringify(price) || ``);
    if (typeof ps_unit !== 'undefined') formData.append(`ps_unit`, ps_unit || ``);
    if (typeof ps_quantity !== 'undefined') formData.append(`ps_quantity`, ps_quantity || ``);
    if (typeof ps_meas !== 'undefined') formData.append(`ps_meas`, ps_meas || ``);
    if (typeof ps_meas_value !== 'undefined') formData.append(`ps_meas_value`, ps_meas_value || ``)
    if (productbrand) formData.append(`productbrand`, productbrand || ``);
    // console.log('product_flavour',product_flavour)
    if (typeof product_flavour !== 'undefined') formData.append(`product_flavour`, product_flavour || ``)
    if (userIndustrys) formData.append(`user_industry`, userIndustrys || ``);
    if (pageDimension) formData.append(`packagedimension`, JSON.stringify(pageDimension) || ``);
    if (pageCaseDimension) formData.append(`packagecasedimension`, JSON.stringify(pageCaseDimension) || ``);
    if (pageInnerDimension) formData.append(`packageinnerdimension`, JSON.stringify(pageInnerDimension) || ``);
    if (productDetailObject) formData.append(`productDetail`, JSON.stringify(productDetailObject) || ``);
    formData.append(`productIngredients`, productIngredients || ``);
    if (image_set_primary || !image_set_primary) formData.append('image_set_primary', image_set_primary || ``);
    if (productMarketing) formData.append('productMarketing', JSON.stringify(productMarketing) || ``);
    additionalgallery && additionalgallery.length > 0 && additionalgallery.map((image) => {
        formData.append('additionalgallery', image);
    })
    if (additionalgalleryDelete) formData.append('additionalgalleryDelete', additionalgalleryDelete || ``);
    if (additionalimage_set_primary || !additionalimage_set_primary) formData.append('additionalimage_set_primary', additionalimage_set_primary || ``);
    if (productTechSpecification) formData.append('productTechSpecification', JSON.stringify(productTechSpecification));

    if (productDistribution) formData.append('productDistribution', JSON.stringify(productDistribution) || ``);
    if (retailersList) formData.append('retailersList', JSON.stringify(retailersList) || ``);
    if (distributorsList) formData.append('distributorsList', distributorsList || ``);
    formData.append('countriesSoldList', countriesSoldList ? countriesSoldList : ``);

    formData.append('certification', JSON.stringify(certification) || ``);
    if (other_certification) formData.append('other_certification', JSON.stringify(other_certification) || ``);

    planogramgallery && planogramgallery.length > 0 && planogramgallery.map((image) => {
        formData.append('planogramgallery', image);
    })
    if (planogramgalleryDelete) formData.append('planogramgalleryDelete', planogramgalleryDelete || ``);
    if (planogram_image_set_primary || !planogram_image_set_primary) formData.append('planogram_image_set_primary', planogram_image_set_primary || ``);
    if(planogramlist) formData.append('planogramlist', planogramlist ? JSON.stringify(planogramlist) : ``)
    if(p_description) formData.append('p_description', p_description);
    
    if(containerlist) formData.append(`containerlist`,JSON.stringify(containerlist) || ``)
    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/products/productUpdate/${productId}`, formData, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default productUpdate;