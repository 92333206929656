//import './App.css';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import dynamicRoutes from './route'
import {Provider} from 'react-redux';
import reduxStore from './store/index';

function App() {
  
  return (
    <Provider store={reduxStore}>
    <div>
       <Router>
      <Routes>
       {dynamicRoutes}
      </Routes>
    </Router>
    </div>
    </Provider>
  );
}

export default App;
