const setBusinessType = (businessTypeObj) => {
    return {
        type: "SET_BUSINESS_TYPE",
        payload: businessTypeObj
    }
}

const getBusinessType = () => {
    return {
        type: "GET_BUSINESS_TYPE",
    }
}


export default {
    setBusinessType,
    getBusinessType
}