import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef, Fragment } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField"
import AddIcon from '@mui/icons-material/Add';

//Apis related imports
import getBudgetList from '../../../../../apis/Supplier/getBudgetList';
import productUpdate from '../../../../../apis/Supplier/productUpdate';

//Components related imports
import AlertMessage from "../../../AlertMessage";
import Loader from "../../../Loader";
import PopupImageViewer from "../../PopupImageViewer";
//Icons related imports
import CameraIcon from '../../../../../icons/svgs/camera';

const ImageUploadSection = (props) => {
    const { isImageUploadSliderShow, onClose, onSave } = props;

    const [uploadImages, setUploadImagesValue] = useState(props.images || []),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``);

    let dropArea;
    let imageVideoWrapper;
    useEffect(() => {
        if (isImageUploadSliderShow) {
            document.body.classList.add('hide-scroll');
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            document.body.classList.remove('hide-scroll');
        }

        dropArea = document.getElementsByClassName('image-upload-wrapper')[0];
        imageVideoWrapper = document.getElementsByClassName('image-upload-sidenav')[0];

        dropArea.addEventListener('dragenter', highlightSection, false)
        dropArea.addEventListener('dragover', highlightSection, false)
        dropArea.addEventListener('dragleave', handleDrop, false)
        dropArea.addEventListener('drop', handleDrop, false)
        return () => {
            document.body.classList.remove('hide-scroll');
        }
    }, [isImageUploadSliderShow])

    useEffect(() => {
        setUploadImagesValue(props.images);
    }, [props.images])

    const showUploadImage = file => new Promise((resolve, reject) => {

        if ([`image/jpeg`, `image/png`, `image/gif`].indexOf(file[`type`]) === -1) {
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage(`File extension not supported!`);
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (event) => {
            resolve({
                "file": file,
                "image": event.target.result
            })
        }
        reader.onerror = (error) => {
            reject(error);
        }
    });


    const removeImage = (index) => {
        let tempArray = uploadImages;
        tempArray = tempArray.filter((file, i) => {
            return i !== index;
        })
        setUploadImagesValue(tempArray)
    }

    const uploadImgOnChange = async (files) => {
        let tempArray = [];
        if (uploadImages.length + files.length > 3) {
            // alert('Upload upto 3 images');
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage(`Upload upto 3 images`);
        } else {
            for (let k = 0; k < files.length; k++) {
                if ([`image/jpeg`, `image/png`, `image/gif`].indexOf(files[k][`type`]) === -1) {
                    setAlternateMessageShow(true);
                    setAlertMessageType("information");
                    setAlertMessage(`File extension not supported!`);
                    return;
                } else {
                    const reader = new FileReader();
                    reader.readAsDataURL(files[k]);
                    reader.onload = (i) => {
                        setUploadImagesValue((uploadImages) => [...uploadImages, {
                            "file": files[k],
                            "image": i.target.result
                        }]);
                    };
                }
            }
        }
    }


    const highlightSection = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.getElementsByClassName(`drop-your-image-wrap`)[0].classList.add('highlight-upload-section');
        // imageVideoWrapper.classList.add('border-none');
    }

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.getElementsByClassName(`drop-your-image-wrap`)[0].classList.remove('highlight-upload-section');
        // imageVideoWrapper.classList.remove('border-none');
        uploadImgOnChange(e.dataTransfer.files);
    }

    const onImageSave = () => {
        if (onSave) onSave(uploadImages);
    }

    const onSliderClose = () => {
        if (uploadImages) {
            onImageSave()
        } else {
            setUploadImagesValue([]);
        }
        onClose(false);
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    return (
        <Box className={`image-upload-wrapper ${isImageUploadSliderShow ? 'show-overlay' : ''}`}>

            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`image-upload-sidenav scroll-bar-theme ${isImageUploadSliderShow ? 'show' : 'hide'}`}>
                <input type='file' className={`visibility-hidden`} name='imageupload' id='imageupload' onChange={(event) => { uploadImgOnChange(event.target.files) }} accept="image/x-png,image/gif,image/jpeg" multiple />
                <div className={`image-upload`}>
                    <div className={`header`}>
                        <div className={`text`}>
                            Upload Image
                        </div>
                        <div className={`close-icon`} onClick={() => { if (onClose) { onSliderClose() } }}>
                            <CloseIcon />
                        </div>
                    </div>
                    <div className={`divider`}></div>

                    <div className={`drop-your-image-wrap`}>
                        <div className={`drop-image`} onClick={() => { document.getElementById('imageupload').click() }}>
                            <CameraIcon />
                        </div>
                        <div className={`text`}>
                            Drop your image(s) here
                        </div>
                    </div>

                    {
                        uploadImages && uploadImages.length > 0
                            ?
                            <div className={`image-upload-success`}>
                                {
                                    uploadImages && uploadImages.length > 0 &&
                                    uploadImages.map((image, index) => {
                                        return (
                                            <div className={`image-info-wrap`} key={index}>
                                                <div className={`image-info`}>
                                                    {
                                                        (image?.file?.name || image?.name) &&
                                                        <div className={`name`}>
                                                            {image?.file?.name || image?.name}
                                                        </div>
                                                    }
                                                    {/* {
                                                        image?.file?.size &&
                                                        <div className={`size`}>
                                                            {`(${image?.file?.size} KB)`}
                                                        </div>
                                                    } */}

                                                </div>

                                                <div className={`close-icon`} onClick={() => { removeImage(index) }}>
                                                    <CloseIcon />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className={`product-upload-information-wrapper`}>
                                <div className={`product-image`}>
                                    <div className={`text`}>Product image</div>
                                    <div className={`description`}>The product image is your first impression to buyers and one of the most important elements of your listing.</div>
                                </div>

                                <div className={`image-requirement`}>
                                    <div className={`text`}>Image requirements</div>
                                    <div className={`description`}>
                                        <ul>
                                            <li>Image must be atleast 600 x 600 px</li>
                                            <li>Use a white background</li>
                                            <li>Show the entire product</li>
                                            <li>Your product should be the focus of the image</li>
                                            <li>Avoid words, logos and watermarks on the image</li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                    }

                    <div className={`divider`}></div>
                    <div className={`button-wrapper`}>
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            id="submit-btn"
                            className={`w-100 btn-primary-after-login ${uploadImages.length > 0 ? '' : 'disabled'}`}
                            onClick={() => { onImageSave() }}
                            disabled={uploadImages.length > 0 ? false : true}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </Box>
    )
}


const PlanogramImageSection = (props) => {
    const { isImageUploadSliderShow, onClose, onSave } = props;

    const [uploadImages, setUploadImagesValue] = useState(props.images || []),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``);

    let dropArea;
    let imageVideoWrapper;
    useEffect(() => {
        if (isImageUploadSliderShow) {
            document.body.classList.add('hide-scroll');
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            document.body.classList.remove('hide-scroll');
        }

        dropArea = document.getElementsByClassName('image-upload-wrapper')[0];
        imageVideoWrapper = document.getElementsByClassName('image-upload-sidenav')[0];

        dropArea.addEventListener('dragenter', highlightSection, false)
        dropArea.addEventListener('dragover', highlightSection, false)
        dropArea.addEventListener('dragleave', handleDrop, false)
        dropArea.addEventListener('drop', handleDrop, false)
        return () => {
            document.body.classList.remove('hide-scroll');
        }
    }, [isImageUploadSliderShow])

    useEffect(() => {
        setUploadImagesValue(props.images);
    }, [props.images])

    const showUploadImage = file => new Promise((resolve, reject) => {

        if ([`image/jpeg`, `image/png`, `image/gif`].indexOf(file[`type`]) === -1) {
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage(`File extension not supported!`);
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (event) => {
            resolve({
                "file": file,
                "image": event.target.result
            })
        }
        reader.onerror = (error) => {
            reject(error);
        }
    });


    const removeImage = (index) => {
        let tempArray = uploadImages;
        tempArray = tempArray.filter((file, i) => {
            return i !== index;
        })
        setUploadImagesValue(tempArray)
    }

    const uploadImgOnChange = async (files) => {
        let tempArray = [];
        if (uploadImages.length + files.length > 5) {
            // alert('Upload upto 3 images');
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage(`Upload upto 5 images`);
        } else {
            for (const file of files) {
                tempArray.push(await showUploadImage(file));
                setUploadImagesValue([...uploadImages, ...tempArray]);
            }
        }
    }


    const highlightSection = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.getElementsByClassName(`drop-your-image-wrap`)[0].classList.add('highlight-upload-section');
        // imageVideoWrapper.classList.add('border-none');
    }

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.getElementsByClassName(`drop-your-image-wrap`)[0].classList.remove('highlight-upload-section');
        // imageVideoWrapper.classList.remove('border-none');
        uploadImgOnChange(e.dataTransfer.files);
    }

    const onImageSave = () => {
        if (onSave) onSave(uploadImages);
    }

    const onSliderClose = () => {
        if (uploadImages) {
            onImageSave()
        } else {
            setUploadImagesValue([]);
        }
        onClose(false);
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    return (
        <Box className={`image-upload-wrapper ${isImageUploadSliderShow ? 'show-overlay' : ''}`}>

            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`image-upload-sidenav scroll-bar-theme ${isImageUploadSliderShow ? 'show' : 'hide'}`}>
                <input type='file' className={`visibility-hidden`} name='planogramImageUpload' id='planogramImageUpload' onChange={(event) => { uploadImgOnChange(event.target.files) }} accept="image/x-png,image/gif,image/jpeg" multiple />
                <div className={`image-upload`}>
                    <div className={`header`}>
                        <div className={`text`}>
                            Upload Image
                        </div>
                        <div className={`close-icon`} onClick={() => { if (onClose) { onSliderClose() } }}>
                            <CloseIcon />
                        </div>
                    </div>
                    <div className={`divider`}></div>

                    <div className={`drop-your-image-wrap`}>
                        <div className={`drop-image`} onClick={() => { document.getElementById('planogramImageUpload').click() }}>
                            <CameraIcon />
                        </div>
                        <div className={`text`}>
                            Drop your image(s) here
                        </div>
                    </div>

                    {
                        uploadImages && uploadImages.length > 0
                            ?
                            <div className={`image-upload-success`}>
                                {
                                    uploadImages && uploadImages.length > 0 &&
                                    uploadImages.map((image, index) => {
                                        return (
                                            <div className={`image-info-wrap`} key={index}>
                                                <div className={`image-info`}>
                                                    {
                                                        (image?.file?.name || image?.name) &&
                                                        <div className={`name`}>
                                                            {image?.file?.name || image?.name}
                                                        </div>
                                                    }
                                                    {/* {
                                                        image?.file?.size &&
                                                        <div className={`size`}>
                                                            {`(${image?.file?.size} KB)`}
                                                        </div>
                                                    } */}

                                                </div>

                                                <div className={`close-icon`} onClick={() => { removeImage(index) }}>
                                                    <CloseIcon />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className={`product-upload-information-wrapper`}>
                                <div className={`product-image`}>
                                    <div className={`text`}>Product image</div>
                                    <div className={`description`}>The product image is your first impression to buyers and one of the most important elements of your listing.</div>
                                </div>

                                <div className={`image-requirement`}>
                                    <div className={`text`}>Image requirements</div>
                                    <div className={`description`}>
                                        <ul>
                                            <li>Image must be atleast 600 x 600 px</li>
                                            <li>Use a white background</li>
                                            <li>Show the entire product</li>
                                            <li>Your product should be the focus of the image</li>
                                            <li>Avoid words, logos and watermarks on the image</li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                    }

                    <div className={`divider`}></div>
                    <div className={`button-wrapper`}>
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            id="submit-btn"
                            className={`w-100 btn-primary-after-login ${uploadImages.length > 0 ? '' : 'disabled'}`}
                            onClick={() => { onImageSave() }}
                            disabled={uploadImages.length > 0 ? false : true}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </Box>
    )
}

const PlanogramSection = forwardRef((props, ref) => {

    const { onSave, planogramGallery } = props;
    const [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [planogramArray, setPlanogramArray] = useState([]),
        [planogramCount, setPlanogramCount] = useState(1),
        [isImageViewShow, setIsImageViewShow] = useState(false),
        [imageViewerData, setImageViewerData] = useState({});

    // useEffect(() => {
    //     setPlanogramArray([{
    //         "id": 1,
    //         "type": ``,
    //         "image": {}
    //     }])
    // }, [])

    useEffect(() => {
        if (Object.keys(imageViewerData).length > 0) {
            setIsImageViewShow(true);
        }
    }, [imageViewerData])

    useEffect(() => {
        if (planogramGallery && planogramGallery.length > 0) {
            planogramGallery.map((data) => {
                renderPlanogramViaApi(data?.pro_planogram_id, data?.name, { 'image': data?.image });
            })
        }
    }, [planogramGallery]);

    useImperativeHandle(ref, () => ({
        checkPlanogramErrors() {

            let isError = false;

            if (planogramArray && planogramArray.length > 0) {
                planogramArray.map((data) => {
                    if (!data?.type) {
                        if (document.getElementById(`type-error-${data?.id}`)) {
                            document.getElementById(`type-error-${data?.id}`).classList.remove(`hide`);
                            document.getElementById(`type-error-${data?.id}`).scrollIntoView({ block: 'end' ,behavior: 'smooth', inline: 'start'});
                        }

                        isError = true;
                    }

                    if (Object.keys(data?.image).length === 0) {
                        if (document.getElementById(`image-error-${data?.id}`)) {
                            document.getElementById(`image-error-${data?.id}`).classList.remove(`hide`);
                            document.getElementById(`image-error-${data?.id}`).scrollIntoView({ block: 'end' ,behavior: 'smooth', inline: 'start'});
                        }

                        isError = true;
                    }
                })
            }

            return isError;
        }
    }));

    const showUploadImage = file => new Promise((resolve, reject) => {

        if ([`image/jpeg`, `image/png`, `image/gif`].indexOf(file[`type`]) === -1) {
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage(`File extension not supported!`);
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (event) => {
            resolve({
                "file": file,
                "image": event.target.result
            })
        }
        reader.onerror = (error) => {
            reject(error);
        }
    });

    const onImageUpload = async (files, id) => {

        let tempArray = [], tempDataArray = planogramArray;
        for (const file of files) {
            tempArray.push(await showUploadImage(file));

            if (tempDataArray && tempDataArray.length > 0) {
                tempDataArray.map((data) => {
                    if (data?.id == id) {
                        data[`image`] = tempArray[0];
                    }
                })
            }
        }

        if (tempArray && tempArray.length > 0) {

            if (document.getElementById(`planogram-img-upload-${id}`)) {
                document.getElementById(`planogram-img-upload-${id}`).classList.add(`hide`);
            }

            if (document.getElementById(`planogram-img-view-${id}`)) {
                document.getElementById(`planogram-img-view-${id}`).classList.remove(`hide`);
            }

            let element = document.getElementById(`upload-wrapper-${id}`);

            if (element && element.getElementsByClassName(`image-upload-wrap`)[0]) {
                element.getElementsByClassName(`image-upload-wrap`)[0].classList.add(`hide`)
            }
        }

        if (onSave) {
            onSave(tempDataArray);
        }
        setPlanogramArray(tempDataArray);
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onAddNewPlanogramImage = () => {
        if (planogramCount === 5) {
            setAlternateMessageShow(true);
            setAlertMessageType("information");
            setAlertMessage("you cannot add more than 5");
        } else {

            setPlanogramCount(planogramCount => planogramCount + 1);
            let randomNumber = Math.floor(Math.random() * 100) + 2;

            let tempArray = planogramArray;

            tempArray.push({
                "id": randomNumber,
                "type": ``,
                "image": {}
            })

            setPlanogramArray(tempArray);

            let element = `
                <div class="form-control position-relative input-type-wrap">
                    <input type="text" id="planogram-input-${randomNumber}" class="input-wrap name-input" placeholder="Enter planogram type..." />
                    <p class="field-err-msg type-error hide" id="type-error-${randomNumber}">Planogram type required</p>
                </div>

                <div class="image-upload-wrap" >
                    <div class="skip-btn" id="planogram-img-upload-${randomNumber}">
                        Upload image
                    </div>
                    <p class="field-err-msg image-error hide" id="image-error-${randomNumber}">Image required</p>
                </div>

                <div class="image-upload-wrap view-image skip-btn hide" id="planogram-img-view-${randomNumber}">
                    View
                </div>

                <div class="close-icon-wrap" id="planogram-close-icon-${randomNumber}">
                    <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
                </div>

                <input type="file" class="visibility-hidden file-upload" id="planogram-file-upload-${randomNumber}" accept="image/x-png,image/gif,image/jpeg" />
            `

            var div = document.createElement('div');
            div.classList.add('upload-wrapper');
            div.id = `upload-wrapper-${randomNumber}`;
            div.innerHTML = element;

            if (document.getElementsByClassName(`image-upload-wrapper`)[0]) {
                document.getElementsByClassName(`image-upload-wrapper`)[0].append(div);
            }

            if (document.getElementById(`planogram-input-${randomNumber}`)) {
                document.getElementById(`planogram-input-${randomNumber}`).addEventListener(`blur`, (event) => {
                    onPlanogramTypeBlur(event.target.value, randomNumber);
                })

                document.getElementById(`planogram-input-${randomNumber}`).addEventListener(`input`, (event) => {
                    onPlanogramInputChange(event.target.value, randomNumber);
                })

            }

            if (document.getElementById(`planogram-img-upload-${randomNumber}`) && document.getElementById(`planogram-file-upload-${randomNumber}`)) {
                document.getElementById(`planogram-img-upload-${randomNumber}`).addEventListener('click', () => {
                    document.getElementById(`planogram-file-upload-${randomNumber}`).click();
                })

                document.getElementById(`planogram-file-upload-${randomNumber}`).addEventListener(`change`, (event) => {
                    onImageUpload(event.target.files, `${randomNumber}`)
                })
            }

            if (document.getElementById(`planogram-close-icon-${randomNumber}`)) {
                document.getElementById(`planogram-close-icon-${randomNumber}`).addEventListener(`click`, () => {
                    onPlanogramDelete(randomNumber);
                })
            }

            if (document.getElementById(`planogram-img-view-${randomNumber}`)) {
                document.getElementById(`planogram-img-view-${randomNumber}`).addEventListener(`click`, () => {
                    onImageView(randomNumber);
                })
            }
        }
    }

    const onPlanogramDelete = (id) => {

        setPlanogramCount(planogramCount => planogramCount - 1);
        document.getElementById(`upload-wrapper-${id}`).remove();

        let tempArray = [];

        if (planogramArray && planogramArray.length > 0) {
            planogramArray.map((data) => {
                if (data?.id != id) {
                    tempArray.push(data);
                }
            })
        }

        if (onSave) {
            onSave(tempArray);
        }
        setPlanogramArray(tempArray);

    }

    const onPlanogramTypeBlur = (text, id) => {

        let tempDataArray = planogramArray;
        if (tempDataArray && tempDataArray.length > 0) {
            tempDataArray.map((data) => {
                if (data?.id == id) {
                    data[`type`] = text
                }
            })
        }

        if (onSave) {
            onSave(tempDataArray);
        }
        setPlanogramArray(tempDataArray);
    }

    const onImageView = (id) => {

        if (planogramArray && planogramArray.length > 0) {
            planogramArray.map((data) => {
                if (data?.id == id) {
                    setImageViewerData(data?.image);
                }
            })
        }
    }

    const onPlanogramInputChange = (text, id) => {
        if (text.trim()) {
            if (document.getElementById(`type-error-${id}`)) {
                document.getElementById(`type-error-${id}`).classList.add(`hide`);
            }
        } else {
            if (document.getElementById(`type-error-${id}`)) {
                document.getElementById(`type-error-${id}`).classList.remove(`hide`);
            }
        }
    }

    const renderPlanogramViaApi = (id, type, image) => {

        let tempArray = planogramArray;

        tempArray.push({
            "id": id,
            "type": type,
            "image": image
        })

        let data = tempArray.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id && t.name === value.name
            ))
        )

        setPlanogramCount(data.length);
        setPlanogramArray(data);
    }
    return (
        <Box className={`form-control margin-bottom-28 planogram-section-wrapper`}>
            {
                isImageViewShow &&
                <PopupImageViewer
                    data={imageViewerData}
                    onPopupClose={(value) => { setIsImageViewShow(value); setImageViewerData({}) }}
                />
            }

            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            
            <Typography className={`label margin-btm-15`}>Planogram(Optional)</Typography>

            <div className={`image-upload-wrapper`}>

                {
                    false && planogramGallery && planogramGallery.length === 0
                        ?
                        <div className={`upload-wrapper`} id={`upload-wrapper-1`}>
                            <div className={`form-control position-relative input-type-wrap`}>
                                <input type="text"
                                    id="planogram-input-1"
                                    className={`input-wrap name-input`}
                                    placeholder={`Enter planogram type...`}
                                    onBlur={(event) => onPlanogramTypeBlur(event.target.value, `1`)}
                                    onInput={(event) => onPlanogramInputChange(event.target.value, `1`)}
                                />
                                <p class="field-err-msg type-error hide" id="type-error-1">{`Planogram type required`}</p>
                            </div>

                            <div className={`image-upload-wrap`} >
                                <div id="planogram-img-upload-1" className="skip-btn" onClick={() => { if (document.getElementById(`planogram-file-upload-1`)) document.getElementById(`planogram-file-upload-1`).click() }}>
                                    Upload image
                                </div>
                                <p class="field-err-msg image-error hide" id="image-error-1">{`Image required`}</p>
                            </div>

                            <div className={`image-upload-wrap view-image skip-btn hide`} id="planogram-img-view-1" onClick={() => onImageView(1)}>
                                View
                            </div>

                            <div className={`close-icon-wrap`} id="planogram-close-icon-1" onClick={() => { onPlanogramDelete(1) }}>
                                <CloseIcon />
                            </div>

                            <input type={`file`} className={`visibility-hidden file-upload`} id="planogram-file-upload-1" onChange={(event) => { onImageUpload(event.target.files, `1`) }} accept="image/x-png,image/gif,image/jpeg" />
                        </div>
                        :
                        <Fragment>
                            {
                                planogramGallery && planogramGallery.length > 0 &&
                                planogramGallery.map((image) => {
                                    let randomNumber = image?.pro_planogram_id;
                                    return (
                                        <Fragment>
                                            <div className={`upload-wrapper`} id={`upload-wrapper-${randomNumber}`}>
                                                <div className={`form-control position-relative input-type-wrap`}>
                                                    <input type="text"
                                                        id={`planogram-input-${randomNumber}`}
                                                        className={`input-wrap name-input`}
                                                        placeholder={`Enter planogram type...`}
                                                        onBlur={(event) => onPlanogramTypeBlur(event.target.value, randomNumber)}
                                                        onInput={(event) => onPlanogramInputChange(event.target.value, randomNumber)}
                                                        value={image?.name}
                                                    />
                                                    <p class="field-err-msg type-error hide" id={`type-error-${randomNumber}`}>{`Planogram type required`}</p>
                                                </div>

                                                <div className={`image-upload-wrap view-image skip-btn`} id={`planogram-img-view-${randomNumber}`} onClick={() => onImageView(randomNumber)}>
                                                    View
                                                </div>

                                                <div className={`close-icon-wrap`} id={`planogram-close-icon-${randomNumber}`} onClick={() => { onPlanogramDelete(randomNumber) }}>
                                                    <CloseIcon />
                                                </div>

                                                <input type={`file`} className={`visibility-hidden file-upload`} id={`planogram-file-upload-${randomNumber}`} onChange={(event) => { onImageUpload(event.target.files, randomNumber) }} accept="image/x-png,image/gif,image/jpeg" />
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </Fragment>
                }

            </div>

            {
                planogramCount !== 5 &&
                <div className={`skip-btn`}>
                    <div className={`add-another-contact-wrap`} onClick={() => onAddNewPlanogramImage()}>
                        <AddIcon />
                        Add one more
                    </div>
                </div>
            }
        </Box>
    )
})

const ProductMarketing = (props) => {

    const { brandId, productDetails, onSectionApiCallSuccess } = props;

    const planogramRef = useRef(null);

    const [productMarketingDetail, setProductMarketingDetails] = useState(productDetails?.products_marketing),
        [budgetList, setBudgetList] = useState([]),
        [selectedBudget, setSelectedBudget] = useState(productMarketingDetail?.[0]?.budget || ''),
        [uploadImages, setUploadImagesValue] = useState(productDetails?.products_additional_gallery || []),
        [primaryImageValue, setPrimaryImageValue] = useState(''),
        [removedImageIds, setRemovedImageIds] = useState([]),
        [isImageUploadSliderShow, setImageUploadSliderShow] = useState(false),
        [videoLink, setVideoLink] = useState(productMarketingDetail?.[0]?.video_url || ``),
        [isVideoLinkHasError, setIsVideoLinkHasError] = useState(false),
        [isBudgetHasError, setIsBudgetHasError] = useState(false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isLoading, setIsLoading] = useState(false),
        [PlanogramPrimaryImageValue, setPlanogramPrimaryImageValue] = useState(0),
        [isPlanogramImageSliderShow, setIsPlanogramImageSliderShow] = useState(false),
        [planogramUploadImage, setPlanogramUploadImage] = useState(productDetails?.products_planogram_gallery || []),
        [planogramRemovedImageIds, setPlanogramRemovedImageIds] = useState([]),
        [planogramImages, setPlanogramImages] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getBudgetList()
            .then((response) => {
                if (response?.status === 200 && response?.data?.status && response?.data?.data) {
                    setBudgetList(response?.data?.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("we have errot at budgetList", error);
            });
    }, []);

    useEffect(() => {
        setProductMarketingDetails(productDetails?.products_marketing)
        setSelectedBudget(productDetails?.products_marketing?.[0]?.budget || '');
        setVideoLink(productDetails?.products_marketing?.[0]?.video_url || ``);

        if (productDetails?.products_additional_gallery && productDetails?.products_additional_gallery.length > 0) {
            let tempArray = [];
            productDetails?.products_additional_gallery.map((img, index) => {
                tempArray.push({
                    "image": img[`image`],
                    "name": img[`name`],
                    "pro_add_gallery_id": img[`pro_add_gallery_id`]
                })

                if (img[`default`]) {
                    setPrimaryImageValue(index);
                }
            })
            setUploadImagesValue(tempArray);
        }
        if (productDetails?.products_planogram_gallery && productDetails?.products_planogram_gallery.length > 0) {
            let tempArray = [];
            productDetails?.products_planogram_gallery.map((img, index) => {
                tempArray.push({
                    "image": img[`image`],
                    "name": img[`name`],
                    "pro_planogram_id": img[`pro_planogram_id`]
                })

                if (img[`default`]) {
                    setPlanogramPrimaryImageValue(index);
                }
            });

            setPlanogramUploadImage(tempArray);
        }

    }, [productDetails]);

    const onBudgetChange = (event) => {
        setIsBudgetHasError(false);
        setSelectedBudget(event.target.value);
    };

    const onPrimaryImageChange = (event) => {
        setPrimaryImageValue(event.target.value);
    };

    const removeImage = (index, imageId) => {
        let tempArray = uploadImages;
        tempArray = tempArray.filter((file, i) => {
            return i !== index;
        })
        if (imageId) {
            setRemovedImageIds([...removedImageIds, imageId]);
        }

        if (tempArray.length == 0) {
            setPrimaryImageValue(``);
        }

        setUploadImagesValue(tempArray)
    }

    const onImagesSave = (images) => {
        setUploadImagesValue(images);
        setImageUploadSliderShow(false);
        if (images && images.length > 0) {
            setPrimaryImageValue(0)
        }
    }

    // const onVideoLinkChange = (value) => {

    //     if (value === ``) {
    //         setIsVideoLinkHasError(false);
    //     } else {
    //         if (value.trim()) {
    //             setIsVideoLinkHasError(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value) ? false : true);
    //         }
    //     }

    //     setVideoLink(value);
    // }

    const onVideoLinkChange = (value) => {
        // YouTube embedded link pattern (e.g., https://www.youtube.com/embed/videoId)
        const youtubePattern = /^https:\/\/www\.youtube\.com\/embed\/[a-zA-Z0-9_-]{11}$/;
    
        // Vimeo embedded link pattern (e.g., https://player.vimeo.com/video/videoId)
        const vimeoPattern = /^https:\/\/player\.vimeo\.com\/video\/\d+$/;
    
        // If value is empty
        if (value === ``) {
            setIsVideoLinkHasError(false);
        } else if (value.trim()) {
            // Validate against both patterns
            if (youtubePattern.test(value) || vimeoPattern.test(value)) {
                setIsVideoLinkHasError(false);
            } else {
                setIsVideoLinkHasError(true);
            }
        }
    
        setVideoLink(value);
    }
    


    const onPlanogramImagesSave = (images) => {
        setPlanogramUploadImage(images);
        setIsPlanogramImageSliderShow(false);
        if (images && images.length > 0) {
            setPlanogramPrimaryImageValue(0)
        }
    }

    const onPlanogramPrimaryImageChange = (event) => {
        setPlanogramPrimaryImageValue(event.target.value);
    }

    const removePlanogramImage = (index, imageId) => {
        let tempArray = planogramUploadImage;
        tempArray = tempArray.filter((file, i) => {
            return i !== index;
        })
        if (imageId) {
            setPlanogramRemovedImageIds([...planogramRemovedImageIds, imageId]);
        }

        if (tempArray.length == 0) {
            setPlanogramPrimaryImageValue(``);
        }

        setPlanogramUploadImage(tempArray)
    }

    const onFormSubmit = (event) => {
        event.preventDefault();

        let isPlanogramError = planogramRef.current.checkPlanogramErrors();

        let tempImageArray = [], tempPlanogramImageArray = [], tempArray = [], tempCountryList = [], tempPlanogramArray = [];

        // if (!selectedBudget) {
        //     setIsBudgetHasError(true);
        //     return;
        // }

        if (isVideoLinkHasError) {
            return;
        }

        if (isPlanogramError) {
            return;
        }

        if (uploadImages && uploadImages.length > 0) {
            uploadImages.map((image) => {
                if (image[`file`]) {
                    tempImageArray.push(image[`file`]);
                }
            })
        }


        productDetails?.product_usps && productDetails?.product_usps.length > 0 &&
            productDetails?.product_usps.map((data) => {
                tempArray.push(data[`name`]);
            })

        const productMarketing = {
            "budget_id": selectedBudget || "",
            "video_url": videoLink || "",
        }

        if (productDetails?.products_sold_countries && productDetails?.products_sold_countries.length > 0) {
            productDetails?.products_sold_countries.map((soldCountry) => {
                tempCountryList.push(soldCountry?.country);
            })
        }

        if (planogramUploadImage && planogramUploadImage.length > 0) {
            planogramUploadImage.map((image) => {
                if (image[`file`]) {
                    tempPlanogramImageArray.push(image[`file`]);
                }
            })
        }

        if (planogramImages && planogramImages.length > 0) {
            planogramImages.map((data) => {
                tempPlanogramArray.push({
                    "name": data?.type,
                    "image": data?.image?.image
                })
            })
        }

        const productMarketingObject = {
            'productId': productDetails?.products_id,
            "productMarketing": productMarketing,
            "additionalgallery": tempImageArray,
            "additionalgalleryDelete": removedImageIds.join(),
            "additionalimage_set_primary": parseInt(primaryImageValue) + 1,
            "usps": tempArray.length > 0 ? tempArray.join() : '',
            "countriesSoldList": tempCountryList.join(),
            "planogramgallery": tempPlanogramImageArray,
            "planogramgalleryDelete": planogramRemovedImageIds.join(),
            // "planogram_image_set_primary": parseInt(PlanogramPrimaryImageValue) + 1,
            "planogramlist": tempPlanogramArray,
            "productIngredients" : productDetails?.products_ingredients?.[0]?.name || ``
        }

        setIsLoading(true);
        productUpdate(productMarketingObject)
            .then((response) => {
                if (response?.status === 200 && response?.data?.status && response.data.data) {
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response?.data?.message);
                    setIsLoading(false);

                    setTimeout(() => {
                        if (onSectionApiCallSuccess) {
                            onSectionApiCallSuccess(true);
                        }
                    }, 300)
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                    setTimeout(() => {
                        if (onSectionApiCallSuccess) {
                            onSectionApiCallSuccess(false);
                        }
                    }, 300)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setTimeout(() => {
                    if (onSectionApiCallSuccess) {
                        onSectionApiCallSuccess(false);
                    }
                }, 300)
                console.log("We have error at productUpdate", error);
            })
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };


    const onPlanogramImagesChange = (images) => {
        setPlanogramImages(images);
    }
    return (
        <Box className={`product-marketing-wrapper`}>
            {isLoading && <Loader />}

            {isAlertMessageShow &&
                <AlertMessage
                    message={alertMessage}
                    alertType={alertMessageType}
                    isAlertShow={isAlertMessageShow}
                    onClose={onAlertClose}
                />
            }
            <div className={`heading`}>
                Marketing
            </div>

            <form className={`marketing-form form-input-control`} onSubmit={onFormSubmit}>
                <Box className={`form-control margin-bottom-28 budget-wrapper`}>
                    <Typography className={`label margin-btm-15`}>Promotional Budget (Optional)</Typography>
                    <FormControl sx={{ m: 1, minWidth: 120 }} error={isBudgetHasError}>
                        <Select
                            value={selectedBudget}
                            onChange={onBudgetChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            className={`promotional-budget`}
                        >
                            <MenuItem value="" disabled>
                                <em>Select</em>
                            </MenuItem>
                            {
                                budgetList && budgetList.length > 0 &&
                                budgetList.map((budget, index) => {
                                    return (
                                        <MenuItem key={index} value={budget?.budget_id}>{budget?.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    {
                        isBudgetHasError &&
                        <p className={`field-err-msg`}>{`Budget is invalid`}</p>
                    }
                </Box>

                <PlanogramSection
                    onSave={(images) => { onPlanogramImagesChange(images) }}
                    ref={planogramRef}
                    planogramGallery={productDetails?.products_planogram_gallery}
                />

                {/* <Box className={`form-control margin-bottom-28`}>
                    <Typography className={`label`}>Planogram(optional)</Typography>

                    <div className={`add-image-wrapper scroll-bar-theme`}>
                        {
                            planogramUploadImage && planogramUploadImage.length > 0 &&
                            planogramUploadImage.map((image, index) => {
                                return (
                                    <div key={index} className={`added-image-wrap`}>
                                        <div className={`default-radio`}>
                                            <Radio
                                                checked={parseInt(PlanogramPrimaryImageValue) === index}
                                                onChange={onPlanogramPrimaryImageChange}
                                                value={index}
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': index }}
                                            />
                                            {
                                                parseInt(PlanogramPrimaryImageValue) === index &&
                                                <span className={`primary`}>Primary</span>
                                            }
                                        </div>
                                        <div className={`add-image image`}>
                                            <img src={image['image']} />
                                        </div>

                                        <div className={`remove`} onClick={() => { removePlanogramImage(index, image?.pro_planogram_id || ``) }}><CloseIcon /></div>
                                    </div>
                                )
                            })
                        }

                        {
                            planogramUploadImage && planogramUploadImage.length !== 5 &&
                            <div className={`add-image-wrap `} onClick={() => { setIsPlanogramImageSliderShow(true); }}>
                                + Add Image
                                <p className={`img-info`}>Image must be at least 600 x 600 px.</p>
                            </div>
                        }
                    </div>
                </Box> */}

                <Box className={`form-control margin-bottom-28`}>
                    <Typography className={`label`}>Additional images/Gallery (Optional)</Typography>

                    <div className={`add-image-wrapper`}>
                        {
                            uploadImages && uploadImages.length > 0 &&
                            uploadImages.map((image, index) => {
                                return (
                                    <div key={index} className={`added-image-wrap`}>
                                        <div className={`default-radio`}>
                                            <Radio
                                                checked={parseInt(primaryImageValue) === index}
                                                onChange={onPrimaryImageChange}
                                                value={index}
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': index }}
                                            />
                                            {
                                                parseInt(primaryImageValue) === index &&
                                                <span className={`primary`}>Primary</span>
                                            }
                                        </div>
                                        <div className={`add-image image`}>
                                            <img src={image['image']} />
                                        </div>

                                        <div className={`remove`} onClick={() => { removeImage(index, image?.pro_add_gallery_id || ``) }}><CloseIcon /></div>
                                    </div>
                                )
                            })
                        }

                        {
                            uploadImages && uploadImages.length !== 3 &&
                            // <div className={`add-image-wrap`} onClick={() => { document.getElementById(`imageupload`).click() }}>
                            <div className={`add-image-wrap `} onClick={() => { setImageUploadSliderShow(true); }}>
                                + Add Image
                                <p className={`img-info`}>Image must be at least 600 x 600 px.</p>
                            </div>
                        }


                        {/* <input className={`visibility-hidden`} type='file' name='imageupload' id='imageupload' onChange={(event) => { onImageUpload(event.target.files) }} accept="image/x-png,image/gif,image/jpeg" multiple /> */}
                    </div>
                </Box>

                <Box className={`form-control margin-bottom-28 video-wrapper information-icon-block information-icon`}>
                    <Typography className={`label margin-btm-15`}>Product Video (Optional)</Typography>

                    <TextField
                        type={"text"}
                        id="videUrl"
                        name="videUrl"
                        className={`input-wrap custom-tt-2`}
                        placeholder={`Enter a youtube embed or vimeo embed URL`}
                        margin="dense"
                        spellCheck={false}
                        error={isVideoLinkHasError ? true : false}
                        tooltip={videoLink}
                        value={videoLink}
                        inputProps={{
                            onInput: (e) => {
                                onVideoLinkChange(e.target.value);
                            }
                        }}
                    />
                    {
                        isVideoLinkHasError &&
                        <p className={`field-err-msg`}>{`Video link is invalid`}</p>
                    }
                </Box>
                <div className={`button-wrapper`}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        id="product-marketing-submit-btn"
                        className={`w-100 btn-primary-after-login visibility-hidden display-none`}
                    >
                        Sign up
                    </Button>
                </div>
            </form>

            <ImageUploadSection
                isImageUploadSliderShow={isImageUploadSliderShow}
                onClose={(value) => { setImageUploadSliderShow(value) }}
                onSave={(images) => { onImagesSave(images) }}
                images={uploadImages}
            />

            {/* <PlanogramImageSection
                isImageUploadSliderShow={isPlanogramImageSliderShow}
                onClose={(value) => { setIsPlanogramImageSliderShow(value) }}
                onSave={(images) => { onPlanogramImagesSave(images) }}
                images={planogramUploadImage}
            /> */}




        </Box>
    )
}

export default ProductMarketing;