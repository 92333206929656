import React, { useState, useEffect } from "react";

// mui or UI related imports
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import Switch from '@mui/material/Switch';

// Apis related imports
import updatePorductSetting from '../../../../apis/Supplier/updatePorductSetting';

//Components related imports
import Loader from "../../Loader";
import AlertMessage from "../../AlertMessage";

const ProductSetting = (props) => {
    const { isSettingShow, onClose, onSuccess, page } = props;

    const [isLoading, setIsLoading] = useState(false),
        [userSetting, setUserSetting] = useState(props?.userSettings || {}),
        [isUspsShow, setIsUspsShow] = useState(userSetting?.pro_usps || false),
        [isDimensionalShow, setIsDimensionalShow] = useState(userSetting?.pro_dimensions || false),
        [isVolumesShow, setIsVolumesShow] = useState(userSetting?.pro_volume || false),
        [isWeightsShow, setIsWeightsShow] = useState(userSetting?.pro_weight || false),
        [isPackSizeShow, setIsPackSizeShow] = useState(userSetting?.pro_pack_size || false),
        [isBarcodeShow, setIsBarcodeShow] = useState(userSetting?.pro_barcode || false),
        [isHscodeShow, setIsHscodeShow] = useState(userSetting?.pro_hs_code || false),
        [isPalletQuantityShow, setIsPalletQuantityShow] = useState(userSetting?.pro_case_pallete_quantity || false),
        [isCasePerUnitShow, setIsCasePerUnitShow] = useState(userSetting?.pro_case_per_unit || false),
        [isPriceItemShow, setIsPriceItemShow] = useState(userSetting?.pro_priceitem || false),
        [isMsrpShow, setIsMsrpShow] = useState(userSetting?.pro_msrp || false),
        [isFlavourShow, setIsFlavourShow] = useState(userSetting?.pro_flavour || false),
        [isContainerLoadability20ft, setIsContainerLoadability20ft] = useState(userSetting?.pro_container_20 || false),
        [isContainerLoadability40ft, setIsContainerLoadability40ft] = useState(userSetting?.pro_container_40 || false),
        [isContainerLoadability20ftLoose, setIsContainerLoadability20ftLoose] = useState(userSetting?.pro_container_20_loose || false),
        [isContainerLoadability40ftLoose, setIsContainerLoadability40ftLoose] = useState(userSetting?.pro_container_40_loose || false),
        [isFullTruckLoad, setFullTruckLoad] = useState(userSetting?.pro_full_truck_load || false),
        [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [userSettingTrueCount, setUserSettingTrueCount] = useState(0);

    useEffect(() => {
        if (isSettingShow) {
            document.body.classList.add('hide-scroll');
        } else {
            document.body.classList.remove('hide-scroll');
        }

        setUserSetting(props?.userSettings || {});
        setIsUspsShow(props?.userSettings?.pro_usps || false);
        setIsDimensionalShow(props?.userSettings?.pro_dimensions || false);
        setIsVolumesShow(props?.userSettings?.pro_volume || false);
        setIsWeightsShow(props?.userSettings?.pro_weight || false);
        setIsPackSizeShow(props?.userSettings?.pro_pack_size || false);
        setIsBarcodeShow(props?.userSettings?.pro_barcode || false);
        setIsHscodeShow(props?.userSettings?.pro_hs_code || false);
        setIsPalletQuantityShow(props?.userSettings?.pro_case_pallete_quantity || false);
        setIsCasePerUnitShow(props?.userSettings?.pro_case_per_unit || false);
        setIsPriceItemShow(props?.userSettings?.pro_priceitem || false);
        setIsMsrpShow(props?.userSettings?.pro_msrp || false);
        setIsFlavourShow(props?.userSettings?.pro_flavour || false);
        setIsContainerLoadability20ft(props?.userSettings?.pro_container_20 || false);
        setIsContainerLoadability40ft(props?.userSettings?.pro_container_40 || false);
        setIsContainerLoadability20ftLoose(props?.userSettings?.pro_container_20_loose || false);
        setIsContainerLoadability40ftLoose(props?.userSettings?.pro_container_40_loose || false);
        setFullTruckLoad(props?.userSettings?.pro_full_truck_load || false);

        setUserSettingTrueCount(Object.values(props?.userSettings).reduce((a, item) => a + (item === true ? 1 : 0), 0))
        return () => {
            document.body.classList.remove('hide-scroll');
        }
    }, [isSettingShow])

    const onSettingSave = () => {
        const productSettingObject = {
            'pro_usps': isUspsShow ? 'True' : 'False',
            'pro_dimensions': isDimensionalShow ? 'True' : 'False',
            'pro_volume': isVolumesShow ? 'True' : 'False',
            'pro_weight': isWeightsShow ? 'True' : 'False',
            'pro_pack_size': isPackSizeShow ? 'True' : 'False',
            'pro_barcode': isBarcodeShow ? 'True' : 'False',
            'pro_hs_code': isHscodeShow ? 'True' : 'False',
            'pro_case_pallete_quantity': isPalletQuantityShow ? 'True' : 'False',
            'pro_case_per_unit': isCasePerUnitShow ? 'True' : 'False',
            'pro_priceitem': isPriceItemShow ? 'True' : 'False',
            'pro_msrp': isMsrpShow ? 'True' : 'False',
            'pro_flavour': isFlavourShow ? 'True' : 'False',
            'pro_container_20': isContainerLoadability20ft ? 'True' : 'False',
            'pro_container_40': isContainerLoadability40ft ? 'True' : 'False',
            'pro_container_20_loose' : isContainerLoadability20ftLoose ? 'True' : 'False',
            'pro_container_40_loose' : isContainerLoadability40ftLoose ? 'True' : 'False',
            'pro_full_truck_load' : isFullTruckLoad ? 'True' : 'False'
        }

        updatePorductSetting(productSettingObject)
            .then((response) => {
                if (response?.status === 200 && response?.data?.status && response?.data?.data) {
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response?.data?.message);
                    if (onSuccess) onSuccess(response?.data?.data)
                    if (onClose) onClose(false);
                }
            })
            .catch((err) => {
                console.log("we have error at updatePorductSetting", err);
            })
    }

    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const onSwitchChange = (flag) => {
        if (flag) {
            setUserSettingTrueCount(userSettingTrueCount + 1)
        } else {
            setUserSettingTrueCount(userSettingTrueCount - 1)
        }
    }
    return (
        <Box className={`setting-wrapper ${isSettingShow ? 'show-overlay' : ''}`}>
            <div className={`setting-sidenav ${isSettingShow ? 'show' : 'hide'}`}>
                {isLoading && <Loader />}
                {isAlertMessageShow &&
                    <AlertMessage
                        message={alertMessage}
                        alertType={alertMessageType}
                        isAlertShow={isAlertMessageShow}
                        onClose={onAlertClose}
                    />
                }
                <div className={`setting-wrap`}>
                    <div className={`heading-wrap`}>
                        <div className={`heading`}>
                            Show/hide product details
                        </div>

                        <div className={`close-icon`} onClick={() => { if (onClose) onClose(false) }}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className={`divider`}></div>

                    <div className="progress-bar-wrap">
                        <div className="progress-bar-counter">
                            <span>{userSettingTrueCount}</span> out of <span>{Object.keys(userSetting) && Object.keys(userSetting).length > 1 ? Object.keys(userSetting).length - 1 : 0}</span> are Selected!
                        </div>
                        {/* <div className="progress-bar-container">
                            <div class="progress-bar" style={{ width: "59%" }}></div>
                        </div> */}
                    </div>

                    <div className={`setting scroll-bar-theme`}>
                        <div>
                            <label>Show USPs (Unique Selling Proposition)</label>
                            <Switch
                                checked={isUspsShow}
                                onChange={() => { onSwitchChange(!isUspsShow); setIsUspsShow(!isUspsShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Product Dimensions</label>
                            <Switch
                                checked={isDimensionalShow}
                                onChange={() => { onSwitchChange(!isDimensionalShow); setIsDimensionalShow(!isDimensionalShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Product Volume</label>
                            <Switch
                                checked={isVolumesShow}
                                onChange={() => { onSwitchChange(!isVolumesShow); setIsVolumesShow(!isVolumesShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Product Weight</label>
                            <Switch
                                checked={isWeightsShow}
                                onChange={() => { onSwitchChange(!isWeightsShow); setIsWeightsShow(!isWeightsShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Case Configuration/Pack Size</label>
                            <Switch
                                checked={isPackSizeShow}
                                onChange={() => { onSwitchChange(!isPackSizeShow); setIsPackSizeShow(!isPackSizeShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Container Loadability 20 ft (Palletized)</label>
                            <Switch
                                checked={isContainerLoadability20ft}
                                onChange={() => { onSwitchChange(!isContainerLoadability20ft); setIsContainerLoadability20ft(!isContainerLoadability20ft) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Container Loadability 20 ft (Loose)</label>
                            <Switch
                                checked={isContainerLoadability20ftLoose}
                                onChange={() => { onSwitchChange(!isContainerLoadability20ftLoose); setIsContainerLoadability20ftLoose(!isContainerLoadability20ftLoose) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Container Loadability 40 ft</label>
                            <Switch
                                checked={isContainerLoadability40ft}
                                onChange={() => { onSwitchChange(!isContainerLoadability40ft); setIsContainerLoadability40ft(!isContainerLoadability40ft) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Container Loadability 40 ft (Loose)</label>
                            <Switch
                                checked={isContainerLoadability40ftLoose}
                                onChange={() => { onSwitchChange(!isContainerLoadability40ftLoose); setIsContainerLoadability40ftLoose(!isContainerLoadability40ftLoose) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div>
                            <label>Show Full Truck load (Cases)</label>
                            <Switch
                                checked={isFullTruckLoad}
                                onChange={() => { onSwitchChange(!isFullTruckLoad); setFullTruckLoad(!isFullTruckLoad) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Product Barcode</label>
                            <Switch
                                checked={isBarcodeShow}
                                onChange={() => { onSwitchChange(!isBarcodeShow); setIsBarcodeShow(!isBarcodeShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Product HS Code</label>
                            <Switch
                                checked={isHscodeShow}
                                onChange={() => { onSwitchChange(!isHscodeShow); setIsHscodeShow(!isHscodeShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Pallet Quantity</label>
                            <Switch
                                checked={isPalletQuantityShow}
                                onChange={() => { onSwitchChange(!isPalletQuantityShow); setIsPalletQuantityShow(!isPalletQuantityShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Units per carton/case</label>
                            <Switch
                                checked={isCasePerUnitShow}
                                onChange={() => { onSwitchChange(!isCasePerUnitShow); setIsCasePerUnitShow(!isCasePerUnitShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show B2B Price</label>
                            <Switch
                                checked={isPriceItemShow}
                                onChange={() => { onSwitchChange(!isPriceItemShow); setIsPriceItemShow(!isPriceItemShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Retail Price</label>
                            <Switch
                                checked={isMsrpShow}
                                onChange={() => { onSwitchChange(!isMsrpShow); setIsMsrpShow(!isMsrpShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div>
                            <label>Show Variant/Flavour</label>
                            <Switch
                                checked={isFlavourShow}
                                onChange={() => { onSwitchChange(!isFlavourShow); setIsFlavourShow(!isFlavourShow) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                    </div>


                    <div className={`button-wrapper flex flex-justify-content-end save-btn`}>
                        <Button
                            variant="contained"
                            size="large"
                            className={`w-100 btn-primary-after-login`}
                            onClick={() => { onSettingSave() }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default ProductSetting;