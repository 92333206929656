const setOnBoardingObject = (onBoardingObject) => {
    return {
        type: "SET_ONBOARDING_OBJECT",
        payload: onBoardingObject
    }
}

const getOnBoardingObject = () => {
    return {
        type: "GET_ONBOARDING_OBJECT"
    }
}


export default {
    setOnBoardingObject,
    getOnBoardingObject
}