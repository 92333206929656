// React router dom related imports
import { Route, Navigate } from "react-router-dom";

//Components related imports
import Main from '../../component/common/Supplier/Main'
import MyBrand from '../../component/common/Supplier/MyBrand'
import Catalogues from '../../component/common/Supplier/Catalogues'
import Profile from '../../component/common/Supplier/Profile';
import EditProduct from "../../component/common/Supplier/EditProduct";
import CatalogueDetail from "../../component/common/Supplier/CatalogueDetail";
import WebsitePreview from "../../component/common/WebsitePreview/LandingPage";
import WebsitePreivewDetails from "../../component/common/WebsitePreview/DetailsPage";
import Support from "../../component/common/Support";

//ProtectedRoute routes
import ProtectedRoute from "../protectedRoute";

const supplier = [
   <Route path="/" element={<Main />} key="Main">

      <Route
         element={<ProtectedRoute />}
      >
         <Route
            path="myproducts"
            element={<MyBrand route={`brand`} />}
            key="brand"
         />
         <Route
            path="catalogues"
            element={<Catalogues route={`catalogues`} />}
            key="catalogues"
         />

         <Route
            path="catalogue/detail"
            element={<CatalogueDetail route={`catalogueDetail`} />}
            key="catalogueDetail"
         />

         <Route
            path="profile"
            element={<Profile route={`profile`} />}
            key="profile"
         />

         <Route
            path="editproduct/:productId"
            element={<EditProduct route={`editproduct`} />}
            key="editproduct"
         />

      </Route>,
   </Route>,
   <Route
      path="webcatalogue/:catalogueId"
      element={<WebsitePreview route={`webCataloguePreview`} />}
      key="webCatalogue"
   />,
   <Route
      path="webcatalogue/details/:catalogueId/:productId"
      element={<WebsitePreivewDetails route={`webCataloguePreview`} />}
      key="WebsitePreivewDetails"
   />,
   <Route
      path="*"
      element={<Navigate to="/login" replace route={`login`} />}
      key="Login"
   />,
   <Route
      path="support"
      element={<Support route={`support`} />}
      key="support"
   />
];

export default supplier;