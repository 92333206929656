
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const brandUpdate = async (brandId, brandName, tagLine, brandLogo, brandBanner, banneridentifier = 2) => {

    const headers = await getApiHeader() || {};

    if(!brandId) {
        return {
            error: true,
            message: 'brandId mandatory in brandUpdate function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in brandUpdate function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();

    if(brandName) formData.append("name", brandName);
    formData.append("tagline", tagLine);
    if(brandLogo) formData.append("b_logo", brandLogo);
    console.log(banneridentifier)
    if(banneridentifier == 2){
        console.log(banneridentifier)
        formData.append("b_banner", brandBanner || ``);
    }
    
    
    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/brand/brand/${brandId}`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default brandUpdate;