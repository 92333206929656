//import React from "react";

// React related imports
import React, { useState, useEffect } from "react";

// mui or UI related imports
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

//Other imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// React route related imports
import { Link } from "react-router-dom";

//Redux related imports
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

//Components related imports
import AlertMessage from "../AlertMessage";
import Loader from "../Loader";

//Apis related imports
import resetPasswordLink from "../../../apis/resetPasswordLink";

const schema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("email is required"),
  })
  .required();

const ForgotPassword = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { app } = props,
    [user, setUser] = useState({ email: "" }),
    [isAlertMessageShow, setAlternateMessageShow] = useState(false),
    [alertMessageType, setAlertMessageType] = useState(``),
    [alertMessage, setAlertMessage] = useState(``),
    [isLoading, setIsLoading] = useState(false),
    [isEmailCheck, setIsEmailCheck] = useState(false);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(user);
  }, [user]);

  const onSubmit = (data) => {
    setAlertMessage(true);
    resetPasswordLink(data?.email)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          // alert(response?.data?.message);
          setAlertMessage(false);
          setAlertMessageType("success");
          setAlertMessage(response?.data?.message);
          setAlternateMessageShow(true);
        } else {
          if (
            response &&
            response.response &&
            response.response.data &&
            response.response.data.message
          ) {
            setAlertMessage(false);
            setAlertMessageType("error");
            setAlternateMessageShow(true);
            if (response?.message === `Network Error`) {
              setAlertMessage(`No internet connection`);
            } else {
              setAlertMessage(response?.response?.data?.message);
            }
          }
        }
      })
      .catch((error) => {
        setAlertMessage(false);
        console.log("Checking error", error);
      });
  };

  const onAlertClose = (value) => {
    setAlternateMessageShow(value);
  };

  return (
    <Box className={"forgot-password-component-wrap bg-modal-window"}>
      {isLoading && <Loader />}
      {isAlertMessageShow && (
        <AlertMessage
          message={alertMessage}
          alertType={alertMessageType}
          isAlertShow={isAlertMessageShow}
          onClose={onAlertClose}
        />
      )}

      <Box>
        <Typography align="center" variant="h1" className={`first-heading`}>
          Reset your password
        </Typography>
        <Typography
          textAlign={"center"}
          variant="h2"
          className={`second-heading`}
        >
          Enter the associated email address, we’ll send you a reset link.
          {/* Choose a secure new password to complete the reset. */}
        </Typography>
      </Box>

      <form className={`form-input-control`} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

        <Typography className={`label`}>Email Address</Typography>

        <Box className={`form-control email-control`}>
          <TextField
            id="email"
            name="email"
            placeholder={`e.g johndoe@company.com`}
            margin="dense"
            {...register("email")}
            error={errors.email ? true : false}
            // className={`margin-bottom-28`}
            {...register("email", {
              onChange: (event) => {
                if (event.target.value) setIsEmailCheck(true);
              },
            })}
          />
          {
            !errors.email && isEmailCheck &&
            <img src={`https://discoverrr-s3-bucket.s3.me-south-1.amazonaws.com/frontend/icon/Check.svg`} alt="verified image" />
          }
          {/* <input {...register("email")} /> */}
          <p className={`field-err-msg`}>{errors.email?.message}</p>
        </Box>

        {/* <input type="submit" /> */}
        <Button
          type="submit"
          variant="contained"
          size="large"
          className={`w-100 button-primary margin-bottom-28 margin-top-28 ${isValid ? `` : `disabled`}`}
          disabled={isValid ? false : true}
        >
          Reset Password
        </Button>
        <div
          className={`flex flex-items-center flex-justify-content-center label-wrap`}
        >
          Back To
          <Link to={`/login`} className={`login-label skip-btn`}>
            Login
          </Link>
        </div>
      </form>
    </Box>
  );
};

//map state
const mapStateToProps = (state) => {
  return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
