
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import { CONFIG } from '../../helpers/getApiConfig';

const getCatalogueCustomWebsiteProductsIds = async (catalogueID) => {

    const headers = await getApiHeader() || {};;

    if (!catalogueID) {
        return {
            error: true,
            message: 'catalogueID mandatory in getCatalogueCustomWebsiteProductsIds function'
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in getCatalogueCustomWebsiteProductsIds function' 
        }
    }

    const instance = axios.create();
    instance.defaults.timeout = CONFIG.timeout || 15000;

    return instance.get(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/catalogue/catalogue-customweb-productIDs/${catalogueID}`, { headers })
        .then((xhrResponse) => {
            return xhrResponse
        })
        .catch((error) => {
            return error
        });
}

export default getCatalogueCustomWebsiteProductsIds;