var apiBaseDiscoverrr = {
    "production": 'https://api.discoverrr.com/api',
    "staging": 'https://api.discoverrr.com/api',
    "development": 'https://api.discoverrr.com/api'
};

exports.apiBaseDiscoverrr = apiBaseDiscoverrr;

var CONFIG = {
    env: 'staging',
    //production|staging|development
    timeout: 15000
};
exports.CONFIG = CONFIG;

var getDiscoverrrAPIBase = function getDiscoverrrAPIBase() {
    var env = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : CONFIG.env;
    var url = apiBaseDiscoverrr[["production", "staging", "development"].indexOf(env) !== -1 ? env : CONFIG.env];
    return url;
};

exports.getDiscoverrrAPIBase = getDiscoverrrAPIBase;