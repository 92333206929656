
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const brandCategoryUpdate = async (ProductCategoryID,name) => {

    const headers = await getApiHeader() || {};;

    if(!ProductCategoryID) {
        return {
            error: true,
            message: 'ProductBrandID mandatory in catalogueDelete function' 
        }
    }
    if(!name) {
        return {
            error: true,
            message: 'ProductBrandID mandatory in catalogueDelete function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in catalogueDelete function' 
        }
    }


    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;
    var formData = new FormData();
    if(name) formData.append("name", name);

    return instance.put(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/brand/brandcategoryupdate/${ProductCategoryID}`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default brandCategoryUpdate;