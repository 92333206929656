import store from '../store/index.jsx';
import readCookie from './readCookie';
import tokenRefresh from '../apis/tokenRefresh';
import createCookie from './createCookie';
import userAction from "../store/action/userAction";

const getUserToken = async (userToken) => {
    
    let promise = new Promise((resolve, reject) => {
        if (userToken) {
            if (!readCookie(`discoverrrToken`)) {
                tokenRefresh(readCookie(`discoverrrRefreshToken`))
                    .then((response) => {
                        if (response?.status === 200 && response?.data) {

                            createCookie("discoverrrToken", response?.data?.access, 120);

                            store.dispatch(userAction.setUser({
                                "token": response?.data
                            }))

                            resolve(response?.data?.access);
                        }
                    })
                    .catch((error) => {
                        resolve({});
                    })
            } else {
                resolve(userToken);
            }
        } else {
            resolve(userToken);
        }

    })

    return promise;
}
const getApiHeader = async () => {

    const reduxStore = store.getState();
    const headers = {};
    const userToken = reduxStore?.usersData?.user?.token?.access || ``;

    let result = await getUserToken(userToken);

    if(result) headers['Authorization'] = `Bearer ${result}`;

    return headers;
}

export default getApiHeader;