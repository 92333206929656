import React, { useState, useEffect, Fragment } from 'react';

// mui or UI related imports
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Rename from '../Rename';
import FlavourProductUpdate from '../../../../../../apis/Supplier/flavourProductUpdate';

//import other component related imports
import AlertMessage from "../../../../AlertMessage";
import EditIcon from '../../../../../../icons/svgs/editIcon';
import TrashIcon from '../../../../../../icons/svgs/trashIcon';
import ConformationPopup from "../../../ConformationPopup";

//Api related imports
import flavourProductDelete from '../../../../../../apis/Supplier/flavourProductDelete';
import getFlavourSingleList from '../../../../../../apis/Supplier/getFlavourSingleList';

const FlavourSection = (props) => {

    const [FlavourProductList, setFlavourProductList] = useState([]);
    const [isAlertMessageShow, setAlternateMessageShow] = useState(false),
        [alertMessageType, setAlertMessageType] = useState(``),
        [alertMessage, setAlertMessage] = useState(``),
        [isLoading, setIsLoading] = useState(false),
        [isConfirmationPopupShow, setIsConfirmationPopupShow] = useState(false),
        [conformationPopupSuccessBtnText, setConformationPopupSuccessBtnText] = useState(``),
        [conformationPopupCancelBtnText, setConformationPopupCancelBtnText] = useState(``),
        [conformationPopupText, setConformationPopText] = useState(``),
        [conformationPopupType, setConformationType] = useState(``),
        [reNameshow, setreNameshow] = useState(false),
        [editdetail, setEditdetail] = useState(``);

    useEffect(() => {
        setIsLoading(true);
        BrandProductSingleList()
    }, []);


    const BrandProductSingleList = () => {
        setIsLoading(true);
        getFlavourSingleList()
            .then((response) => {
                if (
                    response &&
                    response.status === 200 &&
                    response.data.status &&
                    response.data.data &&
                    response.data.data.length > 0
                ) {
                    setIsLoading(false);
                    setFlavourProductList(response.data.data);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("We have error", error);
            });
    }

    const ondeleteClick = (e, value) => {

        setIsConfirmationPopupShow(true);
        setConformationPopText(`Are you sure you want to delete this Flavour?`);
        setConformationPopupSuccessBtnText(`Yes`);
        setConformationPopupCancelBtnText(`No`);
        setConformationType(value);

    }

    const oneditClick = (e, value) => {
        setEditdetail(value)
        setreNameshow(true);
    }

    const reNameclosepopup = (value) => {
        setreNameshow(value);
    }
    const renamePopupSuccess = (value) => {
        BrandProductSingleList()
    }

    const onConformationPopupClose = (value) => {
        setIsConfirmationPopupShow(value);
    }
    const onAlertClose = (value) => {
        setAlternateMessageShow(value);
    };

    const contentupdate = (value) => {
        setIsLoading(true);
        FlavourProductUpdate(editdetail?.flavour_id, value?.name)
            .then((response) => {
                if (response && response.status === 200 && response.data.status) {

                    setreNameshow(false);
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        BrandProductSingleList()
                    }, 500);

                }
                else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((err) => {
                console.log("we have error at PersonaUpdate", err);
                setIsLoading(false);
            });
    };

    const onConformationPopupSuccess = () => {
        console.log(conformationPopupType)
        setIsConfirmationPopupShow(false);
        flavourProductDelete(conformationPopupType)
            .then((response) => {
                if (response && response.status === 200 && response.data && response.data.status) {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("success");
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        getFlavourSingleList()
                    }, 500);
                } else {
                    setIsLoading(false);
                    setAlternateMessageShow(true);
                    setAlertMessageType("error");
                    if (response?.message === `Network Error`) {
                        setAlertMessage(`No internet connection`);
                    } else {
                        setAlertMessage(response?.data?.message)
                    }
                }
            })
            .catch((err) => {
                console.log("we have error on Product Action", err);
            })
    }

    return (
        <Fragment>

            <Box className={`flavour-section-wrapper`}>
                {
                    isConfirmationPopupShow &&
                    <ConformationPopup
                        heading={conformationPopupText}
                        cancelBtnText={conformationPopupCancelBtnText}
                        submitBtnText={conformationPopupSuccessBtnText}
                        onCancel={onConformationPopupClose}
                        onSubmit={onConformationPopupSuccess}
                    />
                }
                {isAlertMessageShow &&
                    <AlertMessage
                        message={alertMessage}
                        alertType={alertMessageType}
                        isAlertShow={isAlertMessageShow}
                        onClose={onAlertClose}
                    />
                }
                {
                    reNameshow &&
                    <Rename
                        renamepopupclose={reNameclosepopup}
                        renameSuccess={renamePopupSuccess}
                        popupData={editdetail}
                        headingLineText={'Edit Flavour Name'}
                        contentupdate={contentupdate}
                        placeHolderText={'Enter Flavour Name'}
                        validationText={'Flavour Name is required'}
                        renameType={'Flavour'}
                        SubmitbuttonText={'Update'}
                    />
                }
                <div className={`heading-wrap m-mt-20`}>
                    <div className={`heading`}>
                        My Variants/Flavours
                    </div>
                </div>

                <div className={`flavour-display-wrap product-table table-scroll-wrap`}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={`table-heading sixtyp`}>Name</TableCell>
                                    <TableCell className={`table-heading twentyp`} align="left">Number of Products</TableCell>
                                    <TableCell className={`table-heading twentyp`} align="left">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    FlavourProductList && FlavourProductList.length > 0 &&
                                    FlavourProductList.map((brandproduct, index) => {
                                        return (
                                            <Fragment key={index} >
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" className={`category-name`}>
                                                    {brandproduct.name}
                                                    </TableCell>
                                                    <TableCell align="left" className={`category-count`}>{brandproduct.product_count}</TableCell>
                                                    <TableCell align="left">
                                                        <div className={`category-icons-wrap`}>
                                                            <div className={`edit-icon`} onClick={(e) => { oneditClick(e, brandproduct) }} tooltip="Edit">
                                                                <EditIcon />
                                                            </div>
                                                            {

                                                                brandproduct.product_count == 0 ?
                                                                    <div className={`delete-icon`} onClick={(e) => { ondeleteClick(e, brandproduct.flavour_id) }} tooltip="Delete">
                                                                        <TrashIcon />
                                                                    </div>
                                                                    : <></>


                                                            }
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </Fragment>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>
        </Fragment>
    )
}

export default FlavourSection;