
import React, { useState, useEffect } from "react";

//Redux related imports
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Navigate, Outlet } from 'react-router-dom';
import createCookie from "../helpers/createCookie";
import readCookie from "../helpers/readCookie";
import tokenRefresh from "../apis/tokenRefresh"
import userAction from "../store/action/userAction";
import Loader from "../component/common/Loader"

const ProtectedRoute = (props) => {
    const dispatch = useDispatch();

    const refreshToken = readCookie(`discoverrrRefreshToken`) || ``;
    const [user, setUser] = useState(props?.usersData?.user || {});
    const { redirectPath = '/login', children } = props;

    useEffect(() => {
        if (refreshToken && !user.token) {
            tokenRefresh(refreshToken)
                .then((response) => {
                    if (response?.status && response?.data) {
                        dispatch(userAction.setUser({
                            "token": response?.data
                        }))
                        setUser({
                            "token": response?.data
                        })
                        createCookie("discoverrrToken", response?.data?.access, 120);
                    }
                })
                .catch((err) => {
                    console.log("we have error at tokenRefresh", err);
                })
        }
    }, [])

    if (!user?.token && !refreshToken) {
        return <Navigate to={redirectPath} replace />;
    } else if (!user?.token && refreshToken) {
        return <Loader />
    } else if (user?.token) {
        return children ? children : <Outlet />;
    }
 
}

//map state
const mapStateToProps = (state) => {
    return state;
};

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);