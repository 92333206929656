
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const productDelete = async (productID) => {

    const headers = await getApiHeader() || {};;

    if(!productID) {
        return {
            error: true,
            message: 'productID mandatory in productAction function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in productAction function' 
        }
    }

    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();
    
    return instance.delete(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/products/productActionUpdate/${productID}`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default productDelete;