
import axios from 'axios';

import getApiHeader from '../../helpers/getApiHeader';

import {CONFIG} from '../../helpers/getApiConfig';

const productAddDraft = async (brandId, brandName, parentProductId = ``) => {
    
    const headers = await getApiHeader() || {};;

    if(!brandId) {
        return {
            error: true,
            message: 'brandId mandatory in productAddDraft function' 
        }
    }

    if(!brandName) {
        return {
            error: true,
            message: 'brandName mandatory in productAddDraft function' 
        }
    }

    if(!headers?.Authorization) {
        return {
            error: true,
            message: 'authentication mandatory in productAddDraft function' 
        }
    }
    const instance = axios.create();  
    instance.defaults.timeout = CONFIG.timeout || 15000;

    var formData = new FormData();

    if(brandName) formData.append("name", brandName);
    if(parentProductId) formData.append("parent", parentProductId);
    
    return instance.post(`${process.env.REACT_APP_DISCOVERRR_APIBASE}/products/productAddDraft/${brandId}`,formData, {headers})
    .then((xhrResponse) => {
        return xhrResponse
    })
    .catch((error) => {
        return error
    });
}

export default productAddDraft;